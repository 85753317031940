import React, { useEffect } from "react";
import { Content, SubHeadWrap, BlogPage } from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

/* eslint-disable jsx-a11y/anchor-is-valid */

function CountryOfMediators(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header>
        <BlogPage>
          <HeadWrap>
            What Kind of Training is Required to Build a Country of Mediators?
          </HeadWrap>
          <br />
          <br />
          <Content>
            <b
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontStyle: "italic",
              }}
            >
              {" "}
              Authored by : Aprameya Manthena{" "}
            </b>
            <br />
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              Introduction
            </SubHeadWrap>
            <p>
              Mediation is gaining ground as a mainstream dispute resolution
              process for settling commercial and financial disputes across the
              world. Clients who are involved in high-stakes disputes prefer the
              services of mediators and arbitrators who promise fast, efficient,
              private and confidential resolution. Therefore, the proper
              training of mediators is an important step in the encouragement of
              mediation.
            </p>
            <br />
            <p>
              The United Nations Convention on International Settlement
              Agreements Resulting from Mediation, 2019 (Singapore Convention),
              to which India is a signatory, ensures that settlements reached
              through mediation are enforceable, in a manner similar to arbitral
              awards. This makes private mediation an attractive option for
              those looking for time and cost- effective ways to resolve
              personal or business disputes. Section 43D of the 2019 Amendment
              to the Arbitration and Conciliation Act,{" "}
              <span style={{ fontStyle: "italic" }}>1996</span> enumerates the
              duties and functions of the Arbitration Council in the promotion
              and encouragement of mediation alongside other modes of
              alternative dispute resolution.
            </p>
            <br />
            <p>
              The International Mediation Institute (IMI) <sup>1</sup> as a
              non-profit charity registered in The Hague, is well-recognised as
              being one of the few institutions that set competent standards for
              mediation as a profession. The IMI does not deliver training or
              assessment; organisations submit their programs for appraisal
              against the IMI Competency Criteria and the quality is assessed by
              an independent international Appraisal Committee. The Asia-Pacific
              Centre for Arbitration and Mediation (APCAM) is another platform
              that provides accreditation based on the respective Experience
              Qualification Path (EQP) or the Qualifying Assessment Programs
              (QAP) to maintain high standards of professionalism, conduct and
              rigour in the conduct of the neutrals. <sup>2</sup>
            </p>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              Eligibility
            </SubHeadWrap>
            <p>
              The eligibility and proper training of mediators has been
              enumerated in particular instances. The Supreme Court in the{" "}
              <span style={{ fontStyle: "italic" }}>
                Salem Bar Association <sup>3</sup>
              </span>{" "}
              case provided a list of persons eligible to be appointed as
              mediators which included retired judges of the High Courts,
              Supreme Court, District &amp; Sessions Court judges, civil court
              judges, legal practitioners with fifteen years standing at the
              Supreme Court, High Court or District Courts experts or other
              professionals with at least 15 years of experience or retired
              senior Executives and Bureaucrats.
            </p>
            <br />
            <p>
              The draft Mediation Bill, 2021 underlines the importance of
              regulating the profession of mediators and provides for the
              setting up of a Mediation Council whose functions include
              registration of mediators, grading mediation service providers,
              laying down standards for the professional conduct of mediators,
              mediation service providers, and mediation institutes, recognising
              mediation service providers and mediation institutes which train,
              educate and certify mediators. <sup>4</sup>
            </p>
            <br />
            <p>
              The numbers of mediators and mediation centres in the country seem
              to be increasing steadily, particularly in some states more than
              others. <sup>5</sup> Karnataka, Kerala, Madhya Pradesh,
              Maharashtra and Uttar Pradesh have the highest number of cases
              settled through mediation. The numbers of judicial officer
              mediators, lawyer mediators and other mediators look promising but
              the field is unsaturated and requires greater participation.
              Training and awareness programmes in mediation could be useful in
              encouraging people to choose mediation as a profession.
            </p>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              Training in Mediation
            </SubHeadWrap>
            <p>
              The then Chief Justice of India, in 2005, set up the Mediation and
              Conciliation Project Committee popularly known as MCPC under the
              aegis of the Supreme Court for resolving disputes under Section 89
              of the Code of Civil Procedure. The MCPC offers various training
              modules including the 40 hours Mediation Training Programme,
              Awareness Programme, Capsule Training Programme, Referral Judges
              Training Programme and the Training of Trainers among other
              offerings. <sup>6</sup> The MCPC is well-regarded in the field of
              mediation training and employs the expert services of a range of
              trainers from different parts of the country. A sub-committee
              consisting of experts drawn from different mediation centres in
              the country led by J. Cyriac Joseph, former Judge of the Supreme
              Court developed a uniform Mediation Training Manual <sup>7</sup> .
              This model has been replicated by private mediation centres and
              institutes across the country to train mediators.
            </p>
            <br />
            <p>
              A mediator uses specialized communication and negotiation
              techniques in order to reach settlements that are mutually
              acceptable. The mediator is expected to identify the barriers to
              the agreement between the parties. Mediation also involves
              facilitating the parties’ needs, interests and goals in the final
              resolution of the dispute. Since this is a structured process
              which involves introductions, joint sessions, caucuses and
              building towards agreement, the mediator has to be trained in
              managing the various stages in the dispute resolution process and
              developing the most suitable terms of agreement. According to the
              MCPC brochure (2015), any person who undergoes 40 hours of
              training as a mediator, has at least 10 mediations resulting in a
              settlement and at least 20 mediations overall is eligible to be
              accredited as a qualified mediator.
            </p>
            <br />
            <p>
              There are no strict or binding procedural rules to be followed
              during mediation, therefore the mediator is free to bring
              creativity and flexibility to the conduct of the mediation. The
              parties interact in person, in the presence of the mediator, in
              order to reach quick, effective and harmonious settlements.
            </p>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              In conclusion
            </SubHeadWrap>
            <p>
              While there are numerous certification courses and expert mediator
              training available in varied fields, the law captures the evolving
              contours of the professional capacities, case management,
              subject-matter expertise and conduct required of the mediator to
              bring a dispute to a conclusive settlement and hence, clarifies
              the contours of the training programmes required to build a
              country of mediators.
            </p>
            <Block />
            <p>
              <sup>1</sup> IMI’s quality standards are established and
              maintained by independent international task forces, including
              users, highly-experienced mediators, leading judges, providers,
              trainers and educators from countries around the world. The
              standards are applied in practice by service provider
              organisations that are approved by the Appraisal Committee to run
              ‘Qualifying Assessment Programs’ (QAPs). QAPs then assess and
              qualify experienced and competent mediators for IMI
              Certification. https://imimediation.org/about/vision-and-mission/
            </p>
            <p>
              <sup>2</sup> https://apcam.asia/our_services/accreditation/
            </p>
            <p>
              <sup>3</sup> Salem Advocate Bar Association vs Union of India,
              (2005) 6 SCC 344
            </p>
            <p>
              <sup>4</sup>{" "}
              https://prsindia.org/billtrack/the-mediation-bill-2021
            </p>
            <p>
              <sup>5</sup>
              https://nalsa.gov.in/statistics/settlement-through-mediation-report-april-2021-to-march-2022
            </p>
            <p>
              <sup>6</sup>{" "}
              https://main.sci.gov.in/pdf/mediation/Brochure%20-%20MCPC.pdf
            </p>
            <p>
              <sup>7</sup>{" "}
              https://main.sci.gov.in/pdf/mediation/MT%20MANUAL%20OF%20INDIA.pdf
            </p>
          </Content>
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default CountryOfMediators;

export const ContentExample = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
`;

export const Block = styled.div`
  width: 193px;
  height: 0;
  border: 1px solid ${COLORS.INPUT_BLACK};
  margin: 3px;
  display: inline-block;
  margin-top: 12px;
  margin-bottom: 12px;
  font-family: ${theme.fonts.primaryFontRegular};
`;

export const HeadWrap = styled.div`
  text-align: center;
  font-size: 18px;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontBold};
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 25px;
  }
`;

export const SubHeadWrapHeading = styled.div`
  text-align: left;
  font-size: 20px;
  padding-left: 1.5%;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontRegular};
`;

export const TextLinesData = styled.div`
  padding-left: 30px;
  margin-left: 30px;
  text-align: justify;
`;
