import React, { useState, useEffect } from "react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import StatusBadge from "../../../common/StatusBadge";
import { Images } from "../../../../assets/images";
import {
  DropDown,
  Icon,
  Actions,
  Heading,
  Header,
  Title,
} from "../styles";
import ClaimTabel from "./ClaimTable";
import _ from "lodash";
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    [theme.breakpoints.up(640)]: {
      border: `solid 0.5px #e1e3ee`,
    },
  },
  content: {
    margin: `0 !important`,
    "& .MuiExpansionPanelSummary-content.Mui-expanded": {
      margin: `0 !important`,
    },
  },
  details: {
    display: "flex",
    flexDirection: "column",
    padding: `0 15px 15px !important`,
    [theme.breakpoints.up(640)]: {
      padding: `0 21px 21px !important`,
    },
  },
}));

export default function ClaimBox({
  title,
  claimItems,
  status,
  enableCheckBox = false,
  expand,
  showRespondantView,
  isRespondent,
  errorText,
  formikRef,
  negotiationRound,
  caseId,
  roundNumber,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(expand);
  const [items, setItems] = useState(claimItems ? claimItems : []);
  const [trigggerStateChange, setTrigggerStateChange] = useState(false);
  const themes = useTheme();
  const isBigScreen = useMediaQuery(themes.breakpoints.up(769));

  const itemsChecked = (checked, item, setFieldValue, name, oldValue) => {
    if (_.isEmpty(item?.acceptedOffer) && item?.offer) {
      item.offer.status = checked ? "accepted" : "offered";
      setFieldValue(`${name}.status`, checked ? "accepted" : "offered");
      if (checked) {
        if (item?.previousOffer) {
          setFieldValue(`${name}.claimValue`, item?.previousOffer);
        } else if (item?.claimValue) {
          setFieldValue(`${name}.claimValue`, item?.claimValue);
        }
      } else {
        setFieldValue(`${name}.claimValue`, oldValue);
      }
      setTrigggerStateChange(true);
    }
  };

  const selectAll = (e, setFieldValue, initialValues) => {
    isRespondent && showRespondantView
      ? items.respondentClaims.map((item, index) =>
          itemsChecked(
            e,
            item,
            setFieldValue,
            `items[${index}].offer`,
            initialValues?.items[index]?.offer?.claimValue
          )
        )
      : items.claimantClaims.map((item, index) =>
          itemsChecked(
            e,
            item,
            setFieldValue,
            `items[${index}].offer`,
            initialValues?.items[index]?.offer?.claimValue
          )
        );
  };

  useEffect(() => {
    if (trigggerStateChange) {
      setTrigggerStateChange(false);
    }
    setItems(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigggerStateChange]);

  const checkIsFromMakeOffer = (items, index) => {
    return items[index]?.some((el) => el?.offer?.statusAlais === "makeOffer");
  };

  const checkboxEnable = (items, index) => {
    return items[index]?.some((el) => el?.offer?.status === "accepted");
  };

  return (
    <ExpansionPanel className={classes.root} expanded={expanded}>
      <ExpansionPanelSummary
        classes={{ content: classes.content }}
        onClick={() => setExpanded((e) => !e)}
      >
        <Header>
          <div className="title-container">
            <Heading
              style={{
                marginBottom: "unset",
                textTransform: "none",
              }}
            >
              <Title>{title}</Title>
            </Heading>
            {!status && roundNumber === 4 ? (
              <StatusBadge
                text={"This is your Final Round !"}
                status={"round4"}
              />
            ) : (
              ""
            )}
            {status && (!expanded || isBigScreen) && (
              <StatusBadge
                text={
                  status === "success"
                    ? "Negotiation Successful"
                    : errorText
                    ? errorText
                    : "Negotiation In Progress"
                }
                status={status}
              />
            )}
          </div>
          <Actions>
            <DropDown>
              <Icon
                src={Images.dropDownArrow}
                alt="dropdown"
                expanded={expanded}
              />
            </DropDown>
          </Actions>
        </Header>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <div>
          {items.claimantClaims && (
            <ClaimTabel
              {...{
                allowEdit: checkIsFromMakeOffer(items, "claimantClaims"),
                disableChekBox:
                  (showRespondantView && isRespondent) || !enableCheckBox,
                enableCheckBox:
                  negotiationRound > 1 ||
                  enableCheckBox ||
                  showRespondantView ||
                  checkboxEnable(items, "claimantClaims"),
                itemsChecked,
                selectAll,
                items: items.claimantClaims,
                heading: isRespondent ? "Claimant Claim" : "Your Claim",
                formikRef,
                isRespondent: false,
                caseId,
                roundNumber,
                status,
                errorText,
              }}
            />
          )}
          {showRespondantView && (
            <ClaimTabel
              {...{
                allowEdit: checkIsFromMakeOffer(items, "respondentClaims"),
                enableCheckBox: enableCheckBox || showRespondantView,
                disableChekBox: !enableCheckBox || !expand,
                itemsChecked,
                selectAll,
                items: items.respondentClaims,
                isRespondent: true,
                heading: isRespondent ? "Your offer" : "Respondent Offer",
                formikRef,
                caseId,
                roundNumber,
              }}
            />
          )}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
