import React, { createRef, useEffect, useRef, useState } from 'react'
import theme from '../../../../assets/theme'
import { Checkbox, Tooltip, makeStyles } from '@material-ui/core'
import { Images } from '../../../../assets/images'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { Formik, FieldArray } from 'formik'
import DescriptionModal from '../../../internal/CreateCase3/components/DescriptionModal'
import { CustomInputField } from '../../../common/FormInputs'
import NumberFormat from 'react-number-format'
import COLORS from '../../../../assets/Colors'
import {
  formatNumberToMetrics,
  getErrorMessage
} from '../../../../helpers/functions'
import CaseService from '../../../../services/CaseService'
import _ from 'lodash'
import AWSService from '../../../../services/AWSService'
import useLoader from '../../../../hooks/useLoader'
import { useSnackbar } from 'notistack'
import useAlert from '../../../../hooks/useAlert'
import StatusBadge from '../../../common/StatusBadge'

const SmallClaimTable = ({
  items,
  enableCheckBox,
  selectAll,
  allowEdit,
  itemsChecked,
  disableChekBox,
  heading,
  formikRef,
  isRespondent,
  caseId,
  roundNumber,
  status,
  errorText
}) => {
  const useTooltipStyles = makeStyles(() => ({
    tooltip: {
      margin: 0
    }
  }))
  const { hideAlert, showAlert } = useAlert()

  const classes = useTooltipStyles()
  const [modal, setModal] = useState({
    state: false
  })
  const fileRef = useRef([])
  const [length, setLength] = useState(1)
  const [documents, setDocuments] = useState([])
  const { setLoader } = useLoader()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (items?.length > 0) {
      setLength(items?.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items?.length])

  useEffect(() => {
    if (fileRef.current.length !== length) {
      // add or remove refs
      fileRef.current = Array(length)
        .fill()
        .map((_, i) => fileRef.current[i] || createRef())
    }
  }, [fileRef.current.length, length])

  const showwarning = value => {
    return Number(value?.offer?.claimValue) === Number(value?.previousOffer)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps

  async function getCaseDocuments () {
    try {
      const result = await CaseService.getcaseDocuments(
        caseId,
        '?page=1&perPage=10000'
      )
      if (result?.data)
        setDocuments(result.data.filter(doc => doc?.meta?.round === roundNumber))
    } catch (error) {}
  }

  useEffect(() => {
    if (items?.length) {
      getCaseDocuments()
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  function renderUploadBox ({ item, index }) {
    const document = documents.find(
      doc =>
        doc?.meta?.claimItemId === item?.id &&
        (isRespondent
          ? doc?.meta?.role === 'respondent'
          : doc?.meta?.role === 'claimant') &&
        doc?.meta?.round === roundNumber
    )
    if (
      allowEdit &&
      !(
        item?.offer?.status === 'accepted' ||
        item?.acceptedOffer?.status === 'accepted'
      )
    ) {
      if (_.isEmpty(document)) {
        return (
          <HyperLink onClick={() => fileRef?.current[index]?.current?.click()}>
            Add
          </HyperLink>
        )
      } else {
        return (
          <Row style={{ marginTop: 0, width: 123 }}>
            <HyperLink onClick={() => window.open(document?.url)}>
              View
            </HyperLink>
            <Seperator />
            <HyperLink
              onClick={() => fileRef?.current[index]?.current?.click()}
            >
              Update
            </HyperLink>
          </Row>
        )
      }
    } else {
      return !_.isEmpty(document) || item.description_document_url ? (
        <HyperLink
          onClick={() =>
            window.open(
              !_.isEmpty(document)
                ? document.url
                : item.description_document_url
            )
          }
        >
          {'Download'}
        </HyperLink>
      ) : (
        <div>-</div>
      )
    }
  }

  async function addOrUpdateDoc ({ file, claimItemId }) {
    try {
      const update = documents.find(
        doc => doc?.meta?.claimItemId === claimItemId
      )
      const errorMessage = 'Invalid File Type'
      if (file?.type !== 'application/pdf') throw errorMessage
      setLoader({ state: true, message: `Uploading file ${file.name} ...` })
      const payload = {
        key: file.name
      }
      const s3Response = await AWSService.getS3URL(payload)
      if (s3Response.url) {
        const url = await AWSService.uploadToS3(s3Response.url, file)
        let payload = {
          url
        }
        if (!_.isEmpty(update)) {
          const docId = update.id
          const response = await CaseService.caseUpdateDocument(
            payload,
            caseId,
            docId
          )
          if (response) {
            const newDocs = documents.map(doc => ({
              ...doc,
              url: doc?.id === docId ? url : doc?.url
            }))
            setDocuments(newDocs)
          }
        } else {
          payload.meta = {
            round: roundNumber,
            claimItemId,
            role: isRespondent ? 'respondent' : 'claimant'
          }
          const response = await CaseService.caseAddDocument(payload, caseId)
          if (response?.uploadedDocument) {
            setDocuments(s => [...s, response.uploadedDocument])
          }
        }
        enqueueSnackbar('Successfully Uploaded', { variant: 'success' })
      }
    } catch (error) {
      const message = getErrorMessage(error)
      enqueueSnackbar(message, { variant: 'error' })
    } finally {
      setLoader({ state: false })
    }
  }
  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        items: items
      }}
      enableReinitialize
      validateOnBlur
      validateOnChange
      validateOnMount
    >
      {({ values, handleChange, setFieldValue, errors, initialValues }) => (
        <>
          <ClaimBoxHeading>
            <Title>
              <div style={{ marginLeft: 4 }}>
                {enableCheckBox && (
                  <Tooltip
                    title={
                      disableChekBox ||
                      values?.items?.every(el =>
                        el?.offer
                          ? el?.offer?.status === 'accepted'
                          : el?.acceptedOffer?.status === 'accepted'
                      )
                        ? ''
                        : 'Accept All'
                    }
                    placement='top'
                    classes={classes}
                  >
                    <div>
                      <Checkbox
                        icon={
                          <img
                            alt='checked'
                            style={
                              disableChekBox
                                ? {
                                    filter: 'grayscale(100%) !important',
                                    opacity: 0.5
                                  }
                                : {}
                            }
                            src={Images.checkBox}
                          />
                        }
                        checked={values?.items?.every(el =>
                          el?.offer
                            ? el?.offer?.status === 'accepted'
                            : el?.acceptedOffer?.status === 'accepted'
                        )}
                        onChange={ev => {
                          const checked = ev.target.checked
                          showAlert({
                            heading: 'Please confirm!',
                            desc: `You are about to accept the offer made by the other Party. Are you sure, you want to proceed? Claim items once accepted cannot be unaccepted.`,
                            primaryBtnText: 'Yes',
                            secondaryBtnText: 'No',
                            clickSecondarybtn: () => hideAlert(),
                            clickPrimaryBtn: () => {
                              selectAll(checked, setFieldValue, initialValues)
                              hideAlert()
                            },
                            descriptionTextStyle: {
                              textAlign: 'left'
                            },
                            isOpen: true,
                            onDialogClose: () => hideAlert()
                          })
                        }}
                        disabled={
                          disableChekBox ||
                          values?.items?.every(el =>
                            el?.offer
                              ? el?.offer?.status === 'accepted'
                              : el?.acceptedOffer?.status === 'accepted'
                          )
                        }
                        checkedIcon={
                          <img
                            alt='checked'
                            style={
                              disableChekBox
                                ? {
                                    filter: 'grayscale(100%) !important',
                                    opacity: 0.5
                                  }
                                : {}
                            }
                            src={Images.checkBoxSelected}
                          />
                        }
                      />
                    </div>
                  </Tooltip>
                )}
              </div>
              <div style={{ marginLeft: 8 }}>{heading}</div>
            </Title>
            <div style={{ marginLeft: 4 }}>
              {status && (
                <StatusBadge
                  text={
                    status === 'success'
                      ? 'Negotiation Successful'
                      : errorText
                      ? errorText
                      : 'Negotiation In Progress'
                  }
                  status={status}
                />
              )}
            </div>
          </ClaimBoxHeading>

          <ClaimTable>
            {values?.items && (
              <FieldArray
                name='items'
                render={() =>
                  values?.items?.map((item, index) => (
                    <TR
                      isEven={index % 2 === 0}
                      key={index}
                      accepted={
                        item?.offer
                          ? item?.offer?.status === 'accepted'
                          : item?.acceptedOffer?.status === 'accepted'
                      }
                    >
                      {enableCheckBox && (
                        <Tooltip
                          title={
                            disableChekBox ||
                            item?.acceptedOffer?.status === 'accepted' ||
                            item?.offer?.status === 'accepted'
                              ? ''
                              : 'Accept claim line item'
                          }
                          placement='top'
                          classes={classes}
                        >
                          <div
                            style={{
                              width: 44,
                              marginTop: '-5px'
                            }}
                          >
                            <Checkbox
                              icon={
                                <img
                                  alt='checked'
                                  style={
                                    (disableChekBox ||
                                    item?.acceptedOffer?.status === 'accepted'
                                      ? {
                                          filter: 'grayscale(100%) !important',
                                          opacity: 0.5
                                        }
                                      : {},
                                    { width: 15, height: 15 })
                                  }
                                  src={Images.checkBox}
                                />
                              }
                              checked={
                                item?.offer
                                  ? item?.offer?.status === 'accepted'
                                  : item?.acceptedOffer?.status === 'accepted'
                              }
                              onChange={ev => {
                                const checked = ev.target.checked
                                showAlert({
                                  heading: 'Please confirm!',
                                  desc: `You are about to accept the offer made by the other Party. Are you sure, you want to proceed? Claim items once accepted cannot be unaccepted.`,
                                  primaryBtnText: 'Yes',
                                  secondaryBtnText: 'No',
                                  clickSecondarybtn: () => hideAlert(),
                                  clickPrimaryBtn: () => {
                                    itemsChecked(
                                      checked,
                                      item,
                                      setFieldValue,
                                      `items[${index}].offer`,
                                      initialValues?.items[index].offer
                                        .claimValue
                                    )
                                    hideAlert()
                                  },
                                  descriptionTextStyle: {
                                    textAlign: 'left'
                                  },
                                  isOpen: true,
                                  onDialogClose: () => hideAlert()
                                })
                              }}
                              disabled={
                                disableChekBox ||
                                item?.acceptedOffer?.status === 'accepted' ||
                                item?.offer?.status === 'accepted'
                              }
                              checkedIcon={
                                <img
                                  alt='checked'
                                  style={
                                    (disableChekBox ||
                                    item?.acceptedOffer?.status === 'accepted'
                                      ? {
                                          filter: 'grayscale(100%) !important',
                                          opacity: 0.5
                                        }
                                      : {},
                                    { width: 15, height: 15 })
                                  }
                                  src={Images.checkBoxSelected}
                                />
                              }
                            />
                          </div>
                        </Tooltip>
                      )}

                      <Details>
                        <LabelValueContainer>
                          <Label>Claim Item</Label>
                          <Value>
                            <div className='element'>{item.title}</div>
                          </Value>
                        </LabelValueContainer>

                        <LabelValueContainer>
                          <Label>Claim Description</Label>
                          <Value>
                            {item.description ||
                            item?.offer?.reason ||
                            (allowEdit &&
                              !(
                                item?.offer?.status === 'accepted' ||
                                item?.acceptedOffer?.status === 'accepted'
                              )) ? (
                              <HyperLink
                                onClick={() =>
                                  setModal({
                                    state: true,
                                    name: `items[${index}].offer.reason`,
                                    index,
                                    des:
                                      item?.offer?.reason ||
                                      item.description ||
                                      '',
                                    editable:
                                      allowEdit &&
                                      !(
                                        item?.offer?.status === 'accepted' ||
                                        item?.acceptedOffer?.status ===
                                          'accepted'
                                      )
                                  })
                                }
                              >
                                {item?.offer?.reason || item.description
                                  ? 'View'
                                  : 'Add'}
                              </HyperLink>
                            ) : (
                              <div>-</div>
                            )}
                          </Value>
                        </LabelValueContainer>

                        <LabelValueContainer>
                          <Label>Claim Value</Label>
                          <Value>
                            <div
                              className='claim-table'
                              style={{ textAlign: 'left' }}
                            >
                              {allowEdit &&
                              (item?.offer
                                ? item?.offer?.status !== 'accepted'
                                : item?.acceptedOffer?.status !==
                                  'accepted') ? (
                                <>
                                  <InputContainer>
                                    <NumberFormat
                                      thousandSeparator
                                      thousandsGroupStyle='lakh'
                                      isNumericString={true}
                                      decimalScale={2}
                                      allowNegative={false}
                                      data-tip
                                      data-for={`input_${index}`}
                                      error={
                                        errors?.items &&
                                        errors?.items[index]?.offer?.claimValue
                                          ? true
                                          : false
                                      }
                                      className={'input-white'}
                                      value={item.offer?.claimValue}
                                      // fixedDecimalScale
                                      type={'text'}
                                      customInput={CustomInputField}
                                      name={`items[${index}].offer.claimValue`}
                                      onChange={e => {
                                        if (e.target.value) {
                                          const numberText = e.target.value
                                            ?.split(',')
                                            .join('')
                                          setFieldValue(
                                            `items[${index}].offer.claimValue`,
                                            Number(numberText)
                                          )
                                        }
                                      }}
                                      onBlur={e => {
                                        if (e.target.value) {
                                          const numberText = e.target.value
                                            ?.split(',')
                                            .join('')
                                          setFieldValue(
                                            `items[${index}].offer.claimValue`,
                                            Number(numberText).toFixed(2)
                                          )
                                        }
                                      }}
                                      style={{
                                        maxWidth: 120
                                      }}
                                      variant='outlined'
                                      min={0}
                                    />
                                    <NumberFormat
                                      isNumericString={true}
                                      decimalScale={2}
                                      allowNegative={false}
                                      value={item.offer?.claimValue}
                                      type={'text'}
                                      name={`items[${index}].offer.claimValue`}
                                      onChange={handleChange}
                                      style={{ maxWidth: 110, display: 'none' }}
                                      min={0}
                                    />
                                  </InputContainer>
                                  {!!item?.offer?.suggestedClaimValue &&
                                    item.unit && (
                                      <ReactTooltip
                                        className='tool-tip'
                                        place='bottom'
                                        backgroundColor='#e1edf9'
                                        id={`input_${index}`}
                                        arrowColor='#007aff'
                                        borderColor='#007aff'
                                        type='warning'
                                        effect='solid'
                                      >
                                        {/* <div className="name">JA Suggested</div> */}
                                        <div className='message'>
                                          {item.unit}{' '}
                                          {formatNumberToMetrics(
                                            parseFloat(
                                              item?.offer?.suggestedClaimValue
                                            )
                                              .toFixed(2)
                                              .toString(),
                                            item.unit
                                          )}{' '}
                                          ( JustAct SmartSuggest )
                                        </div>
                                      </ReactTooltip>
                                    )}

                                  {showwarning(
                                    values?.items[index],
                                    initialValues?.items[index].previousOffer
                                  ) && (
                                    <div className='waring-msg'>
                                      You've entered the same value as the offer
                                      made by the other Party
                                    </div>
                                  )}
                                </>
                              ) : (
                                <div className='element '>
                                  {item?.offer
                                    ? formatNumberToMetrics(
                                        parseFloat(item?.offer?.claimValue)
                                          .toFixed(2)
                                          .toString(),
                                        item?.unit
                                      )
                                    : item?.acceptedOffer
                                    ? formatNumberToMetrics(
                                        parseFloat(
                                          item?.acceptedOffer?.claimValue
                                        )
                                          .toFixed(2)
                                          .toString(),
                                        item?.unit
                                      )
                                    : formatNumberToMetrics(
                                        parseFloat(item.claimValue)
                                          .toFixed(2)
                                          .toString(),
                                        item?.unit
                                      )}
                                </div>
                              )}
                            </div>
                          </Value>
                        </LabelValueContainer>

                        <LabelValueContainer>
                          <Label>Unit</Label>
                          <Value>
                            <div className='element'>{item.unit}</div>
                          </Value>
                        </LabelValueContainer>

                        <LabelValueContainer>
                          <Label>Supporting Document</Label>
                          <Value>
                            {renderUploadBox({ item, index })}
                            <input
                              ref={fileRef?.current[index]}
                              onChange={e =>
                                addOrUpdateDoc({
                                  file: e?.target?.files[0],
                                  round: roundNumber,
                                  claimItemId: item?.id
                                })
                              }
                              type='file'
                              accept='.pdf'
                              style={{ display: 'none' }}
                            />
                          </Value>
                        </LabelValueContainer>
                      </Details>
                    </TR>
                  ))
                }
              />
            )}
          </ClaimTable>

          <DescriptionModal
            {...{
              modal,
              setModal,
              handleChange,
              values,
              setFieldValue,
              readOnly: true
            }}
          />
        </>
      )}
    </Formik>
  )
}

export default SmallClaimTable

const ClaimTable = styled.table`
  width: 100%;
  & .tool-tip {
    border: solid 0.5px #007aff;
    & .name {
      font-family: ${theme.fonts.primaryFontBold};
      font-size: 12px;
      font-weight: bold;
      line-height: 1.83;
      color: #007aff;
    }
    & .message {
      font-family: ${theme.fonts.primaryFontSemiBold};
      font-size: 12px;
      font-weight: 600;
      color: #293461;
    }
  }
  .input-white {
    font-size: 12px;
    & input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    & input[type='number'] {
      -moz-appearance: textfield;
    }
  }
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

const InputContainer = styled.div`
  padding-right: 15px;
`

const TR = styled.tr`
  display: flex;
  border-radius: 10px;
  background-color: #f9f9f9;
  margin-bottom: 15px;
  padding: 10px 15px 0 6px;
  width: 100%;

  .element {
    opacity: ${props => (props.accepted ? '0.3' : '1')};
    font-family: ${theme.fonts.primaryFontSemiBold};
    font-size: 12px;
    color: #293461;
  }
`

const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  color: #00838c;
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
`

const Seperator = styled.div`
  height: 18px;
  width: 1px;
  background-color: ${COLORS.INPUT_BORDER};
`

const ClaimBoxHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 12px;
  font-weight: bold;
  line-height: 1.83;
  color: #293461;
  text-transform: capitalize;
  margin-bottom: 10px;
`

const Title = styled.div`
  display: flex;
  align-items: center;
`

const LabelValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-bottom: 20px;
  &:nth-child(5n) {
    margin-bottom: 15px;
  }
`

const Label = styled.div`
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 10px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.7;
  margin-bottom: 6px;
  color: ${COLORS.INPUT_LABEL};
  text-align: left;
  & span {
    padding: 0px;
  }
`

const Value = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 12px;
  color: #293461;
  & span {
    padding: 0px;
  }
  & .waring-msg {
    color: ${COLORS.WARNING_MSG_GREEN};
    font-size: 10px;
    white-space: nowrap;
    position: absolute;
  }
`

const Details = styled.div`
  width: 100%;
  display: flex;
  padding-left: 5px;
  flex-wrap: wrap;
`
