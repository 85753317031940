import React from 'react';
import styled from 'styled-components';
import COLORS from '../../../assets/Colors';
import theme from '../../../assets/theme';
import { H2, Light } from './Styles';

function OurAdvantages(props) {
    return (
        <>
            
            <H2 style={{textAlign:"center"}}>Our Advantages</H2>
            <BodyWrap>
            <ContentBox>
            <ImageWrap src={require("../../../assets/images/independent.jpg")} alt="Independent"/>
                    <TextWrap>
                    
                    <div>Independent</div>
                    <Light>JustAct is not an agent of any of the disputing parties. It aims to deliver dispassionate settlements in the most convenient way.</Light>
                    </TextWrap>
            </ContentBox>

            <ContentBox>
            <ImageWrap src={require("../../../assets/images/technology.jpg")} alt="Technology and AI"/>
                    <TextWrap>
                    <div>Technology & AI</div>
                    <Light>We use technology to make the process of resolution accessable and data driven insights to encourage parties to resolve faster to the benefit of all.</Light>
                    </TextWrap>
            </ContentBox>

            <ContentBox>
            <ImageWrap src={require("../../../assets/images/professionals.jpg")} alt="Trained Professionals"/>
                    <TextWrap>
                    
                    <div>Trained Resolution Experts</div>
                    <Light>JustAct's resolution process is guided by a set of trained, empanelled and trusted experts. This provides both parties the best chance of getting a just resolution within the shortest time.</Light>
                    </TextWrap>
            </ContentBox>

            <ContentBox>
            <ImageWrap src={require("../../../assets/images/agreement.jpg")} alt="Agreement"/>
                    <TextWrap>
                    <div>Enforceable Agreements & Awards</div>
                    <Light>The outcome of a resolution on JustAct is a settlement agreement  or an arbitral award which is enforceable in a court of law.</Light>
                    </TextWrap>
                    
            </ContentBox>
            </BodyWrap>       
                    
            
            
        </>
    );
}

export default OurAdvantages;

const ImageWrap=styled.img `
margin:20px;
width:400px;
height:200px;
border: 1px solid gray;

@media(max-width:600px){
    margin:20px;
width:300px;
height:150px;
}

`
const BodyWrap= styled.div`
display:flex;
flex-direction:column;
flex-wrap:wrap;
align-items:center;
justify-content:center;
font-size:18px;
padding:1%;
color: ${COLORS.BTN_GREEN};
font-family: ${theme.fonts.primaryFontBold};
width:100%;
@media(max-width:700px){
    flex-direction:column;
}
`
const ContentBox=styled.div`
display:flex;
flex-direction:row;
align-items:center;
@media(max-width:700px){
    flex-direction:column;
};
@media(max-width:1024px){
    flex-direction:column;
}
`

const TextWrap=styled.div `
display:flex;
flex-direction:column;
flex-wrap:wrap;
width:500px;
margin-left:20px;
@media(max-width:1024px){
    width:unset;
    text-align:center;
    margin:20px;
}
`