import React from "react";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import { H2, RuleContainer, TextLinesData } from "./Styles";
import { Helmet } from "react-helmet";
import { HyperLink } from "../../common/FormInputs";

function MediationClauseRules(props) {
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>
          Mediation and Conciliation Model Clause - Dispute Resolution Clauses -
          JustAct
        </title>
        <meta
          name="description"
          content="Learn about the JustAct Mediation & Conciliation Model Clause."
        />
        <meta
          name="keywords"
          content="Mediation and Conciliation Clause Rules, Mediation and Conciliation Model Clause Rules, Mediation Clause Rules, Conciliation Clause Rules, Online Mediation, JustAct"
        />
      </Helmet>

      <div>
        <Header />
        <H2> MODEL CLAUSE</H2>
        <RuleContainer>
          <p>
            <span>
              <strong>1.</strong>{" "}
              <strong>JustAct Model Mediation Clause</strong>
            </span>
          </p>
          <br />

          <TextLinesData>
            <ul class="first-li-before" style={{ marginBottom: 10 }}>
              <li>
                <p>
                  <span>
                    In the event of any dispute arising out of or in connection
                    with anything contained in the Agreement or otherwise, such
                    dispute shall be referred to by Parties, for{" "}
                    <HyperLink
                      onClick={() =>
                        window.open(`https://justact.co.in/mediation-rules`)
                      }
                    >
                      Mediation
                    </HyperLink>{" "}
                    through JustAct [www.justact.co.in] (“Institution”).&nbsp;
                  </span>
                </p>
              </li>
            </ul>

            <ul class="second-li-before" style={{ marginBottom: 10 }}>
              <li>
                <p>
                  <span>
                    The parties may use or any other ADR institution provided
                    such institution is as agreed mutually by the parties.&nbsp;
                  </span>
                </p>
              </li>
            </ul>

            <ul class="third-li-before" style={{ marginBottom: 10 }}>
              <li>
                <p>
                  <span>
                    The Institution shall, among other things, appoint a neutral
                    Mediator/Conciliator and provide a platform for settlement
                    of dispute between the parties as per the mediation Rules of
                    the Institution.&nbsp;
                  </span>
                </p>
              </li>
            </ul>

            <ul class="four-li-before">
              <li>
                <p>
                  <span>
                    Should this clause create a contradiction with a statutory
                    requirement or a judicial mandate to resolve certain types
                    of disputes through specific courts, the statutory
                    requirement shall prevail.&nbsp;
                  </span>
                </p>
              </li>
            </ul>
          </TextLinesData>
        </RuleContainer>
        <Footer />
      </div>
    </>
  );
}

export default MediationClauseRules;
