import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { ReactMic } from "react-mic";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import axios from "axios";
import { Tooltip } from "@material-ui/core";
import useDictation from "../../../hooks/useDictation";
import { randomAlphaNumeric } from "../../../helpers/functions";

const Index = ({
  timeInterval,
  settings,
  caseId,
  mergedBlobURL,
  setMergedBlobURL,
  isLocalTranscriptionServer,
}) => {
  const {
    setComment,
    setIsTranscribing,
    setIsRecording,
    isRecording,
    transcribedData,
    setTranscribedData,
    mediaIdArr,
    setMediaIdArr,
    transcriptionReceived,
    setTranscriptionReceived,
    disabled,
    setDisabled,
    recordStatus,
    setRecordStatus,
    mainMediaIdArr,
    setMainMediaIdArr,
  } = useDictation();
  const transcribeTimeout = timeInterval ? parseInt(timeInterval * 60) : 5;
  const [stopTranscriptionSession, setStopTranscriptionSession] =
    useState(false);
  // const [transcribedData, setTranscribedData] = useState([]);

  const stopTranscriptionSessionRef = useRef(stopTranscriptionSession);
  stopTranscriptionSessionRef.current = stopTranscriptionSession;
  const intervalRef = useRef(null);
  const [blobURL, setBlobURL] = useState([]);
  // const [isRecording, setIsRecording] = useState(false);
  // const [isTranscribing, setIsTranscribing] = useState(false);

  useEffect(() => {
    if (transcribedData?.length) {
      const convertToString = transcribedData.join("");
      const sentences = convertToString.split(/[.?!]+\s+/);
      // capitalize the first letter after a dot in each sentence
      const capitalizedSentences = sentences.map((sentence) => {
        return sentence.charAt(0).toUpperCase() + sentence.slice(1);
      });
      const transcribedText = {};
      transcribedText["text"] = capitalizedSentences.join("").trim();
      transcribedText["audioUrl"] = "";
      transcribedText["textType"] = "Question";
      transcribedText["speaker"] = "speaker 1";
      setComment(transcribedText);
    }
  }, [transcribedData]); // eslint-disable-line react-hooks/exhaustive-deps

  function startRecording() {
    setStopTranscriptionSession(false);
    setIsRecording(true);
    setDisabled(true);
    setRecordStatus(true);
    setTranscribedData([]);
    intervalRef.current = setInterval(
      transcribeInterim,
      transcribeTimeout * 1000
    );
  }

  function stopRecording() {
    clearInterval(intervalRef.current);
    setStopTranscriptionSession(true);
    setIsRecording(false);
    setRecordStatus(false);
    // setTranscribedData([]);
  }

  function transcribeInterim() {
    clearInterval(intervalRef.current);
    setIsRecording(false);
  }

  function onData(recordedBlob) {
    // console.log('chunk of real-time data is: ', recordedBlob);
  }

  function onStop(recordedBlob) {
    transcribeRecording(recordedBlob);
    setIsTranscribing(true);
  }

  const transcribeRecording = async (recordedBlob) => {
    const headers = {
      "content-type": "multipart/form-data",
    };
    if (!settings.transcriptionModelParams) {
      settings.transcriptionModelParams = { modelType: "base.en" };
    }
    let transcriptionParams = { ...settings.transcriptionModelParams };

    const formData = new FormData();
    // formData.append("model_size", "base");

    formData.append("audio_data", recordedBlob.blob, "temp_recording");
    const mediaId = await randomAlphaNumeric();
    transcriptionParams["mediaId"] = mediaId;
    formData.append("transcriptionParams", JSON.stringify(transcriptionParams));

    if (mediaIdArr[caseId]) mediaIdArr[caseId].push(mediaId);
    else mediaIdArr[caseId] = [mediaId];
    setMediaIdArr(mediaIdArr);
    if (mainMediaIdArr[caseId]) mainMediaIdArr[caseId].push(mediaId);
    else mainMediaIdArr[caseId] = [mediaId];
    setMainMediaIdArr(mainMediaIdArr);
    setBlobURL((prevBlob) => [...prevBlob, recordedBlob.blobURL]);
    axios
      .post(
        isLocalTranscriptionServer === 1
          ? `https://transcription-api.justact.co.in/orderDictate`
          : isLocalTranscriptionServer === 2
          ? `https://transcription.justact.co.in/orderDictate`
          : `https://transcription-api.justact.co.in/orderDictate`,
        formData,
        {
          headers,
        }
      )
      .then((res) => {
        let position = mainMediaIdArr[caseId].indexOf(res.data["mediaId"]);
        setTranscribedData((prevArray) => {
          const newArray = [...prevArray];
          newArray[position] = res.data["text"];
          return newArray;
        });

        setIsTranscribing(false);
        mediaIdArr[caseId] = mediaIdArr[caseId].filter(function (item) {
          return item !== res.data["mediaId"];
        });
        if (
          stopTranscriptionSessionRef.current &&
          mediaIdArr[caseId] &&
          mediaIdArr[caseId].length === 0
        ) {
          transcriptionReceived[caseId] = true;
          setTranscriptionReceived(transcriptionReceived);
          setBlobURL([]);
        }
      })
      .catch((err) => {
        setTranscribedData((oldData) => [...oldData, " "]);
        if (mediaId) {
          mediaIdArr[caseId] = mediaIdArr[caseId].filter(function (item) {
            return item !== mediaId;
          });
        }
        if (
          stopTranscriptionSessionRef.current &&
          mediaIdArr[caseId] &&
          mediaIdArr[caseId].length === 0
        ) {
          transcriptionReceived[caseId] = true;
          setTranscriptionReceived(transcriptionReceived);
          setBlobURL([]);
        }
      });

    intervalRef.current = setInterval(
      transcribeInterim,
      transcribeTimeout * 1000
    );

    if (!stopTranscriptionSessionRef.current) {
      setIsRecording(true);
    }
  };

  useEffect(() => {
    async function mergeBlobs(blobUrls) {
      try {
        // Fetch data from each Blob URL and convert to Blobs
        const blobPromises = blobUrls.map(async (url) => {
          let response = await fetch(url);
          return await response.blob();
        });

        const blobs = await Promise.all(blobPromises);

        // Concatenate the Blobs into a single Blob
        const mergedBlob = new Blob(blobs, { type: "audio/mp3" });
        // Create a new Blob URL from the concatenated Blob
        const mergedBlobUrl = URL.createObjectURL(mergedBlob);
        return mergedBlobUrl;
      } catch (error) {
        console.error("Error merging Blobs:", error);
        return null;
      }
    }

    if (blobURL?.length) {
      mergeBlobs(blobURL)
        .then((mergedBlobUrl) => {
          setMergedBlobURL(mergedBlobUrl);
          // Use the mergedBlobUrl as needed (e.g., set it to an audio element's src)
        })
        .catch((error) => {
          console.error("Error merging Blobs:", error);
        });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blobURL]);

  return (
    <Container>
      {!recordStatus && (
        <Tooltip title="Start Dictation" placement="top">
          <ActionButtonRecord
            disabled={disabled}
            onClick={startRecording}
            outlined={false}
          >
            {"Record"}
          </ActionButtonRecord>
        </Tooltip>
      )}
      {recordStatus && (
        <Tooltip title="Stop Dictation" placement="top">
          <ActionButton onClick={stopRecording} outlined={true}>
            Stop
          </ActionButton>
        </Tooltip>
      )}
      <ReactMic
        record={isRecording}
        className="react-mic"
        onStop={onStop}
        onData={onData}
        mimeType="audio/mp3"
        // height={50}
        strokeColor="#00838c"
        backgroundColor="#f6f6ef"
        visualSetting="none"
      />
    </Container>
  );
};

export default Index;

const ActionButton = styled.button`
  border-radius: 4px;
  background-color: ${({ outlined }) =>
    outlined ? COLORS.LOGOUT_RED : COLORS.BTN_GREEN};
  color: ${COLORS.PRIMARY_WHITE};
  border: ${({ outlined }) =>
    outlined
      ? `1px solid ${COLORS.LOGOUT_RED}`
      : `1px solid ${COLORS.BTN_GREEN}`};
  width: 138px;
  height: 30px;
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 14px;
  text-align: center;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  margin-right: 3px;
`;

const ActionButtonRecord = styled.button`
  border-radius: 4px;
  background-color: ${({ disabled }) =>
    disabled ? "#cccccc" : COLORS.BTN_GREEN};
  color: ${({ disabled }) =>
    disabled ? "#666666" : `${COLORS.PRIMARY_WHITE}`};
  border: ${({ disabled }) =>
    disabled ? "1px solid #999999" : `1px solid ${COLORS.BTN_GREEN}`};
  width: 138px;
  height: 30px;
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 14px;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
  margin-right: 3px;
`;

const Container = styled.div`
  display: flex;
  .react-mic {
    width: 5%;
  }
`;
