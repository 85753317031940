import React from "react";
import { CustomRadioGroup } from "../../../common/FormInputs";
import {
  StyledFormElementsWrapper,
  StyledFormElementsWrapper1,
  StyledLabel
} from "../Styles";

const CaseTypeRadioButton = ({ values, handleChange, resolutionKind }) => {
  return (
    <>
      <StyledFormElementsWrapper1 style={{ marginBottom: 15, marginTop: 15 }}>
        <StyledLabel>Select a Case Type</StyledLabel>
        <CustomRadioGroup
          radioInputs={[
            { label: "Negotiation", value: "negotiation" },
            { label: "Mediation", value: "mediation" },
            { label: "Arbitration", value: "arbitration" }
          ]}
          name={"resolutionKind"}
          handleChange={e => handleChange(e, "resolutionKind")}
          {...{ values }}
        />
      </StyledFormElementsWrapper1>
      {(resolutionKind === "mediation" || resolutionKind === "arbitration") && (
        <StyledFormElementsWrapper style={{ marginBottom: 15, marginTop: 15 }}>
          {resolutionKind === "mediation" ? (
            <StyledLabel>Select Mediator Type</StyledLabel>
          ) : (
            <StyledLabel>Select Arbitrator Type</StyledLabel>
          )}
          <div className="mediator-type">
            <CustomRadioGroup
              radioInputs={[
                {
                  label: `${
                    values?.resolutionKind === "mediation"
                      ? "JustAct Institutional Mediation – with JustAct Mediation Panel & Rules"
                      : "JustAct Institutional Arbitration – with JustAct Arbitral Panel & Rules"
                  }`,
                  value: "normal"
                },
                {
                  label: `${
                    values?.resolutionKind === "mediation"
                      ? "Adhoc Mediation – with parties’ agreed upon Mediation Panel & Rules"
                      : "Adhoc Arbitration – with parties’ agreed upon Arbitral Panel & Rules"
                  }`,
                  value: "adhoc"
                }
                // {
                //   label: `I want JustAct's ${
                //     values?.resolutionKind === "mediation"
                //       ? "mediator"
                //       : "arbitrator"
                //   } to resolve the dispute
                //       according to JustAct Rules`,
                //   value: "normal",
                // },
                // {
                //   label: `I submit that the parties wish to use the JustAct platform for conducting the ${
                //     values?.resolutionKind
                //   } ${values.resolutionKind === "mediation"
                //       ? "with a"
                //       : "with an"}
                //   ${
                //     values?.resolutionKind === "mediation"
                //       ? "mediator"
                //       : "arbitrator"
                //   } of our choice; Rules have been agreed between us`,
                //   value: "adhoc",
                // },
              ]}
              name={"subscriptionKind"}
              handleChange={e => handleChange(e, "subscriptionKind")}
              {...{ values }}
            />
          </div>
        </StyledFormElementsWrapper>
      )}
    </>
  );
};

export default CaseTypeRadioButton;
