import React from "react";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import { RuleContainer, H2, TextLines } from "./Styles";
import { Helmet } from "react-helmet";
import { HyperLink } from "../../common/FormInputs";

function ArbitrationRules(props) {
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>
          Arbitration Rules - Arbitration Dispute Resolution - Arbitration and
          Conciliation
        </title>
        <meta
          name="description"
          content="Learn about the JustAct Arbitration & Conciliation Rules. "
        />
        <meta
          name="keywords"
          content="Arbitration Rules, Arbitration Dispute Resolution, Arbitration and Conciliation Rules, Arbitration Mediation and Conciliation, Online Dispute Resolution, JustAct"
        />
      </Helmet>

      <div>
        <Header />
        <H2> Arbitration Rules</H2>
        <RuleContainer>
          <div>
            <span>
              <strong>1.</strong> <strong>Definitions</strong>
            </span>
          </div>

          <TextLines style={{ padding: "20px" }}>
            <div>
              <span>
                <strong>1.1.</strong>&nbsp;‘Act’ refers to the Arbitration and
                Conciliation Act, 1996.
              </span>
            </div>

            <div>
              <span>
                <strong>1.2.</strong>&nbsp;‘Arbitral Award’ refers to the award
                passed by an arbitral tribunal and includes interim award
              </span>
            </div>

            <div style={{ marginBottom: "10px" }}>
              <span>
                <strong>1.3.</strong>&nbsp;‘Court’ refers to court as defined by
                the Act
              </span>
            </div>

            <div style={{ lineHeight: "1.4", marginBottom: "10px" }}>
              <span>
                <strong>1.4.</strong>&nbsp;‘Date of reference’ means the date on
                which the arbitrators have received intimation of their
                appointment to the tribunal&nbsp;
              </span>
            </div>

            <div style={{ marginBottom: "10px" }}>
              <span>
                <strong>1.5.</strong>&nbsp;‘List’ refers to the{" "}
                <HyperLink
                  onClick={() =>
                    window.open(`https://justact.co.in/neutral/arbitrators`)
                  }
                >
                  {" "}
                  list of Arbitrators
                </HyperLink>{" "}
                empanelled as arbitrators with JustAct
              </span>
            </div>

            <div style={{ marginBottom: "15px" }}>
              <span>
                <strong>1.6.</strong>&nbsp;‘Rules’ refers to the JustAct
                Arbitration Rules and any amendments made to the said rules from
                time to time.
              </span>
            </div>

            <div style={{ lineHeight: "1.4", marginBottom: "20px" }}>
              <span>
                <strong>1.7.</strong>&nbsp;&nbsp;‘Party’ means a Party which
                includes any individual, firm, company, government, governmental
                agency and any other organization including its representatives,
                agents, power agents or attorney(s) appointed by them and
                authorised to file a claim and/ or depose before the arbitral
                panel for the specific dispute in reference.
              </span>
            </div>

            <div style={{ lineHeight: "1.4" }}>
              <span>
                <strong>1.8.</strong>&nbsp;&nbsp;‘Tribunal’ refers to a sole
                arbitrator or a panel of arbitrators appointed to arbitrate over
                a dispute. Any singular noun shall be understood to refer to the
                plural where appropriate. Any pronoun shall be understood as
                gender neutral
              </span>
            </div>
          </TextLines>
          <div>
            <span>
              <strong>2.</strong> <strong>Applicability of rules</strong>
            </span>
          </div>

          <TextLines style={{ padding: "20px" }}>
            <div>
              <span>
                <strong>2.1.</strong>&nbsp; Rules of JustAct Arbitration will
                apply to all institutional arbitration conducted through JustAct
                including:&nbsp;
              </span>
            </div>

            <TextLines style={{ padding: "13px" }}>
              <div style={{ marginBottom: "10px" }}>
                <span>
                  <strong>2.1.1.</strong>&nbsp;Arbitration carried out by
                  JustAct using JustAct empanelled individual arbitrator(s),
                  and&nbsp;
                </span>
              </div>

              <div style={{ lineHeight: "1.4" }}>
                <span>
                  <strong>2.1.2.</strong>&nbsp;Arbitration carried out under
                  arrangements with arbitral houses/ centres and utilising
                  arbitrators empanelled with such centres or otherwise
                </span>
              </div>
            </TextLines>

            <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>2.2.</strong>&nbsp;For ad hoc arbitration carried out
                through JustAct, parties may agree upon their own set of rules.
                In such cases, it will be the responsibility of the parties, to
                ensure that the dispute is arbitrable under law
              </span>
            </div>

            <div style={{ lineHeight: "1.4" }}>
              <span>
                <strong>2.3.</strong>&nbsp;In case of any dispute on the
                applicability of rules or otherwise, JustAct would be the
                deciding authority in this regard
              </span>
            </div>
          </TextLines>

          <div>
            <span>
              <strong>3.</strong> <strong>Services Offered</strong>
            </span>
          </div>

          <TextLines style={{ padding: "20px" }}>
            <div style={{ lineHeight: "1.4" }}>
              <span>
                <strong>3.1.</strong>&nbsp;Conduct of arbitral proceedings:
              </span>
            </div>
            <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                JustAct will make available resources and infrastructure for
                filing of a claim, submission of defence statement, uploading of
                documents, appointment of arbitrators, scheduling of meetings
                either online or requiring physical presence and issue of
                arbitral award for institutional arbitration cases in accordance
                with the JustAct Arbitration Rules. &nbsp;
              </span>
            </div>

            <div style={{ lineHeight: "1.4" }}>
              <span>
                <strong>3.2.</strong>&nbsp;Advisory services:
              </span>
            </div>
            <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                Drafting of arbitral agreements for cases other than
                institutional arbitration conducted under the JustAct
                Arbitration Rules will also be offered by JustAct on a
                case-to-case basis and at its sole discretion. The fees for the
                same will be determined keeping in mind the case in hand.
              </span>
            </div>

            <div style={{ lineHeight: "1.4" }}>
              <span>
                <strong>3.3.</strong>&nbsp;Administrative Services:
              </span>
            </div>
            <div style={{ lineHeight: "1.4" }}>
              <span>
                Includes providing halls, secretarial services, or any other
                incidental requirement during conduct of the arbitration.
              </span>
            </div>
          </TextLines>

          <div>
            <span>
              <strong>4.</strong> <strong>Jurisdiction for Arbitration</strong>
            </span>
          </div>

          <TextLines style={{ paddingBottom: "20px", paddingTop: "20px" }}>
            <div>
              <span>
                JustAct offers its services to resolve / undertake
                administrative support for &nbsp;
              </span>
            </div>

            <TextLines style={{ padding: "20px" }}>
              <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
                <span>
                  <strong>4.1.</strong>&nbsp;All cases pertaining to a civil
                  dispute between parties that may be referred to JustAct for
                  arbitration under these rules.
                </span>
              </div>

              <div style={{ marginBottom: "15px" }}>
                <span>
                  <strong>4.2.</strong>&nbsp;All cases referred by the Court for
                  arbitration to JustAct.
                </span>
              </div>

              <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
                <span>
                  <strong>4.3.</strong>&nbsp;All cases where both parties are
                  member to any organization whose guidelines, including but not
                  limited to Standard Operating Procedures (SOPs), stipulate
                  arbitration using JustAct &nbsp;
                </span>
              </div>

              <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
                <span>
                  <strong>4.4.</strong>&nbsp;All cases wherein the terms of the
                  contract, which is the subject of a claim or dispute,
                  stipulate arbitration by JustAct.
                </span>
              </div>

              <div style={{ lineHeight: "1.4" }}>
                <span>
                  <strong>4.5.</strong>&nbsp;Offer administrative services in
                  connection with arbitration of disputes provided the parties
                  or the arbitrators appointed by the parties agree to and
                  request JustAct to administer such cases through JustAct.
                </span>
              </div>
            </TextLines>
            <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                All arbitration will be subject to the JustAct Arbitration Rules
                and amendments thereof, provided the dispute in hand is
                arbitrable. &nbsp;
              </span>
            </div>

            <div style={{ lineHeight: "1.4" }}>
              <span>
                JustAct retains the right to refuse to arbitrate any dispute, at
                its discretion, including if it finds that such arbitration is
                contrary to its rules or beyond its jurisdiction.&nbsp;
              </span>
            </div>
          </TextLines>

          <div>
            <span>
              <strong>5.</strong> <strong>The Tribunal</strong>
            </span>
          </div>

          <TextLines style={{ paddingBottom: "20px", paddingTop: "20px" }}>
            <div style={{ lineHeight: "1.4" }}>
              <span>
                The arbitration tribunal, herein after referred to as the
                ‘Tribunal’ comprises of an arbitrator or a panel of arbitrators
                who are equipped to handle the disputes referred for resolution.
                The tribunal will consist of an odd number of arbitrators viz 1
                and 3. JustAct may limit cases to be resolved below a threshold
                or a type of dispute to be arbitrated with only a single
                arbitrator tribunal. In its endeavour to provide trained and
                qualified arbitrators, JustAct may enter an arrangement with one
                or more arbitral institutions as well as independent arbitrators
                to arbitrate disputes sourced through the platform/ other
                channels.
              </span>
            </div>
          </TextLines>
          <div>
            <span>
              <strong>6.</strong> <strong>Appointment of Tribunal</strong>
            </span>
          </div>

          <TextLines style={{ paddingBottom: "20px", paddingTop: "20px" }}>
            <div>
              <span>
                The Tribunal shall consist of single arbitrator or a panel of
                odd number of arbitrators including a Presiding Arbitrator
              </span>
            </div>

            <TextLines style={{ paddingTop: "16px" }}>
              <div>
                <span>
                  <strong>6.1.</strong>&nbsp; Appointment of arbitrators:
                </span>
              </div>

              <TextLines style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <div
                  style={{
                    lineHeight: "1.4",
                    marginBottom: "15px",
                    marginTop: "10px",
                  }}
                >
                  <span>
                    <strong>6.1.1.</strong>&nbsp;The Case Manager will assign an
                    arbitrator / panel of arbitrators for the case.
                  </span>
                </div>

                <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
                  <span>
                    <strong>6.1.2.</strong>&nbsp;JustAct will make reasonable
                    enquiries to establish if there is an actual or potential
                    conflict of interest. The Arbitrator(s) shall provide no
                    conflict declaration within 4 days of their appointment.
                  </span>
                </div>

                <div style={{ lineHeight: "1.4" }}>
                  <span>
                    <strong>6.1.3.</strong>&nbsp;In case there is a likely or
                    actual conflict so disclosed, JustAct will communicate the
                    same to the parties who can choose to waive the same, in
                    which case the arbitrator(s) can be appointed failing which
                    a new arbitrator will need to be appointed following the
                    procedure set out above.
                  </span>
                </div>
              </TextLines>
            </TextLines>
          </TextLines>

          <div>
            <span>
              <strong>7.</strong>{" "}
              <strong>Challenge to appointment of arbitrator</strong>
            </span>
          </div>

          <TextLines style={{ padding: "20px" }}>
            <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>7.1.</strong>&nbsp; If a Party wishes to challenge the
                appointment of an arbitrator for grounds of lack of independence
                or any other grounds, it must do so within 15 days of the
                appointment and only for grounds that became known to it post
                the appointment of the said arbitrator(s) to the panel. In case
                such Party fails to communicate its objection within such period
                they will be deemed to have waived their right to object.
              </span>
            </div>

            <div style={{ lineHeight: "1.4" }}>
              <span>
                <strong>7.2.</strong>&nbsp;The challenge will carry details of
                the ground of challenge and will be submitted to JustAct with a
                copy to the other Party and the arbitrators separately. The
                decision of JustAct in this regard will be final and the same
                will be communicated within 15 days of the challenge having been
                filed
              </span>
            </div>
          </TextLines>
          <div>
            <span>
              <strong>8.</strong>{" "}
              <strong>Resignation/Death of Arbitrator</strong>
            </span>
          </div>

          <TextLines style={{ marginBottom: "20px", marginTop: "20px" }}>
            <div style={{ lineHeight: "1.4" }}>
              <span>
                <strong></strong>&nbsp;If at any point during the arbitration,
                If at any point during the arbitration, if any arbitrator dies
                or if any arbitrator claims that he/she is unable to perform
                their duties to the best of their ability and as per the rules,
                such arbitrator is deemed to have been terminated and, in their
                place, JustAct shall find and appoint the replacement within 15
                days in consonance with provision of clause 6 above.
              </span>
            </div>
          </TextLines>

          <div>
            <span>
              <strong>9.</strong> <strong>Powers of the Tribunal</strong>
            </span>
          </div>

          <TextLines style={{ padding: "20px" }}>
            <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong></strong>Within the procedural framework of the JustAct
                Arbitration Rules, in consultation with the Case Manager
                appointed by JustAct and within the framework of extant
                regulations and law including The Arbitration & Conciliation
                Act, 1996:
              </span>
            </div>

            <TextLines>
              <div style={{ marginBottom: "10px" }}>
                <span>
                  <strong>9.1.</strong>&nbsp;The Tribunal may rule on the
                  existence or validity of the arbitration agreement
                </span>
              </div>

              <div style={{ marginBottom: "10px" }}>
                <span>
                  <strong>9.2.</strong>&nbsp;The tribunal may combine disputes
                  under its jurisdiction if it deems fit
                </span>
              </div>

              <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
                <span>
                  <strong>9.3.</strong>&nbsp;The Tribunal shall determine its
                  own procedure to conduct the arbitration proceedings and its
                  amendments thereof subject to these provisions.
                </span>
              </div>

              <div style={{ marginBottom: "10px" }}>
                <span>
                  <strong>9.4.</strong>&nbsp;The tribunal shall decide its own
                  rules of admissibility of evidence
                </span>
              </div>

              <div style={{ lineHeight: "1.4", marginBottom: "10px" }}>
                <span>
                  <strong>9.5.</strong>&nbsp;The Tribunal may request for one or
                  more experts on specific issues if need be. Here, it is
                  assumed that, with a domain expertise- based allocation of
                  arbitrators, the need for such an expert will be de minimis.
                  In the rare event that such an expert is needed, the presiding
                  arbitrator will make a clear request to JustAct, well before
                  the start of the arbitration proceedings, for such an expert
                  giving reason for such a request. &nbsp;
                </span>
              </div>

              <div>
                <span>
                  <strong>9.6.</strong>&nbsp;The Tribunal may terminate or stay
                  the proceeding if it sees necessary cause
                </span>
              </div>

              <div>
                <span>
                  <strong>9.7.</strong>&nbsp;The Tribunal may award interim
                  awards.&nbsp;
                </span>
              </div>
            </TextLines>
          </TextLines>
          <div>
            <span>
              <strong>10.</strong> <strong>Arbitral Assistance</strong>
            </span>
          </div>

          <TextLines style={{ padding: "20px" }}>
            <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>10.1.</strong>&nbsp;To assist efficient and timely
                resolution of the arbitration, a ‘Case Manager’ will be assigned
                for every dispute referred to JustAct.&nbsp;
              </span>
            </div>

            <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>10.2.</strong>&nbsp;Such ‘Case Manager’ will provide all
                support for resolution including response to queries, scheduling
                arbitral sessions, communicating reminders and administrative
                coordination.&nbsp;
              </span>
            </div>

            <div style={{ lineHeight: "1.4" }}>
              <span>
                <strong>10.3.</strong>&nbsp;JustAct may also assign such
                secretarial staff, as required for every proceeding to support
                and record the proceedings as per the rules
              </span>
            </div>
          </TextLines>
          <div>
            <span>
              <strong>11.</strong> <strong>Request for Arbitration:</strong>
            </span>
          </div>

          <TextLines
            style={{
              paddingLeft: "20px",
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
          >
            <div>
              <span>
                <strong>11.1.</strong>&nbsp;The request to initiate arbitration
                proceedings could emanate in any of the following ways:
              </span>
            </div>

            <TextLines
              style={{
                paddingLeft: "20px",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              <div>
                <span>
                  <strong>11.1.1.</strong>&nbsp;In case one Party registers the
                  dispute in the JustAct platform as a claim. &nbsp;
                </span>
              </div>

              <TextLines
                style={{
                  paddingLeft: "20px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
                  <span>
                    <strong>11.1.1.1.</strong>&nbsp;The Party raising the claim
                    (Party A) furnishes details of the other Party (Party B).
                    These include Name, Mobile, email, PAN and Address of the
                    person(s) to the extent possible &nbsp;
                  </span>
                </div>

                <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
                  <span>
                    <strong>11.1.1.2.</strong>&nbsp;JustAct will intimate the
                    opposite Party and ask the parties to register on JustAct to
                    start the arbitration process. &nbsp;
                  </span>
                </div>

                <div>
                  <span>
                    <strong>11.1.1.3.</strong>&nbsp;Once both parties are
                    registered and have chosen to arbitrate the arbitration
                    process begins
                  </span>
                </div>
              </TextLines>
            </TextLines>

            <TextLines style={{ paddingLeft: "20px" }}>
              <div>
                <span>
                  <strong>11.1.2.</strong>&nbsp;In case the same is escalated
                  from{" "}
                  <HyperLink
                    onClick={() =>
                      window.open(`https://justact.co.in/negotiation-rules`)
                    }
                  >
                    negotiation
                  </HyperLink>{" "}
                  /
                  <HyperLink
                    onClick={() =>
                      window.open(`https://justact.co.in/mediation-rules`)
                    }
                  >
                    mediation
                  </HyperLink>
                </span>
              </div>

              <TextLines
                style={{
                  paddingLeft: "20px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                <div style={{ marginBottom: "15px" }}>
                  <span>
                    <strong>11.1.2.1.</strong>&nbsp;Both the parties are already
                    registered.&nbsp;
                  </span>
                </div>

                <div style={{ lineHeight: "1.4" }}>
                  <span>
                    <strong>11.1.2.2.</strong>&nbsp;On escalation, the case is
                    assigned to the JustAct team for completing the case details
                    as necessary and resolution process is started
                  </span>
                </div>
              </TextLines>
            </TextLines>

            <TextLines
              style={{
                paddingLeft: "20px",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
                <span>
                  <strong>11.1.3.</strong>&nbsp;In case both parties or their
                  arbitrators agree to arbitrate and approach JustAct / the
                  reference to JustAct for arbitration is part of the contract
                  between them
                </span>
              </div>
              <TextLines style={{ paddingLeft: "20px" }}>
                <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
                  <span>
                    <strong>11.1.3.1.</strong>&nbsp;In such a case, both the
                    parties register on JustAct and put in details of the case
                    in brief with a claim statement and a reply statement.
                    &nbsp;
                  </span>
                </div>

                <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
                  <span>
                    <strong>11.1.3.2.</strong>&nbsp;After the claim and reply
                    statement are filed, then the resolution process begins
                  </span>
                </div>
              </TextLines>
            </TextLines>
            <TextLines style={{ paddingLeft: "20px" }}>
              <div>
                <span>
                  <strong>11.1.4.</strong>&nbsp;In case of a Court referred for
                  arbitration, the details and the court order of such
                  directions are handed over to the JustAct team to ensure that
                  both the parties are registered on JustAct
                </span>
              </div>
            </TextLines>
          </TextLines>
          <div>
            <span>
              <strong>12.</strong> <strong>Submission of documents</strong>
            </span>
          </div>

          <TextLines
            style={{
              paddingBottom: "20px",
              paddingTop: "20px",
              paddingLeft: "20px",
            }}
          >
            <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>12.1.</strong>&nbsp;&nbsp;On initiation of arbitration,
                the claim with the supporting documents, the reply /counterclaim
                with supporting documents and any reply to the counterclaim need
                to be submitted to every Party, every Arbitrator of the Tribunal
                and JustAct.
              </span>
            </div>

            <div>
              <span>
                <strong>12.2.</strong>&nbsp;&nbsp;Documents need to be made
                available within 7 days of the Tribunal seeking it &nbsp;
              </span>
            </div>
          </TextLines>

          <div>
            <span>
              <strong>13.</strong> <strong>Combination of Disputes</strong>
            </span>
          </div>

          <TextLines style={{ paddingBottom: "20px", paddingTop: "20px" }}>
            <div style={{ lineHeight: "1.4" }}>
              <span>
                In case similar claims arise out of different contracts, such
                claims can be combined and filed together provided all parties
                to the disputes agree to arbitrate the same through JustAct.
              </span>
            </div>
          </TextLines>

          <div>
            <span>
              <strong>14.</strong> <strong>Conduct of Arbitration</strong>
            </span>
          </div>

          <TextLines style={{ padding: "20px" }}>
            <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>14.1.</strong>&nbsp;At all times during the conduct of
                the Arbitration, the Tribunal shall treat both the parties
                equally and provide adequate opportunity to substantiate their
                claim and counter claim
              </span>
            </div>

            <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>14.2.</strong>&nbsp;The Tribunal will decide on the
                manner of conduct of proceedings as it deems fit and as per the
                rules; the same is detailed in para 9.3 above
              </span>
            </div>

            <div style={{ marginBottom: "10px" }}>
              <span>
                <strong>14.3.</strong>&nbsp;The Tribunal will aim at timely and
                efficient resolution of the dispute
              </span>
            </div>

            <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>14.4.</strong>&nbsp;On Date of reference, the Case
                Manager shall arrange a meeting between the Tribunal and the
                parties along with their representatives.
              </span>
            </div>

            <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>14.5.</strong>&nbsp;During such meeting the Tribunal
                will also discuss the conduct of the proceedings and rules that
                shall be applicable
              </span>
            </div>

            <div>
              <span>
                <strong>14.6.</strong>&nbsp;The tribunal may choose to hold oral
                hearing or decide the case upon the submitted documents and
                evidence.
              </span>
            </div>

            <div style={{ marginBottom: "10px" }}>
              <span>
                <strong>14.7.</strong>&nbsp;The tribunal may also seek the
                Court’s support in recording such evidence. &nbsp;
              </span>
            </div>

            <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>14.8.</strong>&nbsp;Based on the claim and counter claim
                submitted, the Tribunal will decide on number of arbitration
                sessions and the dates of arbitration.
              </span>
            </div>

            <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>14.9.</strong>&nbsp;&nbsp;The Tribunal may allow for
                amendment of claims if it deems fit and such Party shall do it
                within such stipulated time, failing which the original claim
                stands
              </span>
            </div>

            <div style={{ lineHeight: "1.4" }}>
              <span>
                <strong>14.10.</strong>&nbsp;The Tribunal may take the help of
                the Case Manager and/or the secretarial staff for smooth and
                fair conduct of the proceedings.
              </span>
            </div>
          </TextLines>
          <div>
            <span>
              <strong>15.</strong> <strong>Waiver of right to object:</strong>
            </span>
          </div>

          <TextLines style={{ paddingBottom: "20px", paddingTop: "20px" }}>
            <div style={{ lineHeight: "1.4" }}>
              <span>
                Any Party who proceeds with the arbitration post the date of
                reference despite knowledge of any non-compliance of the rules,
                waives its right to object to the proceedings
              </span>
            </div>
          </TextLines>
          <div>
            <span>
              <strong>16.</strong> <strong>Emergency Arbitration</strong>
            </span>
          </div>

          <TextLines style={{ padding: "20px" }}>
            <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>16.1.</strong>&nbsp;&nbsp;During the proceedings or
                prior to commencement of arbitration proceedings if any Party
                seeks emergency relief, it may request for an emergency
                arbitrator by notifying the JustAct Case Manager, Tribunal and
                other Party. &nbsp;
              </span>
            </div>

            <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>16.2.</strong>&nbsp;In case of JustAct finding the
                request worthy of appointment of Emergency Arbitrator, it would
                do so in 7 (seven) days of receipt of such request and at such a
                fee as deemed necessary. The Party will then be asked to pay the
                fee of Emergency Arbitrator in advance before the Emergency
                Arbitrator is notified of his / her likely appointment.
              </span>
            </div>

            <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>16.3.</strong>&nbsp;The arbitrator will then identify if
                there exists or is likely to exist a conflict of interest and
                the procedure as followed in Rule 6 will prevail
              </span>
            </div>

            <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>16.4.</strong>&nbsp;Once the appointment of Emergency
                Arbitrator is complete, he/she will have a discussion with both
                parties and their representatives and decide the schedule of
                arbitration in keeping with the exigent circumstances.
              </span>
            </div>

            <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>16.5.</strong>&nbsp;The Emergency Arbitrator will then
                proceed to examine the interim relief sought and may issue an
                interim award if necessary.
              </span>
            </div>

            <div style={{ lineHeight: "1.4" }}>
              <span>
                <strong>16.6.</strong>&nbsp;Emergency Arbitrator’s jurisdiction
                shall prevail only over the interim relief sought and will not
                extend over to the main arbitral procedure. His / her
                appointment will also cease to have effect once the arbitral
                panel is appointed.
              </span>
            </div>
          </TextLines>
          <div>
            <span>
              <strong>17.</strong> <strong>End of proceedings</strong>
            </span>
          </div>

          <TextLines style={{ padding: "20px" }}>
            <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>17.1.</strong>&nbsp;&nbsp;The Tribunal may proceed to
                close the proceedings if they are satisfied that there are no
                further proofs to be submitted or witnesses to be examined
                arguments to be addressed. For this purpose, the panel may get
                an affirmation in this regard from the parties
              </span>
            </div>

            <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>17.2.</strong>&nbsp;Once closed, the Tribunal will
                proceed to make the award.&nbsp;
              </span>
            </div>

            <div style={{ lineHeight: "1.4" }}>
              <span>
                <strong>17.3.</strong>&nbsp; If, however the Tribunal at any
                point between closure of proceedings and the issue of arbitral
                award feels the need to re-open the hearing of the case it may
                do so in exceptional circumstances
              </span>
            </div>
          </TextLines>
          <div>
            <span>
              <strong>18.</strong> <strong>Fees, Costs and Expenses</strong>
            </span>
          </div>

          <TextLines style={{ padding: "20px" }}>
            <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>18.1.</strong>&nbsp;Unless otherwise agreed upon, all
                arbitral costs shall be borne equally by both parties. Such fees
                shall be determined as per the Fee Schedule specified by
                JustAct, that may be amended from time to time.
              </span>
            </div>

            <div style={{ lineHeight: "1.4", marginBottom: "10px" }}>
              <span>
                <strong>18.2.</strong>&nbsp;Incidental expenses of arbitral
                panel and cost of any external assistance that was solicited for
                the conducting of the arbitration need to be reimbursed by the
                parties as and when incurred.
              </span>
            </div>

            <div>
              <span>
                <strong>18.3.</strong>&nbsp;Charges for secretarial and
                administrative services also are determined as per the Fee
                schedule.
              </span>
            </div>

            <div>
              <span>
                <strong>18.4.</strong>&nbsp;Charges for ad-hoc arbitration shall
                also be determined as per the Fee Schedule.
              </span>
            </div>

            <div>
              <span>
                <strong>18.5.</strong>&nbsp;All costs need to be paid in the
                manner prescribed by JustAct Fee Schedule.
              </span>
            </div>
          </TextLines>

          <div>
            <span>
              <strong>19.</strong> <strong>Place of Arbitration</strong>
            </span>
          </div>

          <TextLines style={{ paddingTop: "20px", paddingBottom: "20px" }}>
            <div>
              <span>
                Unless otherwise stated, the place of arbitration for the
                platform will be treated as India.
              </span>
            </div>
          </TextLines>
          <div>
            <span>
              <strong>20.</strong> <strong>Language of Arbitration</strong>
            </span>
          </div>
          <TextLines style={{ paddingTop: "20px", paddingBottom: "20px" }}>
            <div>
              <span>
                Unless otherwise stated or as agreed upon by the parties, the
                language used would be English.
              </span>
            </div>
          </TextLines>

          <div>
            <span>
              <strong>21.</strong> <strong>Applicable Law</strong>
            </span>
          </div>
          <TextLines style={{ paddingTop: "20px", paddingBottom: "20px" }}>
            <div style={{ lineHeight: "1.4", marginBottom: "10px" }}>
              <span>
                Unless otherwise agreed upon by parties, the law applicable for
                the arbitration would be the law applicable at the agreed place
                of arbitration.
              </span>
            </div>
          </TextLines>
          <div>
            <span>
              <strong>22.</strong> <strong>Time Limit</strong>
            </span>
          </div>

          <TextLines style={{ padding: "20px" }}>
            <div style={{ marginBottom: "10px" }}>
              <span>
                <strong>22.1.</strong>&nbsp;&nbsp;The Tribunal shall make its
                award within 12 months from the date of entrance of reference
                &nbsp;
              </span>
            </div>

            <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>22.2.</strong>&nbsp;The date of entering the reference
                is the date on which the arbitrator(s) have received the
                intimation of their appointment in writing&nbsp;
              </span>
            </div>

            <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>22.3.</strong>&nbsp;An extension of up to 6 months over
                the 12-month limit may be agreed upon by the parties &nbsp;
              </span>
            </div>

            <div>
              <span>
                <strong>22.4.</strong>&nbsp;However, any extension beyond that
                will need to be granted by the Court and with such conditions as
                imposed by the Court granting the extension &nbsp;
              </span>
            </div>
          </TextLines>

          <div>
            <span>
              <strong>23.</strong> <strong>Fast Track Arbitration</strong>
            </span>
          </div>

          <TextLines style={{ padding: "20px" }}>
            <div>
              <span>
                <strong>23.1.</strong>&nbsp;The parties can opt for fast-track
                arbitration for speeding up the resolution. &nbsp;
              </span>
            </div>

            <div>
              <span>
                <strong>23.2.</strong>&nbsp;In such a case, the parties also
                agree that the Tribunal will consist of a sole arbitrator.
                &nbsp;
              </span>
            </div>

            <div style={{ marginBottom: "10px" }}>
              <span>
                <strong>23.3.</strong>&nbsp; The process of appointment of sole
                arbitrator will remain as described in Rule 6. &nbsp;
              </span>
            </div>

            <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>23.4.</strong>&nbsp;The Tribunal shall proceed based on
                the submissions by the parties and without any oral hearing. The
                sole arbitrator may, however, call for any further evidence or
                details as necessary from either of the parties. &nbsp;
              </span>
            </div>

            <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>23.5.</strong>&nbsp;The arbitrator may also call for an
                oral hearing if required or if requested by any of the parties.
                Even in that case, the hearing may be so conducted to ensure
                least delay and without any technical formalities for conduct.
              </span>
            </div>

            <div>
              <span>
                <strong>23.6.</strong>&nbsp;The award will be made within 6
                months of the date of the tribunal entering the reference.
              </span>
            </div>

            <div>
              <span>
                <strong>23.7.</strong>&nbsp;In case the award is delayed, this
                will attract provisions as laid out under rule 22
              </span>
            </div>
          </TextLines>
          <div>
            <span>
              <strong>24.</strong> <strong>Settlement</strong>
            </span>
          </div>

          <TextLines style={{ padding: "20px" }}>
            <div style={{ marginBottom: "10px" }}>
              <span>
                <strong>24.1.</strong>&nbsp;&nbsp;If the parties settle a
                dispute during arbitral proceedings, the tribunal will terminate
                the proceedings.
              </span>
            </div>

            <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>24.2.</strong>&nbsp;Also, if requested by the parties
                and not objected to by the arbitral tribunal, this settlement
                will be recorded in the form of an arbitral award on agreed
                terms.
              </span>
            </div>

            <div>
              <span>
                <strong>24.3.</strong>&nbsp;The arbitral award on agreed terms
                will have the same status and effect as any other arbitral
                award.
              </span>
            </div>
          </TextLines>
          <div>
            <span>
              <strong>25.</strong> <strong>Arbitral Award</strong>
            </span>
          </div>

          <TextLines style={{ padding: "20px" }}>
            <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>25.1.</strong>&nbsp;An arbitral award shall be duly
                signed by all members of the Tribunal. The award may also be
                issued if majority of the arbitrators (in case of more than one
                arbitrator in the panel) sign provided the reason for missing
                signatures may be mentioned &nbsp;
              </span>
            </div>

            <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>25.2.</strong>&nbsp;Unless otherwise agreed, the award
                will state the reasons for the award and include place and date
                of the award
              </span>
            </div>

            <div style={{ lineHeight: "1.4" }}>
              <span>
                <strong>25.3.</strong>&nbsp; An arbitral award will be issued
                electronically to both parties only after they complete the
                payment of dues as per the structure shared by JustAct and
                amended from time to time
              </span>
            </div>
          </TextLines>
          <div>
            <span>
              <strong>26.</strong> <strong>Award of costs</strong>
            </span>
          </div>

          <TextLines style={{ padding: "20px" }}>
            <div style={{ marginBottom: "10px" }}>
              <span>
                <strong>26.1.</strong>&nbsp;&nbsp;All costs of arbitration
                unless specified by the Tribunal are borne equally by both the
                parties
              </span>
            </div>

            <div style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>26.2.</strong>&nbsp;The Tribunal will have power to
                decide on payment of costs by one Party to the other in case of
                the dispute in hand.&nbsp;
              </span>
            </div>

            <div style={{ lineHeight: "1.4" }}>
              <span>
                <strong>26.3.</strong>&nbsp;This includes cost of arbitrators,
                fees paid for registration or administrative services and any
                other expenses incurred during the arbitration
              </span>
            </div>
          </TextLines>
          <div>
            <span>
              <strong>27.</strong> <strong>Termination of Arbitration</strong>
            </span>
          </div>

          <TextLines
            style={{
              paddingLeft: "20px",
              paddingBottom: "20px",
              paddingTop: "20px",
            }}
          >
            <div>
              <span>
                <strong>27.1.</strong>&nbsp;An arbitration shall be considered
                as terminated by issue of an arbitral award
              </span>
            </div>

            <div style={{ lineHeight: "1.4", marginBottom: "10px" }}>
              <span>
                <strong>27.2.</strong>&nbsp;The arbitration shall be considered
                terminated following also an arbitration will be treated as
                terminated and a notice issued to that effect.
              </span>
            </div>
            <TextLines>
              <div style={{ lineHeight: "1.4", marginBottom: "10px" }}>
                <span>
                  <strong>27.2.1.</strong>&nbsp;Party A takes back its claim
                  unless Party B objects to the same and the tribunal sees a
                  good reason for Party B to insist on an arbitral award
                </span>
              </div>

              <div>
                <span>
                  <strong>27.2.2.</strong>&nbsp;Both parties agree to terminate
                  the arbitration
                </span>
              </div>

              <div>
                <span>
                  <strong>27.2.3.</strong>&nbsp;The arbitrator(s) so find that
                  continuing the arbitration will not be possible and /or
                  necessary.
                </span>
              </div>

              <div>
                <span>
                  <strong>27.2.4.</strong>&nbsp;On the default of a Party
                  without enough cause
                </span>
              </div>
            </TextLines>
          </TextLines>
          <div>
            <span>
              <strong>28.</strong>{" "}
              <strong>Revision or Correction of Award</strong>
            </span>
          </div>

          <TextLines
            style={{
              paddingLeft: "20px",
              paddingBottom: "20px",
              paddingTop: "20px",
            }}
          >
            <div style={{ lineHeight: "1.4" }}>
              <span>
                <strong>28.1.</strong>&nbsp;A Party may ask for the following
                within 15 days from issue of arbitral award and with due notice
                to the other Party.
              </span>
            </div>

            <TextLines
              style={{
                paddingLeft: "20px",
                paddingBottom: "10px",
                paddingTop: "10px",
              }}
            >
              <div>
                <span>
                  <strong>28.1.1.</strong>&nbsp;correction of computational /
                  clerical / typographical errors, and / or &nbsp;
                </span>
              </div>

              <div style={{ marginBottom: "10px" }}>
                <span>
                  <strong>28.1.2.</strong>&nbsp;give an interpretation to a
                  specific point or part of the award, and / or
                </span>
              </div>

              <div style={{ lineHeight: "1.4" }}>
                <span>
                  <strong>28.1.3.</strong>&nbsp;an additional arbitral award as
                  to claims presented in the arbitral proceedings but omitted
                  from the arbitral award
                </span>
              </div>
            </TextLines>

            <div>
              <span>
                <strong>28.2.</strong>&nbsp;If the arbitral panel finds the
                requests made from the above justified it would, do the
                following
              </span>
            </div>

            <TextLines
              style={{
                paddingLeft: "20px",
                paddingBottom: "10px",
                paddingTop: "10px",
              }}
            >
              <div style={{ lineHeight: "1.4", marginBottom: "10px" }}>
                <span>
                  <strong>28.2.1.</strong>&nbsp;Correct errors (it may also do
                  such corrections suo motu) within 15 days of receipt of such
                  request and / or
                </span>
              </div>

              <div>
                <span>
                  <strong>28.2.2.</strong>&nbsp;Issue the interpretation sought
                  within 15 days of receipt of such request and / or,
                </span>
              </div>

              <div>
                <span>
                  <strong>28.2.3.</strong>&nbsp;Issue an additional award within
                  15 days of receipt of such request
                </span>
              </div>
            </TextLines>

            <div style={{ marginBottom: "10px" }}>
              <span>
                <strong>28.3.</strong>&nbsp;The panel may seek additional time
                for issue of corrected / additional award or interpretation as
                well.
              </span>
            </div>

            <div style={{ lineHeight: "1.4" }}>
              <span>
                <strong>28.4.</strong>&nbsp;Confidentiality conditions as
                specified under Rule 30 on “Confidentiality” will also apply to
                the corrected award/ interpretation / additional award so issued
              </span>
            </div>
          </TextLines>
          <div>
            <span>
              <strong>29.</strong>{" "}
              <strong>
                Enforceability and Setting aside of Arbitral Award
              </strong>
            </span>
          </div>

          <TextLines
            style={{
              paddingLeft: "20px",
              paddingBottom: "10px",
              paddingTop: "10px",
            }}
          >
            <div style={{ marginBottom: "10px" }}>
              <span>
                <strong>29.1.</strong>&nbsp;An arbitral award is final and
                binding on all parties claiming under such arbitration
                proceeding.
              </span>
            </div>

            <div style={{ lineHeight: "1.4", marginBottom: "10px" }}>
              <span>
                <strong>29.2.</strong>&nbsp;An application for setting aside an
                arbitral award may be made to the Court, in consonance with the
                provisions of the Arbitration and Conciliation Act 1996.
              </span>
            </div>

            <div style={{ lineHeight: "1.4" }}>
              <span>
                <strong>29.3.</strong>&nbsp;Post the period mentioned above and
                unless the Court adjourns such an application, the award
                continues to remain enforceable in the same manner as a court
                decree.
              </span>
            </div>
          </TextLines>
          <div>
            <span>
              <strong>30.</strong> <strong>Confidentiality</strong>
            </span>
          </div>

          <TextLines style={{ paddingBottom: "20px", paddingTop: "20px" }}>
            <div style={{ lineHeight: "1.4" }}>
              <span>
                JustAct, the arbitrator(s) and the parties and their agents
                shall maintain confidentiality of all arbitral proceedings
                except award where its disclosure is necessary for the purpose
                of implementation and enforcement of award.
              </span>
            </div>
          </TextLines>
          <div>
            <span>
              <strong>31.</strong> <strong>Indemnity of JustAct</strong>
            </span>
          </div>

          <TextLines style={{ padding: "20px" }}>
            <div style={{ lineHeight: "1.4", marginBottom: "10px" }}>
              <span>
                <strong>31.1.</strong>&nbsp;Neither JustAct, its employees or
                any of the arbitrators shall be liable due to anything which is
                done / intended to be done in good faith in connection with the
                arbitration, and the services rendered in respect of the same.
              </span>
            </div>

            <div style={{ lineHeight: "1.4" }}>
              <span>
                <strong>31.2.</strong>&nbsp;Unless required by law, JustAct or
                its agents / employees shall not be required to testify in any
                proceedings – judicial or otherwise – with respect to conduct of
                the arbitration under these rules.
              </span>
            </div>
          </TextLines>
          <div>
            <span>
              <strong>32.</strong> <strong>Fee Schedule</strong>
            </span>
          </div>

          <TextLines style={{ padding: "20px" }}>
            <div style={{ lineHeight: "1.4", marginBottom: "10px" }}>
              <span>
                <strong>32.1.</strong>&nbsp;The fee schedule will be maintained
                by JustAct which will be the sole authority to charge all
                disputing parties for the arbitration.
              </span>
            </div>

            <div style={{ lineHeight: "1.4", marginBottom: "10px" }}>
              <span>
                <strong>32.2.</strong>&nbsp;The charges for the arbitration will
                be based on a number of parameters and will be made known to the
                Party filing a dispute at the time of raising the claim or reply
                statement respectively. &nbsp;
              </span>
            </div>

            <div style={{ lineHeight: "1.4", marginBottom: "10px" }}>
              <span>
                <strong>32.3.</strong>&nbsp;This pricing principle will,
                however, not be applicable to disputes where the claim value is
                not amenable to monetary quantification. JustAct will, in such
                cases, determine the fee within 7 days of claim filling and
                before commencement of arbitration, and communicate the same to
                the parties
              </span>
            </div>

            <div style={{ lineHeight: "1.4" }}>
              <span>
                <strong>32.4.</strong>&nbsp;Unless otherwise agreed between the
                parties and communicated to JustAct with the necessary
                documentation, both parties will be charged equally for the
                arbitration.
              </span>
            </div>
          </TextLines>
          <div>
            <span>
              <strong>33.</strong>{" "}
              <strong>Amendments to JustAct Arbitration Rules</strong>
            </span>
          </div>

          <TextLines style={{ padding: "20px" }}>
            <div style={{ lineHeight: "1.4", marginBottom: "10px" }}>
              <span>
                <strong>33.1.</strong>&nbsp;JustAct reserves the right to amend
                these rules from time to time. However, any case once registered
                will continue to be processed for arbitration under the rules
                under which it was registered. &nbsp;
              </span>
            </div>

            <div>
              <span>
                <strong>33.2.</strong>&nbsp;The date, for this purpose will be
                reckoned to be the date when filing of the claim was done.
                &nbsp;
              </span>
            </div>

            <div>
              <span>
                <strong>33.3.</strong>&nbsp;In case of any disputes, the
                decision of JustAct will be binding in this regard
              </span>
            </div>
          </TextLines>
        </RuleContainer>
        <Footer />
      </div>
    </>
  );
}

export default ArbitrationRules;
