import React, { useEffect } from "react";
import { Content, SubHeadWrap, BlogPage } from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

/* eslint-disable jsx-a11y/anchor-is-valid */

function OnlineDisputeFifteen(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header>
        <BlogPage>
          <HeadWrap>TIME & COST BENEFITS OF ARBITRATION & MEDIATION</HeadWrap>
          <br />
          <b
            style={{ textAlign: "left", fontSize: "16px", fontStyle: "italic" }}
          >
            {" "}
            Authored by: Aprameya Manthena
          </b>
          <br />
          <Content>
            <p>
              Although the traditional court system is associated primarily with
              the dispensation of justice, methods of Alternative Dispute
              Resolution are beginning to be seen as viable correlatives in
              different industries that see a heavy volume of civil and
              commercial disputes. The overwhelming technicalities inherent in
              the practice of law, procedural complexities, inordinate delays
              and obscure language make the legal terrain challenging to
              navigate. It is in this context that ADR is gaining ground and the
              time and cost benefits of ADR are being foregrounded as among the
              better alternatives to the difficulties created by the court
              system.
            </p>
            <br />
            <p>
              This article will be divided into two segments and each segment
              will deal with the cost and time benefits inherent in the
              Alternative Dispute Resolution ecosystem, with a specific focus on
              arbitration and mediation.
            </p>
            <br />
            <SubHeadWrap>Arbitration</SubHeadWrap>
            <br />
            <p>
              The costs of arbitration relate to certain heads such as fees of
              the lawyers apart from the expenses involved in the proceedings.
              Therefore, the contents of the arbitration clause and the
              procedure as agreed upon in the agreement or under the provisions
              of the applicable laws would determine the extent of the costs
              involved. The time and cost benefits of using arbitration are as
              follows:
            </p>
            <br />
            <SubHeadWrapHeading>
              1. Less interference by the courts
            </SubHeadWrapHeading>
            <br />
            <p>
              Under the Arbitration and Conciliation Act, 1996, arbitral awards
              are not subject to appeal. The Court may set aside arbitral awards
              under the grounds mentioned under Section 34 but this is not an
              appellate power to review the decisions made by arbitral
              tribunals. The 1996 Act was enacted in consonance with the
              UNCITRAL Model Law which sought to make arbitrations cost and
              time-effective by reducing the interference of the courts.
            </p>
            <br />
            <SubHeadWrapHeading>2. Expedited procedure</SubHeadWrapHeading>
            <br />
            <p>
              The Act, 1996 under Section 29B allows the parties to an
              arbitration agreement to resolve their dispute by fast-track
              procedure. This procedure provides that the arbitral tribunal
              shall decide the dispute on the basis of written pleadings,
              documents and submissions filed by the parties without any oral
              hearing. The award under this section shall be made within a
              period of six months from the date that the arbitral tribunal
              enters upon the reference. This is helpful for parties looking for
              speedy and effective resolution.
            </p>
            <br />
            <SubHeadWrapHeading>
              3. Greater level of expertise
            </SubHeadWrapHeading>
            <br />
            <p>
              Arbitrators are generally professionals with specialized domain
              expertise and hence, would provide better quality justice in
              shorter time periods because of their familiarity with the subject
              matter. This would also boost confidence in the quality of the
              proceedings and the resultant arbitral awards. The proceedings
              would be shorter compared to court hearings, less expensive and
              subject to the availability of the parties and arbitrators
              involved.
            </p>
            <br />
            <SubHeadWrap>Mediation</SubHeadWrap>
            <br />
            <p>
              Mediation allows parties to explore practicable and interest-based
              solutions in a private, person-centric, confidential space in
              order to ensure the continuance of business and personal
              relationships. This ensures that precious time and resources are
              saved by not taking recourse to the court system which could
              prolong disputes and bring strife into interpersonal and
              professional relationships. The time and cost benefits of using
              mediation are as follows:
            </p>
            <br />
            <SubHeadWrapHeading>1. Amount of preparation</SubHeadWrapHeading>
            <br />
            <p>
              The costs and length of IPR litigation in some European nations
              was captured by WIPO studies which show that litigation continues
              to be a time and cost-intensive process, which leads to narrower
              outcomes. However, it takes significantly less effort to bring
              parties to mediation. The mediator does not need to depend on
              voluminous evidence and statements in order to bring the parties
              to resolution.
            </p>
            <br />
            <SubHeadWrapHeading>2. Rapid settlements</SubHeadWrapHeading>
            <br />
            <p>
              Mediation provides a useful opportunity to the parties to identify
              the terms of the dispute with precision. The parties have the
              power to decide the scope of the mediation. This forms the basis
              for direct negotiation between the parties (in the presence of a
              neutral) without cumbersome delays. It allows the parties to
              identify only those issues that are significant to the dispute as
              also to reach quicker settlements that could be decided at any
              stage of the resolution process thus saving time and money.
            </p>
            <br />
            <SubHeadWrapHeading>3. Flexibility</SubHeadWrapHeading>
            <br />
            <p>
              There are no fixed methods in mediation; it is widely understood
              that different mediators adopt differing styles. This allows for a
              flexible tailored approach in keeping with the specific needs of
              the parties thus reducing time and miscellaneous costs. The
              parties also reserve the right to exit when they find the process
              unsuitable as a method of resolving their dispute.
            </p>
            <br />
            <SubHeadWrapHeading>4. Procedural costs</SubHeadWrapHeading>
            <br />
            <p>
              Since the presence of lawyers in mediation is not mandatory, this
              helps in saving costs in regard to payments made to legal
              professionals. There are also no procedural fees involved, unlike
              in litigation.
            </p>
            <br />
            <p>
              It is therefore, apparent that the costs of litigation far
              outweigh the costs of Alternative Dispute Resolution methods and
              the latter should be preferred in order to facilitate speedier and
              more effective resolution. The time spent by litigants in a
              labyrinthine legal system could be saved by taking recourse to
              other options that allow for more control and success in the
              resolution of disputes thus underscoring the time and cost
              benefits of using arbitration and mediation.
            </p>
            <br />
            <Block />
            <p>
              <sup>1</sup>
              <a
                style={{
                  color: "blue",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open(
                    "https://www.wipo.int/wipo_magazine/en/2010/01/article_0008.html"
                  )
                }
              >
                {
                  "https://www.wipo.int/wipo_magazine/en/2010/01/article_0008.html"
                }
              </a>
            </p>
          </Content>
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default OnlineDisputeFifteen;

export const ContentExample = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
`;

export const Block = styled.div`
  width: 153px;
  height: 0;
  border: 1px solid ${COLORS.INPUT_BLACK};
  margin: 3px;
  display: inline-block;
  margin-bottom: 12px;
  font-family: ${theme.fonts.primaryFontRegular};
`;
export const HeadWrap = styled.div`
  text-align: center;
  font-size: 18px;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontBold};
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 25px;
  }
`;

export const SubHeadWrapHeading = styled.div`
  text-align: left;
  font-size: 20px;
  padding-left: 1.5%;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontRegular};
`;
