import React, { useEffect } from "react";
import { Content, SubHeadWrap, BlogPage } from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

/* eslint-disable jsx-a11y/anchor-is-valid */

function OnlineDisputeSeventeen(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header>
        <BlogPage>
          <HeadWrap>Enforcement of Foreign Arbitral Awards</HeadWrap>
          <br />
          <br />
          <Content>
            <SubHeadWrap style={{ marginBottom: "10px" }}>Part II</SubHeadWrap>
            <SubHeadWrap style={{ marginBottom: "10px" }}>
              The Early Stages
            </SubHeadWrap>
            <p>
              Prior to the enactment of The Arbitration and Conciliation Act,
              1996 which was modelled on the UNCITRAL Model Law, the Arbitration
              Act, 1940 was enacted to consolidate and amend the law relating to
              arbitration.
            </p>
            <br />
            <p>
              The Arbitration Act, 1940 allowed for court interference at
              various stages of the arbitration proceedings which stalled the
              progress of the dispute and gave the court oversight over the
              resolution process. These delays and the lack of speedy resolution
              led to foreign investors becoming sceptical of investing in
              unstable domestic business environments. The Supreme Court in the
              case of Guru Nanak Foundations Vs. Rattan Singh[1] held that “the
              way in which proceedings under the Act are conducted and without
              an exception challenged, has made lawyers laugh and legal
              philosophers weep” in view of the “unending prolixity, at every
              stage providing a legal trap to the unwary.”
            </p>
            <br />
            <p>This criticism comes from the fact that</p>
            <br />
            <div style={{ marginLeft: "40px" }}>
              <ul>
                <li>
                  The 1940 Act gave powers to the court to interfere with the
                  proceedings and undercut the authority of the arbitral
                  tribunals,
                </li>
                <li>
                  The parties could move the courts whenever they felt that the
                  matter was not proceeding in their favour and,
                </li>
                <li>
                  The awards granted were allowed to be challenged on numerous
                  grounds which defeated the entire purpose of arbitration
                </li>
              </ul>
            </div>
            <br />
            <SubHeadWrap>The Aftermath</SubHeadWrap>
            <br />
            <p>
              Under Part II of the Arbitration and Conciliation Act, 1996, the
              imprint of the New York Convention can clearly be seen. Section
              47, for example, reflects words used in the Convention verbatim
              and other sections are similarly enumerated in the spirit of the
              Convention.
            </p>
            <br />
            <p>
              Section 47 states{" "}
              <span
                style={{
                  fontStyle: "italic",
                }}
              >
                “Evidence.—(1) The party applying for the enforcement of a
                foreign award shall, at the time of the application, produce
                before the court—
              </span>
            </p>
            <br />
            <p
              style={{
                fontStyle: "italic",
              }}
            >
              (a) the original award or a copy thereof, duly authenticated in
              the manner required by the law of the country in which it was
              made;
            </p>
            <br />
            <p
              style={{
                fontStyle: "italic",
              }}
            >
              (b) the original agreement for arbitration or a duly certified
              copy thereof; and
            </p>
            <br />
            <p
              style={{
                fontStyle: "italic",
              }}
            >
              (c) such evidence as may be necessary to prove that the award is a
              foreign award.
            </p>
            <br />
            <p
              style={{
                fontStyle: "italic",
              }}
            >
              (2) If the award or agreement to be produced under sub-section (1)
              is in a foreign language, the party seeking to enforce the award
              shall produce a translation into English certified as correct by a
              diplomatic or consular agent of the country to which that party
              belongs or certified as correct in such other manner as may be
              sufficient according to the law in force in India.”
            </p>
            <br />
            <p>
              This is derivative of Article 4 of the Convention which states,
            </p>
            <br />
            <p
              style={{
                fontStyle: "italic",
              }}
            >
              “To obtain the recognition and enforcement mentioned in the
              preceding article, the party applying for recognition and
              enforcement shall, at the time of the application, supply:
            </p>
            <br />
            <p
              style={{
                fontStyle: "italic",
              }}
            >
              (a) The duly authenticated original award or a duly certified copy
              thereof;
            </p>
            <br />
            <p
              style={{
                fontStyle: "italic",
              }}
            >
              (b) The original agreement referred to in Article II or a duly
              certified copy thereof.
            </p>
            <br />
            <p
              style={{
                fontStyle: "italic",
              }}
            >
              2. If the said award or agreement is not made in an official
              language of the country in which the award is relied upon, the
              party applying for recognition and enforcement of the award shall
              produce a translation of these documents into such language. The
              translation shall be certified by an official or sworn translator
              or by a diplomatic or consular agent.”
            </p>
            <br />
            <p>
              The striking similarity between the Convention and domestic law
              may be observed and is a demonstration of India’s commitment to
              the Convention and to other contracting parties. This law reflects
              the Government’s attempts to create a conducive environment that
              promotes arbitration at all levels.
            </p>
            <br />
            <SubHeadWrap>
              Bharat Aluminium Co v. Kaiser Aluminium Technical Services
              (‘BALCO’)[2]
            </SubHeadWrap>
            <br />
            <p>
              This judgement, recognised as influential, laid down broad
              propositions in regard to harmonising inconsistent decisions taken
              in past arbitration matters. It also paved the way in bringing the
              arbitration regime in India on par with the New York Convention
              and UNCITRAL Model Law as well as to attenuate the court’s role in
              intervening in ongoing arbitrations.[3]
            </p>
            <br />
            <p>
              While discussing whether Part I of the Act had been impliedly
              excluded, the court overruled{" "}
              <span style={{ fontStyle: "italic" }}>
                Bhatia International v. Bulk Trading SA[4] and Venture Global
                Engineering v. Satyam Computer Services Ltd[5]
              </span>{" "}
              and held that Part I of the Act would be considered impliedly
              excluded when the juridical seat is outside India or where a
              foreign law is chosen as the law governing the arbitration
              agreement. This would apply prospectively only to arbitration
              agreements concluded on or after 6 September, 2012.
            </p>
            <br />
            <SubHeadWrap>In conclusion</SubHeadWrap>
            <br />
            <p>
              Nations globally have striven to create a regime of standard legal
              rights, liabilities and procedures that help in seamless
              resolution of disputes through the adoption of various Conventions
              since 1928. India has fulfilled its international obligations by
              accepting established global norms by the incorporation of various
              amendments, legislations and precedents. Though India’s experience
              with the ADR ecosystem has been rife with missteps, progress is
              steadily being made in regard to building a reliable dispute
              resolution system which instils confidence in the minds of
              businesses, investors and other stakeholders.
            </p>
          </Content>
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default OnlineDisputeSeventeen;

export const ContentExample = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
`;

export const Block = styled.div`
  width: 153px;
  height: 0;
  border: 1px solid ${COLORS.INPUT_BLACK};
  margin: 3px;
  display: inline-block;
  margin-bottom: 12px;
  font-family: ${theme.fonts.primaryFontRegular};
`;
export const HeadWrap = styled.div`
  text-align: center;
  font-size: 18px;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontBold};
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 25px;
  }
`;

export const SubHeadWrapHeading = styled.div`
  text-align: left;
  font-size: 20px;
  padding-left: 1.5%;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontRegular};
`;
