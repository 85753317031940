import React, { useEffect } from "react";
import { Content, HeadWrap, SubHeadWrap, BlogPage } from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { Helmet } from "react-helmet";

/* eslint-disable jsx-a11y/anchor-is-valid */

function OnlineDisputeBlogTwelve(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>Enforcement of Foreign Arbitral Awards - JustAct</title>
        <meta
          name="description"
          content="Learn about the enforcement of foreign arbitral awards, ensuring legal protection globally. Explore laws, procedures, and challenges."
        />
        <meta
          name="keywords"
          content="Enforcement of Foreign Arbitral Awards, Arbitration Enforcement Procedures, Arbitration Awards Enforcement Process, Dispute Resolution, Online Arbitration, JustAct"
        />
      </Helmet>
      <Header>
        <BlogPage>
          <HeadWrap>Enforcement of Foreign Arbitral Awards</HeadWrap>
          <br />
          <SubHeadWrap>Introduction</SubHeadWrap>
          <br />
          <Content>
            <p>
              Effective and speedy dispute resolution mechanisms for domestic
              and global investors are essential in order to facilitate the
              growth of business, trade and commercial transactions across the
              globe. If foreign awards are not properly enforced within the
              country, the purpose of resorting to swifter methods of resolution
              is defeated and the parties may be compelled to take recourse to
              the traditional legal system. It is in this context that the
              proper enforcement of foreign arbitral awards in the country is
              crucial.
            </p>
            <br />
            <SubHeadWrap>A Brief History</SubHeadWrap>
            <br />
            <p>
              A general consensus to recognise and enforce foreign arbitral
              awards was first brought forth by the Geneva Protocol of 1923
              under Articles 1, 2 and 3.
            </p>
            <br />
            <p>
              It was subsequently followed by the Geneva Convention of 1927.
              Article 1 of the 1927 Convention enumerates that arbitral awards
              shall be recognized as binding and enforceable in accordance with
              rules of the procedure of the territory where the award is relied
              upon.
            </p>
            <br />
            <p>
              These treaties would form the first of many pacts among nations
              that would be signed to materialize the outcomes of international
              arbitrations.
            </p>
            <br />
            <p>
              The Arbitration (Protocol and Convention) Act, 1937, in its
              interpretation clause, defines the term ‘foreign award’ within the
              context of commercial law and arbitration and the succeeding
              provisions give effect to such awards. This Act was the first
              attempt at enforcing within the Indian territory arbitral awards
              otherwise decided in foreign jurisdictions. It would lay the
              foundation for future legislation such as the Arbitration Act,
              1940 and the Arbitration and Conciliation Act, 1996 both in a
              general sense and in regard to enforcing foreign awards.
            </p>
            <br />
            <p>
              Later, The Convention on the Recognition and Enforcement of
              Foreign Arbitral Awards, also known as the New York Convention,
              was adopted in 1958. This charter along with the UNCITRAL Model
              Law on International Commercial Arbitration (1985) paved the way
              for the current state of Indian laws and practices in enforcing
              foreign awards.
            </p>
            <br />
            <p>
              This article is primarily focused on the New York Convention and
              the ways in which it helped reform ineffective laws into viable
              mechanisms of effectuating foreign awards in India.
            </p>
            <br />
            <SubHeadWrap>The New York Convention</SubHeadWrap>
            <br />
            <p>
              The Convention on the Recognition and Enforcement of Foreign
              Arbitral Awards, 1958 also known as New York Convention, entered
              into by 169 countries, is meant to give effect to outcomes of
              arbitrations held outside the territorial jurisdiction of the
              country where the award is sought to be enforced.
            </p>
            <br />
            <p>
              The Convention has gained wide acceptance across the world. The
              UNCITRAL Model Law on International Commercial Arbitration, 1985
              was developed to work in tandem with the Convention, with respect
              to every stage of the arbitration process.
            </p>
            <br />
            <ul style={{ marginLeft: "40px" }}>
              <li>
                Article 1 of the Convention deals with its applicability in
                various contexts and the scope of the term ‘arbitral awards’.
              </li>
              <li>
                Article 2 specifies that written arbitration agreements shall be
                recognized by the contracting states, the agreements shall
                contain arbitral clauses and the court of a contracting party
                shall refer the disputes to arbitration.
              </li>
              <li>
                Article 3 holds that each Contracting State shall recognize
                arbitral awards as binding and enforce them in accordance with
                the rules of procedure of the territory where the award is
                relied upon, under the conditions laid down in the following
                articles.
              </li>
              <li>
                Article 7 repeals the authority of the Geneva Protocol of 1923
                and Geneva Convention of 1927 and empowers any other bilateral
                or multilateral treaty/(ies) the state/s may be enjoying in
                relation to the enforcement of foreign awards. It also holds
                that no party shall be deprived of its right under such a treaty
                owing to the New York Convention.
              </li>
            </ul>
            <br />
            <SubHeadWrap>The past</SubHeadWrap>
            <br />
            <p>
              Prior to the enactment of The Arbitration and Conciliation Act,
              1996 which was modelled on the UNCITRAL Model Law, the Arbitration
              Act, 1940 applied to matters relating to Alternative Dispute
              Resolution.
            </p>
            <br />
            <p>
              The Arbitration Act, 1940 allowed for court interference at
              various stages of the arbitration proceedings which stalled the
              progress of the dispute and gave the court oversight over the
              resolution process. This resulted in delays which led to foreign
              investors becoming sceptical of investing in unstable domestic
              business environments. This was exacerbated by the fact that the
              system did not guarantee speedy resolution of disputes and a
              commitment to the rule of law among other reasons.
            </p>
            <br />
            <p>
              The Supreme Court in the case of{" "}
              <span style={{ fontStyle: "italic" }}>
                Guru Nanak Foundations Vs. Rattan Singh
              </span>{" "}
              held that “the way in which proceedings under the Act are
              conducted and without an exception challenged, has made lawyers
              laugh and legal philosophers weep” in view of the “unending
              prolixity, at every stage providing a legal trap to the unwary.”
            </p>
            <br />
            <p>
              This criticism comes from the fact that
              <br />
              <ul style={{ paddingLeft: "40px" }}>
                <li>
                  The 1940 Act gave powers to the court to interfere with the
                  proceedings and undercut the authority of the arbitral
                  tribunals.
                </li>
                <li>
                  The parties could move the courts whenever they felt that the
                  matter was not proceeding in their favour and,
                </li>
                <li>
                  The awards granted were allowed to be challenged on numerous
                  grounds which defeated the entire purpose of ADR.
                </li>
              </ul>
            </p>
            <br />
            <SubHeadWrap>The Aftermath</SubHeadWrap>
            <br />
            <p>
              Under Part II of the Arbitration and Conciliation Act, 1996, the
              imprint of the New York Convention can clearly be seen. Section
              47, for example, reflects words used in the Convention verbatim
              and other sections are similarly enumerated in the spirit of the
              guidelines set by the Convention.
            </p>
            <br />
            <p>Section 47 states</p>
            <br />
            <p>“Evidence —</p>
            <br />
            <p>
              (1) The party applying for the enforcement of a foreign award
              shall, at the time of the application, produce before the court—
            </p>
            <br />
            <p>
              (a) the original award or a copy thereof, duly authenticated in
              the manner required by the law of the country in which it was
              made;
            </p>
            <br />
            <p>
              (b) the original agreement for arbitration or a duly certified
              copy thereof; and
            </p>
            <br />
            <p style={{ marginBottom: "18px" }}>
              (c) such evidence as may be necessary to prove that the award is a
              foreign award.
            </p>
            <br />
            <p>
              (2) If the award or agreement to be produced under sub-section (1)
              is in a foreign language, the party seeking to enforce the award
              shall produce a translation into English certified as correct by a
              diplomatic or consular agent of the country to which that party
              belongs or certified as correct in such other manner as may be
              sufficient according to the law in force in India.
            </p>
            <br />
            <p>
              This is derivative of Article 4 of the Convention which states:
            </p>
            <br />
            <p>
              1. To obtain the recognition and enforcement mentioned in the
              preceding article, the party applying for recognition and
              enforcement shall, at the time of the application, supply:
            </p>
            <br />
            <p>
              a) The duly authenticated original award or a duly certified copy
              thereof;
            </p>
            <br />
            <p>
              (b) The original agreement referred to in Article II or a duly
              certified copy thereof.
            </p>
            <br />
            <p>
              2. If the said award or agreement is not made in an official
              language of the country in which the award is relied upon, the
              party applying for recognition and enforcement of the award shall
              produce a translation of these documents into such language. The
              translation shall be certified by an official or sworn translator
              or by a diplomatic or consular agent.”
            </p>
            <br />
            <p>
              The striking similarity between the Convention and domestic law
              may be observed and is a demonstration of India’s commitment to
              the Convention and to other contracting parties. This law reflects
              the Government’s attempts to create a conducive environment that
              promotes arbitration at all levels.
            </p>
            <br />
            <SubHeadWrap>
              Bharat Aluminium Co v. Kaiser Aluminium Technical Services
              (‘BALCO’)
            </SubHeadWrap>
            <br />
            <p>
              This judgement is recognised as having been instrumental in laying
              down broad propositions in regard to harmonizing inconsistent and
              erroneous decisions in past arbitration matters, to bringing the
              arbitration regime in India on par with the New York Convention
              and UNCITRAL Model Law as well as to attenuate the court’s role in
              intervening in the arbitral process-
            </p>
            <br />
            <ul style={{ marginLeft: "40px" }}>
              <li>
                The Court held that Indian courts are not empowered to set aside
                foreign arbitral awards pursuant to S. 34 of the A & C Act,
                1996. It also noted that the courts will not hold any power
                towards awarding interim relief in foreign seated arbitrations
                pursuant to S. 9 of the same Act.
              </li>
              <li>
                The Indian courts’ jurisdiction was not dependent on deciphering
                the express or implied intention of the parties, and thus
                parties need not expressly exclude the application of Part I of
                the Act in agreements that provide for foreign-seated
                arbitration on or after 6 September 2012.
              </li>
              <li>
                A civil suit under the Code of Civil Procedure, 1908 will not
                lie for seeking interim relief in foreign-seated arbitrations
                primarily because interim relief would not constitute a
                substantive cause of action.
              </li>
            </ul>
            <br />
            <SubHeadWrap>Conclusion</SubHeadWrap>
            <br />
            <p>
              Nations globally have strived to create a regime of procedure and
              obligations that help in seamless resolution of disputes through
              the adoption of various Conventions beginning from the year 1928.
              India has fulfilled its international obligations by accepting
              established global norms in the incorporation of various
              amendments, legislations and precedents. Though India’s path
              through the ADR ecosystem has been rife with missteps, progress is
              steadily being achieved in regard to building a trustworthy
              dispute resolution system which instills confidence in the minds
              of businesses, investors and other stakeholders.
            </p>
            <br />
          </Content>
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default OnlineDisputeBlogTwelve;

export const ContentExample = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
`;

export const Block = styled.div`
  width: 353px;
  height: 0;
  border: 1px solid ${COLORS.INPUT_BLACK};
  margin: 3px;
  display: inline-block;
  font-family: ${theme.fonts.primaryFontRegular};
`;
