import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { PrimaryCTAButton } from "../../common/Buttons";

export const HeadWrap = styled.div`
  text-align: center;
  font-size: 25px;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontBold};
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 36px;
  }
`;

export const DivWrap = styled.div`
  font-size: 12px;
  justify-content: space-between;
  text-align: justify;
`;

export const SubHeading = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.BTN_GREEN};
  font-size: 18px;
  text-align: center;
`;

export const SubHeadWrap = styled.div`
  text-align: left;
  font-size: 22px;
  // padding:1.5%;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontRegular};
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 16px;
`;
export const ImageWrap = styled.div`
  text-align: center;
  margin: 1%;
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  padding: 1%;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontBold};
  width: 100%;
  @media (max-width: 640px) {
    flex-direction: column;
    padding: 0 15px;
  }
`;
export const ImageWrapper = styled.img`
  margin: 20px;
  width: 400px;
  height: 200px;
  border: 1px solid gray;

  @media (max-width: 600px) {
    margin: 20px;
    width: 300px;
    height: 150px;
  }
`;
export const ContentBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;
export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 500px;
  margin-left: 50px;
  @media (max-width: 1024px) {
    width: unset;
    text-align: center;
    margin: 50px;
  }
  @media (max-width: 640px) {
    margin: 50px 15px;
  }
`;
export const H2 = styled.h2`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
  font-size: 30px;
  margin-bottom: 32px;
  cursor: default;
  padding: 0 20px;
  margin: 0 auto;
  @media (max-width: 1024px) {
    font-size: 24px;
    text-align: center;
  }
`;
export const LightWrapper = styled.div`
  font-size: 12px;
  justify-content: space-between;
  text-align: justify;
  font-family: ${theme.fonts.primaryFontRegular};
  color: ${COLORS.COLOR_DARK};
`;

export const BlogPage = styled.div`
  display: flex;
  flex-direction: column;
  text-align: justify;
  width: 70%;
  margin: 40px auto;
  @media (max-width: 640px) {
    width: 100%;
    margin: 0px;
    padding: 20px;
  }
`;

export const BlogImageWrap = styled.img`
  margin: auto;
  width: 100%;
  height: 170px;
  margin-left: auto;
  margin-right: auto;
  // border: 1px solid gray;
  // align-items:center;
  @media ${theme?.breakpoints?.sm_up} {
    width: 350px;
  }
`;

export const PrimaryStyledButton = styled(PrimaryCTAButton)`
  width: 30%;
  margin: 5px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  font-size: 12px;
  padding: 10px;
  font-family: ${theme.fonts.primaryFontRegular};
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 15px;
  }
`;

export const BlogImage = styled.img`
  margin: auto;
  width: 624px;
  height: 343px;
  margin-left: auto;
  margin-right: auto;
  // border: 1px solid gray;
  // align-items:center;
  @media ${theme?.breakpoints?.sm_down} {
    width: 100%;
  }
`;

export const TextLines = styled.div`
  padding: 20px;
  margin-left: 20px;
`;

export const TextLinesData = styled.div`
  padding-left: 20px;
  margin-left: 20px;
  text-align: justify;
  & .first-li-before {
    list-style: "a.  ";
    list-style-position: outside !important;
  }
  & .second-li-before {
    list-style: "b.  ";
    list-style-position: outside !important;
  }
  & .third-li-before {
    list-style: "   ";
    list-style-position: outside !important;
  }
`;

export const Frame = styled.div`
  overflow: hidden;
  border-radius: 8px;
  width: auto;
  height: 200px;
  border: solid 0.3px #979797;
  margin: 0 15px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 300px;
  }
`;
