import React, { useEffect } from "react";
import { Content, SubHeadWrap, BlogPage } from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

/* eslint-disable jsx-a11y/anchor-is-valid */

function ExpertsAsArbitrator(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header>
        <BlogPage>
          <HeadWrap>Experts as Arbitrators</HeadWrap>
          <br />
          <br />
          <Content>
            <b
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontStyle: "italic",
              }}
            >
              {" "}
              Authored by : Aprameya Manthena{" "}
            </b>
            <SubHeadWrap style={{ marginTop: "10px", marginBottom: "15px" }}>
              Introduction
            </SubHeadWrap>
            <p>
              In a fast-paced world where disputes between private parties are
              gaining in complexity and value, the need for experts as
              arbitrators has increased greatly. In a traditional legal system,
              the judge, though exposed to various domains of law, is not a
              subject matter expert unless he/she has built areas of
              specialisation within his/her legal practice. It is the job of the
              lawyer as an officer of the court to assist the judge in properly
              comprehending the nuances of the dispute in question. The court
              has the time and opportunity to understand the matter in great
              depth and detail in the context of litigation. However,
              arbitration proceedings are subject to time-based limits in regard
              to the completion of various stages of the proceedings.
            </p>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              The Arbitration Amendment Act of 2019
            </SubHeadWrap>
            <p>
              Presently, due to the Arbitration and Conciliation (Amendment)
              Act, 2019, institutions offering Alternative Dispute Resolution
              services have received an added impetus in order to make India an
              arbitration-friendly jurisdiction. The High- Level Committee under
              the steering of Justice. B. N. Srikrishna was tasked with
              providing suggestions to encourage institutional arbitration among
              other issues.
            </p>
            <br />
            <p>
              Institutions providing ADR services empanel a wide range of
              subject matter experts as arbitrators and mediators in order to
              facilitate smooth resolution of disputes. Such empanelled
              arbitrators have expertise and experience in fast-evolving fields
              of law including finance, energy, information technology and IPR
              among others. Institutions are also meant to assist the High Court
              and Supreme Court in the appointment of arbitrators as per the
              Amendment Act. This empowers them to play a more active role in
              the arbitration landscape in the country.
            </p>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              Experts as arbitrators
            </SubHeadWrap>
            <p>
              It has been the trend to appoint retired High Court and Supreme
              Court judges as arbitrators because of their knowledge and
              familiarity with the law. However, arbitration in the present day,
              is greatly benefited by expertise in domain subject matter as
              discussed below:
            </p>
            <br />
            <p>
              Since the experts have a close understanding of the industry
              concerned, their insight and handling of the dispute would be far
              more masterful than a non-expert’s. They generally stay abreast of
              the latest developments in the law and their respective fields of
              expertise.
            </p>
            <br />
            <p>
              Expertise in the subject matter would mean that complex, technical
              facts could be established with greater clarity and issues could
              be framed with greater precision.
            </p>
            <br />
            <p>
              It saves time and the need for multiple hearings since an expert
              has the technical know-how to immediately hone in on the issues
              concerned and offer certain helpful perspectives while being
              grounded in the law.
            </p>
            <br />
            <p>
              Experts could also offer suggestions on procedure that allow for
              quicker resolution of disputes in keeping with the nature of the
              subject matter.  The expert could guide the proceedings in keeping
              with their understanding of the issues and subject matter, without
              getting distracted by the complexities of the case.
            </p>
            <br />
            <p>
              Appointing experts as neutrals would greatly improve the quality
              of orders. This is especially crucial in light of the awards being
              challenged often in court.
            </p>
            <br />
            <p>
              Experts can bring a level of objectivity and grounded analysis to
              a dispute that would otherwise suffer from confusion and lack of
              comprehension around technical information.
            </p>
            <br />
            <p>
              They do not offer value judgments and are not guided by morality
              in the determination of disputes, unlike litigation which tends to
              heighten emotions.
            </p>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              In conclusion
            </SubHeadWrap>
            <p>
              The future of arbitration in the country involves the
              participation of experts in disputes, whether before, during or
              after the process, for speedy and timely resolution of contentious
              issues. Experts have a range of advantages to offer that could
              transform the present ADR ecosystem for the better.
            </p>
          </Content>
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default ExpertsAsArbitrator;

export const ContentExample = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
`;

export const Block = styled.div`
  width: 193px;
  height: 0;
  border: 1px solid ${COLORS.INPUT_BLACK};
  margin: 3px;
  display: inline-block;
  margin-top: 12px;
  margin-bottom: 12px;
  font-family: ${theme.fonts.primaryFontRegular};
`;

export const HeadWrap = styled.div`
  text-align: center;
  font-size: 18px;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontBold};
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 25px;
  }
`;

export const SubHeadWrapHeading = styled.div`
  text-align: left;
  font-size: 20px;
  padding-left: 1.5%;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontRegular};
`;

export const TextLinesData = styled.div`
  padding-left: 30px;
  margin-left: 30px;
  text-align: justify;
`;
