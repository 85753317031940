import React, { Fragment, useState } from "react";
import { Container, TableWrapper, HyperLink, StyledTitle } from "./Styles";
import { Heading } from "../../../styles/component/style";
import _ from "lodash";
import { navigate } from "@reach/router";
import ActionBar from "../../common/ActionBar";
import CustomTable from "../../common/CustomTable/CustomTable";
import queryString from "query-string";
import CaseService from "../../../services/CaseService";
import moment from "moment";
import {
  getErrorMessage,
  parseTimeStamp,
  numberFormat,
} from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import labels from "../../../helpers/labels.json";

const Index = () => {
  const [state, setState] = useState();
  const breadcrumbs = [_.startCase(labels.home), labels.case_invites];
  const { enqueueSnackbar } = useSnackbar();

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "home":
        navigate("/dashboard");
        break;
      default:
        break;
    }
  };

  async function getCases(query) {
    try {
      const response = await CaseService.caseInvitesSent(query);
      return { ...response };
    } catch (error) {
      throw error;
    }
  }

  const columns = [
    {
      field: "id",
      title: labels["table.case_id"],
      sorting: false,
      tooltip: "Unique Identifier for the Case across JustAct platform",
      render: (rowData) => (
        <HyperLink onClick={() => navigate(`/dashboard/cases/${rowData.id}`)}>
          {rowData.id}
        </HyperLink>
      ),
      headerStyle: {
        paddingLeft: "28px",
        display: "flex",
        flexWrap: "nowrap",
      },
      cellStyle: {
        paddingLeft: "28px",
        width: "11%",
      },
    },
    {
      field: "title",
      title: labels["table.case_title"],
      sorting: false,
      render: (rowData) => (
        <StyledTitle onClick={() => navigate(`/dashboard/cases/${rowData.id}`)}>
          {rowData.title}
        </StyledTitle>
      ),
    },
    {
      field: "claimantParty.name",
      title: labels["table.party"],
      sorting: false,
      tooltip: "Parties representing to file the case",
      render: (rowData) => <>{_.startCase(rowData.claimantParty.name)}</>,
    },
    {
      field: "respondentName",
      title: labels["table.sent_to"],
      sorting: false,
      tooltip: "Sent to User",
      render: (rowData) => (
        <div>
          {rowData?.respondentParty?.name ? (
            rowData.respondentParty.name
          ) : rowData?.respondentName ? (
            rowData.respondentName
          ) : rowData?.respondentParties?.length ? (
            rowData?.respondentParties[0]?.name
          ) : (
            <div style={{ marginLeft: 35 }}>-</div>
          )}
        </div>
      ),
    },
    {
      field: "resolutionKind",
      title: labels["table.caseType"],
      render: (rowData) => <>{_.startCase(rowData.resolutionKind)}</>,
      headerStyle: {
        minWidth: 140,
      },
      sorting: false,
    },
    {
      field: "totalClaimValue",
      title: labels["table.claim_value"],
      tooltip: "Claim Value of the Case",
      render: (rowData) => {
        const caseItems = rowData.totalClaimValue;
        if (caseItems)
          return numberFormat(
            parseFloat(caseItems).toFixed(2),
            rowData?.currencyUnit
          );
        // return (
        //   "INR " +
        //   formatNumberToMetrics(parseFloat(caseItems).toFixed(2), "INR")
        // );
        else return "Non Monetary";
      },
      headerStyle: {
        width: "15%",
      },
    },
    {
      field: "submittedOn",
      title: labels["table.sent_on"],
      render: (rowData) => (
        <>
          {rowData?.submittedOn
            ? moment(rowData.submittedOn).format("DD/MM/YYYY")
            : moment(parseTimeStamp(rowData.created_at)).format("DD/MM/YYYY")}
        </>
      ),
    },
    {
      field: "respondentStatus",
      title: labels["table.status"],
      sorting: false,
      render: (rowData) => _.startCase(rowData.respondentStatus),
    },
  ];

  const data = (query) =>
    new Promise((resolve) => {
      let params = {
        page: query.page + 1,
        perPage: 10,
        query: query.search,
      };
      if (query.orderBy?.field) {
        params.sort = query.orderBy.field;
        params.sortDirection = query.orderDirection;
      }
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      getCases(stringParams)
        .then((result) => {
          if (result) {
            resolve({
              data: result.data,
              page: result.page - 1,
              total: result.total,
            });
            setState(result);
          }
        })
        .catch((error) => {
          enqueueSnackbar(getErrorMessage(error), {
            variant: "error",
          });
          setState({ data: [], lastPage: 1, page: 1, perPage: 10, total: 0 });
          resolve({
            data: [],
            page: 0,
            total: 0,
          });
        });
    });

  return (
    <Fragment>
      <ActionBar {...{ breadcrumbs, onBreadcrumbClick }} />
      <Container className="CreateCase">
        <Heading>{labels.case_invites}</Heading>
        <TableWrapper>
          <CustomTable
            {...{
              columns,
              data,
              state,
            }}
            noToolbar
            pageSize={10}
            singularTitle=""
            pluralTitle={labels.invites}
          />
        </TableWrapper>
      </Container>
    </Fragment>
  );
};
export default Index;
