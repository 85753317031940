import React, { useEffect } from "react";
import {
  Content,
  SubHeadWrap,
  BlogPage,
  BlogImage,
  TextLinesData,
} from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import { Helmet } from "react-helmet";
import { HyperLink } from "../../common/FormInputs";

function OnlineDisputeBlogThree(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>
          Understanding Arbitration & The Arbitration Act - Negotiation
          Mediation & Arbitration
        </title>
        <meta
          name="description"
          content="Arbitration is one of the three methods of Dispute Resolution. Dispute Resolution uses Negotiation, Mediation and Arbitration in order to let parties arrive at a consensual resolution without having to go to court."
        />
        <meta
          name="keywords"
          content="Understanding Arbitration and The Arbitration Act, Dispute Resolution Mechanisms , Mediation, Consensual Resolution, Arbitration. Online Arbitration, Online Mediation, Arbitration Dispute Resolution, JustAct"
        />
      </Helmet>
      <Header>
        <BlogPage>
          <SubHeadWrap>
            Understanding Arbitration and The Arbitration Act
          </SubHeadWrap>
          <br />
          <Content>
            <p>
              <HyperLink onClick={() => window.open(`https://justact.co.in/`)}>
                Dispute Resolution
              </HyperLink>{" "}
              uses the mechanisms of Negotiation and Mediation in order to let
              parties arrive at a consensual resolution.{" "}
              <HyperLink
                onClick={() =>
                  window.open(`https://justact.co.in/arbitration-rules`)
                }
              >
                Arbitration
              </HyperLink>{" "}
              is the third main method of Dispute Resolution. It is a mechanism
              in which a dispute is submitted, subject to agreement between the
              parties, wherein one or more{" "}
              <HyperLink
                onClick={() =>
                  window.open(`https://justact.co.in/neutral/arbitrators`)
                }
              >
                arbitrators
              </HyperLink>{" "}
              make a binding decision. In simple terms, it’s one of the three
              dispute resolution methods by which people try to arrive at a
              solution without having to go to court.
            </p>
            <br />
            <BlogImage
              src={require("../../../assets/images/BlogThree.png")}
              alt="Independent"
            />
            <br />
            <p>
              The conduct of the arbitration proceeding is very much outside of
              a court, but resembles a hearing nevertheless, in that both sides
              present their statements and evidence to make a strong case. The
              Arbitration and Conciliation Act, 1996 governs the law of
              Arbitration in India and sets out the conditions under which the
              process of Arbitration, whether domestic or international is
              conducted. It also covers aspects of enforceability of the
              arbitral awards.
            </p>
            <br />
            <p>
              One major milestone in Arbitration in India was set when the 2019
              Amendment of the Indian Arbitration and Conciliation Act 1996 was
              made. The Arbitration and Conciliation (Amendment) Act came into
              effect from 9th August 2019, and was intended to make India an
              Arbitration friendly jurisdiction. The 2019 Amendment inter alia
              introduced the following amendments in Section 11 and Section 29A
              of the Arbitration and Conciliation Act.
            </p>
            <br />
            <p>
              <b>Section 11:</b> The 2019 Amendment Act introduced an
              appointment procedure by arbitral institutions designated
              specifically by the Supreme Court in cases of International
              Commercial Arbitration and the High Court in the other cases
              wherever the Council has graded arbitral institutions, and on an
              application made by parties. Alternatively, it provides for
              maintaining the panel of arbitrators by the Chief Justice of the
              concerned High Court for the discharging function of the arbitral
              institutions.
            </p>
            <br />
            <p>
              The amendment also clarifies that in a situation wherein more than
              one request has been made under sub-section (4) or sub-section (5)
              or sub-section (6) to different arbitral institutions, the
              arbitral institution to which the request has been first made
              shall be competent to appoint, and that the application made under
              this section shall be disposed of by the arbitral institution
              within a period of thirty days from the date of service of notice
              on the opposite Party.
            </p>
            <br />
            <p>
              <b>Section 29A:</b> The provision through 2015 Amendment mandated
              that an award shall be passed in a matter within 12 months of the
              arbitral tribunal entering upon the reference and that the parties
              may, by consent, extend the time for making an award by another
              six months. The 2019 Amendment Act added the following amendments
              to sub section (1) of section 29A:
            </p>
            <br />
            <p>
              One of the silver linings of the COVID pandemic is the adoption of
              online hearings. Even so, two problems still manifest. The first
              is solving the capacity problem, and then there’s the problem of
              accessibility to the judicial system. The only solution is that
              the reach of dispute resolution mechanism has to go beyond what it
              is today.
            </p>
            <br />
            <TextLinesData>
              <ul class="first-li-before">
                <li>
                  international commercial arbitrations shall be excluded from
                  the purview of the timeline provided in the section, but
                  parties thereto must endeavour [retaining the UK English
                  spelling] to adhere to the same; and
                </li>
              </ul>
              <ul class="second-li-before">
                <li>
                  For international commercial arbitration, the time limit for
                  arbitral award shall be within 12 months from the completion
                  of the pleadings of the parties under section 23(4) of the
                  Arbitration Act instead of the existing period of 12 months
                  that starts from the date on which the arbitral tribunal
                  enters upon the reference.
                </li>
              </ul>
              <br />
              <ul class="third-li-before">
                <li>
                  Further amendment was with respect to adhering to the timeline
                  of award being made within six months from the date on which
                  arbitral tribunal enters upon the reference. In such a case,
                  the arbitral tribunal shall be entitled to receive such amount
                  of additional fees as the parties may agree.
                </li>
              </ul>
            </TextLinesData>
            <br />
            <p>
              Thus the 2019 amendments aimed at revamping the appointment
              procedure for Arbitrators under Section 11 and set stricter
              timelines for completion of the Arbitration process under section
              29A. We will discuss the impact of these amendments to Section 11
              and 29A in expediting/impeding the arbitral process in our next
              article!
            </p>
            <br />
          </Content>
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default OnlineDisputeBlogThree;
