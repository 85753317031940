import React, { useEffect } from "react";
import { Content, HeadWrap, SubHeadWrap, BlogPage } from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { Helmet } from "react-helmet";

/* eslint-disable jsx-a11y/anchor-is-valid */

function OnlineDisputeBlogEleven(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>
          Culture of Mediation: Why has it not picked up in India? - JustAct
        </title>
        <meta
          name="description"
          content="JustAct Clearly explained here about the Culture of mediation and why has it not been picked up in India."
        />
        <meta
          name="keywords"
          content="Culture of mediation, Mediation Services, Mediation and Conciliation, Mediation Gulture, Cultural Mediation Practices, JustAct"
        />
      </Helmet>
      <Header>
        <BlogPage>
          <HeadWrap>
            Culture of mediation: why has it not picked up in India?
          </HeadWrap>
          <br />
          <SubHeadWrap>Introduction</SubHeadWrap>
          <br />
          <Content>
            <p>
              Alternate Dispute Resolution (ADR) refers to modes by which
              disputes may be resolved without taking recourse to the court
              system. Mediation, among other ADR options, offers voluntary
              participation, flexibility, confidentiality, speedy and
              cost-effective solution-finding with the assistance of the
              mediator, a third party with no stake in the outcome. Litigation
              prolongs disputes, relationships between parties break down due to
              the protracted nature of the process and the outcome is determined
              in favour of only one party. ADR developed as a mode to counteract
              the less than desirable effects of litigation.
            </p>
            <br />
            <SubHeadWrap>Efficacy of mediation as method</SubHeadWrap>
            <br />
            <p>
              Mediation is useful in matters that “do not involve complex
              questions of law or evidence (though mediation is being resorted
              to in complex financial and other disputes of late) and which are
              not dependent on lengthy procedures for final determination”.
              <sup>1</sup>
            </p>
            <br />
            <p>
              Mediation as a method of dispute resolution may be applied to a
              wide range of civil matters and its potential with respect to
              criminal cases is beginning to be explored. Mediation can help
              parties resolve disputes within preferred timelines because
              parties work together to achieve tailored solutions and the
              compliance with the agreements is voluntary.<sup>2</sup> There are
              presently five ways in which mediation as a dispute resolution
              process may be initiated:
            </p>
            <br />
            <ol style={{ paddingLeft: "40px" }}>
              <li>
                It may be provided for in dispute resolution clauses in
                contracts between parties
              </li>
              <li>
                Reference by the court as given under Section 89 of the Code of
                Civil Procedure, 1908
              </li>
              <li>
                Other legislation that provides for mediation as a mode of
                facilitating resolution<sup>3</sup>
              </li>
              <li>
                Mandatory pre-litigation mediation as provided under Section 12A
                of the Commercial Courts Act, 2015<sup>4</sup>
              </li>
              <li>By mutual consent of parties once the dispute has arisen</li>
            </ol>
            <br />
            <SubHeadWrap>
              Reasons why mediation is not popular despite its efficacy
            </SubHeadWrap>
            <br />
            <SubHeadWrap style={{ marginLeft: "20px" }}>
              <li> Binding Nature/Enforceability of Settlements</li>
            </SubHeadWrap>
            <br />
            <p>
              Mediation is not yet a mandatory or even widely preferred mode of
              dispute resolution in the country. One of the many reasons
              suggested is the possible lack of clarity surrounding the
              enforceability of mediation settlements.
            </p>
            <br />
            <p>
              India is a signatory (on August 7, 2019) to the United Nations
              Convention on International Settlement Agreements Resulting from
              Mediation (Singapore Mediation Convention), 2018<sup>5</sup> which
              provides for “a harmonized legal framework for the right to invoke
              mediation settlement agreements.” The Convention provides for the
              agreements to have binding force worldwide bringing mediation on
              par with regimes of litigation and arbitration. Though the
              provisions need to be incorporated within the domestic legal
              framework in order to gain force, this is a step in the right
              direction.
            </p>
            <br />
            <p>
              While there is no legislation which provides for mediation
              settlement agreements to be automatically binding, the Supreme
              Court in M.R. Krishna Murthi v. The New India Assurance Co. Ltd.
              and Ors<sup>6</sup> asked the Government to consider enacting a
              statute to remedy the position.
            </p>
            <br />
            <p>
              Where settlements are reached in court-annexed mediations, the
              court passes an order or decree in terms of the settlement agreed
              upon<sup>7</sup>. The preconceptions around weak compliance in
              private mediation also discourage parties from resorting to
              mediation.
            </p>
            <br />
            <SubHeadWrap style={{ marginLeft: "20px" }}>
              <li>Definitional confusion</li>
            </SubHeadWrap>
            <br />
            <p>
              In the Afcons Infrastructure Ltd. and Ors. v. Cherian Varkey
              Construction Co. (P) Ltd. and Ors<sup>8</sup> the Supreme Court
              examined drafting oversights in Section 89 which included semantic
              confusion around the definitions of “mediation” and “judicial
              settlement” and the details of the procedure to be followed when
              referring matters to mediation under Section 89. Despite the 238
              <sup>th</sup>
              Law Commission Report providing recommendations to seal the
              lacunae in understanding, there has been no effective
              implementation.<sup>9</sup>
            </p>
            <br />
            <SubHeadWrap style={{ marginLeft: "20px" }}>
              <li>Lack of Legislation</li>
            </SubHeadWrap>
            <br />
            <p>
              The Mediation Bill, 2021 seeks to provide clarity and codification
              to mediation as a practicable mode of dispute resolution. It
              stipulates the professionalisation of mediation as a practice, the
              binding nature of mediation agreements, the set-up of regulatory
              bodies for the registration, recognition and laying down of
              standards of professional conduct for mediators and the
              establishment of institutes to train and certify potential
              mediation service providers.<sup>10</sup> It aims to
              institutionalise mediation, to set up the Mediation Council of
              India and popularise community mediation among other provisions.
            </p>
            <br />
            <SubHeadWrap style={{ marginLeft: "20px" }}>
              <li>
                Training of District Judiciary to refer cases under Section 89,
                Code of Civil Procedure
              </li>
            </SubHeadWrap>
            <br />
            <p>
              Even though court-annexed mediation has emerged as a solution due
              to judgements like Salem Bar Association v. Union of India
              <sup>11</sup>, staff and services have been regularised at these
              centres and institutional mediation is also seen as a viable
              option<sup>12</sup>, reports suggest that lower court judges are
              not incentivised or trained to refer matters under section 89,
              Code of Civil Procedure. As a consequence of prolonged exposure to
              the adversarial system, they lack the required objectivity and
              acumen to determine the possibility of settlement between parties.{" "}
            </p>
            <br />
            <SubHeadWrap style={{ marginLeft: "20px" }}>
              <li>Lack of awareness in the general public</li>
            </SubHeadWrap>
            <br />
            <p>
              The public is believed to be unaware of the existence of ADR
              processes. Experts and qualified mediators could be encouraged to
              make more attempts to reach out to litigants and the general
              public through seminars, webinars, conferences and outreach
              programmes that inform and educate the larger community about the
              benefits of mediation which is more party-centric, party-driven
              and focused on practical outcomes, unlike litigation.{" "}
            </p>
            <br />
            <SubHeadWrap style={{ marginLeft: "20px" }}>
              <li>Myths around Mediation</li>
            </SubHeadWrap>
            <br />
            <p>
              Common myths perpetuated by the legal community include the idea
              that opting for mediation demonstrates weakness of character and
              the possibility of failure at trial. It is also believed that it
              is a “lesser form of justice and second to litigation”, where the
              risks are higher but the rewards are greater for the same reason .
              <sup>13</sup>
            </p>
            <br />
            <SubHeadWrap style={{ marginLeft: "20px" }}>
              <li>Paucity of Trained and Experienced Mediators</li>
            </SubHeadWrap>
            <br />
            <p>
              It has been acknowledged, by the authorities that be, that despite
              the overwhelming number of disputes that may be referred to
              mediation, the present number of empanelled mediators is woefully
              inadequate. The quality of the training and the lack of domain or
              subject-specific knowledge encumbers the mediators from resolving
              commercial and other disputes effectively.
            </p>
            <br />
            <Block />
            <br />
            <ContentExample>
              <p>
                <sup>1</sup>
                <a
                  style={{
                    color: "blue",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    window.open(
                      "https://vidhilegalpolicy.in/wp-content/uploads/2021/03/Mandatory-Mediation-in-India-Resolving-to-Resolve.pdf"
                    )
                  }
                >
                  https://vidhilegalpolicy.in/wp-content/uploads/2021/03/Mandatory-Mediation-in-India-Resolving-to-Resolve.pdf
                </a>
              </p>
              <p>
                <sup>2</sup>ibid
              </p>
              <p>
                <sup>3</sup>Statutes include Section 4 of the Industrial
                Disputes Act, Sections 37-38 of the Consumer Protection Act,
                2019, Section 89 read with Order X Rule 1A of the Code of Civil
                Procedure, 1908, Order XXXIIA of the CPC provides for mediation
                in family, matrimonial, guardianship, custody, personal and
                maintenance matters. Section 442 of the Companies Act, 2013 read
                with Rule 3 of the Companies (Mediation and Conciliation Rules),
                2016 also provide for referral of disputes to mediation. The
                Hindu Marriage Act, 1955 and the Special Marriage Act, 1954 also
                provide for the option of mediation to parties to attempt
                reconciliation before initiating proceedings among other
                statutes. Section 30 of the Arbitration and Conciliation Act,
                1996 also provides for mediation, alongside other ADR processes
                during arbitral proceedings to encourage settlement.
              </p>
              <p>
                <sup>4</sup>2018 Amendment to the Commercial Courts Act, 2015;
                the judgement Patil Automation (P) Ltd. v. Rakheja Engineers (P)
                Ltd., (2022) 10 SCC 1 confirmed pre-litigation mediation to be
                mandatory under the provision.
              </p>
              <p>
                <sup>5</sup>
                <a
                  style={{
                    color: "blue",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    window.open(
                      "https://uncitral.un.org/en/texts/mediation/conventions/international_settlement_agreements"
                    )
                  }
                >
                  https://uncitral.un.org/en/texts/mediation/conventions/international_settlement_agreements
                </a>
              </p>
              <p>
                <sup>6</sup>M.R. Krishna Murthi v. New India Assurance Co. Ltd.,
                (2020) 15 SCC 493
              </p>
              <p>
                <sup>7</sup>The Supreme Court in Afcons Infrastructure clarified
                that where the reference is to a neutral third party on a court
                reference, even though it will be deemed to be reference to a
                Lok Adalat, the mediation settlement will be governed by Section
                21 of the Legal Services Authorities Act, 1987 and will have to
                be placed before the court for recording the settlement and
                disposal. Consequently, in cases referred by courts to
                mediation, a settlement reached by the parties is not
                enforceable automatically. Even then, it is unclear how a
                settlement arrived during pre-litigation mediation or ad-hoc
                mediation would be enforced if one of the parties reneges on its
                promises. Applying the regular law of contract to such cases
                would only result in delays, defeating the entire purpose of
                resorting to mediation. With so much confusion around
                enforceability, lawyers hesitate to advise their clients to opt
                for mediation.{" "}
                <a
                  style={{
                    color: "blue",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    window.open(
                      "https://vidhilegalpolicy.in/wp-content/uploads/2021/03/Mandatory-Mediation-in-India-Resolving-to-Resolve.pdf"
                    )
                  }
                >
                  https://vidhilegalpolicy.in/wp-content/uploads/2021/03/Mandatory-Mediation-in-India-Resolving-to-Resolve.pdf
                </a>
              </p>
              <p>
                <sup>8</sup>(2010) 8 SCC 24
              </p>
              <p>
                <sup>9</sup>
                <a
                  style={{
                    color: "blue",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    window.open(
                      "https://vidhilegalpolicy.in/wp-content/uploads/2021/03/Mandatory-Mediation-in-India-Resolving-to-Resolve.pdf"
                    )
                  }
                >
                  https://vidhilegalpolicy.in/wp-content/uploads/2021/03/Mandatory-Mediation-in-India-Resolving-to-Resolve.pdf
                </a>
              </p>
              <p>
                <sup>10</sup>{" "}
                <a
                  style={{
                    color: "blue",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    window.open(
                      "https://prsindia.org/billtrack/the-mediation-bill-2021"
                    )
                  }
                >
                  https://prsindia.org/billtrack/the-mediation-bill-2021
                </a>
              </p>
              <p>
                <sup>11</sup>
                (2003) 1 SCC 49
              </p>
              <p>
                <sup>12</sup>
                <a
                  style={{
                    color: "blue",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    window.open(
                      "https://thewire.in/law/mediation-cji-judges-hyderabad"
                    )
                  }
                >
                  https://thewire.in/law/mediation-cji-judges-hyderabad
                </a>
              </p>
              <p>
                <sup>13</sup> Gupta, Juhi. 2018. “Bridge over Troubled Water:
                The Case for Private Commercial Mediation in India.” American
                Journal of Mediation 11: 59-88
              </p>
            </ContentExample>
          </Content>
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default OnlineDisputeBlogEleven;

export const ContentExample = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
`;

export const Block = styled.div`
  width: 353px;
  height: 0;
  border: 1px solid ${COLORS.INPUT_BLACK};
  margin: 3px;
  display: inline-block;
  font-family: ${theme.fonts.primaryFontRegular};
`;
