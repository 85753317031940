import React from "react";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import { H2, RuleContainer, TextLines, TextLinesLine } from "./Styles";
import { Helmet } from "react-helmet";
import { HyperLink } from "../../common/FormInputs";

function NegotiationRules(props) {
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>Negotiation Rules - Dispute Resolution Online - JustAct</title>
        <meta
          name="description"
          content="Learn about JustAct Negotiation Rules for Online Dispute Resolution."
        />
        <meta
          name="keywords"
          content="Negotiation Rules, Online Dispute resolution, Negotiation Mediation and Arbitration, Resolve Disputes Online, Negotiation Model Clause Rules, JustAct"
        />
      </Helmet>
      <div>
        <Header />
        <H2> Negotiation Rules</H2>
        <RuleContainer>
          <div>
            <p>
              <span>
                <strong>1.</strong> <strong>Initiating Negotiation</strong>
              </span>
            </p>
            <TextLines style={{ padding: "20px" }}>
              <p>
                <span>
                  <strong>1.1.</strong> Any of the parties may initiate
                  negotiation through JustAct.&nbsp;
                </span>
              </p>

              <p>
                <span>
                  <strong>1.2.</strong> To initiate the process of negotiation,
                  the prerequisites are:
                </span>
              </p>
              <TextLines style={{ paddingLeft: "20px" }}>
                <p>
                  <span>
                    <strong>1.2.1.</strong> Claimant is registered with JustAct
                  </span>
                </p>

                <p>
                  <span>
                    <strong>1.2.2.</strong> Claimant submits a claim with
                    JustAct with contact details of respondent.
                  </span>
                </p>

                <p style={{ lineHeight: "1.4" }}>
                  <span>
                    <strong>1.2.3.</strong> With these details, JustAct
                    intimates the respondent of the case filed and asks the
                    respondent to respond to the claim.
                  </span>
                </p>

                <p>
                  <span>
                    <strong>1.2.4.</strong> Respondent must register with
                    JustAct before responding to the claim made by the claimant
                  </span>
                </p>

                <p style={{ lineHeight: "1.4" }}>
                  <span>
                    <strong>1.2.5.</strong> Both parties agree to Negotiation as
                    a mode of{" "}
                    <HyperLink
                      onClick={() => window.open(`https://justact.co.in`)}
                    >
                      dispute resolution
                    </HyperLink>{" "}
                    without prejudice to any legal proceedings pending between
                    parties
                  </span>
                </p>

                <p>
                  <span>
                    <strong>1.2.6.</strong> Proofs in support of the claim or
                    reply-to-claim should be adequate
                  </span>
                </p>
                <p style={{ lineHeight: "1.4" }}>
                  <span>
                    <strong>1.2.7.</strong> The respondent may also reject the
                    claim completely in which case the parties may choose to
                    escalate the dispute to{" "}
                    <HyperLink
                      onClick={() =>
                        window.open(`https://justact.co.in/mediation-rules`)
                      }
                    >
                      mediation
                    </HyperLink>{" "}
                    or{" "}
                    <HyperLink
                      onClick={() =>
                        window.open(`https://justact.co.in/arbitration-rules`)
                      }
                    >
                      arbitration
                    </HyperLink>
                    .
                  </span>
                </p>
              </TextLines>
            </TextLines>

            <p>
              <span>
                <strong>
                  <strong>2.</strong>
                </strong>{" "}
                <strong>Representation</strong>
              </span>
            </p>

            <TextLines style={{ padding: "20px" }}>
              <p style={{ lineHeight: "1.4" }}>
                <span>
                  <strong>2.1.</strong> It is expected that Negotiation is
                  resorted to for disputes that are quantifiable and hence
                  amenable to resolution by this mode
                </span>
              </p>

              <p style={{ lineHeight: "1.4", marginTop: "15px" }}>
                <span>
                  <strong>2.2.</strong> The parties will negotiate themselves
                  and it is not expected for the parties to be represented by a
                  counsel / other representative except for genuine reasons such
                  as disability / age / medical condition(s).
                </span>
              </p>
            </TextLines>

            <p>
              <span>
                <strong>3.</strong> <strong>Support for Resolution</strong>
              </span>
            </p>

            <TextLines style={{ padding: "20px" }}>
              <p style={{ lineHeight: "1.4" }}>
                <span>
                  To handle resolution on an end-to-end basis, JustAct will
                  assign a Case Manager for the dispute. The Case Manager will
                  provide all support for resolution including resolving queries
                  and coordination for faster dispute resolution.&nbsp;
                </span>
              </p>
            </TextLines>

            <p>
              <span>
                <strong>4.</strong> <strong>Conduct of Negotiation</strong>
              </span>
            </p>

            <TextLinesLine style={{ padding: "20px" }}>
              <p>
                <span>
                  <strong>4.1.</strong> JustAct facilitates the negotiation by
                  use of claim and reply-to-claim values to start with.
                </span>
              </p>

              <p style={{ marginTop: "15px" }}>
                <span>
                  <strong>4.2.</strong> In subsequent rounds of negotiation, both
                  parties modify their numbers. The claimant is allowed to only
                  reduce the amount and the respondent is allowed only to
                  increase the amount offered.
                </span>
                {/* <span>
                  <strong>4.2.</strong> In subsequent rounds of negotiation, both
                  parties modify their numbers. The claimant is allowed to only
                  reduce the amount and the respondent is allowed only to
                  increase the amount offered.
                </span> */}
              </p>

              <p style={{ marginTop: "15px" }}>
                <span>
                  <strong>4.3.</strong> At any point during the process of
                  negotiation, if both parties agree at the same amount(s), the
                  settlement is deemed to have been achieved and the Case
                  Manager progresses the case to the agreement phase.
                </span>
              </p>
            </TextLinesLine>

            <p>
              <span>
                <strong>5.</strong> <strong>Role of Parties</strong>
              </span>
            </p>

            <TextLines style={{ padding: "20px" }}>
              <p style={{ lineHeight: "1.4" }}>
                <span>
                  The parties agree to attend the negotiation sessions with the
                  objective to reach a meaningful settlement to the dispute and
                  with adequate effort and preparation in this regard.&nbsp;
                </span>
              </p>
            </TextLines>

            <p>
              <span>
                <strong>6.</strong> <strong> Fees for negotiation</strong>
              </span>
            </p>

            <TextLinesLine style={{ padding: "20px" }}>
              <p>
                <span>
                  {" "}
                  Fees will be as per the Fee Schedule specified by JustAct as
                  may be amended from time to time.{" "}
                </span>
              </p>
            </TextLinesLine>

            <p>
              <span>
                <strong>7.</strong> <strong> Settlement Agreement</strong>
              </span>
            </p>

            <TextLinesLine style={{ padding: "20px" }}>
              <p>
                <span>
                  {" "}
                  The Case Manager will prepare a settlement agreement when the
                  parties reach an agreement (ref clause 4.3). The settlement
                  agreement will be generated and signed off by the parties{" "}
                  <b>and authenticated by a Conciliator</b> and issued on
                  payment of the requisite fees.
                </span>
              </p>
            </TextLinesLine>

            <p>
              <span>
                <strong>8.</strong>{" "}
                <strong>Termination of Negotiation&nbsp;</strong>
              </span>
            </p>

            <TextLines style={{ padding: "20px" }}>
              <p>
                <span>
                  The proceedings will terminate in any of the following cases:
                </span>
              </p>

              <TextLines style={{ paddingLeft: "20px" }}>
                <p>
                  <span>
                    <strong>8.1.</strong> a settlement agreement is reached
                    between the two parties on some or all the issues under
                    dispute &nbsp;
                  </span>
                </p>

                <p style={{ lineHeight: "1.4", marginTop: "10px" }}>
                  <span>
                    <strong>8.2.</strong> the parties are unable to come to an
                    agreement in the designated number of rounds (The parties
                    are free to escalate the case to mediation or arbitration
                    through JustAct on an immediate basis).
                  </span>
                </p>

                <p style={{ marginTop: "10px" }}>
                  <span>
                    <strong>8.3.</strong> any of the parties withdrawing from
                    the negotiation proceedings
                  </span>
                </p>
              </TextLines>
            </TextLines>

            <p>
              <span>
                <strong>9.</strong> <strong>Confidentiality&nbsp;</strong>
              </span>
            </p>

            <TextLines style={{ padding: "20px" }}>
              <p style={{ lineHeight: "1.4" }}>
                <span>
                  The parties and their agents, the conciliator and JustAct and
                  its employees shall keep confidential and shall not rely on or
                  introduce as evidence in arbitral, judicial or any other
                  proceeding, the following, whether or not such proceedings
                  relate to the dispute that is the subject of the negotiation
                  :&nbsp;
                </span>
              </p>
              <TextLines style={{ paddingLeft: "20px" }}>
                <p>
                  <span>
                    <strong>9.1.</strong> &nbsp;The mode chosen by the Party for
                    resolution.&nbsp;
                  </span>
                </p>

                <p>
                  <span>
                    <strong>9.2.</strong> &nbsp;Any bids or counteroffers made
                    by any of the parties.&nbsp;
                  </span>
                </p>

                <p style={{ lineHeight: "1.4", marginTop: "10px" }}>
                  <span>
                    <strong>9.3.</strong> &nbsp;Whether or not a Party had
                    indicated willingness to accept the bid / offer for
                    settlement made during the negotiation. &nbsp;
                  </span>
                </p>

                <p style={{ lineHeight: "1.4", marginTop: "10px" }}>
                  <span>
                    <strong>9.4.</strong> &nbsp;All proceedings in the
                    negotiation (save for a settlement agreement) will be
                    without prejudice to their legal rights and claims. &nbsp;
                  </span>
                </p>
              </TextLines>
            </TextLines>
            <p>
              <span>
                <strong>10</strong>{" "}
                <strong>Exclusion of liability&nbsp;</strong>
              </span>
            </p>

            <TextLines style={{ padding: "20px" }}>
              <p style={{ lineHeight: "1.4" }}>
                <span>
                  <strong>10.1.</strong> &nbsp;Neither JustAct, its employees or
                  any of the products / tools used during the process shall be
                  liable to any of the parties due to any error / act of
                  omission during the conduct of the negotiation. &nbsp;
                </span>
              </p>

              <p style={{ lineHeight: "1.4", marginTop: "10px" }}>
                <span>
                  <strong>10.2.</strong> &nbsp;Unless required by law, JustAct
                  or its agents / employees shall not be required to testify in
                  any proceedings – judicial or otherwise – with respect to
                  conduct of the negotiation under these rules &nbsp;
                </span>
              </p>
            </TextLines>
            <p>
              <span>
                <strong>11</strong>{" "}
                <strong>Amendments to JustAct Negotiation Rules</strong>
              </span>
            </p>
            <TextLines style={{ padding: "20px" }}>
              <p style={{ lineHeight: "1.4" }}>
                <span>
                  <strong>11.1.</strong> &nbsp;JustAct reserves the right to
                  amend these rules from time to time. However, a case once
                  registered will continue to be processed for negotiation under
                  the rules under which it was registered. &nbsp;
                </span>
              </p>

              <p style={{ lineHeight: "1.4", marginTop: "10px" }}>
                <span>
                  <strong>11.2.</strong> &nbsp;The date, for this purpose will
                  be reckoned to be the date when filing of the claim was done.
                  &nbsp;
                </span>
              </p>

              <p>
                <span>
                  <strong>11.3.</strong> &nbsp;In case of any disputes, the
                  decision of JustAct will be binding in this regard &nbsp;
                </span>
              </p>
            </TextLines>
          </div>
        </RuleContainer>
        <Footer />
      </div>
    </>
  );
}

export default NegotiationRules;
