import React from "react";
import labels from "../../../helpers/labels.json";
import { Header } from "../../internal/Header/Header";

const Index = ({ children }) => {
  return (
    <Header showSidebar selectedItem={labels.mis_dashboard}>
      {children}
    </Header>
  );
};

export default Index;
