import React, { useEffect } from "react";
import { Content, BlogPage, SubHeadWrap } from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

/* eslint-disable jsx-a11y/anchor-is-valid */

function OnlineDisputeBlogTwenty(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header>
        <BlogPage>
          <HeadWrap>Role of Arbitration in Criminal Cases</HeadWrap>
          <br />
          <br />
          <Content>
            <SubHeadWrap style={{ marginBottom: "10px" }}>Part II</SubHeadWrap>
            <SubHeadWrap style={{ marginBottom: "10px" }}>
              Shift in the Position on the Arbitrability of Fraud
            </SubHeadWrap>
            <p>
              However, this position on fraud as a criminal offence and the
              arbitrability of such disputes saw a shift in the emerging
              jurisprudence of the later years, thus underlining the role of
              arbitration in criminal cases.
            </p>
            <br />
            <p>
              In{" "}
              <span style={{ fontStyle: "italic" }}>
                Swiss Timing Ltd. v. Commonwealth Games 2010 Organising
                Committee<sup>1</sup>,
              </span>{" "}
              the simultaneous conduct of both criminal and arbitration
              proceedings, in relation to an agreement, on grounds of fraud,
              corruption and collusion against certain members of both parties,
              was held to not be an absolute bar to refer disputes to
              arbitration. An objection was raised against reference of the
              disputes to arbitration because the initiation of criminal
              proceedings was alleged to have rendered the agreement void. It
              was also argued that since the arbitration clause in a contract is
              treated as an agreement independent of the other terms of the
              contract, a mere allegation that the underlying contract is null
              and void does not render the arbitration clause invalid.
            </p>
            <br />
            <p>
              It was held that it was mandatory for courts to refer disputes to
              arbitration, if the agreement provided for arbitration. The Court
              could decline to refer disputes to arbitration under Section 11 of
              the Arbitration & Conciliation Act, 1996, if the contract was
              found to be patently void or if the contract was found to be void
              on a meaningful reading of the contract document, without further
              proof. The Court also reminded the parties that allegations of
              fraud, coercion, unsoundness of mind, undue influence and
              misrepresentation resulted in voidable and not void contracts. It
              was held that the balance of convenience tilted in favour of
              letting the arbitration proceedings continue, while also
              underlining that there was no inherent risk of prejudice to any
              party since the findings of the Arbitral Tribunal in the award
              would not be binding in the criminal proceedings.
            </p>
            <br />
            <p>
              <span style={{ fontStyle: "italic" }}>
                India Household and Healthcare Ltd. v. LG Household and
                Healthcare Ltd.<sup>2</sup>,
              </span>
              discussed the difference between Section 8 and Section 45 of the
              Arbitration & Conciliation Act, 1996, where the judicial authority
              could not refer parties to arbitration when the entire contract
              containing the arbitration agreement was found to be null and
              void, inoperative and incapable of being performed. It is
              generally accepted that where the existence of an arbitration
              agreement is found, the Courts would construe the agreement in
              such a manner as to uphold the arbitration agreement. However, it
              would be construed differently where fraud is identified because
              fraud vitiates all solemn acts.
            </p>
            <br />
            <p>
              <span style={{ fontStyle: "italic" }}>
                The N.N. Global Mercantile (P) Ltd. v. Indo Unique Flame Ltd.
              </span>
              <sup>3</sup>, case held that civil aspects of fraud, coercion,
              undue influence, or misrepresentation as defined in Sections 15 to
              19A of the Indian Contract Act, 1872 and/ or allegations of tort
              of deceit, in civil or commercial disputes, are arbitrable so long
              as the dispute(s) in question do not fall in the non-arbitrable
              category which are excluded expressly by statute, or by necessary
              implication. The same applies except when the allegation is that
              the arbitration agreement itself is vitiated by any of the above
              consent-vitiating factors, or the fraud, etc. goes to the validity
              of the underlying contract, and impeaches the arbitration clause
              itself.
            </p>
            <br />
            <p>
              Therefore, it established that the arbitrability of fraud is no
              longer an issue relating to the competence of the arbitrator, or
              in dealing with voluminous evidence. Arbitrators are competent to
              deal with allegations of civil fraud (as defined under Section 17
              of the Indian Contract Act, 1872). However, matters of forgery and
              fabrication which are criminal and in the realm of the public, can
              only be adjudicated upon by a court. The Court thus reaffirmed the
              findings of{" "}
              <span style={{ fontStyle: "italic" }}>Vidya Drolia</span>
              <sup>4</sup> on fraud as arbitrable subject matter.
            </p>
            <br />
            <p>
              The Court also held that disputes as to whether the arbitration
              clause is voidable, the substantive contract is voidable and the
              same impeaches the arbitration clause itself, are arbitrable.
              Whenever it is found that a plea is taken to avoid arbitration on
              the ground of the underlying contract and/ or the arbitration
              clause being void or voidable, the Court or arbitrator is required
              to ascertain the true nature of the defence. The Court also held
              that concurrent arbitration and criminal proceedings in respect of
              the same subject matter does not make such disputes non-arbitrable
              unless the nature of the allegations is such that the matter falls
              principally in the public domain.
            </p>
            <br />
            <SubHeadWrap>Conclusion</SubHeadWrap>
            <br />
            <p>
              It can be seen, in the example of fraud as a criminal offence, the
              difficulty of determining the limits of the Arbitral Tribunal’s
              powers due to the public nature of criminal offences and its
              interaction with the larger justice system. It is inevitable that
              offences of a criminal nature could seep into civil and commercial
              disputes and this could complicate mechanisms around discharging
              the burden of proof, procedural requirements around testimony and
              other forms of evidence when criminal offences are, by their very
              nature, matters within the public domain and hence, tricky to
              adjudicate in private fora. However, despite the challenges, the
              Apex Court has provided broad definitional outlines for the
              application of principles in the determination of fraud as an
              offence within the arbitral regime.
            </p>
            <br />
            <Block />
            <br />
            <p>
              <sup>1</sup>Swiss Timing Ltd. v. Commonwealth Games 2010
              Organising Committee, (2014) 6 SCC 677.
            </p>
            <p>
              <sup>2</sup> India Household and Healthcare Ltd. v. LG Household
              and Healthcare Ltd., (2007) 5 SCC 510.
            </p>
            <p>
              <sup>3</sup>N.N. Global Mercantile (P) Ltd. v. Indo Unique Flame
              Ltd., (2021) 4 SCC 379.
            </p>
            <p>
              <sup>4</sup>Vidya Drolia v. Durga Trading Corporation, (2021) 2
              SCC 1.
            </p>
          </Content>
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default OnlineDisputeBlogTwenty;

export const ContentExample = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
`;

export const Block = styled.div`
  width: 183px;
  height: 0;
  border: 1px solid ${COLORS.INPUT_BLACK};
  margin: 3px;
  display: inline-block;
  margin-bottom: 12px;
  font-family: ${theme.fonts.primaryFontRegular};
`;
export const HeadWrap = styled.div`
  text-align: center;
  font-size: 18px;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontBold};
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 25px;
  }
`;

export const SubHeadWrapHeading = styled.div`
  text-align: left;
  font-size: 20px;
  padding-left: 1.5%;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontRegular};
`;
