import React from "react";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import { H2, RuleContainer, TextLinesData } from "./Styles";
import { Helmet } from "react-helmet";
import { HyperLink } from "../../common/FormInputs";

function ArbitrationClauseRules(props) {
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>
          Arbitration Model Clause Rules - Arbitration Mediation and
          Conciliation - Arbitration Dispute Resolution
        </title>
        <meta
          name="description"
          content="Learn about the JustAct Arbitration & Conciliation Model Clause."
        />
        <meta
          name="keywords"
          content="Arbitration Model Clause Rules, Arbitration Dispute Resolution, Arbitration and Conciliation Rules, Arbitration Mediation and Conciliation, Online Dispute Resolution, Arbitration and Conciliation Rules Model Clauses, JustAct"
        />
      </Helmet>
      <div>
        <Header />
        <H2> MODEL CLAUSE</H2>
        <RuleContainer>
          <p>
            <span>
              <strong>1.</strong>{" "}
              <strong>JustAct Model Arbitration Clause</strong>
            </span>
          </p>
          <br />

          <TextLinesData>
            <ul class="first-li-before" style={{ marginBottom: 10 }}>
              <li>
                <p>
                  <span>
                    In the event of any dispute arising out of or in connection
                    with anything contained in the Agreement [or otherwise], the
                    parties shall refer the dispute for arbitration through
                    JustAct [www.justact.co.in] (“Institution”).&nbsp;
                  </span>
                </p>
              </li>
            </ul>

            <ul class="second-li-before" style={{ marginBottom: 10 }}>
              <li>
                <p>
                  <span>
                    The parties may use any other ADR institution provided such
                    institution is agreed mutually by the parties.&nbsp;
                  </span>
                </p>
              </li>
            </ul>

            <ul class="third-li-before" style={{ marginBottom: 10 }}>
              <li>
                <p>
                  <span>
                    The arbitration shall be in accordance with the Arbitration
                    and Conciliation Act, 1996 or any statutory modification or
                    re-enactment thereof. The seat of arbitration shall be
                    Chennai, India and language of the proceeding shall be
                    English. The award of the Arbitrator shall be final and
                    binding on the Parties. The arbitration may be conducted
                    online.&nbsp;
                  </span>
                </p>
              </li>
            </ul>

            <ul class="four-li-before" style={{ marginBottom: 10 }}>
              <li>
                <p>
                  <span>
                    The Institution shall, among other things, appoint neutral
                    Arbitrator(s) and provide a platform for resolution of the
                    dispute between the parties as per the{" "}
                    <HyperLink
                      onClick={() =>
                        window.open(`https://justact.co.in/arbitration-rules`)
                      }
                    >
                      arbitration Rules
                    </HyperLink>{" "}
                    of the Institution.&nbsp;
                  </span>
                </p>
              </li>
            </ul>

            <ul class="five-li-before">
              <li>
                <p>
                  <span>
                    Should this clause create a contradiction with a statutory
                    requirement or a judicial mandate to resolve certain types
                    of disputes through specific courts, the statutory
                    requirement shall prevail.&nbsp;
                  </span>
                </p>
              </li>
            </ul>
          </TextLinesData>
        </RuleContainer>
        <Footer />
      </div>
    </>
  );
}

export default ArbitrationClauseRules;
