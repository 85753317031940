import React, { useEffect } from "react";
import { Content, BlogPage } from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

/* eslint-disable jsx-a11y/anchor-is-valid */

function MediationInFamily(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header>
        <BlogPage>
          <HeadWrap>Mediation in Family Matters</HeadWrap>
          <br />
          <br />
          <Content>
            <b
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontStyle: "italic",
              }}
            >
              {" "}
              Authored by : Aprameya Manthena{" "}
            </b>
            <p style={{ marginBottom: "10px", marginTop: "10px" }}>
              Interpersonal and professional relationships oriented towards the
              long term are prone to conflict and rupture. It is crucial that
              disputes are resolved with alacrity in order to protect long
              standing relationships. It is in this context that mediation
              emerges as a useful tool for resolving disputes. Mediation is a
              facilitative, peace- and solution- seeking process that
              foregrounds the client&#39;s needs, interests and goals such that
              the participatory sessions are always tailored towards amicable
              resolution.
            </p>
            <p>
              The mediator attempts to achieve consensus between the parties on
              areas of concern and possible modes of settlement. This is
              realised through the setting up of joint and individual sessions
              that involve in-depth discussions on expectations, points of
              conflict, goals, needs and interests. There are no guarantees with
              respect to end solutions. The parties are free to draw up a
              settlement on agreed terms or could request the mediator to do so,
              on their behalf.
            </p>
            <br />
            <p>
              Family law matters include matrimonial, custody, guardianship and
              maintenance among others which clog the courts&#39; dockets for
              years. Section 89 of the Code of Civil Procedure provides for
              alternative dispute resolution in matters that have a fair chance
              of settlement through ADR methods. The Family Courts Act, 1984
              provides for conciliation and speedy settlement of family
              disputes. Since the Family Court is the court of first instance
              i.e. the first forum that parties approach for the resolution of
              their dispute, the Act places the onus on the Family Court to
              persuade parties to reach a mutually beneficial settlement.
              <sup>1</sup>
            </p>
            <br />
            <p>
              Similarly, other provisions in The Hindu Marriage Act, 1955
              <sup>2</sup> and The Special Marriage Act,<sup>3</sup> 1954 also
              instructed the Court to make every endeavour to bring about a
              reconciliation between the parties.
            </p>
            <br />
            <p>
              The criminal ramifications of marital issues in regard to
              settlement, including allegations of domestic violence and
              cruelty, have also been explored by the courts in certain
              judgments.<sup>4</sup> Even though the courts leaned towards
              favouring mediation, caution was advised in matters concerning the
              safety and wellbeing of parties, especially women.
            </p>
            <br />
            <p>
              In custody matters, resorting to litigation would only hurt the
              warring parties and the children caught in the crossfire. The best
              interests of the child should be the paramount consideration of
              the parents. Mediation could help the parents come up with a good
              parenting plan in keeping with the best interests of the child.
            </p>
            <br />
            <p>
              Mediation could also help parties in reaching a mutual, equitable
              divorce settlement that would take both parties’ interests into
              consideration. This is difficult to achieve in a court setting
              since adversarial processes pit one party against the other in the
              battle to win. This could lead to acrimony between the parties and
              affect their relationship with each other and their children.
            </p>
            <br />
            <p>
              In cases regarding waiver of the 6-month cooling off period for
              passing the decree of mutual consent of divorce under Section 13B
              (2) of the Hindu Marriage Act, the Supreme Court<sup>5</sup> noted
              that all efforts for mediation/conciliation including efforts in
              terms of Order XXXIIA Rule 3 CPC/Section 23(2) of the Act/Section
              9 of the Family Courts Act to reunite the parties should have
              failed (apart from other requirements) with no possibility of
              success, to grant the waiver.
            </p>
            <br />
            <p>
              It is clear that the legislature and courts foresee the positive
              effects of ADR on the backlog of civil disputes and encourage both
              litigants and participants in the justice delivery system to take
              full advantage of low-cost, low-risk options like mediation to
              resolve disputes.
            </p>
            <Block />
            <p>
              <sup>1</sup> Section 9
            </p>
            <p>
              <sup>2</sup> Section 23
            </p>
            <p>
              <sup>3</sup> Section 34
            </p>
            <p>
              <sup>4</sup> K. Srinivas Rao v D.A. Deepa, Civil Appeal No. 1794
              of 2013, Supreme Court of India, Afcons Infrastructure Ltd. vs.
              Varkey Construction Co. Pvt. Ltd. 2010 (8) SCC 24, Dr. Jaya Sagade
              v The State of Maharashtra SOM.PIL.104/2015-DB
            </p>
            <p>
              <sup>5</sup> Amardeep Singh vs Harveen Kaur (2017 8 SCC 746)
            </p>
          </Content>
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default MediationInFamily;

export const ContentExample = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
`;

export const Block = styled.div`
  width: 193px;
  height: 0;
  border: 1px solid ${COLORS.INPUT_BLACK};
  margin: 3px;
  display: inline-block;
  margin-top: 12px;
  margin-bottom: 12px;
  font-family: ${theme.fonts.primaryFontRegular};
`;

export const HeadWrap = styled.div`
  text-align: center;
  font-size: 18px;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontBold};
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 25px;
  }
`;

export const SubHeadWrapHeading = styled.div`
  text-align: left;
  font-size: 20px;
  padding-left: 1.5%;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontRegular};
`;

export const TextLinesData = styled.div`
  padding-left: 30px;
  margin-left: 30px;
  text-align: justify;
`;
