import React from "react";
import {
  // Content,
  HeadWrap,
  // SubHeadWrap,
  // TrainingPage,
  DivWrap,
  ContentBoxWrapper,
  BodyWrapper,
  TextWrapper,
  LightWrapper,
  Frame
} from "./Styles";
import { Helmet } from "react-helmet";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import styled from "styled-components";

function Webinar(props) {
  // const meta = {
  //   title: "Webinar Videos",
  //   description: "Testing",
  //   canonical: "http://example.com/path/to/page",
  //   meta: {
  //     charset: "utf-8",
  //     name: {
  //       keywords: "react Tag test",
  //     },
  //   },
  // }
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>Online Dispute Resolution (ODR) Webinar Videos - JustAct</title>
        <meta
          name="description"
          content="JustAct conducts several webinar sessions on ODR with experts who will debate different issues and aspects of mediation, arbitration and other dispute resolution processes."
        />
        <meta
          name="keywords"
          content="Online Dispute Resolution Webinar, Arbitration and Mediation Webinar, Online Dispute Webinar, Arbitration Mediation and Conciliation, Resolve Disputes online, Alternative Dispute Resolution Online, JustAct"
        />
      </Helmet>

      <BodyWrapper>
        <br />
        <HeadWrap>Webinar Videos</HeadWrap>
        <br />
        <ContentBoxWrapper>
          <Frame>
            <iframe
              height="100%"
              width="100%"
              title="video1"
              src="https://www.youtube.com/embed/46bbKLdd4Z8"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen="true"
            ></iframe>
          </Frame>
          {/* <TextWrapper>
                    <div>Webinar</div>
                    <LightWrapper>Description Coming Soon.</LightWrapper>
                  </TextWrapper> */}
          <TextWrapper>
            <DivWrap>
              Online Dispute Resolution Webinar - Opportunities & Challenges
            </DivWrap>
            <LightWrapper>
              Over the next few months, we will have several sessions with
              experts who will debate different issues and aspects of mediation,
              arbitration and other dispute resolution processes. The first of
              the series was on the{" "}
              <HyperLink
                style={{ fontSize: "12px" }}
                onClick={() =>
                  window.open(
                    `https://justact.co.in/blog/online-dispute-resolution-opportunities-and-challenges`
                  )
                }
              >
                {" "}
                Opportunities and Challenges in Online Dispute Resolution
              </HyperLink>{" "}
              - do the opportunities outweigh denial of access to underserved
              communities? Our expert panelists are Siddharth D'Souza and Harish
              Narasappa.Mr. Karthik Krishna , founder of JustAct, stood as the
              moderator for this event
            </LightWrapper>
          </TextWrapper>
        </ContentBoxWrapper>
      </BodyWrapper>
      <BodyWrapper>
        <br />
        <ContentBoxWrapper>
          <Frame>
            <iframe
              height="100%"
              width="100%"
              title="video2"
              src="https://www.youtube.com/embed/RbPb68VO3pE"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Frame>
          <TextWrapper>
            <DivWrap>
              Online Dispute Resolution Webinar - Evaluation of Section 11 &
              Section 29A
            </DivWrap>
            <LightWrapper>
              Our latest webinar in the series evaluated Section 11 & 29A;
              analyzing their impact in{" "}
              <HyperLink
                onClick={() =>
                  window.open(
                    `https://justact.co.in/blog/impeding-or-expediting-the-arbitration-process`
                  )
                }
              >
                expediting or impeding the arbitral process.
              </HyperLink>{" "}
              Our expert panelists were Mr. Murari Raghavan and Mr. Vyapak
              Desai. Mr. Amit George , Advocate at the High Court of Delhi stood
              as moderator of this event.
            </LightWrapper>
          </TextWrapper>
        </ContentBoxWrapper>
      </BodyWrapper>
      <br />
      <br />
      <br />
    </>
  );
}

export default Webinar;

export const HyperLink = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  color: ${COLORS.BTN_GREEN};
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  cursor: pointer;
`;
