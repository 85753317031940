import React from "react";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import { H2, RuleContainer, TextLinesData } from "./Styles";
import { Helmet } from "react-helmet";
import { HyperLink } from "../../common/FormInputs";

function NegotiationClauseRules(props) {
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>Model Dispute Resolution Clause - Negotiation - JustAct</title>
        <meta
          name="description"
          content="Learn about JustAct Negotiation Model Clause."
        />
        <meta
          name="keywords"
          content="Negotiation Model Clause Rules, Negotiation Mediation and Arbitration, Negotiation Dispute Resolution, Negotiation Rules, Online Dispute Resolution, Alternative Dispute Resolution, JustAct"
        />
      </Helmet>
      <div>
        <Header />
        <H2> MODEL CLAUSE</H2>
        <RuleContainer>
          <p>
            <span>
              <strong>1.</strong>{" "}
              <strong>JustAct Model Negotiation Clause</strong>
            </span>
          </p>
          <br />

          <TextLinesData>
            <ul class="first-li-before" style={{ marginBottom: 10 }}>
              <li>
                <p>
                  <span>
                    The parties shall endeavour to resolve any dispute arising
                    out of or in connection with anything contained in the
                    Agreement or otherwise by negotiation through JustAct,
                    [www.justact.co.in] within 30 (thirty days) of its
                    occurrence or such extended time as may be mutually agreed
                    by the parties.&nbsp;
                  </span>
                </p>
              </li>
            </ul>

            <ul class="second-li-before" style={{ marginBottom: 10 }}>
              <li>
                <p>
                  <span>
                    The Institution shall, among other things, appoint a neutral
                    Mediator/Conciliator and provide a platform for settlement
                    of dispute between the parties as per the{" "}
                    <HyperLink
                      onClick={() =>
                        window.open(`https://justact.co.in/negotiation-rules`)
                      }
                    >
                      negotiation Rules
                    </HyperLink>{" "}
                    of the Institution. The settlement agreement resulting from
                    the negotiation shall be provided in the form of a
                    Conciliation Award as per section 74 of the Arbitration and
                    Conciliation Act, 1996.&nbsp;
                  </span>
                </p>
              </li>
            </ul>

            <ul class="third-li-before">
              <li>
                <p>
                  <span>
                    Should this clause create a contradiction with a statutory
                    requirement or a judicial mandate to resolve certain types
                    of disputes through specific courts, the statutory
                    requirement shall prevail.&nbsp;
                  </span>
                </p>
              </li>
            </ul>
          </TextLinesData>
        </RuleContainer>
        <Footer />
      </div>
    </>
  );
}

export default NegotiationClauseRules;
