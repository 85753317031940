import React from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import { Helmet } from "react-helmet";
import { HyperLink } from "../../common/FormInputs";

function TermsAndCondition(props) {
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>Terms and Conditions - JustAct</title>
        <meta
          name="description"
          content="Learn about the terms & conditions you agree to when using JustAct."
        />
        <meta
          name="keywords"
          content="Terms and Conditions, JustAct, Online Dispute Resolution, ODR Services, Alternative Dispute Resolution, ADR Services, Online Dispute Resolution in India"
        />
      </Helmet>

      <div>
        <Header />
        <H2 style={{ marginTop: "20px" }}>Terms and Conditions</H2>
        <RuleContainer>
          <SubHeading>{"Contractual Relationship"}</SubHeading>
          <Content>
            <ol style={{ paddingLeft: "20px" }}>
              <Padding>
                These terms and conditions shall govern your use of the JustAct
                platform “JustAct”.
              </Padding>
              <LineBottom />
              <Padding>
                Your access and use of the Services constitutes your agreement
                to be bound by these terms and conditions (“T&C Contract”) in
                full which shall constitute an agreement between yourselves and
                JustAct
              </Padding>
              <LineBottom />
              <Padding>
                Supplemental terms may apply to certain Services, such as
                policies in connection with the applicable Services, which shall
                be deemed to be a part of the T&C Contract for the purposes of
                the applicable Services. Supplemental terms shall prevail over
                this T&C Contract in the event of a conflict with respect to the
                applicable Services.
              </Padding>
              <LineBottom />
              <Padding>
                JustAct may at its discretion terminate this T&C Contract and
                any Services with respect to you, or generally cease offering or
                deny access to the Services, or any portion thereof, at any time
                without notifying any reason.
              </Padding>
              <LineBottom />
              <Padding>
                You must be at least [18] years of age to use the Services; by
                using our Services you warrant and represent to us that you are
                at least [18] years of age. JustAct reserves the right to
                terminate any person’s membership and/or refuse to provide such
                person with access to the Services if it is brought to JustAct’s
                notice or if it is discovered that such person is not eligible
                to use the Services. In such case(s) JustAct reserves the right
                to purge the data pertaining to such person(s) in a reasonable
                time.
              </Padding>
              <LineBottom />
              <Padding>
                Our collection and use of personal information in connection
                with the Services is as provided in{" "}
                <HyperLink
                  onClick={() =>
                    window.open(`https://justact.co.in/privacy-policy`)
                  }
                >
                  JustAct’s Privacy Policy.
                </HyperLink>{" "}
                The privacy policy shall form an integral part of this T&C
                Contract and both these documents constitute the user agreement
                and a legally binding contract between you and JustAct.
              </Padding>
              <LineBottom />
              <Padding>
                JustAct may provide any Regulatory, or Governmental or Law
                Enforcement Authority, on their request, any necessary
                information (including your personal contact information) if
                there is a complaint, dispute, or conflict.
              </Padding>
              <LineBottom />
              <Padding>
                JustAct may amend this T&C Contract and supplemental terms
                including Privacy Policy from time to time. Your continued
                access or use of the Services after such changes are posted ion
                JustAct (www.justact.co.in) constitutes your consent to be bound
                by the T&C Contract and supplemental terms, as amended.
              </Padding>
              <LineBottom />
              <Padding>
                This T&C Contract is an electronic record in the form of an
                electronic contract, and it does not require any physical,
                electronic, or digital signature.
              </Padding>
              <LineBottom />
            </ol>
          </Content>
          <SubHeading>{"The Services & License"}</SubHeading>
          <Content>
            <ol style={{ paddingLeft: "20px" }}>
              <Padding>
                The Services constitute a technology platform that enables users
                of JustAct’s platform accessed through its website or mobile
                application to access online dispute resolution or any other
                services as offered by JustAct.
              </Padding>
              <LineBottom />
              <Padding>
                All services and content made available through JustAct are
                owned by JustAct and/or its licensors and are protected under
                copyright law. You agree and acknowledge that you shall not
                acquire any rights of ownership through the use of the Services
                and that you will only use content for your own personal
                purposes or internal business operations. Any commercial use is
                strictly prohibited.
              </Padding>
              <LineBottom />
              <Padding>
                All application(s), company name, brand names, logos, product
                and service names, the technology platform on which it operates,
                and all associated copyrights, trademarks, and other
                intellectual property rights therein reside solely with JustAct.
              </Padding>
              <LineBottom />
              <Padding>
                You agree and consent that the Application(s) may automatically
                download and install updates, upgrades, and additional features
                that JustAct deems reasonable, beneficial to you and/or
                reasonably necessary. You acknowledge and agree that any
                obligation of JustAct to support the previous version(s) may end
                upon the availability of the update, upgrade and/or
                implementation of additional features.
              </Padding>
              <LineBottom />
              <Padding>
                Additionally, Apple Inc., Google, Inc., Microsoft Corporation
                and other such service providers and/or their applicable
                international subsidiaries and affiliates will be third-party
                beneficiaries to this T&C Contract if you access the Services
                using Applications developed for Apple iOS, Android, Microsoft
                Windows, or other devices, respectively. These third-party
                beneficiaries are not parties to this T&C Contract and are not
                responsible for the provision or support of the Services in any
                manner. Your access to the Services using these devices is
                subject to terms set forth in the applicable third-party
                beneficiary’s terms of service.
              </Padding>
              <LineBottom />
              <Padding>
                We strive to keep the Services up and running; however, all
                online services suffer occasional disruptions and outages
                outside our control of JustAct including those caused by third
                party service providers, and JustAct is not liable for any
                disruption or loss you may suffer as a result.
              </Padding>
              <LineBottom />
              <Padding>
                By registering for our Service, you agree that the Services may
                send you text (SMS), voice drops and email messages as part of
                the normal business operation of your use of the Services.
              </Padding>
              <LineBottom />
              <Padding>
                JustAct may, in its sole discretion, permit you from time to
                time to submit, upload, publish or otherwise make available to
                JustAct through the Services textual, audio, and/or visual
                content and information, including commentary and feedback
                related to the Services, initiation of support requests, and
                submission of entries for competitions and promotions (“User
                Content”).
              </Padding>
              <LineBottom />
              <Padding>
                Any User Content provided by you remains your property and shall
                be maintained by JustAct as confidential. The data related to
                Services offered by JustAct and including, but not limited to,
                case related information as described in “Terms of Use” below
                will continue to be governed by the Privacy Policy.
              </Padding>
              <LineBottom />
              <Padding>
                <b>You represent and warrant that:</b>
                <LineBottom />
                <ol>
                  <ul style={{ paddingLeft: "20px" }}>
                    <Padding>
                      you either are the sole and exclusive owner of all User
                      Content or you have all rights, licenses, consents, and
                      releases necessary to grant JustAct the license to the
                      User Content as set forth above.
                    </Padding>
                    <LineBottom />
                    <Padding>
                      neither the User Content nor your submission, uploading,
                      publishing or otherwise making available of such User
                      Content nor JustAct’s use of the User Content as permitted
                      herein will infringe, misappropriate, or violate a third
                      party’s intellectual property or proprietary rights, or
                      rights of publicity or privacy, or result in the violation
                      of any applicable law or regulation. You agree to not
                      provide User Content that is defamatory, libellous,
                      hateful, violent, obscene, pornographic, unlawful, or
                      otherwise offensive, as determined by JustAct in its sole
                      discretion, whether or not such material may be protected
                      by law. JustAct may, but shall not be obligated to,
                      review, monitor, or remove User Content, at JustAct’s sole
                      discretion and at any time and for any reason, without
                      notice to you.
                    </Padding>
                    <LineBottom />
                  </ul>
                </ol>
              </Padding>
              <Padding>
                <b>
                  You shall not post, transmit, redistribute, upload, modify,
                  publish, host, display or promote any communications, content,
                  or materials that
                </b>
                <LineBottom />
                <ol>
                  <ul style={{ paddingLeft: "20px" }}>
                    <Padding>
                      are illegal, obscene, vulgar, profane, indecent, sexually
                      explicit, threatening, harmful, harassing, abusive,
                      hateful, racially, or ethnically derogatory, blasphemous
                      defamatory, paedophilic, libellous, invasive of privacy or
                      publicity rights, disparaging, relating to or encouraging
                      money laundering or gambling, infringing of intellectual
                      property rights, or otherwise injurious to third parties
                      or objectionable.
                    </Padding>
                    <LineBottom />
                    <Padding>
                      consist of or contain software viruses, corrupted files,
                      or any other similar software files, the intent of which
                      is to damage or alter the operation of another’s computer.
                    </Padding>
                    <LineBottom />
                    <Padding>
                      contain political campaigning, unsolicited advertising,
                      promotional materials, or other forms of solicitation to
                      other users, individuals or entities;
                    </Padding>
                    <LineBottom />
                    <Padding>
                      contain chain letters, pyramid schemes, mass mailings, or
                      any form of “spam”
                    </Padding>
                    <LineBottom />
                    <Padding>
                      use a false email address, impersonates another person or
                      entity, including our company and our employees or agents,
                      or is otherwise misleading as to the origin of the
                      content;
                    </Padding>
                    <LineBottom />
                    <Padding>
                      encourage conduct that would constitute a criminal
                      offense;
                    </Padding>
                    <LineBottom />
                    <Padding>give rise to civil liability;</Padding>
                    <LineBottom />
                    <Padding>
                      belongs to another person and over which you do not have
                      any right to;
                    </Padding>
                    <LineBottom />
                    <Padding>harm minors in anyway;</Padding>
                    <LineBottom />
                    <Padding>
                      deceives or misleads the addressee about the origin of
                      such messages or communicates any information which is
                      grossly offensive or menacing in nature;
                    </Padding>
                    <LineBottom />
                    <Padding>impersonate another person</Padding>
                    <LineBottom />
                    <Padding>
                      threatens the unity, integrity, defence, security or
                      sovereignty of India, friendly relations with foreign
                      states, or public order or causes incitement to commit any
                      cognizable offence or prevents investigation of any
                      offence or is insulting any other nation;
                    </Padding>
                    <LineBottom />
                    <Padding>
                      otherwise violate any law; or in doing so, amounts to any
                      conduct that, in the judgement of JustAct, restricts,
                      impairs, interferes, or inhibits any other user from using
                      or enjoying the Services.
                    </Padding>
                    <LineBottom />
                  </ul>
                </ol>
              </Padding>
              <Padding>
                <b>You shall not:</b>
                <LineBottom />
                <ol>
                  <ul style={{ paddingLeft: "20px" }}>
                    <Padding>
                      Remove any copyright, trademark, or other proprietary
                      notices from any portion of the Services
                    </Padding>
                    <Padding>
                      Reproduce, modify, prepare derivative works based upon,
                      distribute, license, lease, sell, resell, transfer,
                      publicly display, publicly perform, transmit, stream,
                      broadcast or otherwise exploit the Services except as
                      expressly permitted by JustAct in writing
                    </Padding>
                    <LineBottom />
                    <Padding>
                      Sublicense, rent, lease, or otherwise distribute the
                      Application or rights to use the Application to any other
                      party.
                    </Padding>
                    <LineBottom />
                    <Padding>
                      Disassemble, decompile, decrypt, hack, emulate, exploit,
                      or reverse engineer the Services except as may be
                      permitted by applicable law
                    </Padding>
                    <LineBottom />
                    <Padding>
                      Link to, mirror or frame any portion of the Services
                    </Padding>
                    <LineBottom />
                    <Padding>
                      Cause or launch any programs or scripts for the purpose of
                      scraping, indexing, surveying, or otherwise data mining
                      any portion of the Services or unduly burdening or
                      hindering the operation and/or functionality of any aspect
                      of the Services.
                    </Padding>
                    <LineBottom />
                    <Padding>
                      Attempt to gain unauthorized access to or impair any
                      aspect of the Services or its related systems or networks
                    </Padding>
                    <LineBottom />
                    <Padding>
                      Alter, modify, enhance, translate, or create a derivative
                      work of the Service
                    </Padding>
                  </ul>
                </ol>
              </Padding>
              <LineBottom />
              <Padding>
                <b>Notice of Copyright Infringement</b>
                <LineBottom />
                <ol>
                  <ul style={{ paddingLeft: "20px" }}>
                    <Padding>
                      JustAct does not permit copyright infringing activities on
                      these sites /applications and may remove any content of
                      any kind, for example, if properly informed that the
                      content infringes upon another’s copyright rights. JustAct
                      may terminate the ability to submit content if, under
                      appropriate circumstances, a person submitting content to
                      its site/application is determined to be a repeat
                      infringer.
                    </Padding>
                    <LineBottom />
                    <Padding>
                      If you are a copyright owner or an agent thereof and
                      believe that any content on the site/application infringes
                      upon your copyrights, you may notify JustAct by providing
                      the following information in writing:
                    </Padding>
                    <LineBottom />
                    <Padding>
                      A physical or electronic signature of a person authorized
                      to act on behalf of the owner of an exclusive copyright
                      right that is allegedly infringed.
                    </Padding>
                    <LineBottom />
                    <Padding>
                      Identification of the location where the original or an
                      authorized copy of the copyrighted work exists;
                    </Padding>
                    <LineBottom />
                    <Padding>
                      Identification of the material that is claimed to be
                      infringing and a description of the infringing activity
                      and information reasonably sufficient to permit JustAct to
                      locate the same;
                    </Padding>
                    <LineBottom />
                    <Padding>
                      Information reasonably sufficient to permit JustAct to
                      contact you, such as an address, telephone number, and, if
                      available, an email address;
                    </Padding>
                    <LineBottom />
                    <Padding>
                      A statement that you have a good faith belief that use of
                      the material in the manner complained of is not authorized
                      by the copyright owner, its agent, or the law; and
                    </Padding>
                    <LineBottom />
                    <Padding>
                      A statement that the information in the notification is
                      accurate, and, under penalty of perjury, that you are
                      authorized to act on behalf of the owner of an exclusive
                      copyright right that is allegedly infringed.
                    </Padding>
                    <LineBottom />
                  </ul>
                </ol>
              </Padding>
            </ol>
          </Content>
          <SubHeading>{"Terms of Use"}</SubHeading>
          <Content>
            <ol>
              <Padding>
                <b>User Registration:</b>
                <LineBottom />
                <ol>
                  <ul style={{ paddingLeft: "20px" }}>
                    <Padding>
                      In order to use the Services, you must create a
                      registration on the Application or Website and use a valid
                      profile. You may need to download a copy of the
                      Application or use the Application off the world wide web
                      to create a registration/profile. Registration requires
                      you to submit to JustAct certain personal information,
                      such as your name, email ID, address, and mobile phone
                      number.
                    </Padding>
                    <LineBottom />
                    <Padding>
                      You may be required to follow an authentication process
                      using OTPs sent to your email and mobile for this purpose,
                      or such process that may be introduced going ahead.
                    </Padding>
                    <LineBottom />
                    <Padding>
                      You may create profiles for Parties who may want to use
                      the platform for resolving disputes.
                    </Padding>
                    <LineBottom />
                    <Padding>
                      Your failure to maintain accurate, complete, and
                      up-to-date registration or account information may result
                      in your inability to access and use the Services.
                    </Padding>
                    <LineBottom />
                  </ul>
                </ol>
              </Padding>
              <Padding>
                <b>Party Creation/ Addition</b>
                <LineBottom />
                <ol>
                  <ul style={{ paddingLeft: "20px" }}>
                    <Padding>
                      You need to create or add at least one Party in your
                      registration to access to use the Services. Each Party may
                      be associated with an email ID and phone number. If you
                      create a Party, you will be the Primary Agent for such a
                      Party but will be free to add other Agents or Primary
                      Agents to the Party.
                    </Padding>
                    <LineBottom />
                    <Padding>
                      User creation requires you to submit at least one valid ID
                      proof to JustAct.
                    </Padding>
                    <LineBottom />
                    <Padding>
                      Your failure to maintain accurate, complete, and
                      up-to-date payment information may result in your
                      inability to access and use the Services
                    </Padding>
                    <LineBottom />
                    <Padding>
                      Multiple parties may be created under a user account from
                      the JustAct website (justact.co.in).
                    </Padding>
                    <LineBottom />
                    <Padding>
                      You may be invited as an agent for a Party or parties
                      created by other users, if they so choose. You are,
                      however, under no obligation to accept such an invite.
                    </Padding>
                    <LineBottom />
                  </ul>
                </ol>
              </Padding>
              <Padding>
                <b>Account Use:</b>
                <LineBottom />
                <ol style={{ paddingLeft: "20px" }}>
                  <Padding>
                    You agree to comply with all applicable laws when using the
                    Application and you may only use the Application for lawful
                    purposes.
                  </Padding>
                  <LineBottom />
                  <Padding>
                    <b>You agree not to:</b>
                    <LineBottom />
                    <ol>
                      <ul style={{ paddingLeft: "20px" }}>
                        <Padding>
                          Share your access details and account with another
                          individual;
                        </Padding>
                        <LineBottom />
                        <Padding>
                          Create a false identity on the Application for
                          purposes of misleading others;
                        </Padding>
                        <LineBottom />
                        <Padding>
                          Defame, abuse, harass, threaten, spam, indulge in
                          anti-social activity, violate the rights of others
                          and/or otherwise interfere with others’ use and
                          enjoyment of the Services;
                        </Padding>
                        <LineBottom />
                        <Padding>
                          Engage in activity that is harmful to you, the
                          Services, or others (e.g., transmitting viruses,
                          stalking, posting terrorist content, communicating
                          hate speech, or advocating violence against others).
                        </Padding>
                        <LineBottom />
                        <Padding>
                          Publish, transfer or distribute any inappropriate,
                          indecent, obscene, foul or unlawful conduct on the
                          Application;
                        </Padding>
                        <LineBottom />
                      </ul>
                    </ol>
                  </Padding>
                  <Padding>
                    JustAct reserves the right to terminate the License and
                    Services offered through the Application at its sole
                    discretion. JustAct does not endorse the content, messages
                    and/or information exchanged by means of the Services and
                    therefore you agree that JustAct is not liable with respect
                    to such content and/or any action resulting therefrom.
                  </Padding>
                  <LineBottom />
                  <Padding>
                    You are responsible for all actions that occur in connection
                    with the Registration and the associated Parties you use to
                    access the Services. You agree to notify JustAct immediately
                    of any unauthorized use of your registration and/or profile.
                  </Padding>
                  <LineBottom />
                  <Padding>
                    If we reasonably suspect that your JustAct user registration
                    or Party is being used by a third party fraudulently (for
                    example, as a result of an account compromise), JustAct may
                    suspend your registration until you can reclaim ownership
                  </Padding>
                  <LineBottom />
                </ol>
              </Padding>
              <Padding>
                <b>Privacy</b>
              </Padding>
              <LineBottom />
              <Content>
                Privacy of communication is subject to the terms set out in the
                Privacy Policy
              </Content>
              <LineBottom />
              <Padding>
                <b>Network Access and Devices:</b>
                <LineBottom />
                <ol>
                  <ul style={{ paddingLeft: "20px" }}>
                    <Padding>
                      You are responsible for obtaining the data network and
                      phone network access necessary to use the Services.
                    </Padding>
                    <LineBottom />
                    <Padding>
                      Your mobile and internet network’s call, data and
                      messaging rates and fees may apply if you access or use
                      the Services from a wireless-enabled device and you shall
                      be responsible for such rates and fees.
                    </Padding>
                    <LineBottom />
                    <Padding>
                      You are responsible for acquiring and updating compatible
                      hardware or devices necessary to access and use the
                      Services and any updates thereto.
                    </Padding>
                    <LineBottom />
                    <Padding>
                      JustAct does not guarantee that the Services, or any
                      portion thereof, will function on any particular hardware
                      or devices. In addition, the Services may be subject to
                      malfunctions and delays inherent in the use of the
                      Internet and electronic communications and security
                      policies of the network being used.
                    </Padding>
                    <LineBottom />
                  </ul>
                </ol>
              </Padding>
              <Padding>
                <b>Service Quality:</b>
                <LineBottom />
                <Content>
                  Service quality, functionality, availability, and/or
                  reliability may be affected, and JustAct is entitled without
                  any liability, to refuse, limit, suspend, vary, disconnect
                  and/or interrupt the Service, in whole or in part, at its own
                  discretion, with respect to one/all subscriber(s) without any
                  notice, for any reason and/or due to various factors including
                  but not limited to:
                  <LineBottom />
                  <ol>
                    <ul style={{ paddingLeft: "40px" }}>
                      <Padding>
                        Regulations, orders, directions, of Authorities
                        including the changes thereto
                      </Padding>
                      <LineBottom />
                      <Padding>
                        Problems caused by geographical, atmospheric,
                        hydrological, environmental conditions and/or mechanical
                        conditions and/or other such
                        factors/features/conditions, system(s) changes or
                        capacity limitations, for reasons of up gradation,
                        variation, installation, relocation, repairs, operation
                        and/or maintenance of systems/equipment/Services.
                      </Padding>
                      <LineBottom />
                      <Padding>
                        Combat potential fraud, sabotage, wilful destruction
                        etc.
                      </Padding>
                      <LineBottom />
                      <Padding>Any other legitimate business purpose.</Padding>
                      <LineBottom />
                      <Padding>Force-Majeure Events.</Padding>
                      <LineBottom />
                      <Padding>
                        If JustAct is used in any manner that violates any laws
                        etc.
                      </Padding>
                      <LineBottom />
                      <Padding>
                        Any discrepancy/wrong particular(s) in the information
                        provided by the Subscriber
                      </Padding>
                      <LineBottom />
                      <Padding>
                        Interconnections between service providers.
                      </Padding>
                      <LineBottom />
                      <Padding>
                        Breach of any part of this T&C Contract by the
                        subscriber
                      </Padding>
                      <LineBottom />
                    </ul>
                  </ol>
                </Content>
                <LineBottom />
                <Padding>
                  In case of any suspension etc. initiated by JustAct,
                  reactivation may be made by JustAct at its own discretion,
                  subject to its satisfaction and on such other further terms,
                  as JustAct may determine.
                </Padding>
                <LineBottom />
                <Padding>
                  JustAct makes no express or implied warranties or
                  representations with respect to the Services. Moreover,
                  JustAct makes no representation that the Services will be
                  uninterrupted or error-free, and JustAct will not be liable
                  for the consequences of any interruptions or errors. The
                  services are provided “as is” without warranty of any kind,
                  either expressed or implied, including, but not limited to,
                  warranties of merchantability, fitness for a particular
                  purpose, or non-infringement.
                </Padding>
                <LineBottom />
              </Padding>
              <Padding>
                <b>Charges & Payment:</b>
                <LineBottom />
                <ol>
                  <ul style={{ paddingLeft: "40px" }}>
                    <Padding>
                      You understand that use of the Services may result in
                      charges to you, before, during or after receiving the
                      Services, JustAct reserves the right to collect the due
                      payment from its customers.
                    </Padding>
                    <LineBottom />
                    <Padding>
                      Dispute resolution charges are based on a proprietary
                      pricing model.
                    </Padding>
                    <LineBottom />
                    <Padding>
                      JustAct reserves the right to levy charges for usage of
                      its technology and other third-party technology used in
                      the rendering of its Service.
                    </Padding>
                    <LineBottom />
                    <Padding>
                      Charges will be inclusive of taxes as applicable. Charges
                      and fees paid by you are non-refundable, unless otherwise
                      agreed with JustAct
                    </Padding>
                    <LineBottom />
                    <Padding>
                      When you purchase any Services on a subscription basis
                      (e.g., monthly, every 3 months or annually (as
                      applicable)), you acknowledge and agree that you are
                      authorizing recurring payment, and payments shall be made
                      to JustAct by the method you have chosen at the recurring
                      intervals chosen by you, until the subscription for that
                      Service is terminated by you or by JustAct.
                    </Padding>
                    <LineBottom />
                    <Padding>
                      By authorizing recurring payments, you are authorizing
                      JustAct to process such payments as charges to your
                      designated account
                    </Padding>
                    <LineBottom />
                    <Padding>
                      If any payment is returned unpaid or if any transaction is
                      rejected or denied, JustAct reserves the right to collect
                      any applicable return charges, rejection, or insufficient
                      funds fee and to process any such payment as an Electronic
                      Payment
                    </Padding>
                    <LineBottom />
                    <Padding>
                      JustAct reserves the right to establish, remove and/or
                      revise charges for any or all of the Services at any time
                      in JustAct’s sole discretion
                    </Padding>
                    <LineBottom />
                    <Padding>
                      You will be responsible for charges incurred under your
                      user registration, regardless of your awareness of such
                      charges or the amounts thereof
                    </Padding>
                    <LineBottom />
                  </ul>
                </ol>
              </Padding>
            </ol>
          </Content>
          <SubHeading>
            {
              "Limitation of Liability; Indemnity, Governing Laws & Dispute Resolution"
            }
          </SubHeading>
          <Content>
            <LineBottom />
            <ol style={{ paddingLeft: "20px" }}>
              <Padding>
                <b>Limitation of Liability</b>
                <LineBottom />
                <ol>
                  <ul style={{ paddingLeft: "20px" }}>
                    <Padding>
                      JustAct’s maximum aggregate liability to you arising in
                      connection with the grant of the License shall not exceed
                      in relation to case, the total dispute resolution charges
                      collected for any such case.
                    </Padding>
                    <LineBottom />
                    <Padding>
                      JustAct is not responsible (whether in contract, tort
                      (including negligence) or otherwise) for: any special,
                      indirect or consequential loss or damage; loss of
                      business, loss of revenue, loss of profits, loss of
                      anticipated savings or loss of goodwill; losses not caused
                      by JustAct’s breach; your use of a third party login to
                      create your account with JustAct; actions taken in
                      response to breaches of the terms and conditions of this
                      T&C Contract; any content, communication, messages and/or
                      information exchanged by means of the Services; the
                      accuracy or completeness of information contained on the
                      Application; subject to applicable law, the accuracy of
                      identity information of users; and failure to provide the
                      Application or to meet any of JustAct’s obligations under
                      this T&C where such failure is due to any cause beyond
                      JustAct’s reasonable control which prevents JustAct from
                      providing the Services or fulfilling any of JustAct’s
                      other obligations under the License and includes but is
                      not limited to the Force Majeure Events;
                    </Padding>
                    <LineBottom />
                  </ul>
                </ol>
              </Padding>
              <LineBottom />
              <Padding>
                <b>Indemnity</b>
                <LineBottom />
                <Content style={{ paddingLeft: "20px" }}>
                  You agree to indemnify and hold JustAct and its officers,
                  directors, employees, and agents harmless from any and all
                  claims, demands, losses, liabilities, and expenses (including
                  attorneys’ fees) arising out of or in connection with:
                </Content>
                <LineBottom />
                <ol>
                  <ul style={{ paddingLeft: "40px" }}>
                    <Padding>
                      your use of the Services or goods obtained through your
                      use of the services;
                    </Padding>
                    <LineBottom />
                    <Padding>
                      your breach or violation of any of the terms of this T&C
                      Contract;
                    </Padding>
                    <LineBottom />
                    <Padding>JustAct’s use of your User Content; or</Padding>
                    <LineBottom />
                    <Padding>
                      your violation of the rights of any third party, including
                      third party providers.
                    </Padding>
                    <LineBottom />
                  </ul>
                </ol>
              </Padding>
              <LineBottom />
              <Padding>
                <b>Jurisdiction & Governing Laws:</b>
                <LineBottom />
                <Content style={{ paddingLeft: "20px" }}>
                  The terms of this policy shall be governed by Indian Laws and
                  subject to Clause 4.3 above, the Courts in Chennai shall have
                  jurisdiction over any dispute that arises regarding this
                  Agreement.
                </Content>
              </Padding>
              <LineBottom />
              <Padding>
                <b>Dispute resolution:</b>
                <LineBottom />
                <ol>
                  <ul style={{ paddingLeft: "20px" }}>
                    <Padding>
                      Any dispute over privacy is subject to this policy and the
                      terms of use of this policy.
                    </Padding>
                    <LineBottom />
                    <Padding>
                      In case of a dispute arising, the Parties shall endeavour
                      to resolve such dispute through mutual discussion in 30
                      days, failing which the parties hereby agree to resolve
                      such a dispute through mediation, by a mediator to be
                      jointly appointed by the Parties. Any disputes,
                      differences, or claims which, may arise with respect to
                      privacy out of this policy, and survive following the
                      mediation undertaken by the Parties shall be referred to
                      the decision of a sole arbitrator to be appointed by the
                      mutual consent of the parties in accordance with and
                      subject to the provisions of the Arbitration and
                      Conciliation Act, 1996. The arbitration shall take place
                      in Chennai, Tamil Nadu and the proceedings shall be in
                      English. The arbitral award shall be final and binding on
                      the parties. The policy and its terms of use shall remain
                      in full force and effect pending the award of such an
                      arbitration proceeding.
                    </Padding>
                    <LineBottom />
                  </ul>
                </ol>
              </Padding>
            </ol>
          </Content>
          <LineBottom />
          <SubHeading>{"Other Provisions"}</SubHeading>
          <Content>
            <ol style={{ paddingLeft: "20px" }}>
              <Padding>
                This T&C Contract constitutes the entire agreement between you
                and JustAct with respect to the Services and supersedes all
                prior or contemporaneous oral or written agreements and
                understandings regarding such subject matter. In entering into
                this T&C Contract, you agree that you have not relied on and
                will have no remedy in respect of any representations made by
                JustAct other than as set out in this T&C Contract. In the event
                of a conflict between this T&C Contract and any applicable
                purchase or other terms, the terms of this T&C Contract shall
                govern.
              </Padding>
              <LineBottom />
              <Padding>
                No failure to exercise, and no delay in exercising, on the part
                of JustAct, any right or any power hereunder shall operate as a
                waiver thereof, nor shall any single or partial exercise of any
                right or power hereunder preclude further exercise of any other
                right hereunder.
              </Padding>
              <LineBottom />
              <Padding>
                JustAct will not be liable or responsible for any failure to
                perform, or delay in performance of any of JustAct’s obligations
                under this T&C Contract caused by Force Majeure Event. JustAct’s
                obligations under this T&C will be suspended for the duration of
                the Force Majeure Event and JustAct will use all reasonable
                endeavours to mitigate the effect of the Force Majeure Event.
              </Padding>
              <LineBottom />
              <Padding>
                If a provision of this T&C Contract is determined by any court
                or other competent authority to be unlawful and/or
                unenforceable, the other provisions will continue in effect. If
                any unlawful and/or unenforceable provision of this T&C Contract
                would be lawful or enforceable if part of it were deleted, that
                part will be deemed to be deleted, and the rest of the provision
                will continue in effect.
              </Padding>
              <LineBottom />
              <Padding>
                A contract under this T&C Contract is for our benefit and your
                benefit and is not intended to benefit or be enforceable by any
                third party. The exercise of the parties’ rights under a
                contract under this T&C Contract is not subject to the consent
                of any third party.
              </Padding>
              <LineBottom />
              <Padding>
                <b>Statute Of Limitation:</b>
                <LineBottom />
                <Content style={{ paddingLeft: "20px" }}>
                  You agree that regardless of any statute or law to the
                  contrary, any claim or cause of action arising out of or
                  related to use of our facility or this T&C Contract must be
                  filed within three (3) months after such claim or cause of
                  action arose or be forever barred.
                </Content>
                <LineBottom />
              </Padding>
              <LineBottom />
              <Padding>
                <b>Agreement To Be Bound:</b>
                <LineBottom />
                <Content style={{ paddingLeft: "20px" }}>
                  By using these Services, you acknowledge that you have read
                  and agree to be bound by this T&C Contract.
                </Content>
              </Padding>
              <LineBottom />
              <Padding>
                JustAct may give notice by means of a general notice on the
                Services, electronic mail to your email address in your Account,
                or by written communication sent to your address as set forth in
                your Account.
              </Padding>
              <LineBottom />
              <Padding>
                You may give notice to JustAct by written communication to
                JustAct’s address at T-4, 7th Street, VSI industrial estate,
                Phase 2, Thiruvanmiyur Chennai – 600 041.
              </Padding>
              <LineBottom />
              <Padding>
                You shall not assign or transfer this T&C Contract in whole or
                in part without JustAct’s prior written approval. You give your
                approval to JustAct for it to assign or transfer this T&C
                Contract in whole or in part, including to: (i) a subsidiary or
                affiliate; (ii) an acquirer of JustAct’s equity, business, or
                assets; or (iii) a successor by merger, amalgamation, or any
                reconstitution.
              </Padding>
              <LineBottom />
            </ol>
          </Content>
          <SubHeading>{"Grievance Officer: "}</SubHeading>
          <Content>
            In accordance with the Information Technology Act, 2000, and the
            rules made there under, if you have any grievance with respect to
            the Website or the service, you can contact our Grievance Officer
            at:
            <br />
            Grievance Officer Name: Prashant Soni Email ID:
            support@justact.co.in
          </Content>
          <SubHeading>{"Definitions"}</SubHeading>
          <Content>
            <ol>
              <Padding>
                “Administrator” shall mean the person authorised to administer
                the Services rendered by JustAct through its portal and
                application and any other offline services.
              </Padding>
              <LineBottom />
              <Padding>
                <b>
                  “Affiliate” shall mean and include any Person existing or at
                  any time in the future:
                </b>
                <LineBottom />
                <ol>
                  <ul style={{ paddingLeft: "40px" }}>
                    <Padding>
                      who Controls, is Controlled by, or is under the common
                      Control of Lex Carta Private Limited; or where 50% of the
                      voting securities of Lex Carta Private Limited are
                      directly or indirectly owned, legally and beneficially, by
                      such Person; and/or
                    </Padding>
                    <LineBottom />
                    <Padding>
                      any person who has been engaged by Lex Carta Private
                      Limited to market the Services.
                    </Padding>
                    <LineBottom />
                  </ul>
                </ol>
              </Padding>
              <LineBottom />
              <Padding>
                “Force Majeure Event” means the events beyond the control of
                JustAct including (a) Acts of God; (b) fire, explosion, or
                accident; (c) war, outbreak of hostilities (whether war be
                declared or not), invasion, riot, revolution, insurrection,
                military or usurped power, civil disturbance, act of foreign
                enemies; (d) import or export regulations or embargoes; (e) acts
                of terrorism; (f) acts, restrictions, regulations, laws,
                refusals of any government or authority (including refusal or
                revocation of any licence or consent); (g) strikes, lock-outs or
                other industrial actions or trade disputes of whatever nature;
                (h) power failure, failure of telecommunications lines, failure
                or breakdown of plant, machinery or vehicles; (i) default of
                suppliers or sub-contractors; or (j)theft, malicious damage,
                strike, lock-out or industrial action of any kind.
              </Padding>
              <LineBottom />
              <Padding>
                “Person” means any individual, corporation, company,
                partnership, limited liability company, joint venture,
                association or trust or any other entity or organization.
              </Padding>
              <LineBottom />
            </ol>
          </Content>
          <SubHeading>{"Treatment of Data Erasure Requests"}</SubHeading>
          <Content>
            <ol>
              <Padding>
                <b>Defining Data Erasure</b>
                <LineBottom />
                <Content style={{ paddingLeft: "20px" }}>
                  Data erasure is the process of erasing Personally Identifiable
                  Data, captured electronically on the JustAct platform, and
                  stored in related servers and cloud infrastructure.
                </Content>
              </Padding>
              <Padding>
                <b>When can a Data Erasure request be made</b>
                <LineBottom />
                <Content style={{ paddingLeft: "20px" }}>
                  Data subjects can exercise the right to be forgotten under the
                  following cases
                  <LineBottom />
                  <ol>
                    <ul style={{ paddingLeft: "40px" }}>
                      <Padding>
                        Where Personally Identifiable Data is no longer
                        necessary for the purpose for which it was originally
                        collected or processed by JustAct
                      </Padding>
                      <LineBottom />
                      <Padding>
                        Where personally identifiable data is collected and
                        processed without the consent of the data subject
                      </Padding>
                      <LineBottom />
                      <Padding>
                        Where Data subject wants to withdraw their consent after
                        registering on the platform but before creating a
                        part(y)/(ies) and/ or registering / being part of any
                        case on the JustAct platform
                      </Padding>
                      <LineBottom />
                    </ul>
                  </ol>
                </Content>
              </Padding>
              <Padding>
                <b>When can Data Erasure requests not be made</b>
                <Content style={{ paddingLeft: "20px" }}>
                  Data erasure requests may not be admissible under, but not
                  limited to, the following circumstances:
                  <LineBottom />
                  <ol>
                    <ul style={{ paddingLeft: "40px" }}>
                      <Padding>
                        To meet legal, police, any investigative agency and
                        compliance requirements
                      </Padding>
                      <LineBottom />
                      <Padding>The “right of freedom and expression”</Padding>
                      <LineBottom />
                      <Padding>For supporting legal claims</Padding>
                      <LineBottom />
                    </ul>
                  </ol>
                </Content>
                <LineBottom />
              </Padding>
              <LineBottom />
              <Padding>
                <b>Processing of Data Erasure Requests.</b>
                <LineBottom />
                <ol>
                  <ul style={{ paddingLeft: "20px" }}>
                    <Padding>
                      Where Data subject has already created/ is part of a case,
                      such erasure requests will be processed only subject to
                      and after the permission of all the other parties in the
                      case
                    </Padding>
                    <LineBottom />
                    <Padding>
                      Where the Data subject has already created a Party,
                      deletion of the party will be processed only subject to
                      and after the permission of all other parties and in all
                      the cases that have the said Party.
                    </Padding>
                    <LineBottom />
                    <Padding>
                      Any deletion of case related data may be carried out only
                      after a lapse of 12 months from the date of closure of the
                      case, which closure may be either due to successful
                      resolution or due to quitting/ abandonment of the case by
                      any of the parties.
                    </Padding>
                    <LineBottom />
                    <Padding>
                      At the same time, for the sake of clarity it is stated
                      that JustAct retains the right to use any case data, minus
                      the personally identifiable data for analytical and other
                      purposes.
                    </Padding>
                    <LineBottom />
                    <Padding>
                      When JustAct receives any request for deletion from the
                      data subjects, personal data of the data subjects would be
                      deleted within 30 days of such erasure request being made
                      provided the request does not violate any requirements and
                      is subject to fulfilment of all the clause(s) under this
                      policy.
                    </Padding>
                    <LineBottom />
                  </ul>
                </ol>
              </Padding>
              <Padding>
                <b>Notification of Erasure of Personal Data</b>
                <LineBottom />
                <Content style={{ paddingLeft: "20px" }}>
                  JustAct shall be notifying the data subjects about the erasure
                  of their personal data once the data subject’s personal data
                  has been deleted.
                </Content>
                <LineBottom />
              </Padding>
            </ol>
          </Content>
          <SubHeading>{"Disclaimer"}</SubHeading>
          <Content>
            JustAct does not provide any legal advice and is not a law firm.
            JustAct, however, uses legal professionals for offering us their
            services for resolving disputes. Our services do not constitute
            legal advice, nor does our relation under this contract constitute a
            client-to-attorney relation.
          </Content>
          <LineBottom />
          <SubHeading>{"Updates to this policy"}</SubHeading>
          <Content>
            The Terms & Conditions were last updated on 22nd December 2022. We
            will notify you of a new version of these terms, if we make any
            material changes on your email ID, if the same is registered with
            us. Do please keep your email ID updated, we will not be responsible
            for any non-delivery of emails on any account. We will also post an
            updated copy on our website. Please check our site periodically for
            updates.
          </Content>
        </RuleContainer>
        {/* {settingsLoading ? (
          <Loader>
            <CircularProgress size={60} thickness={3} />
          </Loader>
        ) : (
          <Container>
            {setting?.tnc?.map((terms, index) => (
              <div className="ql-editor" key={index}>
                <Padding>
                  <SubHeading>{terms?.question}</SubHeading>
                  <div
                    dangerouslySetInnerHTML={{ __html: terms?.answer }}
                  ></div>
                </Padding>
              </div>
            ))}
          </Container>
        )} */}
        <Footer />
      </div>
    </>
  );
}

export default TermsAndCondition;

const H2 = styled.h2`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
  font-size: 30px;
  margin-bottom: 32px;
  cursor: default;
  padding: 0 20px;
  text-align: center;
  @media (max-width: 1024px) {
    font-size: 24px;
    text-align: center;
  }
`;

const SubHeading = styled.div`
  font-size: 20px;
  margin: 10px 0;
  color: ${COLORS.BTN_GREEN};
`;

const Padding = styled.li`
  margin: 0 15px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 16px;
  @media ${theme?.breakpoints?.sm_up} {
    padding-left: 40px;
  }
`;

export const RuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: justify;
  margin: 40px 30px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 70%;
    margin: 40px auto;
  }
`;

const LineBottom = styled.div`
  margin-bottom: 10px;
`;
