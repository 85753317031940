import React, { useEffect } from 'react';
import { scroller } from 'react-scroll';

import { Header } from '../../internal/Header/Header';
import Footer from '../LandingPage/Footer';


function Resources({children}) {
    useEffect(()=> {
        scroller.scrollTo(0, {
            duration: 500,
            offset: -70,
            smooth: true,
          })
    },[])
    return (
        <div>
            <Header >
            {children}
            </Header>
            <Footer/>
        </div>
    );
}

export default Resources;
