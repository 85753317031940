import labels from "../../../helpers/labels.json";

export const sidebar_options = [
  {
    icon: require("../../../assets/images/home.svg"),
    inactive: require("../../../assets/images/homeInactive.svg"),
    blueIcon: require("../../../assets/images/Home_active_Blue.svg"),
    title: labels.home,
    path: "/dashboard",
  },
  {
    icon: require("../../../assets/images/my_cases.svg"),
    inactive: require("../../../assets/images/casesInactive.svg"),
    blueIcon: require("../../../assets/images/My Cases_active_Blue.svg"),
    title: labels.my_cases,
    path: "/dashboard/cases",
    listItems: [
      {
        icon: require("../../../assets/images/negotiation-active.svg"),
        inactive: require("../../../assets/images/negotiation-inactive.svg"),
        blueIcon: require("../../../assets/images/Negotiation_active_Blue.svg"),
        title: labels.my_cases_negotiation,
        path: "/dashboard/cases?caseType=negotiation",
        countKey: "negotiationCount",
      },
      {
        icon: require("../../../assets/images/mediation-active.svg"),
        inactive: require("../../../assets/images/mediation-inactive.svg"),
        blueIcon: require("../../../assets/images/Mediation_active_blue.svg"),
        title: labels.my_cases_mediation,
        path: "/dashboard/cases?caseType=mediation",
        countKey: "mediationCount",
      },
      {
        icon: require("../../../assets/images/mediation-active.svg"),
        inactive: require("../../../assets/images/mediation-inactive.svg"),
        blueIcon: require("../../../assets/images/Mediation_active_blue.svg"),
        title: labels.my_cases_arbitration,
        path: "/dashboard/cases?caseType=arbitration",
        countKey: "arbitrationCount",
      },
    ],
  },
  {
    icon: require("../../../assets/images/my_parties.svg"),
    inactive: require("../../../assets/images/partiesInactive.svg"),
    blueIcon: require("../../../assets/images/My Parties_active_Blue.svg"),
    title: labels.my_parties,
    path: "/dashboard/parties",
  },
  {
    icon: require("../../../assets/images/payments.svg"),
    inactive: require("../../../assets/images/paymentsInactive.svg"),
    blueIcon: require("../../../assets/images/Payment History_active_Blue.svg"),
    title: labels.payments,
    path: "/dashboard/payments",
  },
  {
    icon: require("../../../assets/images/my_cases.svg"),
    inactive: require("../../../assets/images/casesInactive.svg"),
    blueIcon: require("../../../assets/images/My Cases_active_Blue.svg"),
    title: labels.daily_casuse,
    path: "/dashboard/daily-cause",
  },
  {
    icon: require("../../../assets/images/Notice Blue V2-03.svg"),
    inactive: require("../../../assets/images/Notice Grey.svg"),
    blueIcon: require("../../../assets/images/Notice Blue.svg"),
    title: labels.notice_generation,
    path: "/dashboard/notice",
  },
  {
    icon: require("../../../assets/images/home.svg"),
    inactive: require("../../../assets/images/homeInactive.svg"),
    blueIcon: require("../../../assets/images/Home_active_Blue.svg"),
    title: labels.mis_dashboard,
    path: "/dashboard/mis-dashboard",
  },
];
