import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

export const HeadWrap = styled.div`
  text-align: center;
  font-size: 25px;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontBold};
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 36px;
  }
`;
export const SubHeadWrap = styled.div`
  text-align: left;
  font-size: 20px;
  padding: 1.5% 0;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontRegular};
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 25px;
    padding: 1.5%;
  }
`;

export const SubHeadWrapBatch = styled.div`
  text-align: left;
  font-size: 16px;
  padding: 1.5%;
  font-family: ${theme.fonts.primaryFontRegular};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 16px;
  @media ${theme?.breakpoints?.sm_up} {
    padding-left: 40px;
  }
`;
export const ImageWrap = styled.div`
  text-align: center;
  margin: 1%;
`;
export const TrainingPage = styled.div`
  display: flex;
  flex-direction: column;
  text-align: justify;
  margin: 40px 30px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 70%;
    margin: 40px auto;
  }
`;
