import { navigate } from "@reach/router";
import React from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { H2 } from "./Styles";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

function index(props) {
  return (
    <>
      <H2 style={{ textAlign: "center", marginTop: "40px" }}>
        Online Dispute Resolution
      </H2>
      <CardContainer>
        <Card>
          <CardHeading>Negotiation</CardHeading>
          <CardBody>
            <div>
              Solving a dispute through a process of bids and offers using
              technology, game theory and AI/ML to enable a quick and amicable
              solution.
            </div>
          </CardBody>
          <BtnStyle
            onClick={() => {
              navigate("/negotiation-rules");
              window.scrollTo(0, 0);
            }}
          >
            Negotiation Rules <OpenInNewIcon style={{ fontSize: "small" }} />
          </BtnStyle>
          <BtnStyle
            onClick={() => {
              navigate("/negotiation-model-clause");
              window.scrollTo(0, 0);
            }}
          >
            Model Clause <OpenInNewIcon style={{ fontSize: "small" }} />
          </BtnStyle>
        </Card>
        <Card>
          <CardHeading>Mediation</CardHeading>
          <CardBody>
            <div>
              Resolution of a dispute through an iterative dialogue facilitated
              by a trained mediator with domain expertise to arrive at a
              settlement agreement.
            </div>
          </CardBody>
          <BtnStyle
            onClick={() => {
              navigate("/mediation-rules");
              window.scrollTo(0, 0);
            }}
          >
            Mediation Rules <OpenInNewIcon style={{ fontSize: "small" }} />
          </BtnStyle>
          <BtnStyle
            onClick={() => {
              navigate("/mediation-model-clause");
              window.scrollTo(0, 0);
            }}
          >
            Model Clause <OpenInNewIcon style={{ fontSize: "small" }} />
          </BtnStyle>
        </Card>
        <Card2>
          <CardHeading>Arbitration</CardHeading>
          <CardBody>
            <div>
              For more complex disputes, Arbitration follows a process where
              parties may engage lawyers and advisors to put forth their case to
              a panel of one or more trained arbitrators to arrive at an
              “arbitral award”. Arbitration can be Fast track or Regular.
            </div>
          </CardBody>
          <BtnStyle
            onClick={() => {
              navigate("/arbitration-rules");
              window.scrollTo(0, 0);
            }}
          >
            <div>
              Arbitration Rules <OpenInNewIcon style={{ fontSize: "small" }} />
            </div>
          </BtnStyle>
          <BtnStyle
            onClick={() => {
              navigate("/arbitration-model-clause");
              window.scrollTo(0, 0);
            }}
          >
            Model Clause <OpenInNewIcon style={{ fontSize: "small" }} />
          </BtnStyle>
        </Card2>
      </CardContainer>
    </>
  );
}

export default index;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  margin: 0px;
  @media (max-width: 960px) {
    margin: 40px 0px;
  }
`;
const CardBody = styled.div`
  font-size: 18px;
  font-family: ${theme.fonts.primaryFontRegular};
  margin: 20px;
  text-align: left;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;
const CardHeading = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.COLOR_DARK};
  font-size: 25px;
  margin: 20px;
  text-align: left;
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;
const Card = styled.div`
  flex-direction: column;
  justify-content: space-between;
  width: 400px;
  min-height: 350px;
  padding: 30px;
  margin: 20px;
  background-color: #ffffff;
  border-radius: 1%;

  @media (max-width: 960px) {
    min-height: auto;
    margin: 20px 0px 0 0;
    width: 80%;
  }

  @media (max-width: 600px) {
    padding: 20px 10px;
    width: 100%;
  }
`;
const Card2 = styled.div`
  flex-direction: column;
  justify-content: space-between;
  width: 400px;
  min-height: 410px;
  padding: 30px;
  margin: 20px;
  background-color: #ffffff;
  border-radius: 1%;

  @media (max-width: 960px) {
    min-height: auto;
    margin: 20px 0px 0 0;
    width: 80%;
  }

  @media (max-width: 600px) {
    padding: 20px 10px;
    width: 100%;
  }
`;
const BtnStyle = styled.section`
  color: #00838c;
  display: flex;
  font-size: 16px;
  flex-direction: row;
  cursor: pointer;
  text-align: left;
  float: left;
  margin-top: auto;
  padding-top: 40px;
  padding-left: 20px;
`;
