import React from "react";
import MaterialTable, { MTableCell } from "material-table";
import { Paper, Tooltip } from "@material-ui/core";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import tableIcons from "./MaterialTableIcons";
import CustomPagination from "./MaterialPagination";
import SmallTable from "./MaterialSmallTable";
import * as XLSX from "xlsx";
import { CloudDownloadOutlined } from "@material-ui/icons";
import { colWidths } from "./constants";

export default function CustomTable({
  hideSearch = false,
  hidePagination = false,
  hideFilterBar = false,
  filters,
  selectedFilter,
  setSelectedFilter,
  placeholderText,
  pageSize = 5,
  MTRef = {},
  state = {},
  noToolbar,
  pluralTitle = "Records",
  singularTitle = "record",
  customFilter,
  filterCount = 0,
  selection = false,
  onFilterClick = () => {},
  onSelectionChange = () => {},
  customMessage,
  top,
  left,
  page,
  setPage,
  loading,
  ...props
}) {
  /**
   * @description create xlsx
   */

  let excel =
    state?.length &&
    state?.map((item) => ({
      CaseId: item?.caseId,
      CaseDescription: item?.description,
      ContractNumber: item?.contractNumber,
      CaseCreated: new Date(item?.case_created_at)
        .toISOString()
        .split("T")[0]
        .split("-")
        .reverse()
        .join("-"),
      TotalClaimValues: item?.totalClaimValue,
      CaseStage: item?.caseStage,
      DetailedStage: item?.detailedStage,
      ...item.parties
        ?.filter((p) => p.partyrole === "claimant")
        .reduce(
          (acc, p, index) => ({
            ...acc,
            [`ClaimantPartyName${index + 1}`]: p.partyName,
          }),
          {},
        ),
      ...item.parties
        ?.filter((p) => p.partyrole === "respondent")
        .reduce(
          (acc, p, index) => ({
            ...acc,
            [`RespondentPartyName${index + 1}`]: p.partyName,
          }),
          {},
        ),
    }));

  const createExcelFile = (data, colWidths) => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert the array of objects to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Set column widths
    worksheet["!cols"] = colWidths.map((width) => ({ wpx: width }));

    // Style the header row
    const headerRange = XLSX.utils.decode_range(worksheet["!ref"]);
    for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
      const cell = worksheet[XLSX.utils.encode_cell({ r: 0, c: C })];
      if (cell) {
        cell.s = {
          font: {
            bold: true,
          },
        };
      }
    }

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the workbook to a file
    XLSX.writeFile(workbook, `Mis.xlsx`);
  };

  return (
    <div className="custom-material-table">
      <DisplayMaterialTable>
        <MaterialTable
          isLoading={loading}
          icons={tableIcons}
          tableRef={MTRef}
          localization={{
            body: {
              emptyDataSourceMessage: (
                <TableEmpty>
                  <H2>No {pluralTitle} found</H2>
                </TableEmpty>
              ),
            },
          }}
          detailPanel={props.detailPanel}
          options={{
            headerStyle: {
              color: "#ffffff",
              padding: "13px 10px",
            },
            toolbar: noToolbar,
            pageSize,
            detailPanelType: "multiple",
            detailPanelColumnAlignment: "right",
            pageSizeOptions: [pageSize],
            emptyRowsWhenPaging: state?.data?.length === 0,
            // maxBodyHeight: 390,
            showEmptyDataSourceMessage: loading ? false : true,
            selection,
            selectionProps: {
              checkedIcon: (
                <img
                  alt="checked"
                  src={require("../../../assets/images/checkBoxSelected.svg")}
                />
              ),
              icon: (
                <img
                  alt="checked"
                  src={require("../../../assets/images/checkbox.svg")}
                />
              ),
              disableRipple: true,
            },
            showSelectAllCheckbox: selection,
          }}
          components={{
            Container: (props) => (
              <Paper
                {...props}
                style={{ backgroundColor: "transparent" }}
                elevation={0}
              />
            ),
            Cell: (props) => {
              return <StyledCell {...props} />;
            },
            Toolbar: (props) => {
              return (
                <StyleToolbar>
                  <div className="style-label-bar">
                    <Tooltip title={"Export as xlsx"} placement="top">
                      <CloudDownloadOutlined
                        style={{
                          fill: "#00838c",
                          marginRight: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => createExcelFile(excel, colWidths)}
                      />
                    </Tooltip>
                  </div>
                  <Label className="style-label-bar">{`Showing ${props.data.length} Cases`}</Label>
                  <div className="input-icons">
                    <div className="search-icons">
                      <SearchIcon
                        src={require("../../../assets/images/searchBar.svg")}
                        alt="search_icon"
                      />
                    </div>
                    <StyledInput
                      placeholder={placeholderText}
                      value={props.searchText}
                      onChange={(e) => props.onSearchChanged(e.target.value)}
                      backgroundColor={"#ffffff"}
                      width={"230px"}
                    />
                  </div>
                </StyleToolbar>
              );
            },
            Pagination: (props) => {
              return (
                <CustomPagination
                  count={Math.ceil(props.count / pageSize)}
                  onChangePage={props.onChangePage}
                  onChangeRowsPerPage={props.onChangeRowsPerPage}
                  page={props.page}
                  colSpan={props.count}
                  {...{ props, page, setPage }}
                />
              );
            },
          }}
          onSelectionChange={onSelectionChange}
          {...props}
        />
      </DisplayMaterialTable>

      <DisplaySmallTable>
        <div className="custom-table">
          <SmallTable
            {...{
              state,
              selection,
              onSelectionChange,
              top,
              left,
            }}
            column={props}
            showSelectAllCheckbox={selection}
            detailPanel={props.detailPanel}
          />
        </div>
      </DisplaySmallTable>
    </div>
  );
}

const DisplayMaterialTable = styled.div`
  @media (min-width: 780px) {
    display: block;
  }
`;

const DisplaySmallTable = styled.div`
  display: block;
  margin-bottom: 25px;
  @media (min-width: 780px) {
    display: none;
  }
`;

const StyledCell = styled(MTableCell)`
  border-bottom: 0;
  font-family: ${theme.fonts.primaryFontRegular} !important;
  color: ${COLORS.COLOR_DARK} !important;
  @media (max-width: 1440px) {
    font-size: 12px;
  }
`;

const TableEmpty = styled.span`
  color: ${COLORS.COLOR_DARK};
  font-weight: 300;
  letter-spacing: 0.1px;
  font-size: 11px;
  margin: 0;
  font-family: ${theme.fonts.primaryFontBold};
  text-align: center;
`;

const StyleToolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
  margin-top: 12px;
`;

const Label = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  margin-right: 12px;
  line-height: 1.9;
  color: ${COLORS.TRANSCRIPT_GRAY};
  text-transform: capitalize;
`;

const SearchIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 39px;
  border-radius: 10px;
  border: solid 1px #e1e3ee;
  background-color: ${({ backgroundColor }) => backgroundColor};
  outline: none;
  font-size: 14px;
  font-weight: 600;
  color: #acb1c2;
  font-family: ${theme.fonts.primaryFontRegular};
  padding-left: 35px;
  &::placeholder {
    color: #acb1c2;
  }
  @media ${theme?.breakpoints?.sm_up} {
    width: 220px;
  }
`;

const H2 = styled.h2`
  margin-top: 170px;
`;
