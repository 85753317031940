import React, { useState, useEffect } from "react";
import { HyperLink } from "../../../styles/component/style";
import { PrimaryOutlinedCTAButton } from "../../common/Buttons";
import { navigate } from "@reach/router";
import { CookieWrapper, Text } from "./Styles";
const Cookies = () => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const hasEnabled = localStorage.getItem("justact_cookies_enabled");
    if (hasEnabled) {
      setShow(false);
    }
  }, []);

  return show ? <Show setShow={setShow} /> : null;
};

export default Cookies;

const Show = ({ setShow }) => {
  return (
    <CookieWrapper>
      <Text>
        This website will place cookies to help us make this website better. To
        find out more about these cookies, see our
        <HyperLink
          style={{ display: "inline", fontSize: 12, paddingLeft: 3 }}
          onClick={() => {
            navigate("/privacy-policy");
          }}
        >
          Privacy Policy
        </HyperLink>
        . Use of this website confirms your acceptance of these cookies.
      </Text>

      <PrimaryOutlinedCTAButton
        onClick={() => {
          localStorage.setItem("justact_cookies_enabled", "true");
          setShow(false);
        }}
        style={{
          width: 212,
          height: 48,
          marginRight: 49,
        }}
      >
        Continue
      </PrimaryOutlinedCTAButton>
    </CookieWrapper>
  );
};
