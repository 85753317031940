import React from "react";
import { CustomRadioGroupBulk } from "../../common/FormInputs";
import {
  StyledFormElementsWrapper1,
  StyledLabel
} from "../../internal/CreateCase/Styles";

const CaseTypeRadioButton = ({ values, handleChange }) => {
  return (
    <>
      <StyledFormElementsWrapper1 style={{ marginBottom: 15, marginTop: 15 }}>
        <StyledLabel>Select a ResolutionKind</StyledLabel>
        <CustomRadioGroupBulk
          radioInputs={[
            { label: "Negotiation", value: "negotiation" },
            { label: "Mediation", value: "mediation" },
            { label: "Arbitration", value: "arbitration" }
          ]}
          name={"resolutionKind"}
          handleChange={e => handleChange(e, "resolutionKind")}
          {...{ values }}
        />
      </StyledFormElementsWrapper1>
    </>
  );
};

export default CaseTypeRadioButton;
