import React from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";
import { H2, SubHeading } from "./Styles";
import { Paper } from "@material-ui/core";

function OurValues(props) {
  return (
    <>
      <H2>Our Values</H2>
      <BodyWrap>
        <Paper
          style={{
            backgroundColor: "#f9f9f9",
            padding: "20px",
            margin: "20px",
          }}
        >
          <TextBox>
            <SubHeading>Respect</SubHeading>
            <TextLines>
              <ul>
                <li>
                  Employees, Customers, Users, Vendors, Government and everyone
                  that we interact with.
                </li>
                <li>Knowledge, Skills and Individuality.</li>
                <li>Seek and listen to others.</li>
              </ul>
            </TextLines>
          </TextBox>
        </Paper>

        <Paper
          style={{
            backgroundColor: "#f9f9f9",
            padding: "20px",
            margin: "20px",
          }}
        >
          <TextBox>
            <SubHeading>Integrity</SubHeading>
            <TextLines>
              <ul>
                <li>Strive to do the Right Thing.</li>
                <li>Be Truthful in Everything.</li>
                <li>Speak what you believe.</li>
              </ul>
            </TextLines>
          </TextBox>
        </Paper>
        <Paper
          style={{
            backgroundColor: "#f9f9f9",
            padding: "20px",
            margin: "20px",
          }}
        >
          <TextBox>
            <SubHeading>Problem Solving</SubHeading>
            <TextLines>
              <ul>
                <li>Deliver Value through Innovation.</li>
                <li>
                  Use knowledge to deliver smart, sustainable and scalable
                  solutions.
                </li>
                <li>Always challenge status quo.</li>
                <li>Look at change as an opportunity to solve problems</li>
              </ul>
            </TextLines>
          </TextBox>
        </Paper>
        <Paper
          style={{
            backgroundColor: "#f9f9f9",
            padding: "20px",
            margin: "20px",
          }}
        >
          <TextBox>
            <SubHeading>Trust</SubHeading>
            <TextLines>
              <ul>
                <li>Transparency and Collaboration.</li>
                <li>Delegate and de centralize.</li>
              </ul>
            </TextLines>
          </TextBox>
        </Paper>
        <Paper
          style={{
            backgroundColor: "#f9f9f9",
            padding: "20px",
            margin: "20px",
          }}
        >
          <TextBox>
            <SubHeading>Rigour and Quality</SubHeading>
            <TextLines>
              <ul>
                <li> All decisions to be informed and data driven. </li>
                <li>Focus on deep thinking and rigorous analysis.</li>
                <li>Attention to detail.</li>
                <li>Keep focus on goal. </li>
              </ul>
            </TextLines>
          </TextBox>
        </Paper>
      </BodyWrap>
    </>
  );
}

export default OurValues;

const BodyWrap = styled.div`
display:flex;
flex-direction:row;
flex-wrap:wrap;
align-items:flex-start;
justify-content:center;
font-size:25px;
padding:1%;
padding-right:0;
margin-top:20px;
width:70%;
@media(max-width:600px){
    font-size:16px;
}
}
`;
const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 325px;
  height: 200px;
  margin-left: 20px;
  @media (max-width: 1024px) {
    height: unset;
  }
  @media (max-width: 600px) {
    width: 250px;
    height: unset;
  }
`;

const TextLines = styled.div`
  color: grey;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 16px;
  padding: 20px;
  margint-left: 0;
  @media (max-width: 600px) {
    font-size: 14px;
    padding: 0;
  }
`;
