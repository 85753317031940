import { makeStyles, Paper, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import themes from "../../../assets/theme";
import Footer from "../LandingPage/Footer";
import { Header } from "../../internal/Header/Header";
import { H2 } from "./Styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "70%",
    textAlign: "center",
    padding: "10px",
    margin: "auto",
    marginTop: "20px",
    marginBottom: "20px",
    "@media(max-width:700px)": {
      width: "90%",
    },
  },
  heading: {
    color: COLORS.BTN_GREEN,
    fontSize: "18px",
    margin: "auto",
  },
  subheading: {
    color: COLORS.COLOR_DARK,
    fontSize: "14px",
    margin: "auto",
  },
  content: {
    fontFamily: themes.fonts.primaryFontSemiBold,
    fontSize: "14px",
  },
}));
function Directors(props) {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header>
        <H2 style={{ textAlign: "center", paddingTop: "30px" }}>
          Board of Directors
        </H2>
        <Paper className={classes.paper}>
          <ProfileBox>
            <ProfileImage>
              <DirectorImg
                src={require("../../../assets/images/director1.jpg")}
                alt="Director"
              />
            </ProfileImage>
            <ProfileText>
              <h2 className={classes.heading}>Justice K. Kannan</h2>
              <h2 className={classes.subheading}>Independent Director</h2>
              <br />
              <Typography className={classes.content}>
                Justice K. Kannan was formerly a Judge at High court of Punjab &
                Haryana, Chandigarh. He has also served as the Chairman of the
                Railways Claims Tribunal, Principal Bench, New Delhi. A trained
                mediator, Justice Kannan writes in leading newspapers on
                socio-legal issues. His principled declaration of assets as the
                first judge in India to do so brought a new paradigm for the
                entire judiciary to follow; and the weekly India Today named him
                among twenty heroes of 2009.
              </Typography>{" "}
              <br />
              <Typography className={classes.content}>
                Apart from an avid interest in medicine and law as an
                inter-disciplinary approach, he is also an expert in liability
                and succession laws. He has written a book on Accidents and
                Compensation Law (Wadhwa Butterworths LexisNexis, 2005),
                contributed to two titles of the Halsbury's Laws of India, Wills
                and Succession and Landlord and Tenant (both 1st and 2nd
                editions), edited and wrote Principles of Insurance by
                M.N.Srinivasan and K.Kannan (LexisNexis 2017).
              </Typography>
              <br />
              <Typography className={classes.content}>
                He is the editor of the renowned Modi's Textbook on Medical
                Jurisprudence and Toxicology, 2016 edition, Mulla's Indian
                Registration Act, Mulla's Sale of Goods Act and Partnership Act
                (Students' edition), and Paruck's Indian Succession Act (the
                last 3 editions).
              </Typography>
            </ProfileText>
          </ProfileBox>
        </Paper>

        <Paper className={classes.paper}>
          <ProfileBox>
            <ProfileImage>
              <DirectorImg
                src={require("../../../assets/images/director2.5,cg_true")}
                alt="Director"
              />
            </ProfileImage>
            <ProfileText>
              <h2 className={classes.heading}>N L Rajah </h2>
              <h2 className={classes.subheading}>Founder & Director</h2>
              <br />
              <Typography className={classes.content}>
                An advocate since 1986, Mr Rajah was designated as a Senior
                Advocate of the Madras High Court in 2016. In 2019, Mr Rajah was
                conferred "Living legend of Law" award by the Bar Association of
                India. He has been a Member of committee of Law Commission to
                suggest changes to Arbitration and Conciliation Act as also
                Consultee to Law Commission on Commercial Courts Act.
              </Typography>{" "}
              <br />
              <Typography className={classes.content}>
                Mr Rajah is a Trustee of the Palkhivala Foundation and a
                Founding Director of Nani Palkhivala Arbitration Centre. He is a
                Member, Arbitration Committee and Member, Heritage Committee of
                the Madras High Court.
              </Typography>
              <br />
              <Typography className={classes.content}>
                Author of Consumer Protection in India (Lexis Nexis), he has
                also edited a book on the history of Madras High Court and also
                a book on the splendour of the Madras HC building.
              </Typography>
            </ProfileText>
          </ProfileBox>
        </Paper>

        <Paper className={classes.paper}>
          <ProfileBox>
            <ProfileImage>
              <DirectorImg
                src={require("../../../assets/images/director3.5,cg_true")}
                alt="Director"
              />
            </ProfileImage>
            <ProfileText>
              <h2 className={classes.heading}>Karthik Krishna</h2>
              <h2 className={classes.subheading}>Founder & Director</h2>
              <br />
              <Typography className={classes.content}>
                An MBA from IIM Calcutta and a Computer Science Engineer from
                BITS Pilani, Karthik worked for over 15 years as the Managing
                Director of Deutsche Bank, London and their Singapore and New
                York offices, before returning to India in 2016. He currently
                runs a series of businesses within the Telekonnectors Group
                delivering telecom products and services. The focus of the group
                is product design and engineering.
              </Typography>{" "}
              <br />
              <Typography className={classes.content}>
                Group companies include Door Sabha Nigam Ltd., which is focused
                on telecom services including tele-conferencing solutions, while
                Telekonnectors focuses on designing and manufacturing telecom
                infrastructure equipment. Recent launches include PollStar and
                MultiCall. He is also a board member of Indian NBFC of KKR & Co
                Inc.
              </Typography>
            </ProfileText>
          </ProfileBox>
        </Paper>
        <Paper className={classes.paper}>
          <ProfileBox>
            <ProfileImage>
              <DirectorImg
                src={require("../../../assets/images/director4.jpg")}
                alt="Director"
              />
            </ProfileImage>
            <ProfileText>
              <h2 className={classes.heading}>Viren Nanavati</h2>
              <h2 className={classes.subheading}>Director</h2>
              <br />
              <Typography className={classes.content}>
                With a Mechanical Engineering degree from Madras University, and
                a Master’s in Manufacturing Management from Coventry University,
                England, Viren’s career spans 25 years across the fields of
                Operations, Purchase and Sales at Raj Petro.
              </Typography>{" "}
              <br />
              <Typography className={classes.content}>
                He is currently a Senior Director – Sales & Marketing, heading
                the domestic business, and is an active member of the Leadership
                Team at Raj Petro and a Joint Venture partner with Brenntag.
              </Typography>
              <br />
            </ProfileText>
          </ProfileBox>
        </Paper>

        <Footer />
      </Header>
    </div>
  );
}

export default Directors;

const DirectorImg = styled.img`
  height: 200px;
  border-radius: 50%;
`;
const ProfileBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ProfileImage = styled.div`
  display: flex;
  height: 250px;
  width: 250px;
  justify-content: center;
  align-items: center;
`;
const ProfileText = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  padding: 0 20px 20px 20px;
  text-align: justify;
  align-items: flex-start;
`;
