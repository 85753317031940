import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";

export const StyledFormElementsWrapper = styled.div`
  margin-bottom: 28px;
  max-width: 436px;
  & .link {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
  }
  & .template {
    margin-top: 0px;
  }
  & .waring-msg {
    color: ${COLORS.WARNING_COLOR};
    font-size: 10px;
    white-space: nowrap;
  }
  & .mediator-type {
    .MuiFormGroup-root {
      display: flex;
      flex-direction: column !important;
      & .MuiFormControlLabel-root {
        padding: 10px 0px;
      }
    }
  }
  @media ${theme?.breakpoints?.sm_down} {
    & .template {
      margin-top: 15px;
    }
  }
`;

export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  cursor: pointer;
  display: table;
  font-size: 14px;
  width: 100%;
  @media (min-width: 780px) {
    font-size: 12px;
    width: auto;
  }
`;

export const Button = styled.div`
  width: 50%;
`;
