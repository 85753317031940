import React, { useEffect } from "react";
import { Content, SubHeadWrap, BlogPage, BlogImage } from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import { Helmet } from "react-helmet";
import { HyperLink } from "../../common/FormInputs";

/* eslint-disable jsx-a11y/anchor-is-valid */

function OnlineDisputeBlogSix(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>ADR Applications to Women-Related Disputes - JustAct</title>
        <meta
          name="description"
          content="This blog discusses the reasons for women to choose ADR over litigation process to resolve their problems successfully."
        />
        <meta
          name="keywords"
          content="ADR Application, ADR Women Related Disputes, Alternative Dispute Resolution, Arbitration, JustAct"
        />
      </Helmet>
      <Header>
        <BlogPage>
          <SubHeadWrap>
            ADR and its applications to women-related disputes (Part 2 of 3)
          </SubHeadWrap>
          <br />
          <Content>
            <b style={{ textAlign: "center" }}> Authored by: Vismita Rathi </b>
            <br />
            <BlogImage
              src={require("../../../assets/images/N1.png")}
              alt="Independent"
            />
            <br />
            <p>
              Violence against women is a matter of grave concern. The immediate
              and long-term physical, sexual, and mental consequences for them
              can prove to be absolutely devastating. Arbitrary killings,
              torture, and sexual violence against women have been on the rise
              and people all over the world are trying to find ways to redress
              these issues, which has accordingly resulted in the interest
              around the scope of Alternate Dispute Resolution in helping
              resolve them.
            </p>
            <br />
            <p>
              A 2018 analysis of prevalence data from 2000-2018 across 161
              countries and areas, conducted by WHO on behalf of the UN
              Interagency working group on violence against women, found that
              worldwide, nearly 1 in 3, or 30%, of women have been subjected to
              physical and/or sexual violence by an intimate partner or
              non-partner sexual violence or both<sup>1</sup>.
            </p>
            <br />
            <p>
              Such staggering figures indicate the need to address this problem
              immediately. Moreover, lockdowns during the COVID-19 pandemic and
              its social and economic impacts have further increased the
              exposure of women to abusive partners and known risk factors,
              while limiting their access to services. Situations of
              humanitarian crises and displacement may exacerbate existing
              violence, such as by intimate partners, as well as non-partner
              sexual violence, and may also lead to new forms of violence
              against women<sup>2</sup>.
            </p>
            <br />
            <p>Now the first question that might arise in your mind is:</p>
            <br />
            <b style={{ textAlign: "center" }}>
              What role will ADR play in effectively resolving these disputes?
            </b>
            <br />
            <BlogImage
              src={require("../../../assets/images/N2.png")}
              alt="Independent"
            />
            <br />
            <p>
              <HyperLink
                onClick={() =>
                  window.open(
                    `https://justact.co.in/training/corporate-short-programme`
                  )
                }
              >
                Alternative Dispute Resolution (ADR)
              </HyperLink>{" "}
              refers to any means of settling disputes outside the courtroom.
              Various kinds of processes and techniques are applied here to
              resolve a dispute without going on the road of litigation. ADR, in
              recent years, has become an alternative to litigation.
            </p>
            <br />
            <p>
              The basic difference between{" "}
              <HyperLink
                onClick={() =>
                  window.open(`https://justact.co.in/arbitration-rules`)
                }
              >
                arbitration
              </HyperLink>{" "}
              and litigation is that the court is involved in the case of
              litigation whereas in arbitration, a settlement between the
              parties is reached outside the court. Though litigation has been
              the primary method of obtain justice in India, people have started
              switching to ADR due to various reasons such as its flexibility,
              offered privacy, lower cost and speedy nature.
            </p>
            <br />
            <p>
              Bringing forth the voice of battered women who have faced
              difficult circumstances is the need of the hour and ADR, with its
              streamlined mechanism and effective negotiation practices has the
              resources to provide for it. The system of Alternative Dispute
              Resolution came into existence due to the failure of the
              traditional court system to resolve disputes effectively and
              efficiently. The total number of pending cases in India as of Feb.
              1, 2020, was 3.65 crore, which clearly showcases that the courts
              are already over-burdened with a huge backlog of cases and there
              is a need for an alternative platform to settle disputes arising
              in the future.
            </p>
            <br />
            <BlogImage
              src={require("../../../assets/images/N3.png")}
              alt="Independent"
            />
            <br />
            <p>
              There are multiple reasons for women to choose ADR over litigation
              process to resolve their problems successfully. Some of them are
              highlighted as follows:
            </p>
            <br />
            <ol style={{ paddingLeft: "40px" }}>
              <li>
                <p style={{ marginBottom: "1px" }}>
                  <b>Confidentiality:</b> Arbitration is strictly private and
                  confidential. The proceedings of a case in an arbitration
                  setting are not subject to public records and are generally
                  held in privacy away from public scrutiny. The confidentiality
                  within the process makes it easier for women to speak about
                  private matters, especially in sexual harassment cases.
                </p>
                <br />
                <p style={{ marginBottom: "1px" }}>
                  Although Section 228A of the Indian Penal Code clearly states
                  that rape victims or survivors of other sexual offences
                  prescribed by law cannot be identified without explicit
                  permission, the legal prohibition continues to be violated and
                  the privacy of the victim is breached.
                </p>
                <br />
                The victim and her identity need to be protected and to make
                this possible, we need a more informal process which is
                confidential and does not involve public opinions and
                interferences.
              </li>
              <br />
              <li>
                <p style={{ marginBottom: "1px" }}>
                  <b>Cost factor:</b> The cost and expenses incurred in an
                  arbitration process are not much as compared to the process of
                  litigation. The primary reason for lower cost of ADR is that
                  most proceedings take lesser time than a lawsuit in a court,
                  which results in it being quite inexpensive for people.
                </p>
                <br />
                In the present time, though the gender pay gap has dropped
                substantially, many women still face hurdles in accessing
                finances and legal advice unlike men. Lack of resources can have
                a negative impact on a lawsuit filed in the court of law. ADR is
                a way to overcome these difficulties faced by women and give
                them a fair chance to claim and fight for their rights.
              </li>
              <br />
              <li>
                <p style={{ marginBottom: "1px" }}>
                  <b>Speedy/Quick judgement:</b> The pace at which litigation
                  and ADR proceed varies greatly. Disputes which are taken to
                  arbitration can be resolved faster than a lawsuit in court.
                  Compared to lawsuits filed in courts which can sometimes take
                  years to get decided, ADR methods entail short timelines which
                  is extremely helpful to the parties involved especially women,
                  who are fighting against harassment or sexual violence.
                </p>
                <br />
                Sensitive cases need to be dealt with as soon as possible so
                that the offender does not get the opportunity to intimidate the
                victim or her family. Many times, in long running cases of rape
                or sexual abuse, the accused easily influences witnesses and
                silences them which results in his acquittal. ADR mechanism
                provides specific fast-track methods, such as expedited
                arbitration, which can be availed by the victim to seek justice.
              </li>
              <br />
              <li>
                <p style={{ marginBottom: "1px" }}>
                  <b>Expert opinion:</b> Specialization of the decision-makers
                  in the two methods is a key factor to be considered. Judges in
                  a court case will often know very little about certain types
                  of cases whereas arbitrators are typically people with
                  experience in the industry pertaining to the specific
                  arbitration disputes and, therefore, may provide a greater
                  level of expertise in comparison to a judge.
                </p>
                <br />
                <p>
                  In the arbitration process, the parties have the authority to
                  select their arbitrator themselves which enhances their trust
                  and satisfaction towards the complete process and helps in
                  achieving high-quality outcomes while limiting the time and
                  cost of the proceedings as compared to court proceedings. An
                  expert will not have a prejudiced opinion on the subject which
                  will ensure a fair trial for the women involved disputes.
                  Improving access to justice for women is one step towards
                  creating full and true equality for women.
                </p>
                <br />
              </li>
            </ol>
            <br />
            <BlogImage
              src={require("../../../assets/images/N4.png")}
              alt="Independent"
            />
            <br />
            <p>
              Exploitation of women is a sad reality even in the 21st century.
              Rape, sex trafficking, forced marriage, slavery, and gender-based
              violence against women are still prevalent. According to the 2019
              annual report of the National Crime Records Bureau (NCRB), 32,033
              rape cases were registered across India<sup>3</sup>. One rape was
              reported every 16 minutes in India and the conviction rate
              recorded that year was 27.8%.
            </p>
            <br />
            <p>
              Even though Indian law provides for rape cases to be completed
              within a year through fast-track courts, they have not been of any
              help. The probable cause for this could be that Fast Tracks Courts
              are no different from the usual courts and they follow the same
              processes and procedures. Fast-track courts, even though dedicated
              to a particular category of cases have delivered worse outcomes
              than ordinary courts in India.
            </p>
            <br />
            <p>
              In view of these facts, it is not surprising at all to look at how
              far short these courts have fallen of their assigned duty.
              Considering all the failed attempts to save women from the
              injustice faced by them, we need to look for a better alternative
              in the form of <b>Alternate Dispute Resolution.</b> ADR is a
              recent but increasingly prevalent phenomenon that has
              significantly evolved due to the failure of the conventional
              process of litigation to provide timely resolution of disputes.
            </p>
            <br />
            <BlogImage
              src={require("../../../assets/images/N5.png")}
              alt="Independent"
            />
            <br />
            <p>
              We need to provide ADR with a fair chance to effectively settle
              various disputes. We must also try and construct such dispute
              resolution mechanisms that are fair in process as well as in
              outcome and those that meet the legal needs of the disputants.
            </p>
            <br />
            <p>
              Alternate Dispute Resolution is not devoid of flaws in its system.
              There’s always a possibility of power imbalance between the two
              parties. Our society is of the opinion that women are far inferior
              in all aspects of life as compared to men. They are consistently
              treated as less worthy of belief than men, specifically because
              they are women.
            </p>
            <br />
            <p>
              This refusal to listen to women seriously is very often unintended
              and seems so natural that it has become hard to even identify it
              now. ADR is more prone to the influence of patriarchal cultural
              assumptions. This in turn affects mediators and counsellors which
              further leads to the marginalization of the aggrieved woman and
              her concerns. The outcome of any dispute should not be based upon
              the gendered inequalities of power.
            </p>
            <br />
            <p>
              Exploitation of women and their vulnerabilities will damage
              people’s belief in the system. The real long-term goal of ADR must
              be to attack the sources of a woman’s exploitation directly.
              Limiting violence against women and protecting them from physical,
              sexual, and mental assault should be their primary responsibility.
              It is already a challenge for survivors to obtain justice in the
              Indian legal system and we, as a community need to transform the
              existing ADR mechanism to a more robust and secure platform to
              save our women from injustice.
            </p>
            <br />
            <b style={{ textAlign: "center", fontSize: "20px" }}>
              {" "}
              References{" "}
            </b>
            <br />
            <p style={{ textAlign: "flex" }}>
              1. Violence against women Prevalence Estimates, 2018. Global,
              regional, and national prevalence estimates for intimate partner
              violence against women and global and regional prevalence
              estimates for non-partner sexual violence against women.
            </p>
            <br />
            <p>
              2. Violence against women. (2021, March 9). World Health
              Organization,
            </p>
            <p>
              <a
                style={{
                  color: "blue",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open(
                    "https://www.who.int/news-room/fact-sheets/detail/violence-against-women"
                  )
                }
              >
                https://www.who.int/news-room/fact-sheets/detail/violence-against-women
              </a>
            </p>
            <br />
            <p>
              3. Ministry of Home Affairs. (2020, September). Crime in India,
            </p>
            <p>
              <a
                style={{
                  color: "blue",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open(
                    "https://ncrb.gov.in/sites/default/files/CII%202019%20Volume%201.pdf"
                  )
                }
              >
                https://ncrb.gov.in/sites/default/files/CII%202019%20Volume%201.pdf
              </a>{" "}
            </p>
          </Content>
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default OnlineDisputeBlogSix;
