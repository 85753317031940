import React, { useState } from "react";
import { Images } from "../../../../assets/images";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  makeStyles,
} from "@material-ui/core";
import { Actions, DropDown, Icon, Header, Heading } from "../styles";
import styled from "styled-components";
import theme from "../../../../assets/theme";
import { sanitize } from "dompurify";

const useStyles = makeStyles((themes) => ({
  root: {
    boxShadow: "none",
    [themes.breakpoints.up(640)]: {
      border: `solid 0.5px #e1e3ee`,
    },
  },
  content: {
    margin: `0 !important`,
    "& .MuiExpansionPanelSummary-content.Mui-expanded": {
      margin: `0 !important`,
    },
  },
  details: {
    display: "flex",
    flexDirection: "column",
    padding: `0 15px 15px !important`,
    [themes.breakpoints.up(640)]: {
      padding: `0 21px 21px !important`,
    },
  },
}));

const CaseAgreement = ({
  title = "Final Award Summary for Case ID: 15372",
  message,
  summaryStatus = false,
  isPaid = false,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  return (
    <ExpansionPanel className={classes.root} expanded={expanded}>
      <ExpansionPanelSummary
        classes={{ content: classes.content }}
        onClick={() => setExpanded((e) => !e)}
      >
        <Header>
          <div className="title-container">
            <Heading style={{ marginBottom: "unset" }}>
              <Title>{title}</Title>
            </Heading>
          </div>
          <Actions>
            <DropDown>
              <Icon
                src={Images.dropDownArrow}
                alt="dropdown"
                expanded={expanded}
              />
            </DropDown>
          </Actions>
        </Header>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <AggrementArea>
          {summaryStatus ? (
            <>
              <div
                dangerouslySetInnerHTML={{ __html: sanitize(message) }}
              ></div>
            </>
          ) : (
            <div className="no-summary">
              <div>
                {`You have reached a legally binding agreement. Please wait while we prepare your summary.`}
                <ol>
                  <li>
                    Please note the the case resolution fees are distributed
                    between the parties.
                  </li>
                  <li>
                    If you haven't already paid for this case, please pay from
                    the Case Cart to collect your Settlement Agreement.
                  </li>
                </ol>
                {isPaid ? (
                  ""
                ) : (
                  <div>
                    You may proceed to pay now to collect the agreement.
                  </div>
                )}
              </div>
            </div>
          )}
        </AggrementArea>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

CaseAgreement.defaultProps = {
  message: `Agreement Summary is Preparing by Case Manager`,
};

export default CaseAgreement;

const AggrementArea = styled.div`
  font-size: 14px;
  & .no-summary {
    font-size: 14px;
    display: flex;
    font-family: ${theme.fonts.primaryFontSemiBold};
    text-align: left;
    & ol {
      font-size: 12px;
      margin: 15px;
      opacity: 0.8;
      & li {
        margin-bottom: 5px;
      }
    }
  }
  & p {
    padding: 10px 0px;
    max-width: 480px;
    font-family: ${theme.fonts.primaryFontRegular};
  }

  @media ${theme?.breakpoints?.sm_up} {
    & .no-summary {
      font-size: 18px;
      padding-left: 30px;
      & ol {
        font-size: 14px;
        margin: 10px 50px 10px;
        margin-right: 0px;
      }
    }
  }
`;

const Title = styled.div`
  font-size: 14px;
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 12px;
    margin-left: 21px !important;
  }
`;
