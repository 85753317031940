import React from "react";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import { H2, RuleContainer, TextLines } from "./Styles";
import { Helmet } from "react-helmet";
import { HyperLink } from "../../common/FormInputs";

function MediationRules(props) {
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>
          Mediation and Conciliation Rules - Mediation - Alternative Dispute
          Resolution - JustAct
        </title>
        <meta
          name="description"
          content="Learn about JustAct Mediation & Conciliation Rules. "
        />
        <meta
          name="keywords"
          content="Mediation and Conciliation Rules, Mediation Alternative Dispute Resolution, Mediation Rules, Conciliation Rules, Online Conciliation, Online Mediation, JustAct"
        />
      </Helmet>
      <div>
        <Header />
        <H2> Mediation Rules</H2>
        <RuleContainer>
          <p>
            <span>
              <strong>1.</strong>{" "}
              <strong>Agreement to Mediate / Conciliate</strong>
            </span>
          </p>

          <TextLines style={{ padding: "20px" }}>
            <p>
              <span>
                This refers to an agreement for resolving disputes through
                mediation / conciliation and can be any of the following:
              </span>
            </p>

            <TextLines style={{ paddingLeft: "20px" }}>
              <p style={{ lineHeight: "1.4", marginBottom: "10px" }}>
                <span>
                  <strong>1.1.</strong> an existing contract that stipulates
                  that the parties will resort to mediation / conciliation under
                  the JustAct mediation / conciliation rules for resolution of
                  existing / future disputes&nbsp;
                </span>
              </p>

              <p style={{ lineHeight: "1.4" }}>
                <span>
                  <strong>1.2.</strong> A request by both parties that they
                  agree to mediate / conciliate for resolution of the dispute in
                  accordance with JustAct mediation / conciliation rules&nbsp;
                </span>
              </p>
            </TextLines>
          </TextLines>

          <p>
            <span>
              <strong>2.</strong>{" "}
              <strong>Initiating Mediation / Conciliation</strong>
            </span>
          </p>

          <TextLines style={{ padding: "20px" }}>
            <p style={{ lineHeight: "1.4", marginBottom: "10px" }}>
              <span>
                <strong>2.1.</strong> Any of the parties may initiate mediation
                / conciliation through JustAct (the case could also be escalated
                to mediation / conciliation if the parties do not reach an
                agreement during{" "}
                <HyperLink
                  onClick={() =>
                    window.open(`https://justact.co.in/negotiation-rules`)
                  }
                >
                  {" "}
                  negotiation
                </HyperLink>
                ).&nbsp;
              </span>
            </p>

            <p>
              <span>
                <strong>2.2.</strong> To initiate the process, the Party must
                submit the following:
              </span>
            </p>

            <TextLines style={{ paddingLeft: "20px" }}>
              <p style={{ marginBottom: "10px" }}>
                <span>
                  <strong>2.2.1.</strong> Agreement to mediate / conciliate as
                  detailed above in 1.1 and 1.2
                </span>
              </p>

              <p style={{ lineHeight: "1.4" }}>
                <span>
                  <strong>2.2.2.</strong> In the absence of an agreement, both
                  parties agreeing to{" "}
                  <HyperLink
                    onClick={() => window.open(`https://justact.co.in/`)}
                  >
                    {" "}
                    resolve disputes
                  </HyperLink>{" "}
                  through JustAct as&nbsp;
                </span>
              </p>
              <p style={{ lineHeight: "1.4", marginBottom: "10px" }}>
                <span>
                  part of the terms and conditions of registration on JustAct,
                  will be deemed to be an agreement to resolve disputes through
                  JustAct, in this case using mediation / conciliation, even
                  though they may sign up at different points of time.
                </span>
              </p>

              <p style={{ marginBottom: "10px" }}>
                <span>
                  <strong>2.2.3.</strong> A statement with brief details of the
                  dispute and the quantum of relief sought
                </span>
              </p>

              <p style={{ marginBottom: "10px" }}>
                <span>
                  <strong>2.2.4.</strong> Any proofs in support of the
                  submission &nbsp;
                </span>
              </p>

              <p style={{ lineHeight: "1.4" }}>
                <span>
                  <strong>2.2.5.</strong> Contact details of the other Party to
                  the dispute. JustAct will intimate the other Party of the
                  request to mediate / conciliate and initiate the process of
                  mediation / conciliation once the other Party agrees to
                  resolve the dispute through JustAct &nbsp;
                </span>
              </p>
            </TextLines>
          </TextLines>

          <p>
            <span>
              <strong>3.</strong> <strong>Representation</strong>
            </span>
          </p>

          <TextLines style={{ padding: "20px" }}>
            <p>
              <span>
                <strong>3.1.</strong> Subject to applicable law, parties can be
                represented by any persons they choose. &nbsp;
              </span>
            </p>

            <p>
              <span>
                <strong>3.2.</strong> The parties will provide representative
                details to JustAct and the other Party through the JustAct
                portal.
              </span>
            </p>
          </TextLines>

          <p>
            <span>
              <strong>4.</strong> <strong>Support for Resolution</strong>
            </span>
          </p>

          <TextLines style={{ paddingBottom: "20px", paddingTop: "20px" }}>
            <p style={{ lineHeight: "1.4" }}>
              <span>
                {" "}
                To handle resolution on an end-to-end basis, JustAct will assign
                a Case Manager for the dispute. The Case Manager will provide
                all support for resolution including queries, scheduling,
                reminders and coordination. &nbsp;
              </span>
            </p>
          </TextLines>

          <p>
            <span>
              <strong>5.</strong>{" "}
              <strong>Appointment of Mediator / Conciliator</strong>
            </span>
          </p>

          <TextLines style={{ padding: "20px" }}>
            <p style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>5.1.</strong> The Case Manager will assign a mediator /
                conciliator from its panel for resolving the dispute.
              </span>
            </p>

            <p style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>5.2.</strong> Prior to accepting to mediate /
                conciliate, the mediator / conciliator will make reasonable
                enquiries to establish if there is actual or potential conflict
                of interest and if so, disclose the same. &nbsp;
              </span>
            </p>

            <p style={{ lineHeight: "1.4" }}>
              <span>
                <strong>5.3.</strong> In case there is a likely or actual
                conflict so disclosed, JustAct will communicate the same to the
                parties who can choose to waive the same, in which case the
                mediator / conciliator can be appointed failing which a new
                mediator / conciliator will be appointed.
              </span>
            </p>
          </TextLines>
          <p>
            <span>
              <strong>6.</strong>{" "}
              <strong>Conduct of Mediation / Conciliation</strong>
            </span>
          </p>

          <TextLines style={{ padding: "20px" }}>
            <p style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>6.1.</strong> After the appointment of mediator /
                conciliator, the Case Manager will work with the parties and the
                mediator / conciliator to schedule the introductory meeting.
                &nbsp;
              </span>
            </p>

            <p style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>6.2.</strong> The parties and the mediator / conciliator
                may agree on a procedure to be followed in the conduct of the
                mediation / conciliation. Where no such procedure is agreed
                upon, the mediator / conciliator will conduct the sessions to
                the best of his judgement and ability with a view to facilitate
                a settlement of the dispute at hand.{" "}
              </span>
            </p>

            <p style={{ lineHeight: "1.4" }}>
              <span>
                <strong>6.3.</strong> The mediator / conciliator may take the
                help of the Case Manager for smooth and fair conduct of the
                proceedings.
              </span>
            </p>
          </TextLines>

          <p>
            <span>
              <strong>7.</strong>{" "}
              <strong>Role of Mediator / Conciliator</strong>
            </span>
          </p>

          <TextLines style={{ padding: "20px" }}>
            <p style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>7.1.</strong> The mediator / conciliator will assist a
                settlement of the dispute by facilitating a free and fair
                communication between parties and objectively identifying issues
                with a view to reduce misunderstandings. The mediator /
                conciliator will however not impose any solution or settlement
                on the parties.
              </span>
            </p>

            <p style={{ lineHeight: "1.4" }}>
              <span>
                <strong>7.2.</strong> The mediator / conciliator will engage
                with the parties separately as required and any information
                shared / views expressed by the Party will not be shared with
                the other Party without express consent of the Party sharing the
                information.
              </span>
            </p>
          </TextLines>

          <p>
            <span>
              <strong>8.</strong> <strong>Role of Parties</strong>
            </span>
          </p>
          <TextLines style={{ paddingBottom: "20px", paddingTop: "20px" }}>
            <p style={{ lineHeight: "1.4" }}>
              <span>
                {" "}
                The parties agree to attend all sessions with the objective of
                reaching a meaningful settlement to the dispute and with
                adequate effort and preparation in this regard.&nbsp;
              </span>
            </p>
          </TextLines>

          <p>
            <span>
              <strong>9.</strong> <strong>Role of JustAct</strong>
            </span>
          </p>

          <TextLines style={{ padding: "20px" }}>
            <p>
              <span>JustAct will be responsible for&nbsp;</span>
            </p>

            <TextLines style={{ paddingLeft: "20px" }}>
              <p>
                <span>
                  <strong>9.1.</strong> appointing the mediator / conciliator
                  for the case
                </span>
              </p>

              <p style={{ marginBottom: "10px" }}>
                <span>
                  <strong>9.2.</strong> organising for the conduct of the
                  mediation / conciliation sessions
                </span>
              </p>

              <p style={{ lineHeight: "1.4", marginBottom: "10px" }}>
                <span>
                  <strong>9.3.</strong> providing the technology and other
                  infrastructure as may be required for conduct of online and/or
                  offline mediation / conciliation. &nbsp;
                </span>
              </p>

              <p style={{ lineHeight: "1.4", marginBottom: "10px" }}>
                <span>
                  <strong>9.4.</strong> provide general support through the case
                  manager and secretarial staff for a smooth and quick
                  resolution
                </span>
              </p>

              <p>
                <span>
                  <strong>9.5.</strong> creating the necessary documentation for
                  the settlement, and performing the actual settlement, if the
                  parties opt for the service, to consummate the agreement
                  &nbsp;
                </span>
              </p>
            </TextLines>
          </TextLines>

          <p>
            <span>
              <strong>10.</strong>{" "}
              <strong>Fees for mediation / conciliation</strong>
            </span>
          </p>

          <TextLines style={{ paddingBottom: "20px", paddingTop: "20px" }}>
            <p style={{ lineHeight: "1.4" }}>
              <span>
                {" "}
                Fees will be as per the Fee Schedule specified by JustAct as may
                be amended from time to time.
              </span>
            </p>
          </TextLines>

          <p>
            <span>
              <strong>11.</strong> <strong>Settlement Agreement</strong>
            </span>
          </p>

          <TextLines style={{ paddingBottom: "20px", paddingTop: "20px" }}>
            <p style={{ lineHeight: "1.4" }}>
              <span>
                The mediator / conciliator will help the disputing parties reach
                a settlement to the dispute. When the mediator / conciliator
                finds possible elements of settlement, he may draft a settlement
                agreement, seek comments of the parties and reformulate the
                agreement basis the comments. Once the same is agreed upon, the
                settlement agreement is generated and signed off by the parties
                and authenticated by the mediator / conciliator.
              </span>
            </p>
          </TextLines>
          <p>
            <span>
              <strong>12.</strong>{" "}
              <strong>Termination of Mediation / Conciliation</strong>
            </span>
          </p>

          <TextLines style={{ padding: "20px" }}>
            <p>
              <span>
                <strong>12.1.</strong> The proceedings will terminate in any of
                the following cases:
              </span>
            </p>

            <TextLines style={{ padding: "10px" }}>
              <p style={{ lineHeight: "1.4", marginBottom: "10px" }}>
                <span>
                  <strong>12.1.1.</strong> a settlement agreement is reached
                  between the two parties on some or all the issues under
                  dispute&nbsp;
                </span>
              </p>

              <p style={{ lineHeight: "1.4", marginBottom: "10px" }}>
                <span>
                  <strong>12.1.2.</strong> the mediator / conciliator concluding
                  that it would be futile to continue to mediate / conciliate as
                  resolution is not possible. (The parties are free to escalate
                  the case to{" "}
                  <HyperLink
                    onClick={() =>
                      window.open(`https://justact.co.in/arbitration-rules`)
                    }
                  >
                    {" "}
                    arbitration
                  </HyperLink>{" "}
                  through JustAct on an immediate basis.
                </span>
              </p>

              <p>
                <span>
                  <strong>12.1.3.</strong> any of the parties withdrawing from
                  the mediation / conciliation proceedings
                </span>
              </p>

              <p>
                <span>
                  <strong>12.1.4.</strong> If a Party fails to attend 3 sessions
                  without any prior intimation and reason&nbsp;
                </span>
              </p>
            </TextLines>

            <p style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>12.2.</strong> The mediator / conciliator will keep the
                case manager informed in writing of termination of proceedings
                within 48 hours mentioning, inter alia, the date of termination,
                whether settlement was reached or not and if reached whether it
                was full or partial. Such an intimation will be copied to both
                parties as well.
              </span>
            </p>

            <p style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>12.3.</strong> JustAct will keep such intimation
                confidential and not share the existence of the same or its
                contents unless expressly agreed upon by both the parties.
                However, such information may be used to process any statistics
                or aggregate date so long as the identity of the disputants is
                not revealed.
              </span>
            </p>

            <p style={{ lineHeight: "1.4" }}>
              <span>
                <strong>12.4.</strong> If the mediation / conciliation is
                terminated, the case manager may suggest escalation to
                arbitration and if both parties agree, transfer the case for
                arbitration.
              </span>
            </p>
          </TextLines>
          <p>
            <span>
              <strong>13.</strong> <strong>Confidentiality&nbsp;</strong>
            </span>
          </p>

          <TextLines style={{ padding: "20px" }}>
            <p style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>13.1.</strong> Confidential information disclosed during
                the mediation / conciliation shall not be divulged by any of the
                parties and their agents / the mediator(s) / conciliator(s) and
                {" "}<b>JustAct and its employees, all of whom</b> shall maintain
                confidentiality of all information obtained and records,
                reports, or other documents received / shared. &nbsp;
              </span>
            </p>

            <p style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>13.2.</strong> All attendees shall maintain the
                confidentiality of the mediation / conciliation.&nbsp;
              </span>
            </p>

            <p style={{ lineHeight: "1.4" }}>
              <span>
                <strong>13.3.</strong> The parties, mediators / conciliators and
                JustAct shall not rely on or introduce as evidence in arbitral,
                judicial or any other proceeding, the following, whether such
                proceedings relate to the dispute that is the subject of the
                mediation / conciliation:
              </span>
            </p>

            <TextLines style={{ paddingTop: "20px", paddingLeft: "20px" }}>
              <p style={{ lineHeight: "1.4", marginBottom: "15px" }}>
                <span>
                  <strong>13.3.1.</strong> Views expressed or suggestions made
                  by any attendee, including the parties and the mediator /
                  conciliator, in respect of a possible settlement of
                  dispute.&nbsp;
                </span>
              </p>

              <p style={{ lineHeight: "1.4", marginBottom: "15px" }}>
                <span>
                  <strong>13.3.2.</strong> Admission made by a Party/its
                  representative in the mediation/ conciliation.&nbsp;
                </span>
              </p>

              <p style={{ lineHeight: "1.4", marginBottom: "15px" }}>
                <span>
                  <strong>13.3.3.</strong> Proposals made or views expressed by
                  the mediator / conciliator.
                </span>
              </p>

              <p style={{ lineHeight: "1.4" }}>
                <span>
                  <strong>13.3.4.</strong> Whether or not a Party had indicated
                  willingness to accept a proposal for settlement made during
                  the mediation / conciliation.
                </span>
              </p>
            </TextLines>
          </TextLines>
          <p>
            <span>
              <strong>14.</strong> <strong>Exclusion of liability&nbsp;</strong>
            </span>
          </p>

          <TextLines style={{ padding: "20px" }}>
            <p style={{ lineHeight: "1.4" }}>
              <span>
                <strong>14.1.</strong> Neither JustAct, its employees or any of
                the mediators / conciliators shall be liable to any of the&nbsp;
              </span>
            </p>
            <p style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                parties due to any error / act of omission during the conduct of
                the mediation / conciliation.&nbsp;
              </span>
            </p>

            <p style={{ lineHeight: "1.4" }}>
              <span>
                <strong>14.2.</strong> Unless required by law, JustAct or its
                agents / employees shall not be required to testify in any
                proceedings – judicial or otherwise – with respect to conduct of
                the mediation / conciliation under these rules
              </span>
            </p>
          </TextLines>
          <p>
            <span>
              <strong>15.</strong>{" "}
              <strong>Amendments to JustAct Mediation Rules</strong>
            </span>
          </p>

          <TextLines style={{ padding: "20px" }}>
            <p style={{ lineHeight: "1.4", marginBottom: "15px" }}>
              <span>
                <strong>15.1.</strong> JustAct reserves the right to amend these
                rules from time to time. However, any case once registered will
                continue to be processed for Mediation/ Conciliation under the
                rules under which it was registered.&nbsp;
              </span>
            </p>

            <p style={{ lineHeight: "1.4", marginBottom: "9px" }}>
              <span>
                <strong>15.2.</strong> The date, for this purpose will be
                reckoned to be the date when filing of the claim was done.&nbsp;
              </span>
            </p>

            <p>
              <span>
                <strong>15.3.</strong> In case of any disputes, the decision of
                JustAct will be binding in this regard.
              </span>
            </p>
          </TextLines>
        </RuleContainer>
        <Footer />
      </div>
    </>
  );
}

export default MediationRules;
