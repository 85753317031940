import React, { useEffect } from "react";
import { Content, SubHeadWrap, BlogPage } from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

/* eslint-disable jsx-a11y/anchor-is-valid */

function OnlineDisputeEighteen(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header>
        <BlogPage>
          <HeadWrap>Role of Arbitration in Criminal Cases</HeadWrap>
          <br />
          <br />
          <Content>
            <SubHeadWrap style={{ marginBottom: "10px" }}>Part I</SubHeadWrap>
            <SubHeadWrap style={{ marginBottom: "10px" }}>
              Introduction
            </SubHeadWrap>
            <p>
              Arbitral tribunals generally possess the jurisdiction to
              adjudicate upon civil and commercial disputes subject to the
              dispute being covered under the arbitration agreement, the parties
              choosing to refer the dispute to arbitration and the disputes,
              under the given law, being amenable to arbitration and settlement.
            </p>
            <br />
            <p>
              However, it is acknowledged that not all cases are arbitrable (see
              previous blog post). The{" "}
              <span style={{ fontStyle: "italic" }}>
                Booz Allen<sup>1</sup>
              </span>{" "}
              case underlines the nature of cases that are non-arbitrable, which
              include disputes relating to rights and liabilities which give
              rise to or arise out of criminal offences, matrimonial disputes
              relating to divorce, judicial separation, restitution of conjugal
              rights and child custody, matters of guardianship, insolvency and
              winding up, testamentary matters, such as the grant of probate,
              letters of administration and succession certificates, eviction or
              tenancy matters governed by special statutes where a tenant enjoys
              special protection against eviction. Specific courts are conferred
              with the exclusive jurisdiction to deal with the disputes relating
              to trust, trustees and beneficiaries arising out of a trust deed.
              The role of arbitration in regard to criminal matters has been
              outlined in certain judgments as discussed below.
            </p>
            <br />
            <SubHeadWrap>Arbitration in Criminal Matters</SubHeadWrap>
            <br />
            <p>
              Criminal offences are believed to be non-arbitrable because they
              concern rights in <span style={{ fontStyle: "italic" }}>rem</span>
              , i.e., rights exercisable against the world, which cannot form
              the subject matter of arbitration. Actions in{" "}
              <span style={{ fontStyle: "italic" }}>rem</span> refer to actions
              which create a legal status and are not arbitrable by a private
              tribunal constituted by the consent of parties. Actions in
              rem decide the rights of the parties as exercisable against the
              world at large which has an{" "}
              <span style={{ fontStyle: "italic" }}>erga omnes</span> effect,
              i.e., rights and obligations owed toward all. Another reason that
              arbitration in criminal cases is untenable flows from the fact
              that it is the sovereign function of the state to prosecute
              criminal offences which cannot become the subject matter of
              adjudication in a private forum constituted by the parties
              concerned.
            </p>
            <br />
            <SubHeadWrap>
              Fraud as a Recurring Category and Example of Criminal Offence
              considered under Arbitration
            </SubHeadWrap>
            <br />
            <p>
              Judicial pronouncements on fraud as an arbitrable subject over a
              period of time have seen inconsistent albeit sometimes nuanced
              positions.
            </p>
            <br />
            <p>
              The Supreme Court of India in{" "}
              <span style={{ fontStyle: "italic" }}>
                N. Radhakrishnan v. Maestro Engineers<sup>2</sup>
              </span>{" "}
              , while dealing with the powers of a civil court under Section 8
              of the Arbitration &amp; Conciliation Act, 1996, relying on the
              judgement of the Supreme Court in{" "}
              <span style={{ fontStyle: "italic" }}>
                Abdul Kadir <sup>3</sup>
              </span>{" "}
              , held that, where serious allegations that border on the criminal
              offence of fraud are made against a party, the matter should not
              be referred to arbitration, and it should be decided by a civil
              court since these have public consequences.
            </p>
            <br />
            <p>
              In{" "}
              <span style={{ fontStyle: "italic" }}>
                A. Ayyasamy v. A. Paramasivam <sup>4</sup>{" "}
              </span>
              , which stood partly overruled and partly affirmed in{" "}
              <span style={{ fontStyle: "italic" }}>
                Vidya Drolia <sup>5</sup>
              </span>{" "}
              , the Court held that fraud constitutes a subject-matter which
              falls exclusively
            </p>
            <br />
            <p>
              within the domain of public fora and would thus be non-arbitrable.
              The Court considered whether the mere allegation of fraud by one
              party against another would be sufficient to exclude the dispute
              from being arbitrated upon.
            </p>
            <br />
            <p>
              In the <span style={{ fontStyle: "italic" }}>Ayyasamy</span> case,
              it was held that even if the fraud concerned was a serious
              offence, complex in nature, with the determination demanding
              extensive evidence, serious allegations of forgery/fabrication of
              documents in support of the plea of fraud, where fraud is alleged
              against the arbitration provision itself, and the fraud alleged
              permeates the entire contract or the fraud goes to the validity of
              the contract, arbitration clause or the contract that contains the
              arbitration clause, the Arbitral Tribunal could still have
              jurisdiction over the matter. It also plotted the distinctions
              between serious fraud and fraud simpliciter cases and found that,
              in most cases, the civil court handled the former. Where the
              allegations of fraud were not of a serious nature, only touching
              upon the internal affairs of the parties{" "}
              <span style={{ fontStyle: "italic" }}>inter se</span> without any
              implication in the public domain, the dispute could be referred to
              arbitration.
            </p>
            <br />
            <p>
              In <span style={{ fontStyle: "italic" }}>Vidya Drolia</span>, the
              Court held that sovereign functions of the state being inalienable
              and non delegable are non-arbitrable as the state alone has the
              exclusive right and duty to perform such functions. The Court also
              emphasized that the correctness or validity of state or sovereign
              functions cannot be made a direct subject matter of a private
              adjudicatory process.
            </p>
            <br />
            <Block />
            <br />
            <p>
              <sup>1</sup>Booz Allen &amp; Hamilton Inc v. SBI Home Finance Ltd,
              (2011) 5 SCC 532.
            </p>
            <p>
              <sup>2</sup>N. Radhakrishnan v. Maestro Engineers, (2010) 1 SCC
              72.
            </p>
            <p>
              <sup>3</sup>Abdul Kadir Shamsuddin Bubere v. Madhav Prabhakar Oak,
              (1962) 3 SCR 702.
            </p>
            <p>
              <sup>4</sup>A. Ayyasamy v. A. Paramasivam, (2016) 10 SCC 386.
            </p>
            <p>
              <sup>5</sup>Vidya Drolia v. Durga Trading Corporation, (2021) 2
              SCC 1.
            </p>
          </Content>
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default OnlineDisputeEighteen;

export const ContentExample = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
`;

export const Block = styled.div`
  width: 183px;
  height: 0;
  border: 1px solid ${COLORS.INPUT_BLACK};
  margin: 3px;
  display: inline-block;
  margin-bottom: 10px;
  font-family: ${theme.fonts.primaryFontRegular};
`;
export const HeadWrap = styled.div`
  text-align: center;
  font-size: 18px;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontBold};
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 25px;
  }
`;

export const SubHeadWrapHeading = styled.div`
  text-align: left;
  font-size: 20px;
  padding-left: 1.5%;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontRegular};
`;
