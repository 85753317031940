import React, { useEffect } from "react";
import {
  RuleContainer,
  ModelClausePage,
  HeadWrap,
  TextLinesData,
  TextLinesDataNeg,
  TextLinesDataMed,
  TextLinesDataArb,
  TextLinesDataSeq
} from "../Rules/Styles";
import { Helmet } from "react-helmet";
import { HyperLink } from "../../common/FormInputs";

function ModelClauseRules(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>
          Model Clauses - Dispute Resolution, Negotiation, Mediation &
          Arbitration Clauses
        </title>
        <meta
          name="description"
          content="JustAct Model Clause has been designed to support the resolution of disputes in a time and cost-effective manner."
        />
        <meta
          name="keywords"
          content="Model Dispute Resolution Clause, Dispute Resolution Online, Dispute Resolution Clause, Negotiation Clause, Mediation Clause, Arbitration Clauses, Alternative Dispute Resolution Clauses, Resolve Disputes Online, JustAct"
        />
      </Helmet>
      <div>
        <ModelClausePage>
          <HeadWrap> MODEL CLAUSE(S)</HeadWrap>
        </ModelClausePage>
        <RuleContainer>
          <p>
            <span>
              <strong>1.</strong>{" "}
              <strong>JustAct Model Dispute Resolution Clause</strong>
            </span>
          </p>
          <br />
          <TextLinesData>
            <ul class="first-li-before" style={{ marginBottom: 10 }}>
              <li>
                <p>
                  <span>
                    The parties shall resolve any dispute arising out of or in
                    connection with anything contained in the Agreement or
                    otherwise by negotiation, mediation or arbitration through
                    JustAct [www.justact.co.in] or any other ADR institution
                    (“Institution”) as agreed mutually by the parties.&nbsp;
                  </span>
                </p>
              </li>
            </ul>

            <ul class="second-li-before" style={{ marginBottom: 10 }}>
              <li>
                <p>
                  <span>
                    If the parties refer the dispute for mediation, the
                    Institution shall, among other things, appoint a neutral
                    Mediator/Conciliator and provide a platform for settlement
                    of dispute between the parties as per the{" "}
                    <HyperLink
                      onClick={() =>
                        window.open(`https://justact.co.in/mediation-rules`)
                      }
                    >
                      mediation Rules
                    </HyperLink>{" "}
                    of the Institution. The mediation may be conducted
                    online.&nbsp;
                  </span>
                </p>
              </li>
            </ul>

            <ul class="third-li-before" style={{ marginBottom: 10 }}>
              <li>
                <p>
                  <span>
                    If the parties refer the dispute for arbitration, the
                    Institution shall, among other things, appoint neutrals
                    (Mediator(s) and/or Arbitrator(s)) and the arbitral
                    proceedings may be conducted as per{" "}
                    <HyperLink
                      onClick={() =>
                        window.open(`https://justact.co.in/arbitration-rules`)
                      }
                    >
                      {" "}
                      Arbitration Rules
                    </HyperLink>{" "}
                    of the Institution or rules agreed between the
                    parties.&nbsp;
                  </span>
                </p>
              </li>
            </ul>

            <ul class="four-li-before" style={{ marginBottom: 10 }}>
              <li>
                <p>
                  <span>
                    The arbitration shall be in accordance with the Arbitration
                    and Conciliation Act, 1996 or any statutory modification or
                    re-enactment thereof. The seat and venue of the arbitration
                    shall be Chennai, India and language of the proceeding shall
                    be English. The award of the Arbitrator shall be final and
                    binding on the Parties. The arbitration may be conducted
                    online.&nbsp;
                  </span>
                </p>
              </li>
            </ul>

            <ul class="five-li-before">
              <li>
                <p>
                  <span>
                    Should this clause create a contradiction with a statutory
                    requirement or a judicial mandate to resolve certain types
                    of disputes through specific courts, the statutory
                    requirement shall prevail.&nbsp;
                  </span>
                </p>
              </li>
            </ul>
          </TextLinesData>
          <br />

          <p>
            <span>
              <strong>2.</strong>{" "}
              <strong>
                JustAct Model Dispute Resolution Clause (Sequential Escalation)
              </strong>
            </span>
          </p>
          <br />

          <TextLinesDataSeq>
            <ul class="first-li-before" style={{ marginBottom: 10 }}>
              <li>
                <p>
                  <span>
                    The parties shall endeavour to resolve any dispute arising
                    out of or in connection with anything contained in the
                    Agreement or otherwise by negotiation through JustAct,
                    [www.justact.co.in] within 30 (thirty days) of its
                    occurrence or such extended time as may be mutually agreed
                    by the parties. <br /> The parties may choose any other ADR
                    institution provided such institution is as agreed mutually
                    by the parties.&nbsp;
                  </span>
                </p>
              </li>
            </ul>

            <ul class="second-li-before" style={{ marginBottom: 10 }}>
              <li>
                <p>
                  <span>
                    If the dispute has not been resolved through negotiation as
                    provided herein, the parties shall endeavour to refer the
                    dispute/disputes for mediation through JustAct
                    [www.justact.co.in] which shall, among other things, include
                    appointment of Mediator/Conciliator and endeavour for
                    settlement of dispute between the parties as per the
                    mediation Rules of JustAct. The mediation may be conducted
                    online.&nbsp;
                  </span>
                </p>
              </li>
            </ul>

            <ul class="third-li-before" style={{ marginBottom: 10 }}>
              <li>
                <p>
                  <span>
                    In the event, such dispute has not been resolved either
                    through negotiation or mediation, as provided, the parties
                    shall refer the dispute for arbitration by appointing
                    Arbitrator/ Arbitrators through JustAct [www.justact.co.in]
                    as per JustAct Arbitration Rules (JAR) or rules agreeable to
                    parties. The arbitration shall be in accordance with the
                    Arbitration and Conciliation Act, 1996 or any statutory
                    modification or re-enactment thereof. The seat of
                    arbitration shall be Chennai, India and language of the
                    proceeding shall be English. The award of the Arbitrator
                    shall be final and binding on the Parties. The arbitration
                    may be conducted online.&nbsp;
                  </span>
                </p>
              </li>
            </ul>

            <ul class="four-li-before" style={{ marginBottom: 10 }}>
              <li>
                <p>
                  <span>
                    The Institution shall, among other things, appoint neutrals
                    (Mediator(s) and/or Arbitrator) and provide a platform for
                    settlement of dispute between the parties as per the
                    mediation Rules of the Institution.&nbsp;
                  </span>
                </p>
              </li>
            </ul>

            <ul class="five-li-before">
              <li>
                <p>
                  <span>
                    Should this clause create a contradiction with a statutory
                    requirement or a judicial mandate to resolve certain types
                    of disputes through specific courts, the statutory
                    requirement shall prevail.&nbsp;
                  </span>
                </p>
              </li>
            </ul>
          </TextLinesDataSeq>
          <br />

          <p>
            <span>
              <strong>3.</strong>{" "}
              <strong>JustAct Model Negotiation Clause</strong>
            </span>
          </p>
          <br />

          <TextLinesDataNeg>
            <ul class="first-li-before" style={{ marginBottom: 10 }}>
              <li>
                <p>
                  <span>
                    The parties shall endeavour to resolve any dispute arising
                    out of or in connection with anything contained in the
                    Agreement or otherwise by{" "}
                    <HyperLink
                      onClick={() =>
                        window.open(
                          `https://justact.co.in/negotiation-model-clause`
                        )
                      }
                    >
                      negotiation
                    </HyperLink>{" "}
                    through JustAct, [www.justact.co.in] within 30 (thirty days)
                    of its occurrence or such extended time as may be mutually
                    agreed by the parties.&nbsp;
                  </span>
                </p>
              </li>
            </ul>

            <ul class="second-li-before" style={{ marginBottom: 10 }}>
              <li>
                <p>
                  <span>
                    The Institution shall, among other things, appoint a neutral
                    Mediator/Conciliator and provide a platform for settlement
                    of dispute between the parties as per the negotiation Rules
                    of the Institution. The settlement agreement resulting from
                    the negotiation shall be provided in the form of a
                    Conciliation Award as per section 74 of the Arbitration and
                    Conciliation Act, 1996.&nbsp;
                  </span>
                </p>
              </li>
            </ul>

            <ul class="third-li-before">
              <li>
                <p>
                  <span>
                    Should this clause create a contradiction with a statutory
                    requirement or a judicial mandate to resolve certain types
                    of disputes through specific courts, the statutory
                    requirement shall prevail.&nbsp;
                  </span>
                </p>
              </li>
            </ul>
          </TextLinesDataNeg>
          <br />

          <p>
            <span>
              <strong>4.</strong>{" "}
              <strong>JustAct Model Mediation Clause</strong>
            </span>
          </p>
          <br />

          <TextLinesDataMed>
            <ul class="first-li-before" style={{ marginBottom: 10 }}>
              <li>
                <p>
                  <span>
                    In the event of any dispute arising out of or in connection
                    with anything contained in the Agreement or otherwise, such
                    dispute shall be referred to by Parties, for{" "}
                    <HyperLink
                      onClick={() =>
                        window.open(
                          `https://justact.co.in/mediation-model-clause`
                        )
                      }
                    >
                      Mediation
                    </HyperLink>{" "}
                    through JustAct [www.justact.co.in] (“Institution”).&nbsp;
                  </span>
                </p>
              </li>
            </ul>

            <ul class="second-li-before" style={{ marginBottom: 10 }}>
              <li>
                <p>
                  <span>
                    The parties may use or any other ADR institution provided
                    such institution is as agreed mutually by the parties.&nbsp;
                  </span>
                </p>
              </li>
            </ul>

            <ul class="third-li-before" style={{ marginBottom: 10 }}>
              <li>
                <p>
                  <span>
                    The Institution shall, among other things, appoint a neutral
                    Mediator/Conciliator and provide a platform for settlement
                    of dispute between the parties as per the mediation Rules of
                    the Institution.&nbsp;
                  </span>
                </p>
              </li>
            </ul>

            <ul class="four-li-before">
              <li>
                <p>
                  <span>
                    Should this clause create a contradiction with a statutory
                    requirement or a judicial mandate to resolve certain types
                    of disputes through specific courts, the statutory
                    requirement shall prevail.&nbsp;
                  </span>
                </p>
              </li>
            </ul>
          </TextLinesDataMed>
          <br />

          <p>
            <span>
              <strong>5.</strong>{" "}
              <strong>JustAct Model Arbitration Clause</strong>
            </span>
          </p>
          <br />

          <TextLinesDataArb>
            <ul class="first-li-before" style={{ marginBottom: 10 }}>
              <li>
                <p>
                  <span>
                    In the event of any dispute arising out of or in connection
                    with anything contained in the Agreement [or otherwise], the
                    parties shall refer the dispute for{" "}
                    <HyperLink
                      onClick={() =>
                        window.open(
                          `https://justact.co.in/arbitration-model-clause`
                        )
                      }
                    >
                      {" "}
                      arbitration
                    </HyperLink>{" "}
                    through JustAct [www.justact.co.in] (“Institution”).&nbsp;
                  </span>
                </p>
              </li>
            </ul>

            <ul class="second-li-before" style={{ marginBottom: 10 }}>
              <li>
                <p>
                  <span>
                    The parties may use any other ADR institution provided such
                    institution is agreed mutually by the parties.&nbsp;
                  </span>
                </p>
              </li>
            </ul>

            <ul class="third-li-before" style={{ marginBottom: 10 }}>
              <li>
                <p>
                  <span>
                    The arbitration shall be in accordance with the Arbitration
                    and Conciliation Act, 1996 or any statutory modification or
                    re-enactment thereof. The seat of arbitration shall be
                    Chennai, India and language of the proceeding shall be
                    English. The award of the Arbitrator shall be final and
                    binding on the Parties. The arbitration may be conducted
                    online.&nbsp;
                  </span>
                </p>
              </li>
            </ul>

            <ul class="four-li-before" style={{ marginBottom: 10 }}>
              <li>
                <p>
                  <span>
                    The Institution shall, among other things, appoint neutral
                    Arbitrator(s) and provide a platform for resolution of the
                    dispute between the parties as per the arbitration Rules of
                    the Institution.&nbsp;
                  </span>
                </p>
              </li>
            </ul>

            <ul class="five-li-before">
              <li>
                <p>
                  <span>
                    Should this clause create a contradiction with a statutory
                    requirement or a judicial mandate to resolve certain types
                    of disputes through specific courts, the statutory
                    requirement shall prevail.&nbsp;
                  </span>
                </p>
              </li>
            </ul>
          </TextLinesDataArb>
        </RuleContainer>
        {/* <Footer/> */}
      </div>
    </>
  );
}

export default ModelClauseRules;
