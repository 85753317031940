import React from "react";
import {
  H2,
  P,
  Section,
  ProductVideos,
  ProductVideoWrapper,
} from "./Styles";
import labels from "../../../helpers/labels.json";
import { HyperLink } from "../../common/FormInputs";

const HowItWorks = () => {
  return (
    <>
      <Section id={`howItWorks`}>
        <H2>{labels["landing.how_title"]}</H2>
        <P
          style={{ textAlign: "justify" }}
          // dangerouslySetInnerHTML={{ __html: labels["landing.how_content"] }}
        >
          JustAct offers{" "}
          <HyperLink
            onClick={() =>
              window.open(`https://justact.co.in/negotiation-rules`)
            }
          >
            Negotiation
          </HyperLink>{" "}
          as a technology assisted mediation which uses a Game Theory based
          algorithm to throw up a settlement number and will use AI to improve
          over time.
          <br />
          <br />
          On the other hand, for{" "}
          <HyperLink
            onClick={() => window.open(`https://justact.co.in/mediation-rules`)}
          >
            Mediation
          </HyperLink>{" "}
          and{" "}
          <HyperLink
            onClick={() =>
              window.open(`https://justact.co.in/arbitration-rules`)
            }
          >
            Arbitration
          </HyperLink>
          , it adopts the aggregator model by sourcing cases online and having
          tie ups with reputed arbitration / mediation institutes and
          independent professionals to resolve disputes
        </P>
        <ProductVideos>
          <ProductVideoWrapper>
            <iframe
              title="Product Video – English"
              height="286px"
              width="512px"
              style={{
                overflow: "hidden",
                borderRadius: 8,
                border: "solid 0.3px #979797"
              }}
              src="https://www.youtube.com/embed/Ra5eZIe5_80?rel=0&showinfo=0&start=0"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
            {/* <ProductVideoText>Product Video – English</ProductVideoText> */}
          </ProductVideoWrapper>
          <ProductVideoWrapper>
            <iframe
              title="Product Video – Hindi"
              height="286px"
              width="512px"
              style={{
                overflow: "hidden",
                borderRadius: 8,
                border: "solid 0.3px #979797"
              }}
              src="https://www.youtube.com/embed/dsYc3_w0ln8?rel=0&showinfo=0&start=0"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
            {/* <ProductVideoText>Product Video – Hindi</ProductVideoText> */}
          </ProductVideoWrapper>
        </ProductVideos>
      </Section>
    </>
  );
};
export default HowItWorks;
