import React, { useEffect } from "react";
import "./App.css";
import "./styles/main.scss";
import { BodySection, Layout } from "./styles/component/style";
import AppRouter from "./AppRoutes";
import Providers from "./providers/Providers";
import Loader from "./components/common/Loader/Loader";
import AlertWrapper from "./components/common/Alert/AlertWrapper";
import ReactGA from "react-ga";
import { globalHistory } from "@reach/router";
import FreshChat from "./components/common/FreshChat";
import SessionLogout from "./components/common/SessionLogout";

ReactGA.initialize("UA-214190369-1");
function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    globalHistory.listen(({ location }) => {
      ReactGA.pageview(window.location.pathname + window.location.search);
      // or use the new gtag API
    });
  }, []);

  return (
    <Providers>
      <Layout>
        <BodySection>
          <Loader />
          <AlertWrapper />
          <AppRouter />
        </BodySection>
        <FreshChat />
        <SessionLogout />
      </Layout>
    </Providers>
  );
}

export default App;
