import React, { useEffect } from "react";
import { Content, HeadWrap, SubHeadWrap, TrainingPage } from "./Styles";
import { Helmet } from "react-helmet";
import { HyperLink } from "../../common/FormInputs";

function ShortTraining(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>
          Alternative Dispute Resolution Corporate Training Program - JustAct
        </title>
        <meta
          name="description"
          content="JustAct Corporate Short Training Program aims to provide corporate managers an exposure to the emerging world of mediation to analyze its efficacy & applicability in their corporate disputes."
        />
        <meta
          name="keywords"
          content="Corporate Dispute Resolution Training, ADR Corporate Training Program, Corporate Short Training Programme, Alternative Dispute Resolution Training, Arbitration Mediation and Conciliation, JustAct"
        />
      </Helmet>
      <TrainingPage>
        <HeadWrap>Corporate Short Programme</HeadWrap>
        <br />
        {/* <SubHeadWrap>Welcome to The World of Mediation</SubHeadWrap> */}
        <Content>
          This document is meant to provide with the course objectives and the
          learning objectives of this training program.
        </Content>

        <SubHeadWrap>Course Objectives</SubHeadWrap>
        <Content>
          <p>
            Litigation is a costly and energy consuming process. Not to mention
            the copious amount of management time used up in it. Alternative{" "}
            <HyperLink onClick={() => window.open(`https://justact.co.in/`)}>
              Dispute Resolution
            </HyperLink>{" "}
            is a non-adversarial form of dispute resolution that addresses these
            issues and has proven to deliver substantially better outcomes for
            all involved. This training program aims to provide corporate
            managers (legal and otherwise) an exposure to the emerging world of
            mediation so that they may analyse its efficacy and applicability in
            their corporate disputes.
          </p>
        </Content>
        <SubHeadWrap>Learning Objectives</SubHeadWrap>
        <Content>
          <ol style={{ paddingLeft: "20px" }}>
            <li>Introduction to ADR.</li>
            <li>What is mediation, its objective and its benefits?</li>
            <li>Mediation vs Litigation - comparative analysis.</li>
            <li>Nature of disputes & personalities in a dispute.</li>
            <li>Structured Process of Mediation.</li>
            <li>Principles of Negotiation.</li>
            <li>Mediation settlement agreement. </li>
            <li>JustAct - Introduction to the platform.</li>
          </ol>
        </Content>
        <SubHeadWrap>Duration</SubHeadWrap>
        <Content>
          <ul style={{ paddingLeft: "20px" }}>
            <li>10 hours of video lectures.</li>
            <li>8 hours of classroom session with one role play.</li>
            <li>Self learning assignments.</li>
          </ul>
        </Content>

        <SubHeadWrap>Contact</SubHeadWrap>
        <Content>
          <p>For scheduling a program and pricing enquiries, please contact:</p>
          <ul
            style={{
              paddingLeft: "40px",
              paddingBottom: "10px",
              paddingTop: "10px",
            }}
          >
            {/* <li>
              <b>Madhav Pudipeddi</b>
            </li> */}
            <div style={{ marginBottom: "10px" }}>
              <b>Email :</b>{" "}
              <a href="mailto:support@justact.co.in">support@justact.co.in</a>
            </div>
            <div>
              {" "}
              <b>Mobile : </b>+91 95009 09888 | 87545 76111 | 73959 13222
            </div>
          </ul>
        </Content>
        {/* <SubHeadWrap>Brochure</SubHeadWrap>
        <Content>
          <a
            href={require("../../../assets/trainingBrochure_20.pdf")}
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here to Open
          </a>
        </Content> */}
      </TrainingPage>
    </>
  );
}

export default ShortTraining;
