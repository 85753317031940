import API from "../api/manager";
import config from "../api/config";

const getNoticeTemplates = (partyId) => {
  return API({
    method: "GET",
    url: config.urls.notice.getNoticeTemplates + `/${partyId}`,
  });
};

const bulkNoticeGenerate = (data) => {
  return API({
    method: "POST",
    url: config.urls.notice.bulkNoticeGenerate,
    data,
  });
};

const uploadNoticeTemplates = (data) => {
  return API({
    method: "POST",
    url: config.urls.notice.uploadNoticeTemplates,
    data,
  });
};

export default {
  getNoticeTemplates,
  bulkNoticeGenerate,
  uploadNoticeTemplates,
};
