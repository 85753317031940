import React, { useEffect } from "react";
import useFreshChat from "../../../hooks/useFreshChat";
import useUser from "../../../hooks/useUser";
import { isEmpty } from "lodash";
import { globalHistory } from "@reach/router";
import { isMobile } from "react-device-detect";

const FreshChat = () => {
  const { userObject } = useUser();
  const { setTag, handlePopup } = useFreshChat({ userObject }, true);

  useEffect(() => {
    initializeFreshChat(
      window.location.href,
      userObject,
      window?.location?.pathname
    );
    return globalHistory.listen(({ action, ...props }) => {
      if (action === "PUSH") {
        initializeFreshChat(
          props?.location?.href,
          userObject,
          props?.location?.pathname
        );
      }
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userObject]);

  const initializeFreshChat = (location, userObject, pathname) => {
    setTimeout(() => {
      if (location && !location.includes("/dashboard/cases/")) {
        // condition to exclude the case details page
        if (location.includes("parties")) {
          setTag(["my_party"]);
        } else if (location.includes("payments")) {
          setTag(["payment_history"]);
        } else if (location.includes("cases?caseType=arbitration")) {
          setTag(["my_cases_arb"]);
        } else if (location.includes("cases?caseType=mediation")) {
          setTag(["my_cases_med"]);
        } else if (location.includes("dashboard/cases/")) {
          setTag(["ind_med_case"]);
        } else if (location.includes("dashboard/cases")) {
          setTag(["my_cases_neg"]);
        } else if (location.includes("cases?caseType=negotiation")) {
          setTag(["my_cases_neg"]);
        } else if (
          location.includes("dashboard/profile" || "change-password")
        ) {
          setTag(["profile"]);
        } else if (location.includes("help")) {
          setTag(["help"]);
        } else if (location.includes("cart")) {
          setTag(["case_cart"]);
        } else if (location.includes("dashboard")) {
          setTag(["dashboard"]);
        } else if (!location.includes("dashboard")) {
          if (isEmpty(userObject)) {
            setTag(["home", "sign_up"]);
          }
        }
      } else if (location) {
        if (
          location.includes("create?caseType=arbitration") ||
          location.includes("step-2?caseType=arbitration")
        ) {
          setTag(["create_arb"]);
        } else if (
          location.includes("create?caseType=mediation") ||
          location.includes("step-2?caseType=mediation")
        ) {
          setTag(["create_med"]);
        } else if (
          location.includes("cases/create") ||
          location.includes("create?caseType=negotiation")
        ) {
          setTag(["create_neg"]);
        } else if (location.includes("bulk-create")) {
          setTag(["create_bulk"]);
        }
      }
      if (pathname === "/") {
        isMobile
          ? setTimeout(() => {})
          : setTimeout(() => {
              handlePopup();
            }, 200);
      }
    }, 0);
  };

  return <div id="Freshchat-js-sdk" />;
};

export default FreshChat;
