import React, { useState, useEffect } from "react";
import VideoBox from "../../common/VideoBox";
import AboutTermsCard from "../../common/AboutTermsCard";
import styled from "styled-components";
import { PrimaryCTAButton } from "../../common/Buttons";
import Accordion from "../../common/Accordion";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import SystemService from "../../../services/SystemService";
import { getErrorMessage } from "../../../helpers/functions";
import labels from "../../../helpers/labels.json";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";

const Index = props => {
  const [systemConfig, setSystemConfig] = useState({
    tutorialUrl: "",
    faq: [],
    terminology: []
  });
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  // const section = props.location.hash.slice(1);

  useEffect(() => {
    async function getSystemConfig() {
      setLoader({ state: true, message: "Please wait. Loading..." });
      try {
        const response = await SystemService.getConfig();
        setSystemConfig(response.systemConfig);
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, { variant: "error" });
      } finally {
        setLoader({ state: false });
      }
    }
    getSystemConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const tutorialUrl = systemConfig.tutorialUrl.split(",");

  const handNavPosition = () => {
    if (typeof window !== "undefined") {
      const hashId = window.location.hash;
      // console.log({ location: window.location, hashId });

      if (hashId) {
        const element = document.querySelector(hashId);
        // const headerOffset = 45;
        // const elementPosition = element.getBoundingClientRect().top;
        // const offsetPosition = elementPosition - headerOffset;

        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start"
          });
        }
      }
    }
  };

  useEffect(() => {
    handNavPosition();
  });

  const setFileName = tutorialUrl => {
    if (tutorialUrl) {
      const fileName = decodeURI(tutorialUrl);
      return fileName.length < 30
        ? fileName
        : fileName
          .substring(tutorialUrl.lastIndexOf("/") + 1 || tutorialUrl)
          .split("/")
          .slice(-1)
          .join()
          .split(".")
          .shift();
    } else {
      return "";
    }
  };

  return (
    <Center>
      <ButtonRight>
        <a href={`tel: ${systemConfig.mobile}`}>
          <PrimaryCTAButton
            style={{
              width: "216px",
              marginLeft: "unset"
            }}
          >
            <span className="data">{systemConfig?.mobile}</span>
            <span className="label">{labels["help.contact_support"]}</span>
          </PrimaryCTAButton>
        </a>
      </ButtonRight>
      <ContentWrapper>
        <VerticalWrap>
          {tutorialUrl.map((tutorialUrl, index) => (
            <VideoCard key={index}>
              {setFileName(tutorialUrl) ===
                "Resolve a case using Negotiation" ? (
                <SubHead id="about-negotiation">
                  {setFileName(tutorialUrl)}
                </SubHead>
              ) : setFileName(tutorialUrl) ===
                "Resolve a case using Mediation" ? (
                <SubHead id="about-mediation">
                  {setFileName(tutorialUrl)}
                </SubHead>
              ) : setFileName(tutorialUrl) ===
                "Resolve a case using Arbitration" ? (
                <SubHead id="about-arbitration">
                  {setFileName(tutorialUrl)}
                </SubHead>
              ) : (
                <SubHead>{setFileName(tutorialUrl)}</SubHead>
              )}
              {/* <SubHead>{setFileName(tutorialUrl)}</SubHead> */}
              <VideoBox src={tutorialUrl} />
            </VideoCard>
          ))}
        </VerticalWrap>
        <AboutTermsCard
          StyleCard={{
            marginBottom: "46px"
          }}
          StyledPara={{
            height: "unset",
            marginBottom: "10px"
          }}
          terms={systemConfig.terminology}
          heading={labels.terms_heading}
        />
      </ContentWrapper>
      <Wrapper>
        {systemConfig?.faq?.length ? (
          <Accordion data={systemConfig.faq} />
        ) : null}
      </Wrapper>
    </Center>
  );
};

export default Index;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media ${theme?.breakpoints?.lg_up} {
    flex-direction: row;
    align-items: space-between;
    justify-content: center;
  }
`;
const Center = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
`;
const ButtonRight = styled.div`
  margin-top: 25px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 1024px;
  display: flex;
  justify-content: center;
  align-items: center;
  & a {
    text-decoration: none;
  }
  & button {
    position: relative;
    & .data {
      display: none;
    }
    &:hover {
      & .data {
        display: block;
      }
      & .label {
        display: none;
      }
    }
  }
  @media ${theme?.breakpoints?.sm_up} {
    justify-content: flex-end;
  }
`;
const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  @media ${theme?.breakpoints?.lg_up} {
    width: 1024px;
  }
`;
const VerticalWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 0px;
  margin-bottom: 20px;
  @media ${theme?.breakpoints?.lg_up} {
    margin-right: 60px;
  }
`;

const SubHead = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  margin: 0 0 10px 10px;
  color: ${COLORS.COLOR_DARK};
  font-size: 16px;
`;

const VideoCard = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media ${theme?.breakpoints?.lg_up} {
    align-items: space-between;
  }
`;
