import React from "react";
import styled from "styled-components";
import { Images } from "../../../../assets/images";
import { PrimaryCTAButton } from "../../../common/Buttons";
import theme from "../../../../assets/theme";
import useLoader from "../../../../hooks/useLoader";
import CaseService from "../../../../services/CaseService";
import { getErrorMessage } from "../../../../helpers/functions";
import { useSnackbar } from "notistack";
import {
  CLAIMENT_PAID_NEGOTIATION,
  RESPONDENT_PAID_NEGOTIATION,
  BOTH_PAID_NEGOTIATION,
} from "../../../../helpers/constants";
import { navigate } from "@reach/router";

const AgreementDocCard = ({
  type = "Download",
  id,
  status,
  isRespondent,
  hasAgreement,
  hasRating,
}) => {
  const { setLoader } = useLoader();

  const { enqueueSnackbar } = useSnackbar();

  const downloadAgreement = async (id) => {
    setLoader({ state: true, message: "Fetching case details..." });
    try {
      const agreementUrl = await CaseService.downloadCaseAgreement(id);
      if (agreementUrl?.data[0]?.url?.url || agreementUrl?.data[0]?.url) {
        const link = document.createElement("a");
        link.href =
          agreementUrl?.data[0]?.url?.url || agreementUrl?.data[0]?.url;
        link.target = "_blank";
        link.rel = "noopener noreferrer";
        document.body.appendChild(link);
        setTimeout(() => {
          link.click();
          document.body.removeChild(link);
        }, 0);
        if (!hasRating) {
          setTimeout(() => {
            navigate(`/dashboard/rate-platform/${id}`);
          }, 1500);
        }
      } else {
        enqueueSnackbar("Case Manager has to upload the Agreement", {
          variant: "error",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  };

  return (
    <DocumentContainerCard
      disableText={
        !hasAgreement ||
        (status !== BOTH_PAID_NEGOTIATION &&
          ((!isRespondent && status !== CLAIMENT_PAID_NEGOTIATION) ||
            (isRespondent && status !== RESPONDENT_PAID_NEGOTIATION)))
      }
    >
      <div className="file-container">
        <img src={Images.filefloder} alt="fileFoleder" />
        <div>
          {hasAgreement
            ? `Your Final Award Summary for Case ID: ${id} is available to download. Please click on download button.`
            : "Your Final Award preparation is under progress, please check back after sometime."}
        </div>
      </div>
      <div className="btn-container">
        <Button>
          <PrimaryCTAButton
            onClick={() => downloadAgreement(id)}
            disabled={
              !hasAgreement ||
              (status !== BOTH_PAID_NEGOTIATION &&
                ((!isRespondent && status !== CLAIMENT_PAID_NEGOTIATION) ||
                  (isRespondent && status !== RESPONDENT_PAID_NEGOTIATION)))
            }
          >
            {type}
          </PrimaryCTAButton>
        </Button>
      </div>
    </DocumentContainerCard>
  );
};

const DocumentContainerCard = styled.div`
  border-radius: 10px;
  width: 100%;

  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  opacity: ${(props) => (props.disableText ? "0.5" : "1")};
  & .file-container {
    display: flex;
    font-family: ${theme.fonts.primaryFontSemiBold};
    align-items: center;
    flex: 1;
    font-size: 14px;
    color: #293461;
    & div {
      margin-left: 15px;
      font-family: ${theme.fonts.primaryFontSemiBold};
    }
  }
  & .btn-container {
    min-width: 175px;
  }
  @media ${theme?.breakpoints?.sm_up} {
    border-radius: 7px;
    border: solid 0.5px #e1e3ee;
    flex-direction: row;
    padding: 20px;
  }
`;

const Button = styled.div`
  margin-top: 20px;
  @media ${theme?.breakpoints?.sm_up} {
    margin-top: 0;
  }
`;

export default AgreementDocCard;
