import React, { useState } from "react";
import { StyledFormElementsWrapper, HyperLink } from "../Styles";
import { navigate } from "@reach/router";
import { Autocomplete } from "@material-ui/lab";
import { FormLabel, makeStyles } from "@material-ui/core";
import { CustomInputField } from "../../../common/FormInputs";
import COLORS from "../../../../assets/Colors";
import theme from "../../../../assets/theme";
import styled from "styled-components";

const useStyles = makeStyles((theme) => ({
  listbox: {
    color: "#293461",
    fontFamily: "openSans-SemiBold, sans-serif",
    fontSize: "16px",
    backgroundColor: "#ffffff",
  },
  noOptions: {
    fontFamily: "openSans-SemiBold, sans-serif",
    color: "#8294a5",
  },
}));

function PartySelect({
  errors,
  touched,
  setFieldValue,
  menuItems,
  getNoticeTemplates,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      // event.preventDefault(); // Prevent the default tab behavior
      if (menuItems.length > 0 && event?.target?.value?.length) {
        const filteredData = menuItems.filter((item) =>
          item.label.toLowerCase().includes(event?.target?.value.toLowerCase())
        );
        if (filteredData?.length) {
          setFieldValue(`partyId`, filteredData[0]?.value);
          getNoticeTemplates(filteredData[0]?.value);
          setOpen(false);
        }
      }
    }
  };

  const handleChange = (event, newValue) => {
    if (newValue === null) {
      setFieldValue("partyId", "");
      setFieldValue("noticeCategory", "");
      setFieldValue("templateCode", "");
    } else {
      getNoticeTemplates(newValue?.value);
      setFieldValue("partyId", newValue?.value);
    }
  };

  return (
    <>
      <StyledFormElementsWrapper style={{ marginBottom: 15, marginTop: 15 }}>
        <div className="form-fields" style={{ width: "100%", paddingTop: 5 }}>
          <FormLabel
            error={errors["partyId"] && touched["partyId"]}
            style={{
              fontFamily: theme.fonts.primaryFontSemiBold,
              fontSize: 12,
              color: COLORS.INPUT_LABEL,
            }}
          >
            Select a Party
            <span className="required-star"> *</span>
          </FormLabel>
          <Autocomplete
            classes={{ listbox: classes.listbox, noOptions: classes.noOptions }}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            onChange={handleChange}
            selectOnFocus
            clearOnBlur
            options={menuItems}
            getOptionLabel={(option) => option.label && option.label.toString()}
            renderOption={(option) => (
              <Container>
                <div className="agent_row">
                  <div>
                    <div className="agent_name">{option.label}</div>
                    <div className="agent_email">({option.email})</div>
                  </div>
                </div>
              </Container>
            )}
            noOptionsText={
              <div variant="body2" color="textSecondary">
                No parties found
              </div>
            }
            style={{ marginTop: 6, marginBottom: 10 }}
            renderInput={(params) => (
              <CustomInputField
                {...params}
                variant="outlined"
                error={errors["partyId"] && touched["partyId"]}
                helperText={touched["partyId"] && errors["partyId"]}
                onKeyDown={handleKeyDown}
              />
            )}
          />
        </div>
        {menuItems?.length > 0 ? null : (
          <HyperLink
            className="link"
            onClick={() => navigate(`/dashboard/parties/create`)}
          >
            If you do not have a Party, please click here to create a Party
          </HyperLink>
        )}
      </StyledFormElementsWrapper>
    </>
  );
}

export default PartySelect;

const Container = styled.div`
  & .agent_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3px 0px;
    & .agent_name {
      font-size: 16px;
      font-weight: 600;
      flex: 1;
      color: ${COLORS.COLOR_DARK};
      font-family: ${theme.fonts.primaryFontSemiBold};
    }
    & .agent_email {
      font-size: 16px;
      font-weight: 600;
      flex: 1;
      color: ${COLORS.TRANSCRIPT_GRAY};
      font-family: ${theme.fonts.primaryFontSemiBold};
    }
    & .add_circle {
      margin: 0px 20px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 131, 140, 0.13);
      cursor: pointer;
      & .add_icon {
        font-size: 14px;
        color: ${COLORS.BTN_GREEN};
      }
    }
  }
`;
