import React, { useEffect } from "react";
import { Content, SubHeadWrap, BlogPage } from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

/* eslint-disable jsx-a11y/anchor-is-valid */

function OnlineDisputeSixteen(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header>
        <BlogPage>
          <HeadWrap>LIMITATION IN ARBITRATION</HeadWrap>
          <br />
          <p
            style={{ textAlign: "left", fontSize: "16px", fontStyle: "italic" }}
          >
            {" "}
            Authored by: Aprameya Manthena
          </p>
          <br />
          <Content>
            <SubHeadWrap style={{ marginBottom: "10px" }}>
              Introduction
            </SubHeadWrap>
            <p>
              The Limitation Act, 1963 is a legislation that bars legal remedy
              to those who have not exercised their right to act on a particular
              issue within the bounds of time prescribed by the law. Such
              limitation has been placed upon the legal rights of persons not to
              deprive them of their rights but to provide the necessary
              motivation to exercise them. The legislation finds its roots in
              the legal maxim,{" "}
              <b
                style={{
                  fontStyle: "italic",
                }}
              >
                vigilantibus non dormientibus jura subveniunt
              </b>
              , i.e., the law assists the vigilant and not those who are
              sluggish in asserting their rights.
            </p>
            <br />
            <SubHeadWrap>
              Limitation as Provided in the Arbitration and Conciliation Act,
              1996
            </SubHeadWrap>
            <br />
            <p>
              The Arbitration and Conciliation Act, 1996 provides for the
              conduct of proceedings in a time- bound manner, i.e., limitation
              in arbitration, within the following provisions:
            </p>
            <br />
            <p>
              Section 8 - application for reference of disputes to arbitration,
              shall be filed not later than submitting the first statement on
              the substance of the dispute;
            </p>
            <br />
            <p>
              Section 9(2) - where a Court passes an order for any interim
              measure of protection, the arbitral proceedings shall be commenced
              within a period of 90 days from the date of such order;
            </p>
            <br />
            <p>
              Section 13 - where a challenge is made against an arbitrator, the
              same must be raised within 15 days from the constitution of the
              tribunal, or after becoming aware of any circumstances mentioned
              in sub-section (3) of Section 12;
            </p>
            <br />
            <p>
              Section 16(2) - a plea that the tribunal does not have
              jurisdiction, shall be raised not later than the submission of the
              statement of defence; and
            </p>
            <br />
            <p>
              Section 34(3) - a maximum period of 90 days after the receipt of
              the signed award, to file objections before the Court.
            </p>
            <br />
            <p>
              The Arbitration and Conciliation (Amendment) Act, 2015 introduced
              new amendments for the speedy disposal of arbitral proceedings:
            </p>
            <br />
            <p>
              Sections 11(13) - an application made either before the Supreme
              Court, or the High Court, or person or institution designated by
              such Court, shall be disposed of as quickly as possible, and an
              endeavour shall be made to dispose of the petition within a period
              of 60 days from the date of service of the notice on the opposite
              party)
            </p>
            <br />
            <p>
              Section 29A the arbitral proceedings must be completed within a
              period of 12 months from the date of completion of pleadings; and
            </p>
            <br />
            <p>
              Section 34(6) - an application under Section 34 shall be disposed
              of expeditiously within a period of 1 year from the date on which
              the notice of filing objections is served upon the other party.
            </p>
            <br />
            <SubHeadWrap>Section 43</SubHeadWrap>
            <br />
            <p>
              The Arbitration and Conciliation Act, 1996 provides for limitation
              in arbitration under Section 43. This section provides for the
              conditions regarding application of limitation to cases, the start
              of proceedings, when the claims would be barred by time and
              conditions that need to be met for time extensions to be granted.
              Here, limitation shall apply to arbitrations as it applies to
              proceedings in Court. It also provides that an arbitration shall
              be believed to have commenced on the date referred to in Section
              21, i.e., the date on which a request for that dispute to be
              referred to arbitration is received by the respondent.
            </p>
            <br />
            <SubHeadWrap>Section 11</SubHeadWrap>
            <br />
            <p>
              Section 11 of the Arbitration and Conciliation Act, 1996 deals
              with the appointment of arbitrators, the procedure, requirements
              and limitations in arbitration insofar as it applies to any stage
              of the proceedings.
            </p>
            <br />
            <p>
              Section 11(4) states that if a party fails to appoint an
              arbitrator within 30 days from the receipt of the request to do so
              or if 2 appointed arbitrators fail to agree on the third
              arbitrator within 30 days from the date of their appointment, the
              appointment shall be made by the High Court, or the Supreme Court,
              as the case may be, on the request of a party.
            </p>
            <br />
            <SubHeadWrap>
              BSNL versus Nortel Network India Pvt. Ltd.
            </SubHeadWrap>
            <br />
            <p>
              This Supreme Court’s judgment in Bharat Sanchar Nigam Ltd. v. M/s
              Nortel Network India Pvt. Ltd. 1 laid down the law to follow in
              regard to time-barred claims and applications in arbitration.
            </p>
            <br />
            <p>This case raised two important questions for decision:</p>
            <br />
            <div style={{ marginLeft: "40px" }}>
              <ol>
                <li>
                  the period of limitation for filing an application under
                  Section 11 of the Arbitration and Conciliation Act, 1996 and
                </li>
                <li>
                  whether the Court may refuse to make the reference under
                  Section 11 where the claims are ex facie time-barred.
                </li>
              </ol>
            </div>
            <br />
            <p>
              Due to the vacuum in the law to provide a period of limitation
              under Section 11, the Courts in prior judgments held that the
              limitation period would be governed by Article 137 of the First
              Schedule to the Limitation Act, 1963, which provides for a period
              of 3 years from the date when the right to apply begins.
            </p>
            <br />
            <p>
              The Court observed that it would be necessary for the Parliament
              to pass an amendment to Section 11 of the Arbitration and
              Conciliation Act, 1996, prescribing a specific period of
              limitation within which a party may approach the Court for making
              an application for appointment of the arbitrator under Section 11.
            </p>
            <br />
            <p>
              The Court also pointed out the Arbitration and Conciliation Act,
              1996 (see Section 29A) was amended in 2015 and 2019 to provide
              time limits for speedy disposal of arbitral proceedings.
            </p>
            <br />
            <p>
              This case has been crucial in the determination of the limitation
              period for the appointment of an arbitrator.
            </p>
            <br />
            <Block />
            <p>
              <sup>1</sup>
              Bharat Sanchar Nigam Ltd. v. M/s Nortel Network India Pvt. Ltd.,
              (2021) 5 SCC 738.
            </p>
          </Content>
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default OnlineDisputeSixteen;

export const ContentExample = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
`;

export const Block = styled.div`
  width: 153px;
  height: 0;
  border: 1px solid ${COLORS.INPUT_BLACK};
  margin: 3px;
  display: inline-block;
  margin-bottom: 12px;
  font-family: ${theme.fonts.primaryFontRegular};
`;
export const HeadWrap = styled.div`
  text-align: center;
  font-size: 18px;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontBold};
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 25px;
  }
`;

export const SubHeadWrapHeading = styled.div`
  text-align: left;
  font-size: 20px;
  padding-left: 1.5%;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontRegular};
`;
