import React, { useEffect } from "react";
import { Content, SubHeadWrap, BlogPage } from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { Helmet } from "react-helmet";

/* eslint-disable jsx-a11y/anchor-is-valid */

function OnlineDisputeFourteen(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>Non-Arbitrable Cases - JustAct</title>
        <meta
          name="description"
          content="Section 2(3) of the Arbitration and Conciliation Act, 1996 (“A & C Act) ousts the jurisdiction of arbitral tribunals wherein the laws in force provide that the particular dispute is not arbitrable."
        />
        <meta
          name="keywords"
          content="Non-Arbitrable Case, Non-Arbitration Disputes, Dispute Resolution, Dispute Resolution Online, Resolve Disputes Online, Arbitration Dispute Resolution, JustAct"
        />
      </Helmet>
      <Header>
        <BlogPage>
          <HeadWrap>NON-ARBITRABLE CASES</HeadWrap>
          <br />
          <p
            style={{ textAlign: "left", fontSize: "16px", fontStyle: "italic" }}
          >
            {" "}
            Authored by: K. Vidya Sagar
          </p>
          <br />
          <p
            style={{ textAlign: "left", fontSize: "16px", fontStyle: "italic" }}
          >
            {" "}
            Edited by: Aprameya Manthena
          </p>
          <br />
          <SubHeadWrap>Introduction</SubHeadWrap>
          <br />
          <Content>
            <p>
              Alternative Dispute Resolution as a means of settling disputes out
              of court is gaining precedence across the world. Most disputes
              that arise may be resolved through methods such as arbitration,
              conciliation and mediation amongst alternative options of
              redressal. However, there are certain classes of disputes that
              cannot be resolved as easily. They are non-arbitrable in the sense
              that requisite authority has not been provided under the law to
              resolve them privately. For instance, Section 2(3) of the
              Arbitration and Conciliation Act, 1996 (“A & C Act) ousts the
              jurisdiction of arbitral tribunals wherein the laws in force
              provide that the particular dispute is not arbitrable.
            </p>
            <br />
            <SubHeadWrap>Non-arbitrability</SubHeadWrap>
            <br />
            <p>
              At first glance it might seem that this is in direct contrast with
              the terms agreed upon in the New York Convention and UNCITRAL
              Model Law since the objective of the Convention is to arm
              arbitration as a method and ensure the enforceability and
              execution of arbitral awards across multiple jurisdictions.
              Therefore, it could be concluded that non-arbitrability clauses in
              Indian law are not compliant with this objective.
            </p>
            <br />
            <p>
              However, it should be noted that Articles 34 and 36 of the
              UNCITRAL Model Law on International Arbitrations provides that no
              arbitral award shall be recognized or enforceable in states where
              the subject matter of arbitration is not capable of being settled
              out of court or if the award is against the public policy of such
              state. The same words have been reiterated by the A&C Act, 1996
              under sections 34 (2) and 48(2). The law, both international and
              domestic, is thus clear that not every issue may be arbitrable and
              that arbitral awards may be set aside in accordance with the laws
              of that country.
            </p>
            <br />

            <SubHeadWrap>
              What constitutes the non-arbitrability of a particular class of
              issues?
            </SubHeadWrap>
            <br />
            <p>
              The A&C Act, 1996 in its entirety does not provide for the class
              of issues that are non-arbitrable. This has been determined by
              judicial precedents and other special statutes. Two cases hold the
              field and provide the necessary guidelines to determine the
              arbitrability of a case. They are –
            </p>
            <br />
            <div style={{ marginLeft: "40px", fontStyle: "italic" }}>
              <ul>
                <li>
                  Booz Allen & Hamilton Inc v SBI Home Finance Ltd, (2011) 5 SCC
                  532
                </li>
                <li>
                  Vidya Drolia and others v Durga Trading Corporation, (2021) 2
                  SCC 1
                </li>
              </ul>
            </div>
            <br />
            <p>
              It may be useful, in this context, to understand the scope of the
              rights available to parties in regard to disputes. These are as
              follows:
            </p>
            <br />
            <div style={{ marginLeft: "40px" }}>
              <p>a) Rights in Rem</p>
              <p>b) Rights in Personam</p>
              <br />
            </div>
            <p>
              Right in rem is a right the person exercises against the world at
              large while right in personam is a right that is exercisable only
              against specific person/s.
            </p>
            <br />
            <p>
              Any case that involves an element of right in rem is generally
              non-arbitrable and by default, taken up by the public fora. The
              types of remedy involved are not those which an arbitral tribunal
              is empowered to give. On the other hand, rights in personam are
              enforceable only against certain people, and are civil in nature;
              hence, these are amenable to arbitration though this is not a
              rigid or inflexible rule.
            </p>
            <br />
            <SubHeadWrap>
              Booz Allen & Hamilton Inc v SBI Home Finance Ltd
            </SubHeadWrap>
            <br />
            <p>
              The case is foundational to the development of the parameters of
              non-arbitrability of disputes and the Supreme Court laid down
              general rules in this regard.
            </p>
            <br />
            <p>
              The Court discussed the three facets of arbitrability relating to
              the jurisdiction of the arbitral tribunal herein:
            </p>
            <br />
            <div style={{ fontStyle: "italic" }}>
              <p>
                i) whether the disputes are capable of adjudication and
                settlement by arbitration? That is, whether the disputes could
                be resolved by a private forum chosen by the parties (the
                arbitral tribunal) or whether they would exclusively fall within
                the domain of public fora (courts)
              </p>
              <br />
              <p>
                (ii) Whether the disputes are covered by the arbitration
                agreement? That is, whether the disputes are enumerated or
                described in the arbitration agreement as matters to be decided
                by arbitration or whether the disputes fall under the `excepted
                matters' excluded from the purview of the arbitration agreement.
              </p>
              <br />
              <p>
                (iii) Whether the parties have referred the disputes to
                arbitration? That is, whether the disputes fall under the scope
                of the submission to the arbitral tribunal, or whether they do
                not arise out of the statement of claim and the counterclaim
                filed before the arbitral tribunal.
              </p>
            </div>
            <br />
            <p>
              The Court also pointed out that adjudication in certain categories
              of cases were reserved for public fora exclusively as a matter of
              public policy. Other categories of proceedings, though not
              reserved exclusively for adjudication by public fora including
              courts and tribunals, may, by necessary implication stand excluded
              from the ambit of private fora. Furthermore, the Court added,
              subordinate rights in personam arising from rights in rem have
              always been considered to be arbitrable and hence may form one of
              the exceptions to the above.
            </p>
            <br />
            <p>
              The class of cases that cannot be arbitrated, as cited by the
              court in this case, includes-
            </p>
            <br />
            <div style={{ lineHeight: "1.9" }}>
              <p>
                (i) disputes relating to rights and liabilities which give rise
                to or arise out of criminal offences;
              </p>
              <p>
                (ii) matrimonial disputes relating to divorce, judicial
                separation, restitution of conjugal rights, child custody;
              </p>
              <p>(iii) guardianship matters;</p>
              <p>(iv) insolvency and winding up matters;</p>
              <p>
                (v) testamentary matters (grant of probate, letters of
                administration and succession certificate); and
              </p>
              <p>
                (vi) eviction or tenancy matters governed by special statutes.
              </p>
            </div>
            <br />
            <p>
              In A. Ayyasamy vs A. Paramasivam & Ors, J. D. Y. Chandrachud
              commented that there are classes of disputes which fall within the
              jurisdiction of special fora created under legislation which
              exclude the jurisdiction of the ordinary civil court.
            </p>
            <br />
            <SubHeadWrap>
              Vidya Drolia and others v Durga Trading Corporation
            </SubHeadWrap>
            <br />
            <div>
              <p>
                In Vidya Drolia, while coming to an understanding of whether
                arbitrability can be analyzed by the Courts under Section 8 or
                11 of the Act, J. N.V. Ramana, in agreement with J. Sanjiv
                Khanna held that Sections 8 and 11 of the Act have the same
                ambit with respect to judicial interference i.e. scope of
                judicial review and jurisdiction of the court is identical but
                extremely limited and restricted.
              </p>
              <br />
              <p>
                Subject matter arbitrability cannot be decided at the stage of
                Sections 8 or 11 of the Act, unless it’s a clear case of
                deadwood. The restricted and limited review is to check and
                protect parties from being forced to arbitrate when the matter
                is demonstrably ‘non-arbitrable’ and to cut off the deadwood.
              </p>
              <br />
              <p>
                The scope of the Court to examine the prima facie validity of an
                arbitration agreement includes only: a. Whether the arbitration
                agreement was in writing? or b. Whether the arbitration
                agreement was contained in exchange of letters,
                telecommunication etc? c. Whether the core contractual
                ingredients qua the arbitration agreement were fulfilled? d. On
                rare occasions, whether the subject ­matter of dispute is
                arbitrable?
              </p>
              <br />
              <p>
                The Court, under Sections 8 and 11, has the power to refer a
                matter to arbitration or to appoint an arbitrator, as the case
                may be, unless a party has established a prima facie (summary
                findings) case of non-­existence of valid arbitration agreement.
              </p>
              <br />
              <p>
                The Court has the power to refer a matter if the validity of the
                arbitration agreement cannot be determined on a prima facie
                basis, as laid down above, i.e., ‘when in doubt, do refer’.
              </p>
              <br />
              <p>
                The Court may interfere at the Section 8 or 11 stage, as little
                as possible, when it is manifestly and ex facie certain that the
                arbitration agreement is non- existent, invalid or the disputes
                are non-arbitrable, though the nature and facet of
                non-arbitrability would, to some extent, determine the level and
                nature of judicial scrutiny.
              </p>
            </div>
            <br />
            <SubHeadWrap>In conclusion</SubHeadWrap>
            <br />
            <div>
              Taking into consideration the aforementioned aspects, the Apex
              Court has provided much-needed clarity into the classes of cases
              that are not arbitrable and provided suitable reasons for the
              categorisation. Even though ADR is a powerful tool for those
              seeking dispute resolution, not every case is arbitrable because
              of the inherent nature of the rights, procedures and obligations
              involved under the existing law.
            </div>
            {/* <br />
            <Block />
            <br />
            <p style={{ fontStyle: "italic" }}>
              The author is a Lawyer and Cultural Studies Academic.
            </p> */}
          </Content>
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default OnlineDisputeFourteen;

export const ContentExample = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
`;

export const Block = styled.div`
  width: 153px;
  height: 0;
  border: 1px solid ${COLORS.INPUT_BORDER};
  margin: 3px;
  display: inline-block;
  font-family: ${theme.fonts.primaryFontRegular};
`;
export const HeadWrap = styled.div`
  text-align: center;
  font-size: 18px;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontBold};
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 25px;
  }
`;
