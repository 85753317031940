import React, { useEffect } from "react";
import { Content, HeadWrap, SubHeadWrap, TrainingPage } from "./Styles";
import { Helmet } from "react-helmet";
import { HyperLink } from "../../common/FormInputs";

/* eslint-disable jsx-a11y/anchor-is-valid */

function ShortTraining(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>Mediation Online Training Programme - JustAct</title>
        <meta
          name="description"
          content="Online Mediation Training Program focuses on ADR in general, and mediation in specific. It is a quick go-to option for people looking to learn about how mediation works."
        />
        <meta
          name="keywords"
          content="Online Mediation Training Program, Arbitration Mediation and Conciliation, Online Training Course for Mediation, ADR Training Course for Mediation, Online Arbitration, Resolve Disputes Online, Negotiation Mediation and Arbitration, JustAct"
        />
      </Helmet>
      <TrainingPage>
        <HeadWrap> World of Mediation</HeadWrap>
        <br />
        {/* <SubHeadWrap>Welcome to The World of Mediation</SubHeadWrap> */}
        <Content>
          We have created a completely online training course just for you. The
          link to the training is placed below for you to join and partake of
          the same.
        </Content>

        <SubHeadWrap>Course Objectives</SubHeadWrap>
        <Content>
          <p>
            The programme focusses on {""}
            <HyperLink
              onClick={() =>
                window.open(
                  `https://justact.co.in/training/corporate-short-programme`
                )
              }
            >
              ADR
            </HyperLink>{" "}
            in general and mediation in specific. It is a quick go-to tool for
            people looking to learn about how mediation works.
          </p>
        </Content>
        <SubHeadWrap>Learning Objectives</SubHeadWrap>
        <Content>
          <ul style={{ paddingLeft: "20px" }}>
            <li>Introduction to ADR.</li>
            <li>What is mediation, its objective and its benefits?</li>
            <li>Mediation vs Litigation - comparative analysis.</li>
            <li>Nature of disputes & personalities in a dispute.</li>
            <li>Structured Process of Mediation.</li>
            <li>Principles of Negotiation.</li>
            <li>Mediation settlement agreement.</li>
            <li>JustAct - Introduction to the platform.</li>
          </ul>
        </Content>
        <SubHeadWrap>Duration</SubHeadWrap>
        <Content>
          <ul style={{ paddingLeft: "20px" }}>
            <li>10 hours of video lectures.</li>
            <li>Self learning assignments.</li>
          </ul>
        </Content>
        <SubHeadWrap>Link</SubHeadWrap>
        <Content>
          <p>
            To access and purchase the World Of Mediation course, please click
            the link below.
          </p>
          <a
            style={{
              color: "blue",
              cursor: "pointer"
            }}
            onClick={() =>
              window.open(
                "https://www.udemy.com/course/world-of-mediation/?referralCode=AC543A687FAF0488CE66"
              )
            }
          >
            https://www.udemy.com/course/world-of-mediation/?referralCode=AC543A687FAF0488CE66
          </a>
        </Content>
        {/* <SubHeadWrap>Brochure</SubHeadWrap>
        <Content>
          <a
            href={require("../../../assets/trainingBrochure_20.pdf")}
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here to Open
          </a>
        </Content> */}
      </TrainingPage>
    </>
  );
}

export default ShortTraining;
