import React, { useEffect } from "react";
import {
  Header3,
  HeadingContainer,
  CardWrapper,
  ContentContainer,
  AnchorText,
  BackArrow,
  Center,
} from "../../../styles/component/style";
import { PrimaryCTAButton } from "../../common/Buttons";
import { Formik } from "formik";
import {
  LoginEmailValidationSchema,
  LoginMobileValidationSchema,
} from "../../../helpers/validationSchema";
import FormField from "../../common/FormField/FormField";
import _ from "lodash";
import AuthService from "../../../services/AuthService";
import { useSnackbar } from "notistack";
import { navigate } from "@reach/router";
import { getErrorMessage } from "../../../helpers/functions";
import styled, { css } from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import useUser from "../../../hooks/useUser";
import useLoader from "../../../hooks/useLoader";
import GLogin from "../../common/GoogleLogin";
import { Helmet } from "react-helmet";

const Login = ({
  heading = "Login",
  inputs = [],
  submitLabel = "Login",
  currentTab,
  setCurrentTab,
  disableSubmit = false,
  location,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setToken, setPassword } = useUser();
  const { state } = location;
  const { setLoader } = useLoader();

  useEffect(() => {
    if (state?.payload?.mobile) {
      setCurrentTab("mobile");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.payload?.mobile]);

  async function onFormSubmit(values) {
    try {
      setLoader({ state: true });
      const payloadType = currentTab;
      let payload = _.pick(values, [currentTab, "password"]);
      if (payloadType === "mobile") {
        delete payload.password;
      }
      const response = await AuthService.login(payload);
      if (
        response.token &&
        (payloadType === "email" || payloadType === "mobile")
      ) {
        setToken(response.token);
        setPassword(response.changePassword);
        enqueueSnackbar("Login successful", {
          variant: "success",
        });
        if (response?.changePassword) {
          navigate("/dashboard/profile/change-password");
        } else {
          navigate("/dashboard");
        }
        // navigate("/dashboard");
      } else {
        enqueueSnackbar("OTP sent successfully", {
          variant: "success",
        });
        navigate("/login/otp", {
          state: {
            payload,
            values,
          },
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }
  
  /**
   * @description Function to login with google
   * @param {*} res
   */
  async function loginWithGoogle(res) {
    if (res?.tokenObj?.id_token) {
      try {
        setLoader({ state: true });
        const payload = {
          idToken: res?.tokenObj?.id_token,
        };
        const response = await AuthService.loginWithgoogle(payload);
        if (response.token) {
          setToken(response.token);
          enqueueSnackbar("Login successful", {
            variant: "success",
          });
          navigate("/dashboard");
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }
  }

  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>Log In - JustAct</title>
        <meta
          name="description"
          content="JustAct Log In - Enter the mail id and password to login to your JustAct Account."
        />
        <meta
          name="keywords"
          content="Log In, JustAct, Resolve Disputes Online, Online Dispute Resolution, ODR Services, Alternative Dispute Resolution, ADR Services, Online Dispute Resolution in India"
        />
      </Helmet>
      <Formik
        initialValues={{
          email: state?.payload?.email || "",
          mobile: state?.payload?.mobile || "",
          password: state?.payload?.password || "",
        }}
        enableReinitialize
        onSubmit={onFormSubmit}
        validationSchema={
          inputs[0].type === "email"
            ? LoginEmailValidationSchema
            : LoginMobileValidationSchema
        }
        validateOnBlur
        validateOnChange
      >
        {({
          values,
          handleChange,
          errors,
          handleSubmit,
          touched,
          handleBlur,
          setFieldValue,
        }) => (
          <CardWrapper onSubmit={handleSubmit}>
            <HeadingContainer>
              <BackArrow
                onClick={() => navigate("/")}
                src={require("../../../assets/images/backArrow.svg")}
              />
              <Header3>{heading}</Header3>
            </HeadingContainer>
            <Tabs>
              <Tab
                selected={currentTab === "email"}
                onClick={() => setCurrentTab("email")}
              >
                With Email
              </Tab>
              <Tab
                selected={currentTab === "mobile"}
                onClick={() => setCurrentTab("mobile")}
              >
                With Phone Number
              </Tab>
            </Tabs>
            <ContentContainer>
              {currentTab === "mobile" ? (
                <Heading>Enter your Registered Mobile Number</Heading>
              ) : (
                ""
              )}
              {inputs
                .filter((input) =>
                  currentTab === "mobile" ? input.name === currentTab : true
                )
                .map((input, index) => {
                  return (
                    <FormField
                      key={index}
                      {...{
                        touched,
                        errors,
                        input,
                        values,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        currentTab,
                      }}
                    />
                  );
                })}
            </ContentContainer>

            <div className="pt20 pb10">
              <PrimaryCTAButton
                type="submit"
                onClick={handleSubmit}
                disabled={disableSubmit}
              >
                {submitLabel}
              </PrimaryCTAButton>
            </div>
            {currentTab === "mobile" ? null : (
              <div className="center">
                <AnchorText
                  onClick={() => {
                    if (!touched.email) {
                      enqueueSnackbar("Enter Your Email", {
                        variant: "error",
                      });
                    }
                    if (!errors.email && touched.email) {
                      navigate("/forgot-password", {
                        state: { email: values.email },
                      });
                    }
                  }}
                >
                  Forgot Password
                </AnchorText>
              </div>
            )}
            <Center className="openSans-SemiBold pt10 pb10">Or</Center>
            <div className="pb20">
              <GLogin
                label="Login with Google"
                className="p8"
                onSuccess={loginWithGoogle}
              />
            </div>
          </CardWrapper>
        )}
      </Formik>
    </>
  );
};

export default Login;

const Tabs = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  border-radius: 10px;
  background-color: #f4f5f9;
  width: 75%;
  margin: 0 auto;
  padding: 8px;
  margin-top: 20px;
  @media (max-width: 600px) {
    width: 95%;
  }
  @media (max-width: 400px) {
    width: 95%;
    flex-direction: column;
  }
`;
const Tab = styled.div`
  flex: 1;
  border-radius: 7px;
  color: ${COLORS.INPUT_LABEL};
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 12px;
  font-family: ${theme.fonts.primaryFontBold};
  ${({ selected }) =>
    selected &&
    css`
      background-color: #293461;
      color: white;
    `}
  @media (max-width: 400px) {
    width: 95%;
  }
`;

const Heading = styled.div`
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  margin-top: 19px;
  margin-bottom: 37px;
  @media (max-width: 500px) {
    margin-top: 10px;
    margin-bottom: 15px;
  }
`;
