import React, { useEffect } from "react";
import { Content, HeadWrap, SubHeadWrap, TrainingPage } from "./Styles";
import { Helmet } from "react-helmet";
import { HyperLink } from "../../common/FormInputs";

function LongTraining(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>
          Online Mediation Training Program - Dispute Resolution Program -
          JustAct
        </title>
        <meta
          name="description"
          content="Online Mediation Training Program involves handling inter- personal dynamics & negotiations to resolve a dispute of simple to moderate complexity, independently through mediation."
        />
        <meta
          name="keywords"
          content="Online Mediation Training Program, Mediation Dispute Resolution Training Programme , Mediation Training Programme, Online Mediation, Dispute Resolution Online, Online Dispute Resolution in India, JustAct"
        />
      </Helmet>
      <TrainingPage>
        <HeadWrap>Mediation Training Programme</HeadWrap>
        <br />
        {/* <SubHeadWrap>Welcome to The World of Mediation</SubHeadWrap> */}
        <Content>
          We’re so glad that you showed interest in training! This document is
          meant to provide with the course objectives and the learning
          objectives of this training program.
        </Content>

        <SubHeadWrap>Course Objectives</SubHeadWrap>
        <Content>
          <p>
            Mediation as a {""}
            <HyperLink onClick={() => window.open(`https://justact.co.in/`)}>
              dispute resolution
            </HyperLink>{" "}
            process involves handling inter- personal dynamics and negotiations.
            The objective of this course is to impart the necessary skills,
            techniques and process knowledge required by anyone to resolve a
            dispute of simple to moderate complexity, independently through
            mediation.
          </p>
        </Content>
        <SubHeadWrap>Learning Objectives</SubHeadWrap>
        <Content>
          <ol style={{ paddingLeft: "20px" }}>
            <li>What is mediation and where is it used ?</li>
            <li>Nature of disputes & personalities in a dispute </li>
            <li>What is the objective of mediation ?</li>
            <li>Characteristics of mediation </li>
            <li>Mediation and its interplay with the court system</li>
            <li>Principles of negotiation </li>
            <li>Structured Process of Mediation </li>
            <li>
              How to handle impasse and how to problem solve a tough mediation
            </li>
            <li>What NOT to do in a mediation and what is NOT mediation</li>
            <li>
              Communication skills required in conducting a mediation -
              Observing, Listening, Reframing, Managing Bias
            </li>
            <li>
              Writing a mediation settlement agreement and minuting meetings
            </li>
            <li>Ethics of a mediator and how to handle conflict of interest</li>
          </ol>
        </Content>
        <SubHeadWrap>Duration</SubHeadWrap>
        <Content>
          <ul style={{ paddingLeft: "20px" }}>
            <li>15 Hours of Classroom Sessions over 3 weekends.</li>
            <li>15 hours of Self Learning Videos.</li>
            <li>10 hours of Role Plays & Exercises.</li>
          </ul>
        </Content>
        <SubHeadWrap>Curriculum</SubHeadWrap>
        <Content>
          <p>
            Participants are expected to complete the self-learning modules and
            exercises prior to the ClassRoom sessions. Role plays & exercises
            during the ClassRoom sessions, may also be followed by similar
            exercises, role plays and discussions to be done among participants
            in groups, on their own, which will be assessed and evaluated. Video
            recording of role plays will need to be shared by participants.
          </p>
        </Content>
        <SubHeadWrap>Pricing</SubHeadWrap>
        <Content>
          <div style={{ marginBottom: "10px" }}>
            <b>Training Cost:</b> Rs. 40,000 + GST**
          </div>
          <div>
            <b>Limited Time offer</b> **Individuals can pay Rs. 25,000 and pay
            the rest when they start mediating
          </div>
        </Content>
        <SubHeadWrap>Batches</SubHeadWrap>
        {/* <SubHeadWrapBatch><b>Size of the batch*:</b></SubHeadWrapBatch> */}
        <Content>
          <p style={{ marginBottom: "10px" }}>
            <b>Size of the batch*:</b>
          </p>
          <ul style={{ paddingLeft: "40px" }}>
            <li>Min participants- 15</li>
            <li>Max participants- 21**</li>
          </ul>
          <br />
          <p>
            *size of the batch is in multiples of 3 for role-play exercises.
            <br />
            **The minimum number of registrations required to commence a batch
            is 15. Kindly note that the registration shall be done for a max of
            21 participants on a first-come-first-serve basis.
          </p>
        </Content>
        <SubHeadWrap>Eligibility</SubHeadWrap>
        <Content>
          <p>Graduate with professional experience.</p>
        </Content>
        <SubHeadWrap>Contact</SubHeadWrap>
        <Content>
          <ul>
            {/* <li>Madhav Pudipeddi</li> */}
            <div style={{ marginBottom: "10px" }}>
              <b>Email :</b>{" "}
              <a href="mailto:support@justact.co.in">support@justact.co.in</a>
            </div>
            <div>
              {" "}
              <b>Mobile : </b>+91 95009 09888 | 87545 76111 | 73959 13222
            </div>
          </ul>
        </Content>
        <SubHeadWrap>Frequently Asked Questions</SubHeadWrap>
        <Content>
          <SubHeadWrap style={{ fontSize: "18px", padding: "0px" }}>
            1. What is JustAct?
          </SubHeadWrap>
          <br />
          <p>
            ❖ JustAct offers Online Dispute Resolution services to disrupt the
            way disputes are resolved today.{" "}
          </p>
          <br />
          <SubHeadWrap style={{ fontSize: "18px", padding: "0px" }}>
            2. What is the duration and curriculum?
          </SubHeadWrap>
          <br />
          <p>The 40-hour training has</p>
          <br />
          <p>❖ 15 Hours of Classroom Sessions over 3 weekends</p>
          <br />
          <p>❖ 15 hours of Self Learning Videos </p>
          <br />
          <p>❖ 10 hours of Role Plays & Exercises</p>
          <br />
          <p>
            The training prepares attendees with concepts and practical learning
            to enable them to become mediators.{" "}
          </p>
          <br />
          <p>
            Participants are expected to complete the self-learning modules and
            exercises prior to the ClassRoom sessions. Role plays & exercises
            during the ClassRoom sessions, may also be followed by similar
            exercises, role plays and discussions to be done among participants
            in groups, on their own, which will be assessed and evaluated. Video
            recording of role plays will need to be shared by participants.{" "}
          </p>
          <br />
          <SubHeadWrap style={{ fontSize: "18px", padding: "0px" }}>
            3. Can I become a mediator with JustAct after this program?{" "}
          </SubHeadWrap>
          <br />
          <p>
            ❖ Mediation as a dispute resolution process involves handling
            inter-personal dynamics and negotiations. The objective of this
            course is to impart the necessary skills, techniques and process
            knowledge required by anyone to independently resolve a dispute of
            simple to moderate complexity.
          </p>
          <br />
          <p>
            ❖ Attendees desirous of empanelling themselves will be required to
            fill up a small form and undergo an evaluation by a panel of
            evaluators.
          </p>
        </Content>
        {/* <SubHeadWrap>Brochure</SubHeadWrap>
        <Content>
          <a
            href={require("../../../assets/trainingBrochure_40.pdf")}
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here to Open
          </a>
        </Content> */}
      </TrainingPage>
    </>
  );
}

export default LongTraining;
