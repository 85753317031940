import React, { useEffect } from "react";
import { Content, SubHeadWrap, BlogPage, BlogImage } from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import { Helmet } from "react-helmet";
import { HyperLink } from "../../common/FormInputs";

function OnlineDisputeBlogEight(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>
          Impeding or Expediting The Arbitration Process - Arbitration and
          Conciliation Act
        </title>
        <meta
          name="description"
          content="Sections 11 and 29A of the Arbitration and Conciliation Act stood as two of the most well-known and ambitious provisions added by 2015 amendment."
        />
        <meta
          name="keywords"
          content="Impeding or Expediting The Arbitration Process, Arbitration Disputes, Institutional Arbitration, Online Dispute Resolution, JustAct"
        />
      </Helmet>
      <Header>
        <BlogPage>
          <SubHeadWrap>
            Section 11 & 29A: Impeding or expediting the Arbitration process?
          </SubHeadWrap>
          <br />
          <Content>
            <p>
              Through the present health catastrophe that the world knows as
              COVID-19, arbitration has been shown to adapt better than other{" "}
              <HyperLink onClick={() => window.open(`https://justact.co.in/`)}>
                dispute resolution
              </HyperLink>{" "}
              methods. For one, it gives parties and arbitrators substantial
              autonomy and control over the process which enables the
              arbitration community to continue proceedings.
            </p>
            <br />
            <p>
              Given this autonomy and flexible structure of proceedings while
              arbitrating disputes, these pandemic’s adverse effects can be
              minimized or mitigated. The 2019 Amendment Act stood as the latest
              testament to this, by marking India’s shift towards institutional
              arbitration. One of its main objectives was to make India an
              arbitration-friendly jurisdiction, something which the previous
              amendments have failed to achieve.
            </p>
            <br />
            <BlogImage
              src={require("../../../assets/images/I1.png")}
              alt="Independent"
            />
            <br />
            <p>
              In order to execute this, Sections 11 and 29A of the Arbitration
              and Conciliation Act stood as two of the most well-known and
              ambitious provisions added by 2015 amendment. These provisions
              aimed to limit the scope of examination by the court, as such
              examination by courts would lead to a mini trial at the beginning
              of the arbitration itself, leading to a lot of delay.
            </p>
            <br />
            <p>
              In many cases it took years to constitute an arbitral tribunal, as
              the issue was kept pending for one or the other reason. Similarly,
              after appointment of arbitral tribunal it has taken several years
              for resolving the dispute through arbitration in a number of
              cases. This consequently led to the introduction of section 29A
              which prescribed a limit within which an arbitration proceeding
              needs to be conducted. Subsequently with the 2019 amendment,
              certain modifications were to be done in relation to the operation
              of these provisions.
            </p>
            <br />
            <p>
              The 2019 amendment further extended the time limit by six months
              for completion of pleadings. It is not very clear if the provision
              is directory or mandatory and whether the courts can extend this
              period further. It is important to understand the efficacy of
              these provision and whether they could achieve what they were
              brought in for.
            </p>
            <br />
            <BlogImage
              src={require("../../../assets/images/I2.png")}
              alt="Independent"
            />
            <br />
            <p>
              Discussions began when it was realized that the arbitration
              proceedings in India sometimes took more time to begin, as
              compared to the claims of foreign jurisdictions about finishing an
              arbitration proceeding altogether! The causes of delay were many,
              perturbing the international community in the process. The
              introduction of section 29A was an excellent step by which India
              sent a very strong message to international community that delay
              is not something which is going to be tolerated so far arbitration
              is concerned.
            </p>
            <br />
            <p>
              Initially this provision was debated at various fora questioning
              its ability to complex arbitration cases, especially in a country
              like India. But it was soon realized that it was actually working
              on the ground. The biggest challenge was to ensure following the
              timeline that even in big complex matters especially involving
              Government. It was also argued that Party autonomy should not be
              given where parties are already at loggerheads. This potentially
              risked further confusions and deadlock.
            </p>
            <br />
            <p>
              Online dispute resolution is here to continue and as technology
              moves forward, we will see arbitral institutions adopting{" "}
              <HyperLink onClick={() => window.open(`https://justact.co.in/`)}>
                online dispute resolution
              </HyperLink>{" "}
              for efficiency as well as saving time in view of the strict
              timelines covered under the Arbitration and Conciliation Act is a
              mandate now.
            </p>
          </Content>
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default OnlineDisputeBlogEight;
