import { makeStyles, Paper, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import themes from "../../../assets/theme";
import Footer from "../LandingPage/Footer";
import { Header } from "../../internal/Header/Header";
import { H2 } from "./Styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "70%",
    textAlign: "center",
    padding: "10px",
    margin: "auto",
    marginTop: "20px",
    marginBottom: "20px",
    "@media(max-width:700px)": {
      width: "90%",
    },
  },
  heading: {
    color: COLORS.BTN_GREEN,
    fontSize: "18px",
    margin: "auto",
  },
  subheading: {
    color: COLORS.COLOR_DARK,
    fontSize: "14px",
    margin: "auto",
  },
  content: {
    fontFamily: themes.fonts.primaryFontSemiBold,
    fontSize: "14px",
  },
}));
function ManagementTeam(props) {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header>
        <H2 style={{ textAlign: "center", paddingTop: "30px" }}>
          Management Team
        </H2>
        <Paper className={classes.paper}>
          <ProfileBox>
            <ProfileImage>
              <ManagementImg
                src={require("../../../assets/images/Rahul Nanuhal.png")}
                alt="Director"
              />
            </ProfileImage>
            <ProfileText>
              <h2 className={classes.heading}>Rahul Nanuhal</h2>
              <h2 className={classes.subheading}>CEO</h2>
              <br />
              <Typography className={classes.content}>
                Rahul has about a decade of leadership experience while leading
                Healthcare, Aerospace, SaaS & IT businesses. He joined JustAct
                in 2024 and was earlier working as a COO at a healthcare
                conglomerate. Rahul has an MBA from IIPM and is an Electronics &
                Communication Engineer from Chitkara University.
              </Typography>
            </ProfileText>
          </ProfileBox>
        </Paper>

        <Paper className={classes.paper}>
          <ProfileBox>
            <ProfileImage>
              <ManagementImg
                src={require("../../../assets/images/Madhav pudipeddi.jpg")}
                alt="Director"
              />
            </ProfileImage>
            <ProfileText>
              <h2 className={classes.heading}>Madhav Pudipeddi</h2>
              <h2 className={classes.subheading}>Business Head</h2>
              <br />
              <Typography className={classes.content}>
                A Maritime Law graduate from NALSAR, Hyderabad, MSc in Subsea
                Engineering from University of Strathclyde and a Bachelors in
                Marine Engineering from BITS Pilani, Madhav has about two
                decades of experience in various industries ranging from
                Shipping, Oil & Gas, Chemicals & Petrochemicals, and Telecom
                before joining JustAct. He has been working with JustAct since
                2020 and has been a part of the founding team.
              </Typography>
            </ProfileText>
          </ProfileBox>
        </Paper>

        <Paper className={classes.paper}>
          <ProfileBox>
            <ProfileImage>
              <ManagementImg
                src={require("../../../assets/images/maheswari.png")}
                alt="Director"
              />
            </ProfileImage>
            <ProfileText>
              <h2 className={classes.heading}>Maheswari J</h2>
              <h2 className={classes.subheading}>
                Software Development Head & CISO
              </h2>
              <br />
              <Typography className={classes.content}>
                Maheswari holds a Master's in Computer Science from Anna
                University, Chennai, and brings over 6 years of experience in
                software application development. She excels in problem-solving
                and is dedicated to delivering high-quality solutions tailored
                to client needs. With a passion for continuous learning, she
                actively keeps up with the latest technologies and industry
                trends.
              </Typography>
            </ProfileText>
          </ProfileBox>
        </Paper>
        <Footer />
      </Header>
    </div>
  );
}

export default ManagementTeam;

const ManagementImg = styled.img`
  height: 200px;
  border-radius: 50%;
`;
const ProfileBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ProfileImage = styled.div`
  display: flex;
  height: 250px;
  width: 250px;
  justify-content: center;
  align-items: center;
`;
const ProfileText = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  padding: 0 20px 20px 20px;
  text-align: justify;
  align-items: flex-start;
`;
