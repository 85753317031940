import React, { useEffect } from "react";
import { Content, SubHeadWrap, BlogPage } from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { Helmet } from "react-helmet";

/* eslint-disable jsx-a11y/anchor-is-valid */

function OnlineDisputeBlogTwelve(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>
          Perkins Eastman Judgement: Impact on the Appointment of a Sole
          Arbitrator - JustAct
        </title>
        <meta
          name="description"
          content="JustAct explains about the Impact on the Appointment of a Sole Arbitrator in Perkins Eastman Judgement."
        />
        <meta
          name="keywords"
          content="Perkins Eastman Legal Case, Perkins Eastman Judgement, Impact on the Appointment of a Sole Arbitrator, Online Arbitration, Arbitration Dispute Resolution, Negotiation Mediation and Arbitration, Dispute Resolution, JustAct"
        />
      </Helmet>
      <Header>
        <BlogPage>
          <SubHeadWrap>
            Perkins Eastman Judgement: Impact on the Appointment of a Sole
            Arbitrator
          </SubHeadWrap>
          <br />
          <Content>
            <p>
              The judgement examined whether a case was made out for the
              exercise of power by the Court to make an appointment of an
              arbitrator, alongside the question of whether the Chairman and
              Managing Director were ineligible to nominate an arbitrator. Taken
              further, the Court expounded on whether a person having an
              interest in the dispute or in the outcome or decision thereof,
              must not only be ineligible to act as an arbitrator but also must
              not be eligible to appoint anyone else as an arbitrator.
            </p>
            <br />
            <p>
              The article will discuss the Perkins Eastman<sup>1</sup> judgement
              in context with other precedents on the matter of the appointment
              of a sole arbitrator.
            </p>
            <br />
            <p>
              It has been held in HRD Corporation vs GAIL<sup>1</sup> that when
              a person directly falls under Schedule VII, ineligibility goes to
              the root of the appointment as per prohibition under Section 12(5)
              read with Schedule VII and such person lacks inherent
              jurisdiction. In Bharat Broadband Network Limited vs United
              Telecoms Limited<sup>2</sup> held that a unilaterally appointed
              arbitrator is de jure ineligible to perform his functions and that
              his mandate is automatically terminated under Section 14(1)(a) of
              the Act. Further, any prior agreement to do away with this
              ineligibility would be wiped out by the non-obstante clause
              contained in Section 12(5), and the same can be cured only through
              an express waiver.
            </p>
            <br />
            <p>
              Similarly, in Yashovardhan Sinha and Ors. vs Satyatej Vyapaar Pvt.
              Ltd<sup>3</sup>. upholding the ratio in the preceding judgements
              cited supra held that there cannot unilateral appointment of a
              sole arbitrator by the Respondent as the same is illegal and
              defeats the very purpose of unbiased and impartial adjudication of
              the dispute between the parties. The guiding principle of
              arbitration is transparency, fairness, neutrality and independence
              in the selection process and hence, appointment of a sole
              arbitrator can either be with mutual consent of parties or by an
              order of the competent court.
            </p>
            <br />
            <p>
              In B.K. Consortium Engineers Private Limited vs Indian Institute
              of Management, Calcutta<sup>4</sup>, J Shekhar B. Saraf of the
              Calcutta High Court held that for arbitration to be seen as a
              viable dispute resolution mechanism and as an alternate recourse
              to litigation, the independence of arbitration process outside the
              purview of undue influence and favor needs to be ensured in both
              letter and spirit and in case of non-adherence to such principles,
              the courts must step in. The very basic essence of the principle
              laid down in the above-mentioned case laws is the natural justice
              principle of nemo judex in causa sua that is 'no one should be
              made a judge in his own case'. For arbitration decisions to be
              respected and accepted as decrees of the court, a similar level of
              integrity in the appointment of arbitrators must be ensured.
            </p>
            <br />
            <p>
              The same judge in Cholamandalam Investment and Finance Company
              Ltd. vs Amrapali Enterprises & Anr<sup>5</sup>. held that it is a
              settled principle of law that compliance with Section 12(5) read
              with Schedule VII is sine qua non for any arbitral reference to
              gain recognition and validity before the Courts. Also, the High
              Court discussed the passing of an award by a sole arbitrator and
              its status in the eyes of law. This award, within the fact matrix
              of the case, passed by a de jure ineligible arbitrator, the Court
              held, suffers from a permanent and indelible mark of bias and
              prejudice which cannot be washed away at any stage including the
              execution proceedings. In fact, as the arbitrator was de jure
              ineligible to perform his functions and therefore lacked inherent
              jurisdiction or competence to adjudicate the disputes in hand, the
              impugned award cannot be accorded the privileged status of an
              award. The Court maintained that an arbitral award passed by a
              unilaterally appointed arbitrator will not survive the Section 34
              challenge.
            </p>
            <br />
            <SubHeadWrap>
              Provisions of the Arbitration & Conciliation Act, 1996 under
              discussion
            </SubHeadWrap>
            <br />
            <p>
              Sections 11 and 12 of the Arbitration & Conciliation Act, 1996
              wherein the power to appoint an arbitrator was discussed by the
              Court within the understanding of independence, impartiality,
              neutrality and validity of the arbitral proceedings.
            </p>
            <br />
            <SubHeadWrap>Section 11</SubHeadWrap>
            <SubHeadWrap>
              11. Appointment of arbitrators.<sup>6</sup> —
            </SubHeadWrap>
            <br />
            <div style={{ fontStyle: "italic" }}>
              <p>
                (1) A person of any nationality may be an arbitrator, unless
                otherwise agreed by the parties.
              </p>
              <br />
              <p>
                (2) Subject to sub-section (6), the parties are free to agree on
                a procedure for appointing the arbitrator or arbitrators.
              </p>
              <br />
              <p>
                (3) Failing any agreement referred to in sub-section (2), in an
                arbitration with three arbitrators, each party shall appoint one
                arbitrator, and the two appointed arbitrators shall appoint the
                third arbitrator who shall act as the presiding arbitrator.
              </p>
              <br />
              <p>
                (4) If the appointment procedure in sub-section (3) applies and—
              </p>
              <br />
              <p>
                (a) a party fails to appoint an arbitrator within thirty days
                from the receipt of a request to do so from the other party; or
              </p>
              <br />
              <p>
                (b) the two appointed arbitrators fail to agree on the third
                arbitrator within thirty days from the date of their
                appointment,
              </p>
              <br />
              <p>
                the appointment shall be made, upon request of a party, by{" "}
                <sup>1</sup>[the Supreme Court or, as the case may be, the High
                Court or any person or institution designated by such Court];
              </p>
              <br />
              <p>
                <b>
                  (5) Failing any agreement referred to in sub-section (2), in
                  an arbitration with a sole arbitrator, if the parties fail to
                  agree on the arbitrator within thirty days from receipt of a
                  request by one party from the other party to so agree the
                  appointment shall be made, upon request of a party, by{" "}
                  <sup>1</sup>[the Supreme Court or, as the case may be, the
                  High Court or any person or institution designated by such
                  Court].
                </b>
              </p>
              <br />
              <p>
                (6) Where, under an appointment procedure agreed upon by the
                parties,—
              </p>
              <br />
              <p>
                (a) a party fails to act as required under that procedure; or
              </p>
              <br />
              <p>
                (b) the parties, or the two appointed arbitrators, fail to reach
                an agreement expected of them under that procedure; or
              </p>
              <br />
              <p>
                (c) a person, including an institution, fails to perform any
                function entrusted to him or it under that procedure,
              </p>
              <br />
              <p>
                a party may request <sup>1</sup>[the Supreme Court or, as the
                case may be, the High Court or any person or institution
                designated by such Court] to take the necessary measure, unless
                the agreement on the appointment procedure provides other means
                for securing the appointment.
              </p>
              <br />
              <p>
                <sup>2</sup>[(6A) The Supreme Court or, as the case may be, the
                High Court, while considering any application under sub-section
                (4) or sub-section (5) or sub-section (6), shall,
                notwithstanding any judgement, decree or order of any Court,
                confine to the examination of the existence of an arbitration
                agreement.
              </p>
              <br />
              <p>
                (6B) The designation of any person or institution by the Supreme
                Court or, as the case may be, the High Court, for the purposes
                of this section shall not be regarded as a delegation of
                judicial power by the Supreme Court or the High Court.]
              </p>
              <br />
              <p>
                (7) A decision on a matter entrusted by sub-section (4) or
                sub-section (5) or sub-section (6) to <sup>3</sup>[the Supreme
                Court or, as the case may be, the High Court or the person or
                institution designated by such Court is final and no appeal
                including Letters Patent Appeal shall lie against such
                decision].
              </p>
              <br />
              <p>
                <sup>4</sup>(8) The Supreme Court or, as the case may be, the
                High Court or the person or institution designated by such
                Court, before appointing an arbitrator, shall seek a disclosure
                in writing from the prospective arbitrator in terms of
                sub-section (1) of section 12, and have due regard to—
              </p>
              <br />
              <p>
                (a) any qualifications required for the arbitrator by the
                agreement of the parties; and
              </p>
              <br />
              <p>
                (b) the contents of the disclosure and other considerations as
                are likely to secure the appointment of an independent and
                impartial arbitrator.]
              </p>
              <br />
              <p>
                (9) In the case of appointment of sole or third arbitrator in an
                international commercial arbitration, <sup>5</sup>[the Supreme
                Court or the person or institution designated by that Court] may
                appoint an arbitrator of a nationality other than the
                nationalities of the parties where the parties belong to
                different nationalities.
              </p>
              <br />
              <p>
                <sup>6</sup>[(10) The Supreme Court or, as the case may be, the
                High Court, may make such scheme as the said Court may deem
                appropriate for dealing with matters entrusted by sub-section
                (4) or sub-section (5) or sub-section (6), to it.]
              </p>
              <br />
              <p>
                (11) Where more than one request has been made under sub-section
                (4) or sub-section (5) or sub-section (6) to the Chief Justices
                of different High Courts or their designates, <sup>7</sup>
                [different High Courts or their designates, the High Court or
                its designate to whom the request has been first made] under the
                relevant sub-section shall alone be competent to decide on the
                request.
              </p>
              <br />
              <p>
                <sup>8</sup>[(12) (a) Where the matters referred to in
                sub-sections (4), (5), (6), (7), (8) and sub-section (10) arise
                in an international commercial arbitration, the reference to the
                "Supreme Court or, as the case may be, the High Court" in those
                sub-sections shall be construed as a reference to the "Supreme
                Court"; and
              </p>
              <br />
              <p>
                (b) Where the matters referred to in sub-sections (4), (5), (6),
                (7), (8) and sub-section (10) arise in any other arbitration,
                the reference to the Supreme Court or, as the case may be, the
                High Court in those sub-sections shall be construed as a
                reference to the "High Court" within whose local limits the
                principal Civil Court referred to in clause (e) of sub-section
                (1) of section 2 is situate, and where the High Court itself is
                the Court referred to in that clause, to that High Court.]
              </p>
              <br />
              <p>
                [(13) An application made under this section for appointment of
                an arbitrator or arbitrators shall be disposed of by the Supreme
                Court or the High Court or the person or institution designated
                by such Court, as the case may be, as expeditiously as possible
                and an endeavour shall be made to dispose of the matter within a
                period of sixty days from the date of service of notice on the
                opposite party.
              </p>
              <br />
              <p>
                (14) For the purpose of determination of the fees of the
                arbitral tribunal and the manner of its payment to the arbitral
                tribunal, the High Court may frame such rules as may be
                necessary, after taking into consideration the rates specified
                in the Fourth Schedule.
              </p>
              <br />
              <p>
                Explanation.— For the removal of doubts, it is hereby clarified
                that this sub-section shall not apply to international
                commercial arbitration and in arbitrations (other than
                international commercial arbitration) in case where parties have
                agreed for determination of fees as per the rules of an arbitral
                institution.]
              </p>
              <br />
            </div>
            <SubHeadWrap>Section 12</SubHeadWrap>
            <SubHeadWrap>
              12. Grounds for challenge<sup>7</sup>.—
            </SubHeadWrap>
            <br />
            <div style={{ fontStyle: "italic" }}>
              <p>
                [(1) When a person is approached in connection with his possible
                appointment as an arbitrator, he shall disclose in writing any
                circumstances,—
              </p>
              <br />
              <p>
                <b>
                  (a) such as the existence either direct or indirect, of any
                  past or present relationship with or interest in any of the
                  parties or in relation to the subject-matter in dispute,
                  whether financial, business, professional or other kind, which
                  is likely to give rise to justifiable doubts as to his
                  independence or impartiality; and
                </b>
              </p>
              <br />
              <p>
                (b) which are likely to affect his ability to devote sufficient
                time to the arbitration and in particular his ability to
                complete the entire arbitration within a period of twelve
                months.
              </p>
              <br />
              <p>
                Explanation 1.—The grounds stated in the Fifth Schedule shall
                guide in determining whether circumstances exist which give rise
                to justifiable doubts as to the independence or impartiality of
                an arbitrator.
              </p>
              <br />
              <p>
                Explanation 2.—The disclosure shall be made by such person in
                the form specified in the Sixth Schedule.]
              </p>
              <br />
              <p>(3) An arbitrator may be challenged only if—</p>
              <br />
              <p>
                (a) circumstances exist that give rise to justifiable doubts as
                to his independence or impartiality, or
              </p>
              <br />
              <p>
                (b) he does not possess the qualifications agreed to by the
                parties.
              </p>
              <br />
              <p>
                (4) A party may challenge an arbitrator appointed by him, or in
                whose appointment he has participated, only for reasons of which
                he becomes aware after the appointment has been made.
              </p>
              <br />
              <p>
                <sup>2</sup>[(5) Notwithstanding any prior agreement to the
                contrary, any person whose relationship, with the parties or
                counsel or the subject-matter of the dispute, falls under any of
                the categories specified in the Seventh Schedule shall be
                ineligible to be appointed as an arbitrator:
              </p>
              <br />
              <p style={{ marginBottom: "18px" }}>
                Provided that parties may, subsequent to disputes having arisen
                between them, waive the applicability of this sub-section by an
                express agreement in writing.]
              </p>
              <br />
            </div>
            <p>The judgement discussed the following issues in some detail:</p>
            <br />
            <b style={{ marginLeft: "20px", marginBottom: "18px" }}>
              1) Whether the Chairman and Managing Director was ineligible to
              nominate an arbitrator i.e. whether a person having an interest in
              the dispute or in the outcome or decision thereof, must not only
              be ineligible to act as an arbitrator but also must not be
              eligible to appoint anyone else as an arbitrator.
            </b>
            <br />
            <p>
              The Court observed that there were two categories of cases under
              consideration:
            </p>
            <br />
            <ul style={{ marginLeft: "40px" }}>
              <li>
                Where the Managing Director himself is named as an arbitrator
                with an additional power to appoint any other person as an
                arbitrator
              </li>
              <li>
                Where the Managing Director is not to act as an arbitrator
                himself but is empowered or authorised to appoint any other
                person of his choice or discretion as an arbitrator
              </li>
            </ul>
            <br />
            <p>
              The Court in regard to the first question, relying on TRF
              <sup>8</sup> among others, held that once the arbitrator has
              become ineligible by operation of law, he cannot nominate another
              as an arbitrator and that the arbitrator becomes ineligible as per
              prescription contained in S. 12 (5). The ineligibility referred to
              was a result of operation of law, in that a person having an
              interest in the dispute or in the outcome or decision thereof,
              must not only be ineligible to act as an arbitrator but must also
              not be eligible to appoint anyone else as an arbitrator.
            </p>
            <br />
            <p>
              If the interest that a person has in the outcome of the dispute is
              taken to be the basis of the possibility of bias, the Court held
              that it would always be present irrespective of whether the matter
              stands under the first or second category of cases. (Paras 18-24)
            </p>
            <br />
            <p>
              Under Sections 11 and 12 of the A & C Act, The Court held that by
              offering both parties an equal chance of nominating arbitrators of
              their choice, whatever advantage a party may derive by nominating
              an arbitrator of its choice would be offset by the opposite party
              asserting its right to choose. Conversely, where only one party
              has the right to appoint a sole arbitrator, there is an element of
              exclusivity in determining the course for dispute resolution.
            </p>
            <br />
            <p>
              Thus, the Court ruled that the person who has an interest in the
              outcome or decision of the dispute must not have the power to
              appoint a sole arbitrator. (Para 21)
            </p>
            <br />
            <p style={{ marginBottom: "18px" }}>
              The Court also underlined the importance of the independence and
              impartiality of the arbitrator and held that if there are
              justifiable doubts as to the independence and impartiality of the
              person nominated, and if other circumstances warrant appointment
              of an independent arbitrator by ignoring the procedure prescribed,
              then such an appointment could be made by the Court. (Para 23)
            </p>
            <br />
            <b style={{ marginLeft: "20px", marginBottom: "18px" }}>
              2) Whether power can be exercised by the Supreme Court under
              Section 11 of the Act when the appointment of an arbitrator has
              already been made by the Respondent?
            </b>
            <br />
            <p style={{ marginBottom: "18px" }}>
              The Court further expounded on Section 11(6) and discussed that
              the jurisdiction could be exercised even in cases where the
              appointment of an arbitrator has already been made. Unless this
              appointment is ex facie valid and such appointment satisfies the
              Court exercising jurisdiction under S. 11(6), the acceptance of
              such appointment as a fait accompli to debar the jurisdiction
              under S. 11(6) cannot be countenanced in law. (Para 26)
            </p>
            <br />
            <b style={{ marginLeft: "20px", marginBottom: "18px" }}>
              3) Whether the arbitration in the present case would be an
              international commercial arbitration i.e. whether the Supreme
              Court could exercise power to deal with the application under
              Section 11(6) read with Section 11 (12) (a) of the A&C Act, 1996.
            </b>
            <br />
            <p>
              During the proceedings, the Respondent did not dispute that it was
              a requisite condition to declare a lead member of the Consortium
              and that by aforesaid declaration Applicant 1 was shown to be the
              lead member of the Consortium. However, Clause 9 of the agreement
              conferred joint and several liability on both applicants for the
              execution of the project. The Court held that even if the
              liability of both applicants was stated to be joint and several,
              that by itself would not change the status of Applicant 1 as the
              lead member. (Para 11)
            </p>
            <br />
            <p>
              Section 2(1)(f)(iii) refers to two different sets of persons: an
              ‘association’ as distinct and separate from a ‘body of
              individuals’; an association referred to in S. 2(1)(f)(iii) would
              include a consortium consisting of two or more bodies corporate,
              at least one of whom is a body corporate incorporated in a country
              other than India. In the facts of the present case, the Court held
              that the lead member of the Consortium i.e. Applicant 1 being an
              architectural firm having its registered office in New York, the
              requirements of S. 2 (1)(f) were satisfied and thus, the
              arbitration would be an “international commercial arbitration”.
              (Paras 12-13)
            </p>
            <br />
            <Block />
            <br />
            <p>
              <sup>1</sup>(2018) 12 SCC 471
            </p>
            <p>
              <sup>2</sup>(2019) 6 S.C.R. 97
            </p>
            <p>
              <sup>3</sup>2022(3) CHN (CAL) 305
            </p>
            <p>
              <sup>4</sup> (2023 SCC OnLine Cal 124)
            </p>
            <p>
              <sup>5</sup> EC 122 of 2022
            </p>

            <p>
              <sup>6</sup>
              <a
                style={{
                  color: "blue",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open(
                    " https://www.indiacode.nic.in/show-data?actid=AC_CEN_3_46_00004_199626_1517807323919&orderno=11"
                  )
                }
              >
                {
                  " https://www.indiacode.nic.in/show-data?actid=AC_CEN_3_46_00004_199626_1517807323919&orderno=11"
                }
              </a>
            </p>
            <p>
              <sup>7</sup>
              <a
                style={{
                  color: "blue",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open(
                    " https://www.indiacode.nic.in/show-data?actid=AC_CEN_3_46_00004_199626_1517807323919&orderno=13"
                  )
                }
              >
                {
                  " https://www.indiacode.nic.in/show-data?actid=AC_CEN_3_46_00004_199626_1517807323919&orderno=13"
                }
              </a>
            </p>
            <p>
              <sup>8</sup>TRF Limited vs Energo Engineering Projects Limited,
              (2017) 8 SCC 377 where the Apex Court Apex Court expanded the
              approach in HRD Corporation (supra) and held that an individual
              who himself is ineligible under the provisions of the Act to be
              appointed as an arbitrator, cannot nominate a sole arbitrator.
            </p>
            <p></p>
          </Content>
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default OnlineDisputeBlogTwelve;

export const ContentExample = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
`;

export const Block = styled.div`
  width: 353px;
  height: 0;
  border: 1px solid ${COLORS.INPUT_BLACK};
  margin: 3px;
  display: inline-block;
  font-family: ${theme.fonts.primaryFontRegular};
`;
