import React, { useEffect, useState } from "react";
import CaseService from "../../../services/CaseService";
import { getErrorMessage } from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles, FormLabel, Popper } from "@material-ui/core";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { CustomInputField } from "../../common/FormInputs";

const useStyles = makeStyles((theme) => ({
  listbox: {
    color: "#293461",
    fontFamily: "openSans-SemiBold, sans-serif",
    fontSize: "16px",
    backgroundColor: "#ffffff",
    border: "2px solid #8B008B",
  },
  noOptions: {
    fontFamily: "openSans-SemiBold, sans-serif",
    color: "#8294a5",
  },
  popper: {
    zIndex: theme.zIndex.tooltip, // Ensure the popper is above other content
    border: "1px solid blue",
  },
}));

const CustomPopper = (props) => {
  return <Popper {...props} style={{ width: "auto", height: "5px" }} />;
};

const DocChangeType = ({ rowData, MTRef, documentTypes }) => {
  const { enqueueSnackbar } = useSnackbar();
  let docId = rowData?.id;
  let caseId = rowData?.entityId;
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState(null);

  const changeDocType = async (category, value) => {
    try {
      const payload = {
        uploadType: value,
        category: category,
      };
      const res = await CaseService.changeDocType(payload, caseId, docId);
      if (res) {
        enqueueSnackbar("Document Type Changed Successfully", {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
    }
  };

  const fetchInitialValue = () => {
    // Simulate fetching initial value (e.g., from DB)
    return { category: rowData?.url?.category, name: rowData?.url?.uploadType };
  };

  useEffect(() => {
    // Set initial value
    const initialValue = fetchInitialValue();
    setSelectedValue(initialValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData?.url]);

  return (
    <Container className="input-select">
      <Autocomplete
        id="size-small-outlined"
        size="small"
        classes={{ noOptions: classes.noOptions }}
        value={selectedValue}
        onChange={(event, newValue) => {
          setSelectedValue(newValue);
          changeDocType(newValue?.category, newValue?.name);
        }}
        options={Object.entries(documentTypes).flatMap(([category, names]) =>
          names.map((name) => ({ category, name })),
        )}
        groupBy={(option) => option.category}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <CustomInputField
              style={{ width: "150px" }}
              {...params}
              variant="outlined"
            />
          </div>
        )}
        popupIcon
        disableClearable
        renderGroup={(params) => (
          <StyledContainer>
            <div className="doc_row">
              <div>
                <FormLabel className="category">{params.group}</FormLabel>
                <div className="name">{params.children}</div>
              </div>
            </div>
          </StyledContainer>
        )}
        noOptionsText={
          <div variant="body2" color="textSecondary">
            No Document type found
          </div>
        }
        PopperComponent={CustomPopper}
      />
    </Container>
  );
};

export default DocChangeType;

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media ${theme?.breakpoints?.sm_up} {
    width: auto;
  }
`;

const StyledContainer = styled.div`
  & .doc_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3px 0px;
    & .category {
      font-size: 16px;
      flex: 1;
      margin-left: 18px;
      color: ${COLORS.INPUT_LABEL};
      font-family: ${theme.fonts.primaryFontSemiBold};
    }
    & .name {
      font-size: 16px;
      font-weight: 600;
      flex: 1;
      margin-left: 15px;
      color: ${COLORS.COLOR_DARK};
      font-family: ${theme.fonts.primaryFontSemiBold};
    }
  }
`;
