const getOptions = ({
  order_id = "",
  handler = () => {},
  name = "",
  email = "",
  contact = "",
}) => ({
  key: process.env.REACT_APP_RAZORPAY_KEY,
  name: "JustAct",
  order_id,
  handler,
  prefill: {
    name,
    email,
    contact,
  },
  save: 0,
  remember_customer: false,
  modal: {
    // removes the overflow : none from the body which is set by razorpay
    ondismiss: function () {
      document.body.style.overflow = null;
    },
  },
});

export default function useRazorPay() {
  function razorPay(options) {
    const rzp_options = getOptions({ ...options });
    var rzp_instance = new window.Razorpay(rzp_options);
    rzp_instance.open();
  }
  return razorPay;
}
