import React, { Fragment, useState, useEffect, useRef } from "react";
import ActionBar from "../../../common/ActionBar";
import {
  PageWrapper,
  StyledLabel,
  LifeCycleContainer,
  Manager,
  Spacing,
  Block,
} from "../styles";
import LifeCycle from "../../../common/LifeCycle/LifeCycle";
import CaseDetails from "../../../common/CaseDetails";
import CaseManager from "../common/CaseManager";
import BigBlueButton from "../common/BigBlueButton";
import DrawerTableCaseDocument from "../../../internal/DrawerTable";
import _ from "lodash";
import { navigate } from "@reach/router";
import CaseCTA from "../common/CaseCTA";
import AlertDialog from "../../../common/Alert";
import CaseService from "../../../../services/CaseService";
import {
  RESPONTANT_LIFE_CYCLE,
  CLAIMANT_LIFE_CYCLE,
  CLAIMENT_STR,
  CLAIMENT_PAID_NEGOTIATION,
  RESPONDENT_PAID_NEGOTIATION,
  RESPONDANT_ONBOARDED,
  NEGOTIATION_ONGOING,
  NEGOTIATION_REACHED,
  NEGOTIATION_QUIT_BY_CLAIMENT,
  NEGOTIATION_QUIT_BY_RESPONDENT,
  BOTH_PAID_NEGOTIATION,
  NEGOTIATION_QUIT_BY_CASEMANAGER,
  AWAITING_RESPONDANT_QUIT,
  LIFE_CYCLE,
} from "../../../../helpers/constants";
import { getErrorMessage } from "../../../../helpers/functions";
import { useSnackbar } from "notistack";
import useLoader from "../../../../hooks/useLoader";
import NegotiationState from "./NegotiationState";
import useFreshChat from "../../../../hooks/useFreshChat";
import useUser from "../../../../hooks/useUser";
import {
  callAcceptCliam,
  callSendOffer,
  callQuitCase,
} from "../negotiation-helper";
import useCartCount from "../../../../hooks/useCartCount";
import useAlert from "../../../../hooks/useAlert";
import theme from "../../../../assets/theme";
import MediationState from "./MediationState";
import queryString from "query-string";
import useNotification from "../../../../hooks/useNotification";
import { CaseManagerCardData } from "../../../pages/NegotiationPages/styles";
import { PrimaryCTAButton } from "../../../common/Buttons";
import ExtraFieldsDrawer from "../../../internal/DrawerTable/ExtraFieldsDrawer";

function negotiationTypeState({ ...props }) {
  switch (props?.caseDetails?.resolutionKind) {
    case "mediation":
      return <MediationState {...props} />;
    case "arbitration":
      return <MediationState {...props} />;
    case "negotiation":
      return <NegotiationState {...props} />;
    default:
      return null;
  }
}

export default function AwaitingRespondantPage({ id }) {
  const initialState = ["My Cases"];
  const [breadcrumbs, setBreadcrumbs] = useState(initialState); // This state is to display the bread crum
  const [state, setState] = useState(); // This state is used for caseDocument
  const [open, setOpen] = useState(false); //This state is for open and Close the model
  const [dialogData, setDialogData] = useState({}); // This state is used to set the dialog content
  const [comments, setComments] = useState([]); // This is list of comments
  const [caseDetails, setCaseDetails] = useState({}); // This state holds the case detaisl
  const [negotiationRounds, setNegotiationRounds] = useState({}); // THis state holds the NegotiationRounds
  const [currentState, setCurrentState] = useState({}); // This state Holds the current state
  const [isRespondent, setIsRespondent] = useState(false); // This state tells current logged in user is respondent or not
  const [triggerCaseCall, setTriggerCaseCall] = useState(true); // This State to trigger the get details call when any update happend in the case
  const [buttonToDisplay, setButtonToDisplay] = useState([]); //This state used to hold the button list based on case life cycle and user Action
  const [showCta, setShowCta] = useState(false); // This state used to show and hide the button based on the user action
  const [partyList, setPartyList] = useState([]);
  const [type, setType] = useState();

  const { enqueueSnackbar } = useSnackbar(); // This Hook is used to trigger the snackbar message
  const { setLoader } = useLoader(); // This Hook is to display the loader
  const { userObject } = useUser(); // This Hook holds the Current User Object
  const { setChatDetail, handlePopup, setTag } = useFreshChat({
    userObject,
  });
  const formikRef = useRef([]); // This reference holds the formik values
  const { setTriggerCount } = useCartCount(); // This context to trigger the cart count when negotiation moves to payment.
  const MTRef = useRef();
  const { hideAlert, showAlert } = useAlert();
  const { triggerNotify, setTriggerNotify } = useNotification(); // hooks to refresh the page
  const [statusCheck, setStatusCheck] = useState();
  const resolutionKindData = caseDetails?.resolutionKind;
  const [docUploadModal, setDocUploadModal] = useState();
  const [fields, setFields] = useState(false);

  useEffect(() => {
    if (triggerNotify) {
      setTriggerCaseCall(true);
      setTriggerNotify(false);
    }
  }, [setTriggerNotify, triggerNotify]);

  /**This useEffect is Used to get the case information */
  useEffect(() => {
    /** Function to get the CaseDetails */
    async function getCaseById(id) {
      setLoader({ state: true, message: "Fetching case details..." });
      try {
        const res = await CaseService.getCase(id);
        const negotiation_res = await CaseService.negotiations(id);
        if (res && negotiation_res) {
          let params = {
            page: 1,
            perPage: 1000,
          };
          if (res?.resolutionKind !== "negotiation") {
            let stringParams = "";
            if (!_.isEmpty(params)) {
              stringParams = `?${queryString.stringify(params)}`;
            }
            const mediation_res = await CaseService.mediations(
              id,
              stringParams,
            ); // This is for getting the party
            if (mediation_res) {
              setComments(mediation_res?.data);
            }
          }

          const partyParam = `?${queryString.stringify(
            params,
          )}&sort=partyRole&sortDirection=asc`;
          const party_list_res = await CaseService.getPartyListByCaseId(
            id,
            partyParam,
          ); // This is for getting the party details based on the case
          if (party_list_res?.data) {
            setPartyList(party_list_res?.data);
          }

          let case_response = { ...res };
          if (!case_response?.respondentPartyId) {
            case_response.status = res?.status;
          } else if (
            case_response?.respondentStatus === "pending" ||
            case_response?.respondentStatus === "declined"
          ) {
            case_response.status = RESPONDANT_ONBOARDED;
          } else if (
            case_response?.status === NEGOTIATION_REACHED &&
            case_response?.subscriptionKind === "adhoc"
          ) {
            case_response.status = BOTH_PAID_NEGOTIATION;
          }
          setNegotiationRounds(negotiation_res);
          setCaseDetails(case_response);
          setBreadcrumbs([
            "My Cases",
            _.startCase(case_response?.resolutionKind),
            case_response?.title,
          ]);
          setIsRespondent(case_response.agentRole !== CLAIMENT_STR);
          if (case_response.resolutionKind !== "negotiation") {
            setInitialValues(
              JSON.parse(JSON.stringify(LIFE_CYCLE)),
              case_response,
              false,
              negotiation_res,
            );
          } else {
            if (case_response.agentRole === CLAIMENT_STR) {
              setInitialValues(
                JSON.parse(JSON.stringify(CLAIMANT_LIFE_CYCLE)),
                case_response,
                false,
                negotiation_res,
              );
            } else {
              setInitialValues(
                JSON.parse(JSON.stringify(RESPONTANT_LIFE_CYCLE)),
                case_response,
                true,
                negotiation_res,
              );
            }
          }
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
        navigate("/dashboard/cases");
      } finally {
        setLoader({ state: false });
        window.scrollTo(0, 0);
      }
    }

    /** Function to get the chatDetails */
    async function getChatDetails(id) {
      try {
        const chatDetails = await CaseService.getChatDetails(id);
        if (!_.isEmpty(chatDetails?.chat)) {
          // setIsInitialised(true);
          setChatDetail(chatDetails?.chat);
          setTag(["ind_neg_case"]);
        }
      } catch (error) {
        // setIsInitialised(true);
        setTag(["ind_neg_case"]);
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    }

    if (id && triggerCaseCall) {
      getCaseById(id);
      setTriggerCaseCall(false);
      getChatDetails(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerCaseCall]);

  useEffect(() => {
    (async function () {
      try {
        const response = await CaseService.getTranscriptionType(id);
        if (response) {
          setType(response);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    })(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function enableLiveTranscription(id, resolutionKind) {
      try {
        if (resolutionKind !== "negotiation") {
          const transcriptionControl =
            await CaseService.enableLiveTranscription(id);
          setStatusCheck(transcriptionControl);
        } else {
        }
        // return;
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    }
    if (id && resolutionKindData) {
      enableLiveTranscription(id, resolutionKindData);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, resolutionKindData]);

  /** This function is used to set the inital values for the component**/
  const setInitialValues = (
    caesLifeCycle,
    caseDetails,
    isRespondent,
    negotiationRounds,
  ) => {
    if (caesLifeCycle.length && !_.isEmpty(caseDetails)) {
      if (
        negotiationRounds?.length > 0 &&
        !isRespondent &&
        caseDetails.status === NEGOTIATION_ONGOING &&
        !isRepondentReply(negotiationRounds) &&
        caseDetails?.resolutionKind === "negotiation"
      ) {
        setCurrentState(caesLifeCycle.find((el) => el.index === 2));
      } else if (caesLifeCycle.some((el) => el.key === caseDetails.status)) {
        const caseState = caesLifeCycle.find(
          (el) => el.key === caseDetails.status,
        );
        setCurrentState(caseState);
        checkSetShowBtn(
          negotiationRounds,
          caseState,
          isRespondent,
          caseDetails,
        );
        displayCaseCTA(caseState, negotiationRounds, caseDetails);
        if (
          caseDetails?.status === "negotiationOngoing" &&
          _.max(_.map(negotiationRounds, "negotiationRound")) !==
            caseDetails?.negotiationRoundLimit
        ) {
          if (isRespondent)
            triggerNegotiateAction(caseState, negotiationRounds, caseDetails);
          else {
            let maxRound = negotiationRounds[0];
            for (const round of negotiationRounds) {
              if (round?.negotiationRound > maxRound?.negotiationRound) {
                maxRound = { ...round };
              }
            }
            const noActionRequiredCurrently = maxRound?.respondentClaims?.every(
              (value) => _.isEmpty(value?.offer),
            );
            if (!noActionRequiredCurrently) {
              triggerMoveTonextRound(caseState, negotiationRounds, caseDetails);
            }
          }
        }
      } else if (!isRespondent) {
        if (caseDetails.respondentId) {
          setCurrentState(caesLifeCycle.find((el) => el.index === 2));
        } else {
          if (caseDetails.status === "finalAward") {
            setCurrentState({
              label: "Final Award",
              key: "finalAward",
              index: 5,
              enableSelection: false,
              cta: [],
            });
          } else {
            setCurrentState(caesLifeCycle.find((el) => el.index === 1));
          }
        }
      } else if (caseDetails.status === "finalAward") {
        setCurrentState({
          label: "Final Award",
          key: "finalAward",
          index: 5,
          enableSelection: false,
          cta: [],
        });
      }
    }
  };

  /**This function is to check whether the respondent has ans back or nor for moving the status to negotiaion ongoing */
  const isRepondentReply = (negotiation) => {
    return negotiation[0]?.respondentClaims?.some(
      (el) => el?.offer?.status === "offered",
    );
  };

  /** This function is used to display the CTA based on the status */
  const displayCaseCTA = (currentState, negotiationRounds, caseDetails) => {
    if (!_.isEmpty(currentState)) {
      if (caseDetails?.resolutionKind !== "negotiation") {
        if (caseDetails.status === NEGOTIATION_ONGOING) {
          setButtonToDisplay(
            currentState?.cta?.filter((el) => el.type === "QUIT"),
          );
        } else {
          setButtonToDisplay(currentState?.cta?.filter((el) => el.displayBtn));
        }
      } else {
        if (
          caseDetails.status === NEGOTIATION_ONGOING &&
          negotiationRounds?.length === caseDetails?.negotiationRoundLimit
        ) {
          setButtonToDisplay(
            currentState?.cta?.filter(
              (el) => el.type === "QUIT" || el.type === "ACCEPT",
            ),
          );
        } else {
          setButtonToDisplay(currentState?.cta?.filter((el) => el.displayBtn));
        }
      }
    }
  };

  /** THis function is used to check if the round is reached miximum limit or not */
  const checkSetShowBtn = (
    negotiationRounds,
    currentState,
    isRespondent,
    caseDetails,
  ) => {
    if (!_.isEmpty(negotiationRounds)) {
      const index =
        Object.keys(negotiationRounds)[
          Object.keys(negotiationRounds).length - 1
        ];
      const isRespondentAns = negotiationRounds[index]?.respondentClaims?.some(
        (el) =>
          el?.offer?.status === "offered" &&
          el?.offer?.offerorId === caseDetails?.respondentPartyId,
      );
      setShowCta(
        (!isRespondentAns && isRespondent) ||
          (!isRespondent && isRespondentAns) ||
          currentState?.index > 3 ||
          caseDetails?.resolutionKind !== "negotiation",
      );
    }
  };

  /**THis function is used for Navigating the case details page */
  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case _.snakeCase(caseDetails?.resolutionKind):
        navigate(`/dashboard/cases?caseType=${caseDetails?.resolutionKind}`);
        break;
      case "my_cases":
        navigate(`/dashboard/cases?caseType=${caseDetails?.resolutionKind}`);
        break;
      default:
        break;
    }
  };

  /**
   * @description Function Execute when Make Offer Button is clickec
   */
  const triggerMakeOffer = async (
    rounds = negotiationRounds,
    caseDetails = {},
  ) => {
    setLoader({ state: true, message: "Loading..." });
    const copytNegotiationRound = [...rounds];
    let index = Object.keys(copytNegotiationRound)[
      Object.keys(copytNegotiationRound).length - 1
    ];
    copytNegotiationRound[index].respondentClaims = JSON.parse(
      JSON.stringify(copytNegotiationRound[index].claimantClaims),
    );
    await Promise.all(
      copytNegotiationRound[index].respondentClaims.map(
        async (el = {}, idex) => {
          if (!el?.offer) {
            el.offer = {};
          }
          if (el.acceptedOffer) {
            el.offer = el.acceptedOffer;
          } else if (el?.offer?.status === "offered") {
            const data = await getSuggestionRespondent(
              copytNegotiationRound[index]?.negotiationRound,
              copytNegotiationRound[index]?.claimantClaims[idex]?.offer
                ?.claimValue,
              copytNegotiationRound[index - 1]?.respondentClaims[idex]?.offer
                ?.claimValue,
              caseDetails,
            );
            el.offer.claimValue = "";
            el.offer.suggestedClaimValue = data;
          }
          el.offer.offerorId = caseDetails?.respondentPartyId;
          el.offer.statusAlais = "makeOffer";
          el.offer.reason = "";
          el.previousOffer =
            copytNegotiationRound[index].claimantClaims[
              idex
            ]?.offer?.claimValue;
        },
      ),
    );

    setNegotiationRounds(copytNegotiationRound);
    setLoader({ state: false });
  };

  /**
   * @description Function execute when Accept Claim button is clicked
   */
  const triggerAcceptToClaim = () => {
    setLoader({ state: true, message: "Updating case details..." });
    callAcceptCliam(id, negotiationRounds, isRespondent).then(
      () => {
        setLoader({ state: false });
        setTriggerCaseCall(true);
        setTriggerCount(true);
      },
      (err) => {
        setLoader({ state: false });
      },
    );
  };

  async function getSuggestionRespondent(
    currentRound,
    currentClaimantValue,
    currentRespondentValue,
    caseDetailsObj,
  ) {
    const data = {
      totalRounds: caseDetailsObj?.negotiationRoundLimit?.toString(),
      currentRound: currentRound?.toString(),
      currentClaimantValue: currentClaimantValue?.toString() || "0",
      currentRespondentValue: currentRespondentValue?.toString() || "0",
      prevClaimantValue: "0",
    };
    return await fetch(
      `${process.env.REACT_APP_BASE_SUGGESTION_API}/respondent`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(data),
      },
    )
      .then(async (response) => {
        const data = await response.json();
        return data?.respondentSuggestion?.nextRespondentValue > 0
          ? data?.respondentSuggestion?.nextRespondentValue
          : 0;
      })
      .catch((err) => {
        enqueueSnackbar("Failed to get the suggestion value", {
          variant: "error",
        });
        return null;
      });
  }

  async function getSuggestionClaimant(
    currentRound,
    currentClaimantValue,
    currentRespondentValue,
    caseDetails,
  ) {
    const data = {
      totalRounds: caseDetails?.negotiationRoundLimit.toString(),
      currentRound: currentRound?.toString(),
      currentClaimantValue: currentClaimantValue?.toString(),
      currentRespondentValue: currentRespondentValue?.toString(),
      prevRespondentValue: "0",
    };
    return await fetch(
      `${process.env.REACT_APP_BASE_SUGGESTION_API}/claimant`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(data),
      },
    )
      .then(async (response) => {
        const data = await response.json();
        return data?.claimantSuggestion?.nextClaimantValue > 0
          ? data?.claimantSuggestion?.nextClaimantValue
          : 0;
      })
      .catch((err) => {
        enqueueSnackbar("Failed to get the suggestion value", {
          variant: "error",
        });
        return null;
      });
  }

  /**
   * @description Function execute when move to next round is clicked
   */
  const triggerMoveTonextRound = async (
    caseState,
    negotiationRounds,
    caseDetails,
  ) => {
    setLoader({ state: true, message: "Loading..." });
    const copyCurrentState = { ...caseState };
    const copytNegotiationRound = [...negotiationRounds];
    copyCurrentState.enableSelection = true;
    const buttonTodisplay = copyCurrentState?.cta.filter(
      (el) => el.type === "SUBMIT_OFFER" || el.type === "QUIT",
    );
    let index = Object.keys(copytNegotiationRound).length;
    copytNegotiationRound[index] = {};
    copytNegotiationRound[index].claimantClaims = JSON.parse(
      JSON.stringify(copytNegotiationRound[index - 1].respondentClaims),
    );
    copytNegotiationRound[index].negotiationRound = index + 1;
    await Promise.all(
      copytNegotiationRound[index].claimantClaims.map(async (el, idx) => {
        if (el.acceptedOffer) {
          el.offer = el.acceptedOffer;
        } else if (el?.offer?.status === "offered") {
          const value = await getSuggestionClaimant(
            copytNegotiationRound[index].negotiationRound,
            copytNegotiationRound[index - 1].claimantClaims[idx]?.offer
              ?.claimValue,
            copytNegotiationRound[index - 1].respondentClaims[idx]?.offer
              ?.claimValue,
            caseDetails,
          );
          el.offer.claimValue = "";
          el.offer.suggestedClaimValue = value;
        }
        el.offer.offerorId = caseDetails?.claimantPartyId;
        el.offer.reason = "";
        el.offer.statusAlais = "makeOffer";
        el.previousOffer =
          copytNegotiationRound[index - 1].respondentClaims[
            idx
          ]?.offer?.claimValue;
      }),
    );
    setButtonToDisplay(buttonTodisplay);
    setNegotiationRounds(copytNegotiationRound);
    setCurrentState(copyCurrentState);
    setLoader({ state: false });
  };

  /**
   * @description Function execute when negotiation button is clicked
   */
  const triggerNegotiateAction = (
    caseState = currentState,
    rounds,
    caseDetailsObj = {},
  ) => {
    const isRespondentAns = rounds[rounds.length - 1]?.respondentClaims?.some(
      (el) =>
        el?.offer?.status === "offered" &&
        el?.offer?.offerorId === caseDetailsObj?.respondentPartyId,
    );
    if (!isRespondentAns) {
      const copyCurrentState = { ...caseState };
      copyCurrentState.enableSelection = true;
      setCurrentState(copyCurrentState);
      triggerMakeOffer(rounds, caseDetailsObj);
    }
  };

  /**
   * @description Function to check the given offer is valid or not
   */

  const checkZeroVal = () => {
    let isValid = true;
    const { values } = formikRef?.current[formikRef?.current?.length - 1];
    for (let i = 0; i < values?.items?.length; i++) {
      if (
        _.isEmpty(values?.items[i].acceptedOffer) &&
        values?.items[i]?.offer?.status !== "accepted"
      ) {
        if (
          !values?.items[i]?.offer?.claimValue ||
          (values?.items[i]?.offer?.claimValue &&
            Number(values?.items[i]?.offer?.claimValue) < 0)
        ) {
          isValid = false;
        }
      }
    }
    return isValid;
  };

  const checkValid = () => {
    let isValid = true;
    let messageObj = {
      greaterOriginalClaim: false,
      claimantlesserClaimValue: false,
      claimantgreaterClaimValue: false,
      respondentlesserClaimValue: false,
      respondentgreaterClaimValue: false,
    };
    const roundLength = negotiationRounds?.length;
    const previousRound =
      roundLength > 1
        ? isRespondent
          ? negotiationRounds[roundLength - 2].respondentClaims
          : negotiationRounds[roundLength - 2].claimantClaims
        : {};
    const { values } = formikRef?.current[formikRef?.current?.length - 1];
    for (let i = 0; i < values?.items?.length; i++) {
      if (
        _.isEmpty(values?.items[i].acceptedOffer) &&
        values?.items[i]?.offer?.status !== "accepted"
      ) {
        if (!values?.items[i]?.offer?.claimValue?.length) {
          isValid = false;
          messageObj.nullValue = true;
        } else if (
          /**Condition for exceeds original Calim Value */
          Number(values?.items[i]?.offer?.claimValue) >
          Number(caseDetails?.caseItems[i]?.claimValue)
        ) {
          isValid = false;
          messageObj.greaterOriginalClaim = true;
        } else if (
          /**Condition for Claimant who cannot enter lesser value than respondent value */
          !isRespondent &&
          Number(values?.items[i]?.offer?.claimValue) <
            Number(values?.items[i]?.previousOffer)
        ) {
          isValid = false;
          messageObj.claimantlesserClaimValue = true;
        } else if (
          /**Condition for claimant who cannot enter grater value than previous Round Claim value */
          !isRespondent &&
          roundLength > 1 &&
          Number(values?.items[i]?.offer?.claimValue) >
            Number(previousRound[i]?.offer?.claimValue)
        ) {
          isValid = false;
          messageObj.claimantgreaterClaimValue = true;
        } else if (
          /** Consition for respondent who cannot enter greater value than claimant value */
          isRespondent &&
          Number(values?.items[i]?.offer?.claimValue) >
            Number(values?.items[i]?.previousOffer)
        ) {
          isValid = false;
          messageObj.respondentgreaterClaimValue = true;
        } else if (
          /** Condition for respondent who cannot enter lesser value than previous repondent value */
          isRespondent &&
          roundLength > 1 &&
          Number(values?.items[i]?.offer?.claimValue) <
            Number(previousRound[i]?.offer?.claimValue)
        ) {
          isValid = false;
          messageObj.respondentlesserClaimValue = true;
        }
      }
    }
    showErrorMsg(messageObj);
    return isValid;
  };

  function showErrorMsg({
    greaterOriginalClaim,
    claimantlesserClaimValue,
    claimantgreaterClaimValue,
    respondentlesserClaimValue,
    respondentgreaterClaimValue,
    nullValue,
  }) {
    if (nullValue) {
      enqueueSnackbar("Response cannot be null", {
        variant: "error",
      });
    } else if (greaterOriginalClaim) {
      enqueueSnackbar("Offer cannot be greater than the original claim value", {
        variant: "error",
      });
    } else if (claimantlesserClaimValue) {
      enqueueSnackbar("Claim value cannot be less than counter offer", {
        variant: "error",
      });
    } else if (claimantgreaterClaimValue) {
      enqueueSnackbar("Claim value cannot be higher than your previous offer", {
        variant: "error",
      });
    } else if (respondentlesserClaimValue) {
      enqueueSnackbar("Claim value cannot be less than your previous offer", {
        variant: "error",
      });
    } else if (respondentgreaterClaimValue) {
      enqueueSnackbar("Claim value cannot be higher than the counter offer", {
        variant: "error",
      });
    }
  }

  const callSubmitOffer = () => {
    setLoader({ state: true, message: "Updating case details..." });
    callSendOffer(id, formikRef)
      .then(
        async () => {
          setLoader({ state: false });
          setTriggerCaseCall(true);
          enqueueSnackbar("Response Sent Successfully", {
            variant: "success",
          });
        },
        (err) => {
          setLoader({ state: false });
          enqueueSnackbar(getErrorMessage(err), {
            variant: "error",
          });
        },
      )
      .finally(() => {
        hideAlert();
      });
  };

  const sameOfferPresent = () => {
    let isSame = false;
    const { values } = formikRef?.current[formikRef?.current?.length - 1];
    for (let i = 0; i < values?.items?.length; i++) {
      if (
        _.isEmpty(values?.items[i].acceptedOffer) &&
        values?.items[i]?.offer?.status !== "accepted"
      ) {
        if (
          Number(values?.items[i]?.offer?.claimValue) ===
          Number(values?.items[i]?.previousOffer)
        ) {
          isSame = true;
        }
      }
    }
    return isSame;
  };

  /**
   * @description Function excute when Submit offer or submit counteroffer is clicked
   */
  const triggerSubmitOffer = () => {
    if (checkZeroVal()) {
      if (checkValid()) {
        if (sameOfferPresent()) {
          setDialogData({
            primaryBtnText: "Proceed",
            secondaryBtnText: "Cancel",
            desc: "You are about to accept one or more of the offers made by the other Party. Would you like to go ahead and accept the offer(s)?",
            heading: "Warning",
            buttonType: "SUBMIT_OFFER",
            descriptionTextStyle: {
              textAlign: "left",
            },
          });
          setOpen(true);
        } else {
          showAlert({
            heading: "Please confirm!",
            desc: `Would you like us to go ahead and submit your offer? Offer once submitted cannot be changed.`,
            //desc: `Are you really sure you want to submit your offer? Offer once submitted cannot be changed.`,
            primaryBtnText: "Yes",
            secondaryBtnText: "No",
            clickSecondarybtn: () => hideAlert(),
            clickPrimaryBtn: () => callSubmitOffer(),
            isOpen: true,
            descriptionTextStyle: {
              textAlign: "left",
            },
            onDialogClose: () => hideAlert(),
          });
        }
      }
    } else {
      enqueueSnackbar("Claim value cannot be empty", {
        variant: "error",
      });
    }
  };

  /**
   * @description Function to trigger the Quit case Details
   */
  const triggerQuitCase = () => {
    setLoader({ state: true, message: "Updating case details..." });
    callQuitCase(id).then(
      (_) => {
        setLoader({ state: false });
        setTriggerCaseCall(true);
      },
      (err) => {
        setLoader({ state: false });
      },
    );
  };

  const showPaymentAlert = async (type) => {
    try {
      setLoader({ state: true, message: "loading..." });
      const negotiationSuccessFee =
        (await CaseService.getCase(id, "?estimateFeeKind=successFee"))?.fee ||
        {};
      setDialogData({
        primaryBtnText: "Go to Case Cart",
        secondaryBtnText: "I'll Pay Later",
        desc: (
          <>
            To download your agreement for{" "}
            <b style={{ fontFamily: `${theme.fonts.primaryFontExtraBold}` }}>
              Case ID {id}
            </b>{" "}
            please go to case cart and complete the payment towards{" "}
            {caseDetails?.resolutionKind} Agreement fee:{" "}
            <b style={{ fontFamily: `${theme.fonts.primaryFontExtraBold}` }}>
              {negotiationSuccessFee?.unit}{" "}
              {parseFloat(negotiationSuccessFee?.total).toFixed(2)}
            </b>
          </>
        ),
        heading: "Case moved to Payment",
        buttonType: type,
      });
      setOpen(true);
    } catch (err) {
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  };

  /**THis case is used to decide what will happend when the button is trigggered */
  const onbuttonClick = (type) => {
    switch (type) {
      case "QUIT": {
        setDialogData({
          primaryBtnText: "Cancel",
          secondaryBtnText: "Quit",
          desc: `Please note once you quit, you cannot continue your ${caseDetails?.resolutionKind}.`,
          heading: "Do you really want to Quit your case?",
          buttonType: type,
          status: true,
          descriptionTextStyle: {
            textAlign: "left",
          },
        });
        setOpen(true);
        break;
      }
      case "MOVE_TO_CART": {
        showPaymentAlert(type);
        break;
      }
      case "ACCEPT": {
        triggerAcceptToClaim();
        break;
      }

      case "NEGOTIATE": {
        if (isRespondent) {
          triggerNegotiateAction();
        } else {
          triggerMoveTonextRound();
        }
        break;
      }

      case "MAKE_OFFER": {
        triggerMakeOffer();
        break;
      }

      case "SUBMIT_OFFER": {
        triggerSubmitOffer();
        break;
      }

      case "MOVE_TO_AGREEMENT": {
        const currentStatus = isRespondent
          ? RESPONTANT_LIFE_CYCLE.find(
              (el) => el.key === CLAIMENT_PAID_NEGOTIATION,
            )
          : CLAIMANT_LIFE_CYCLE.find(
              (el) => el.key === RESPONDENT_PAID_NEGOTIATION,
            );
        setCurrentState(currentStatus);
        displayCaseCTA(currentStatus, negotiationRounds, caseDetails);
        break;
      }

      default: {
        break;
      }
    }
  };

  /**Function to trigger the success model */
  const clickPrimaryBtn = (type) => {
    setOpen(false);
    switch (type) {
      case "MOVE_TO_CART": {
        setTriggerCount(true);
        navigate("/dashboard/cart");
        break;
      }
      case "SUBMIT_OFFER": {
        callSubmitOffer();
        break;
      }

      default: {
        break;
      }
    }
  };

  const clickSecondarybtn = (type, data) => {
    setOpen(false);
    switch (type) {
      case "QUIT": {
        triggerQuitCase();
        break;
      }
      default: {
        break;
      }
    }
  };
  return (
    <Fragment>
      <ActionBar
        breadcrumbs={breadcrumbs}
        onBreadcrumbClick={onBreadcrumbClick}
      />
      <PageWrapper state={state}>
        <LifeCycleContainer>
          <StyledLabel>Case Lifecycle</StyledLabel>
          {!_.isEmpty(caseDetails) && (
            <LifeCycle
              items={
                caseDetails?.resolutionKind !== "negotiation"
                  ? LIFE_CYCLE
                  : isRespondent
                  ? RESPONTANT_LIFE_CYCLE
                  : CLAIMANT_LIFE_CYCLE
              }
              selectedIndex={currentState?.index}
              isMediation={caseDetails?.resolutionKind !== "negotiation"}
              resolutionKind={caseDetails?.resolutionKind}
              kind={isRespondent}
              status={currentState?.text}
              currentStateKey={currentState?.key}
            />
          )}
        </LifeCycleContainer>
        <Spacing>
          <CaseDetails
            isActionsDisabled={
              currentState?.key === NEGOTIATION_REACHED ||
              currentState?.key === NEGOTIATION_QUIT_BY_CLAIMENT ||
              currentState?.key === NEGOTIATION_QUIT_BY_RESPONDENT ||
              currentState?.key === NEGOTIATION_QUIT_BY_CASEMANAGER ||
              currentState?.key === CLAIMENT_PAID_NEGOTIATION ||
              currentState?.key === RESPONDENT_PAID_NEGOTIATION ||
              currentState?.key === BOTH_PAID_NEGOTIATION ||
              currentState?.key === AWAITING_RESPONDANT_QUIT ||
              currentState?.key === "finalAward"
            }
            isViewAction={
              currentState?.key === NEGOTIATION_QUIT_BY_CLAIMENT ||
              currentState?.key === NEGOTIATION_QUIT_BY_RESPONDENT ||
              currentState?.key === NEGOTIATION_QUIT_BY_CASEMANAGER
            }
            {...{
              setState,
              id,
              MTRef,
              partyList,
              docUploadModal,
              setDocUploadModal,
              setFields,
              setTriggerCaseCall,
            }}
            caseDetails={caseDetails}
          />
        </Spacing>
        <Manager>
          {caseDetails?.caseManager?.name && (
            <Block>
              <CaseManager
                handlePopup={handlePopup}
                caseManager={caseDetails?.caseManager}
                hideChat={caseDetails?.resolutionKind !== "negotiation"}
              />
            </Block>
          )}

          {caseDetails?.resolutionKind !== "negotiation" &&
          caseDetails?.requestedMediators?.length ? (
            <>
              {caseDetails?.requestedMediators
                ?.filter((el) => el?.id)
                ?.map((mediator, index) => (
                  <Block key={index}>
                    <CaseManager
                      title={
                        caseDetails?.resolutionKind === "mediation"
                          ? "Mediator"
                          : "Arbitrator"
                      }
                      hideChat={true}
                      caseManager={mediator}
                    />
                  </Block>
                ))}
            </>
          ) : null}
          {caseDetails?.resolutionKind &&
            caseDetails?.resolutionKind !== "negotiation" && (
              <Block>
                <BigBlueButton
                  id={caseDetails?.id}
                  status={caseDetails?.status}
                  resolutionKind={caseDetails?.resolutionKind}
                  type={`Join ${_.capitalize(
                    caseDetails?.resolutionKind,
                  )} Session`}
                />
              </Block>
            )}
          {statusCheck?.enableTranscription === "yes"
            ? caseDetails?.resolutionKind &&
              caseDetails?.resolutionKind !== "negotiation" && (
                <Block>
                  <CaseManagerCardData>
                    <PrimaryCTAButton
                      // style={{ width: "258px" }}
                      onClick={() =>
                        navigate(
                          type?.crossExamination
                            ? `/dashboard/cases/${id}/cross-examination?caseType=${caseDetails?.resolutionKind}`
                            : `/dashboard/cases/${id}/livetranscription?caseType=${caseDetails?.resolutionKind}`,
                          {
                            state: type?.crossExamination ? type : caseDetails,
                          },
                        )
                      }
                    >
                      {type.crossExamination
                        ? "Cross Examination"
                        : "Live Transcription"}
                    </PrimaryCTAButton>
                  </CaseManagerCardData>
                </Block>
              )
            : ""}
          {/* {statusCheck?.enableTranscription === "yes" ? (
            <div className="mr25 mt28">
              {caseDetails?.resolutionKind !== "negotiation" && (
                <CaseManagerCardData>
                  <PrimaryCTAButton
                    style={{ width: "258px" }}
                    onClick={() =>
                      navigate(
                        `/dashboard/cases/${id}/cross-examination?caseType=${caseDetails?.resolutionKind}`,
                        { state: caseDetails }
                      )
                    }
                  >
                    {"Cross Examination"}
                  </PrimaryCTAButton>
                </CaseManagerCardData>
              )}
            </div>
          ) : (
            ""
          )} */}
        </Manager>

        {negotiationTypeState({
          isLocalTranscriptionServer: type?.isLocalTranscriptionServer,
          ownerId: caseDetails?.claimantPartyId,
          respondentId: caseDetails?.respondentPartyId,
          currentState,
          negotiationRounds,
          isRespondent,
          formikRef,
          caseDetails,
          showCta,
          comments,
          setComments,
          partyList,
        })}
        <Spacing>
          {showCta && (
            <CaseCTA
              cta={buttonToDisplay}
              disableButton={false}
              onbuttonClick={(type) => onbuttonClick(type)}
            />
          )}
        </Spacing>

        {/* This is for Modal */}
        <div>
          <AlertDialog
            isOpen={open}
            clickSecondarybtn={() =>
              clickSecondarybtn(dialogData.buttonType, false)
            }
            primaryBtnText={dialogData.primaryBtnText}
            status={dialogData.status}
            secondaryBtnText={dialogData.secondaryBtnText}
            desc={dialogData.desc}
            heading={dialogData.heading}
            clickPrimaryBtn={() => clickPrimaryBtn(dialogData.buttonType, true)}
            onDialogClose={() => setOpen(false)}
            descriptionTextStyle={{
              textAlign: "center",
            }}
          />
        </div>
        <div id="Freshchat-js-sdk" />
      </PageWrapper>
      <DrawerTableCaseDocument
        {...{
          state,
          setState,
          id,
          MTRef,
          caseDetails,
        }}
        isActionsDisabled={
          currentState?.key === NEGOTIATION_REACHED ||
          currentState?.key === NEGOTIATION_QUIT_BY_CLAIMENT ||
          currentState?.key === NEGOTIATION_QUIT_BY_RESPONDENT ||
          currentState?.key === NEGOTIATION_QUIT_BY_CASEMANAGER ||
          currentState?.key === CLAIMENT_PAID_NEGOTIATION ||
          currentState?.key === RESPONDENT_PAID_NEGOTIATION ||
          currentState?.key === BOTH_PAID_NEGOTIATION ||
          currentState?.key === AWAITING_RESPONDANT_QUIT ||
          currentState?.key === "finalAward"
        }
        type={caseDetails?.resolutionKind}
        agentRole={caseDetails?.agentRole}
        agreementsAndAwards={caseDetails?.agreementsAndAwards}
        setTriggerCaseCall={setTriggerCaseCall}
      />
      <ExtraFieldsDrawer {...{ fields, setFields, caseDetails, id }} />
    </Fragment>
  );
}

// export const ReasonForQuit = styled.div`
//   font-family: ${theme.fonts.primaryFontBold};
//   font-size: 16px;
//   color: ${COLORS.LOGOUT_RED};
// `;

// export const ReasonForQuitText = styled.p`
//   font-family: ${theme.fonts.primaryFontBold};
//   font-size: 16px;
//   color: ${COLORS.COLOR_DARK};
//   margin-left: 4px;
// `;

// export const QuitContainer = styled.div`
//   padding: 20px 10px;
//   border-radius: 7px;
//   background-color: ${COLORS.LIGHT_BLUE};
//   border: solid 1px ${COLORS.COLOR_DARK};
//   margin-bottom: 12px;
//   max-width: 100%;
//   & #flex {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//   }
//   @media ${theme?.breakpoints?.sm_up} {
//     padding: 18px;
//     max-width: 80%;
//   }
// `;

// export const QuitLabel = styled.div`
//   color: ${COLORS.LOGOUT_RED};
//   font-family: ${theme.fonts.primaryFontSemiBold};
//   font-size: 14px;
//   @media ${theme?.breakpoints?.sm_up} {
//     font-size: 16px;
//   }
// `;

// export const QuitContent = styled.div`
//   font-size: 12px;
//   word-break: break-word;
//   color: ${COLORS.COLOR_DARK};
//   font-family: ${theme.fonts.primaryFontSemiBold};
//   margin-top: 18px;
//   & strong,
//   & bold {
//     font-family: ${theme.fonts.primaryFontExtraBold};
//   }
//   @media ${theme?.breakpoints?.sm_up} {
//     font-size: 14px;
//   }
// `;
