import React, { useState, useEffect } from "react";
import { FieldArray } from "formik";
import { SecondaryCTAButton } from "../../../common/Buttons";
import FormField from "../../../common/FormField/FormField";
import { DeleteIcon, H2, Row, StyledFormElementsWrapper } from "../Styles";
import CaseService from "../../../../services/CaseService";

const mediatorInitialValue = {
  mediatorName: "",
  mediatorEmail: "",
  mediatorMobile: "",
  mediatorPostalAddress: "",
  mediatorDocuments: "",
};

const MediatorInputs = ({
  index,
  values,
  errors,
  touched,
  setFieldValue,
  setFieldError,
  remove,
  caseObj,
  menuItems,
  subscriptionKind,
}) => {
  const adhocMediatorInputs = [
    {
      type: "autoSelect",
      name: "mediatorName",
      label: `${
        caseObj?.resolutionKind === "mediation" ? "Mediator" : "Arbitrator"
      } Name`,
      required: subscriptionKind === "adhoc" ? true : false,
      index: index,
    },
    {
      type: "email",
      name: "mediatorEmail",
      label: "Email",
      required: subscriptionKind === "adhoc" ? true : false,
      index: index,
    },
    {
      type: "text",
      name: "mediatorMobile",
      label: "Phone Number",
    },
    {
      type: "text",
      name: "mediatorPostalAddress",
      label: "Postal Address",
    },
    {
      type: "fileUpload",
      name: "mediatorDocuments",
      label: "Arbitrator related documents (Reason for suggesting arbitrator)",
      index: index,
      uploadName: "requestedMediators",
      required: errors?.mediatorDocuments ? true : false,
    },
  ];

  return (
    <div key={index}>
      <StyledFormElementsWrapper style={{ marginTop: 40, marginBottom: 10 }}>
        <Row
          style={{
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <H2>
            Enter{" "}
            {caseObj?.resolutionKind === "mediation"
              ? "Mediator"
              : "Arbitrator"}{" "}
            Details {caseObj?.requestedMediators?.length > 1 ? index + 1 : ""}
          </H2>
          <>
            {caseObj?.requestedMediators?.length > 1 ? (
              <DeleteIcon
                onClick={() => remove(index)}
                src={require("../../../../assets/images/removeIcon.svg")}
                alt="delete Icon"
              />
            ) : null}
          </>
        </Row>
      </StyledFormElementsWrapper>
      {adhocMediatorInputs.map((input, key) => (
        <StyledFormElementsWrapper style={{ marginBottom: 0 }} key={key}>
          <FormField
            white
            errors={errors || {}}
            touched={touched || {}}
            {...{
              input,
              values,
              setFieldValue,
              setFieldError,
            }}
            menuItems={menuItems}
            handleChange={(e) => {
              if (input.name === "mediatorMobile") {
                if (e?.target?.value?.match(/^[0-9]*$/)) {
                  setFieldValue(
                    `requestedMediators[${index}].${input?.name}`,
                    e?.target?.value
                  );
                }
              } else
                setFieldValue(
                  `requestedMediators[${index}].${input?.name}`,
                  e?.target?.value
                );
            }}
          />
        </StyledFormElementsWrapper>
      ))}
    </div>
  );
};

const AdditionalMediator = ({
  errors,
  touched,
  setFieldValue,
  setFieldError,
  values,
  handleBlur,
}) => {
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    (async function listRegisteredNeutrals() {
      try {
        const response = await CaseService.listRegisteredNeutrals();
        if (response) {
          setMenuItems(response.neutrals);
        }
      } catch (err) {
        throw err;
      }
    })();
  }, []);

  return (
    <>
      {values?.resolutionKind === "arbitration" && (
        <FieldArray
          name="requestedMediators"
          render={({ push, remove }) => (
            <div style={{ marginBottom: 25 }}>
              {values?.requestedMediators?.map((mediator, index) => (
                <MediatorInputs
                  values={mediator}
                  caseObj={values}
                  errors={
                    errors && errors?.requestedMediators
                      ? errors?.requestedMediators[index]
                      : {}
                  }
                  touched={
                    touched && touched?.requestedMediators
                      ? touched?.requestedMediators[index]
                      : {}
                  }
                  {...{
                    index,
                    setFieldValue,
                    setFieldError,
                    remove,
                    handleBlur,
                  }}
                  menuItems={menuItems}
                  subscriptionKind={values?.subscriptionKind}
                />
              ))}
              {values?.resolutionKind === "arbitration" ? (
                <StyledFormElementsWrapper style={{ marginTop: 15 }}>
                  <SecondaryCTAButton
                    onClick={() => {
                      push({ ...mediatorInitialValue });
                    }}
                    style={{
                      marginTop: "24px",
                      margin: "unset",
                    }}
                  >
                    + Add Another{" "}
                    {values?.resolutionKind === "mediation"
                      ? "Mediator"
                      : "Arbitrator"}
                  </SecondaryCTAButton>
                </StyledFormElementsWrapper>
              ) : null}
            </div>
          )}
        />
      )}
    </>
  );
};

export default AdditionalMediator;
