import React, { useState, useEffect, useRef, createRef } from "react";
import {
  Container,
  Heading,
  Subheading,
  FormContainer,
  BackArrow,
  TableWrapper,
  HyperLink,
  ButtonWrapper,
  DeleteIcon,
  TableContainer,
  MonetaryText,
  Row,
  FormControl,
  Seperator,
  OuterContainer,
  BigTable,
  SmallTable,
  Details,
  LabelValueContainer,
  Label,
  Value,
  Delete,
} from "./Styles";
import ActionBar from "../../common/ActionBar";
import { navigate } from "@reach/router";
import _ from "lodash";
import { Formik } from "formik";
import {
  PrimaryOutlinedCTAButton,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../../common/Buttons";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { CustomInputField } from "../../common/FormInputs";
import CaseService from "../../../services/CaseService";
import { CreateCaseStep3Schema } from "../../../helpers/validationSchema";
import AWSService from "../../../services/AWSService";
import { useSnackbar } from "notistack";
import {
  formatNumberToMetrics,
  getErrorMessage,
} from "../../../helpers/functions";
import DescriptionModal from "./components/DescriptionModal";
import useLoader from "../../../hooks/useLoader";
import { Tooltip } from "@material-ui/core";
import styled from "styled-components";
import useCartCount from "../../../hooks/useCartCount";
import {
  MAX_ROUND_TO_END,
  MAX_ROUND_TO_START,
} from "../../../helpers/constants";
import NumberFormat from "react-number-format";
import useAlert from "../../../hooks/useAlert";
import AlertDialog from "../../common/Alert";
import CustomSelect from "../../common/CustomSelect/CustomSelect";

const useStyles = makeStyles({
  table: {
    backgroundColor: "transparent",
    borderRadius: 7,
    "& .MuiTableCell-root": {
      borderBottom: 0,
    },
  },
  tableHead: {
    fontFamily: theme.fonts.primaryFontSemiBold,
    color: COLORS.INPUT_LABEL,
    fontSize: 10,
    paddingBottom: 0,
  },
  row: {
    borderBottom: `1px solid ${COLORS.INPUT_BORDER}`,
  },
  radioGroup: {
    "& .MuiRadio-colorPrimary.Mui-checked": {
      color: COLORS.BTN_GREEN,
    },
  },
  formLabel: {
    fontFamily: theme.fonts.primaryFontRegular,
    color: COLORS.COLOR_DARK,
    marginRight: 5,
    // minWidth: 98,
  },
  radioButton: {
    color: COLORS.BTN_GREEN,
  },
});

const Index = ({ caseId }) => {
  const breadcrumbs = ["My Cases", "Create New Case"];
  const classes = useStyles();
  const [caseObj, setCaseObj] = useState({});
  const fileRef = useRef([]);
  const formikRef = useRef();
  const [length, setLength] = useState(1);
  const { setTriggerCount } = useCartCount();
  const [maximumRounds, setMaximumRounds] = useState([]);
  const { hideAlert, showAlert } = useAlert();
  const [dialogData, setDialogData] = useState(false);
  const [open, setOpen] = useState(false);
  const initialState = {
    title: "",
    claimValue: null,
    unit: "INR",
    description: "",
    description_document_url: "",
  };
  const [modal, setModal] = useState({
    state: false,
  });
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();

  useEffect(() => {
    window.scroll(0, 0);
    console.log(maximumRounds);
    const tempArray = [];
    for (let index = MAX_ROUND_TO_START; index <= MAX_ROUND_TO_END; index++) {
      let tempObj = {};
      tempObj = { label: index, value: index };
      tempArray.push(tempObj);
    }
    setMaximumRounds(tempArray); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function getCase(caseId) {
      setLoader({ state: true });
      try {
        const response = await CaseService.getCase(caseId);
        if (response) {
          setCaseObj(response);
          if (response.caseItems.length > 0) {
            setLength(response.caseItems.length);
          }
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }

    if (caseId) {
      getCase(caseId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseId]);

  useEffect(() => {
    if (fileRef.current.length !== length) {
      // add or remove refs
      fileRef.current = Array(length)
        .fill()
        .map((_, i) => fileRef.current[i] || createRef());
    }
  }, [fileRef.current.length, length]);

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "my_cases":
        navigate("/dashboard/cases");
        break;
      default:
        break;
    }
  };

  const addClaimItems = (values, setFieldValue) => {
    const currencyUnit =
      values?.claim_items?.length > 1
        ? values?.claim_items[0].unit
        : values?.claim_items[0].unit;
    const new_claim_items = [
      ...values?.claim_items,
      {
        title: "",
        claimValue: "",
        caseId,
        unit: currencyUnit,
        description: "",
        description_document_url: "",
        index: values?.claim_items?.length || 0,
      },
    ];
    setLength(new_claim_items.length);
    setFieldValue("claim_items", new_claim_items);
  };

  const removeClaimItem = (index, values, setFieldValue) => {
    const new_claim_items = [...values?.claim_items].filter(
      (ci, idx) => idx !== index
    );
    setLength(new_claim_items.length);
    setFieldValue("claim_items", new_claim_items);
  };

  async function s3Upload(file, field, setFieldValue, setFieldError) {
    if (file) {
      try {
        setLoader({ state: true, message: `Uploading file ${file.name} ...` });
        const payload = {
          key: file.name,
        };
        const s3Response = await AWSService.getS3URL(payload);
        if (s3Response.url) {
          const res = await AWSService.uploadToS3(s3Response.url, file);
          setFieldValue(field, res);
          enqueueSnackbar("Successfully Uploaded", { variant: "success" });
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, { variant: "error" });
      } finally {
        setLoader({ state: false });
      }
    }
  }

  async function addClaimItem(claim_item) {
    return new Promise(async (resolve, reject) => {
      try {
        claim_item.claimValue = Number(claim_item.claimValue);
        const response = await CaseService.createCaseItem(
          claim_item,
          caseId,
          caseObj.claimantParty.id
        );
        if (response) {
          resolve({ ...response.caseItem, index: claim_item?.index || 0 });
        }
        reject("Error");
      } catch (error) {
        reject(error);
      }
    });
  }

  async function updateClaimItem(claim_id, claim_item) {
    return new Promise(async (resolve, reject) => {
      try {
        claim_item.claimValue = Number(claim_item.claimValue);
        const response = await CaseService.updateCaseItem(
          caseId,
          claim_id,
          claim_item,
          caseObj.claimantParty.id
        );
        if (response) {
          resolve(response.caseItem);
        }
        reject("Error");
      } catch (error) {
        reject(error);
      }
    });
  }

  async function deleteClaimItem(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await CaseService.deleteCaseItem(
          caseId,
          id,
          caseObj.claimantParty.id
        );
        if (response) {
          resolve(response);
        }
        reject("Error");
      } catch (error) {
        reject(error);
      }
    });
  }

  async function submitCase(payload) {
    try {
      setLoader({ state: true, message: "Submitting form..." });
      const response = await CaseService.submitCase(
        payload,
        caseId,
        caseObj.claimantParty.id
      );
      if (response) {
        enqueueSnackbar(response.message, {
          variant: "success",
        });
        navigate("/dashboard/cart");
      }
    } catch (error) {
    } finally {
      setTriggerCount(true);
      setLoader({ state: false });
      hideAlert();
    }
  }

  async function getDeletedItems(orginalData, currentData) {
    const originalIDs = await _.map(orginalData, "id");
    const currentIDs = await _.map(currentData, "id");
    const deletedIDs = await _.difference(originalIDs, currentIDs);
    return deletedIDs;
  }

  async function onFormSubmit(rawValues, props, isDraft) {
    let values = JSON?.parse(JSON.stringify(rawValues));
    values.claim_items = rawValues?.claim_items?.map((ci) => ({
      ...ci,
      description_document_url: !_.isEmpty(ci.description_document_url)
        ? {
            url: ci.description_document_url || "",
            uploadeType: "",
          }
        : null,
      claimValue: ci?.claimValue?.toString()?.split(",")?.join(""),
    }));
    try {
      setLoader({ state: true, message: "Submitting form..." });
      const new_claim_items = values.claim_items.filter((ci) => !ci.id);
      const old_claim_items = values.claim_items.filter((ci) => ci.id);
      const deleted_claim_items = await getDeletedItems(
        caseObj.caseItems,
        old_claim_items
      );
      let count = 0;
      let finalClaimItems = values.claim_items.map((el, index) => {
        return { ...el, index };
      });
      if (deleted_claim_items) {
        count = -deleted_claim_items.length;
        for (const claim_item_id of deleted_claim_items) {
          const deleted_item = await deleteClaimItem(claim_item_id);
          if (deleted_item) {
            count += 1;
          }
        }
      }
      if (new_claim_items) {
        for (const claim_item of new_claim_items) {
          if (claim_item.title && claim_item.claimValue) {
            const addedItem = await addClaimItem(claim_item);
            if (addedItem.id) {
              const index = _.findIndex(
                finalClaimItems,
                (ci) => ci?.index === addedItem?.index
              );
              finalClaimItems[index] = addedItem;
              setCaseObj({ ...caseObj, caseItems: finalClaimItems });
              count += 1;
            }
          }
        }
      }
      if (old_claim_items) {
        for (const claim_item of old_claim_items) {
          const addedItem = await updateClaimItem(claim_item.id, claim_item);
          if (addedItem.id) {
            const index = _.findIndex(
              finalClaimItems,
              (ci) => ci?.id === addedItem?.id
            );
            finalClaimItems[index] = addedItem;
            count += 1;
          }
        }
      }

      /** Service call to update the Negotiation Limit */
      const details_response = await CaseService.updateDraft(
        values,
        caseId,
        caseObj.claimantParty?.id
      );

      if (count === values.claim_items.length) {
        if (props?.setFieldValue)
          props.setFieldValue("claim_items", finalClaimItems);
        const payload = _.pick(caseObj, [
          "respondentKind",
          "respondentName",
          "respondentPanNumber",
          "respondentEmail",
          "respondentMobile",
          "description",
          "description_document_url",
          "agreement_urls",
          "title",
        ]);
        if (!isDraft) {
          const registrationFee =
            (
              await CaseService.getCase(
                caseId,
                "?estimateFeeKind=registrationFee"
              )
            )?.fee || {};
          const negotiationSuccessFee =
            (await CaseService.getCase(caseId, "?estimateFeeKind=successFee"))
              ?.fee || {};
          showAlert({
            heading: "Payment Information",
            desc: (
              <DetailsAlertmodal
                {...{ negotiationSuccessFee, registrationFee, caseObj }}
              />
            ),

            primaryBtnText: "Add to Cart",
            secondaryBtnText: "Cancel",
            clickSecondarybtn: () => {
              hideAlert();
            },
            clickPrimaryBtn: () => submitCase(payload),
            isOpen: true,
            onDialogClose: () => {
              hideAlert();
            },
            descriptionTextStyle: {
              textAlign: "left",
            },
          });
        } else {
          enqueueSnackbar("Case added to draft successfully", {
            variant: "success",
          });
          navigate("/dashboard/cases?caseType=negotiation");
        }
      } else {
        if (details_response) {
          enqueueSnackbar("Case added to draft successfully", {
            variant: "success",
          });
        }
        navigate("/dashboard/cases?caseType=negotiation");
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setTriggerCount(true);
      setLoader({ state: false });
    }
  }

  function renderClaimValue(values) {
    try {
      const sum = _.sum(
        values?.claim_items
          ?.filter((f) => f.unit)
          ?.map(
            (f) => {
              return (
                f?.claimValue &&
                Number(f?.claimValue?.toString()?.split(",")?.join(""))
              );
            }
            //   {
            //   if (f?.claimValue)
            //     return Number(f?.claimValue?.toString()?.split(',')?.join(''))
            // }
          )
      );
      if (sum) {
        return formatNumberToMetrics(
          parseFloat(sum).toFixed(2).toString(),
          "INR"
        );
      } else return 0;
    } catch (error) {
      return 0;
    }
  }

  /**
   * @description Function to trigger the delete case
   * @param {*} param0
   */
  const deleteDraft = async (id) => {
    try {
      setLoader({ state: true, message: "Deleting Cases..." });
      const res = await CaseService.deleteDraftCase(id);
      if (res?.message) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
        if (caseObj?.resolutionKind === "mediation") {
          navigate("/dashboard/cases?caseType=mediation");
        } else {
          navigate("/dashboard/cases?caseType=negotiation");
        }
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setOpen(false);
    }
  };

  /**
   * @description Function to show the delete warning
   * @param {*} id
   */
  const deleteWaring = (id) => {
    setOpen(true);
    setDialogData({
      primaryBtnText: "Proceed",
      secondaryBtnText: "Cancel",
      clickPrimaryBtn: () => deleteDraft(id),
      clickSecondarybtn: () => setOpen(false),
      onDialogClose: () => setOpen(false),
      desc: `Are you sure you want to delete this draft case?`,
      heading: "Delete Case",
      descriptionTextStyle: {
        textAlign: "center",
      },
    });
  };

  return (
    <OuterContainer>
      <ActionBar {...{ breadcrumbs, onBreadcrumbClick }} />
      <Container className="CreateCase">
        <Heading>
          <div style={{ display: "flex", flex: 1 }}>
            <BackArrow
              onClick={() =>
                navigate(
                  `/dashboard/cases/create/${caseId}/step-2?caseType=${caseObj?.respondentKind}`
                )
              }
              src={require("../../../assets/images/backArrow.svg")}
            />
            Create New Case (3/ 3)
          </div>
          <div className="delete" onClick={() => deleteWaring(caseId)}>
            <img
              src={require("../../../assets/images/delete.svg")}
              alt="delete Icon"
            />
          </div>
        </Heading>
        <TooltipHeading
          tooltip="Claim Items for the case"
          style={{ marginBottom: "unset" }}
        >
          Enter Claim Items
        </TooltipHeading>
        <Formik
          innerRef={formikRef}
          initialValues={{
            negotiationRoundLimit:
              caseObj?.negotiationRoundLimit || MAX_ROUND_TO_START,
            claim_items: caseObj?.caseItems?.length
              ? _.map(caseObj?.caseItems, (ci) => ({
                  ...ci,
                  claimValue: parseFloat(ci.claimValue).toFixed(2),
                  unit: ci.unit || "INR", // Comment for always its going to INR
                  // unit: "INR",
                  description_document_url:
                    ci.description_document_url?.url || "",
                }))
              : [initialState] || [initialState],
          }}
          onSubmit={onFormSubmit}
          enableReinitialize
          validationSchema={CreateCaseStep3Schema}
          validateOnBlur
          validateOnChange
          validateOnMount
        >
          {({
            values,
            handleChange,
            errors,
            handleSubmit,
            touched,
            setFieldError,
            setFieldValue,
            handleBlur,
            isValid,
            setFieldTouched,
          }) => (
            <FormContainer className="claim-table" onSubmit={handleSubmit}>
              <TableContainer>
                <TableWrapper>
                  <BigTable>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              width: window.innerWidth <= 1441 ? 183 : 253,
                            }}
                            className={classes.tableHead}
                          >
                            Claim Item
                            <span className="required-star"> *</span>
                          </TableCell>
                          <TableCell
                            style={{ width: 150 }}
                            className={classes.tableHead}
                          >
                            Claim Value{" "}
                            <span className="required-star"> *</span>
                          </TableCell>
                          <TableCell
                            style={{ width: 180 }}
                            className={classes.tableHead}
                          >
                            Unit <span className="required-star"> *</span>
                          </TableCell>
                          <TableCell
                            style={{
                              width: window.innerWidth <= 1441 ? 250 : 350,
                            }}
                            className={classes.tableHead}
                          >
                            Supporting Document{" "}
                            <SuperTextSm>
                              (pdf, mp3, mp4, mov, docx or image)
                            </SuperTextSm>
                          </TableCell>
                          <TableCell
                            style={{ width: 218 }}
                            className={classes.tableHead}
                          >
                            Claim Description
                          </TableCell>
                          <TableCell className={classes.tableHead}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {values?.claim_items?.map((claim_item, index) => (
                          <TableRow
                            className={
                              index < values?.claim_items?.length - 1
                                ? classes.row
                                : "table-row"
                            }
                            key={index}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ position: "relative" }}
                            >
                              <CustomInputField
                                className={"input-white"}
                                value={claim_item.title}
                                name={`claim_items[${index}].title`}
                                onChange={handleChange}
                                style={{
                                  width: window.innerWidth <= 1441 ? 183 : 253,
                                }}
                                variant="outlined"
                                onBlur={handleBlur}
                                error={
                                  errors?.claim_items?.[index]?.title &&
                                  touched?.claim_items?.[index]?.title
                                }
                                helperText={
                                  errors?.claim_items?.[index]?.title &&
                                  touched?.claim_items?.[index]?.title
                                }
                              />
                              <HelperText>
                                {touched?.claim_items?.[index]?.title &&
                                  errors?.claim_items?.[index]?.title}
                              </HelperText>
                            </TableCell>
                            <TableCell
                              className="disabledArrows"
                              style={{ position: "relative" }}
                            >
                              <InputContainer>
                                <NumberFormat
                                  thousandSeparator
                                  thousandsGroupStyle="lakh"
                                  isNumericString={true}
                                  decimalScale={2}
                                  fixedDecimalScale
                                  allowNegative={false}
                                  className={"input-white"}
                                  displayType={"input"}
                                  value={claim_item?.claimValue}
                                  type={"text"}
                                  customInput={CustomInputField}
                                  name={`claim_items[${index}].claimValue`}
                                  onChange={handleChange}
                                  style={{ width: 140 }}
                                  variant="outlined"
                                  error={
                                    errors?.claim_items?.[index]?.claimValue &&
                                    touched?.claim_items?.[index]?.claimValue
                                  }
                                  onBlur={(e) => {
                                    setFieldTouched(
                                      `claim_items[${index}].claimValue`,
                                      true
                                    );
                                  }}
                                />
                              </InputContainer>
                              {touched?.claim_items?.[index]?.claimValue &&
                                errors?.claim_items?.[index]?.claimValue && (
                                  <HelperText style={{ whiteSpace: "nowrap" }}>
                                    {errors?.claim_items?.[index]?.claimValue}
                                  </HelperText>
                                )}
                            </TableCell>
                            <TableCell style={{ position: "relative" }}>
                              <CustomSelect
                                white={true}
                                menuItemValues={[
                                  {
                                    label: "INR",
                                    value: "INR",
                                    disabled:
                                      values?.claim_items?.length > 1
                                        ? values?.claim_items[0].unit === "INR"
                                          ? false
                                          : true
                                        : false,
                                  },
                                  {
                                    label: "SGD",
                                    value: "SGD",
                                    disabled:
                                      values?.claim_items?.length > 1
                                        ? values?.claim_items[0].unit === "SGD"
                                          ? false
                                          : true
                                        : false,
                                  },
                                  {
                                    label: "GBP",
                                    value: "GBP",
                                    disabled:
                                      values?.claim_items?.length > 1
                                        ? values?.claim_items[0].unit === "GBP"
                                          ? false
                                          : true
                                        : false,
                                  },
                                  {
                                    label: "USD",
                                    value: "USD",
                                    disabled:
                                      values?.claim_items?.length > 1
                                        ? values?.claim_items[0].unit === "USD"
                                          ? false
                                          : true
                                        : false,
                                  },
                                ]}
                                value={
                                  values?.claim_items?.length > 1
                                    ? values?.claim_items[0].unit
                                    : claim_item?.unit
                                }
                                name={`claim_items[${index}].unit`}
                                onBlur={(e) => {
                                  setFieldTouched(
                                    `claim_items[${index}].unit`,
                                    true
                                  );
                                }}
                                onChange={handleChange}
                              />
                            </TableCell>
                            <TableCell>
                              {!_.isEmpty(
                                claim_item.description_document_url
                              ) ? (
                                <Row style={{ marginTop: 0, width: 123 }}>
                                  <HyperLink
                                    onClick={() =>
                                      window.open(
                                        claim_item.description_document_url
                                      )
                                    }
                                  >
                                    View
                                  </HyperLink>
                                  <Seperator />
                                  <HyperLink
                                    onClick={() =>
                                      fileRef?.current[index]?.current?.click()
                                    }
                                  >
                                    Update
                                  </HyperLink>
                                </Row>
                              ) : (
                                <>
                                  <HyperLink
                                    onClick={() =>
                                      fileRef?.current[index]?.current?.click()
                                    }
                                  >
                                    Upload
                                  </HyperLink>
                                </>
                              )}
                              <input
                                ref={fileRef.current[index]}
                                onChange={(e) =>
                                  s3Upload(
                                    e?.target?.files[0],
                                    `claim_items[${index}].description_document_url`,
                                    setFieldValue,
                                    setFieldError
                                  )
                                }
                                type="file"
                                accept=".pdf, .mp3, .mp4, .docx, .mov, image/*"
                                style={{ display: "none" }}
                              />
                            </TableCell>
                            <TableCell>
                              {!claim_item.description ? (
                                <HyperLink
                                  onClick={() =>
                                    setModal({
                                      state: true,
                                      name: `claim_items[${index}].description`,
                                      index,
                                    })
                                  }
                                >
                                  Add
                                </HyperLink>
                              ) : (
                                <HyperLink
                                  onClick={() =>
                                    setModal({
                                      state: true,
                                      name: `claim_items[${index}].description`,
                                      index,
                                    })
                                  }
                                >
                                  View
                                </HyperLink>
                              )}
                            </TableCell>
                            <TableCell align="center" style={{ width: 100 }}>
                              {values.claim_items.length > 1 && (
                                <DeleteIcon
                                  onClick={() =>
                                    removeClaimItem(
                                      index,
                                      values,
                                      setFieldValue
                                    )
                                  }
                                  src={require("../../../assets/images/removeIcon.svg")}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </BigTable>
                  <SmallTable>
                    {values?.claim_items?.map((claim_item, index) => (
                      <Details>
                        <LabelValueContainer>
                          <Label>Claim Item</Label>
                          <Value>
                            <div className="element">
                              <CustomInputField
                                className={"input-white"}
                                value={claim_item.title}
                                name={`claim_items[${index}].title`}
                                onChange={handleChange}
                                style={{
                                  paddingRight: 15,
                                }}
                                variant="outlined"
                                onBlur={handleBlur}
                                error={
                                  errors?.claim_items?.[index]?.title &&
                                  touched?.claim_items?.[index]?.title
                                }
                                helperText={
                                  errors?.claim_items?.[index]?.title &&
                                  touched?.claim_items?.[index]?.title
                                }
                              />
                              <HelperText>
                                {touched?.claim_items?.[index]?.title &&
                                  errors?.claim_items?.[index]?.title}
                              </HelperText>
                            </div>
                          </Value>
                        </LabelValueContainer>
                        <LabelValueContainer>
                          <Label> Claim Description</Label>
                          <Value>
                            <div className="element">
                              {!claim_item.description ? (
                                <HyperLink
                                  onClick={() =>
                                    setModal({
                                      state: true,
                                      name: `claim_items[${index}].description`,
                                      index,
                                    })
                                  }
                                >
                                  Add
                                </HyperLink>
                              ) : (
                                <HyperLink
                                  onClick={() =>
                                    setModal({
                                      state: true,
                                      name: `claim_items[${index}].description`,
                                      index,
                                    })
                                  }
                                >
                                  View
                                </HyperLink>
                              )}
                            </div>
                          </Value>
                        </LabelValueContainer>
                        <LabelValueContainer>
                          <Label>Claim Value</Label>
                          <Value>
                            <div className="element">
                              <InputContainer>
                                <NumberFormat
                                  thousandSeparator
                                  thousandsGroupStyle="lakh"
                                  isNumericString={true}
                                  decimalScale={2}
                                  fixedDecimalScale
                                  allowNegative={false}
                                  className={"input-white"}
                                  displayType={"input"}
                                  value={claim_item?.claimValue}
                                  type={"text"}
                                  customInput={CustomInputField}
                                  name={`claim_items[${index}].claimValue`}
                                  onChange={handleChange}
                                  style={{
                                    paddingRight: 15,
                                  }}
                                  variant="outlined"
                                  error={
                                    errors?.claim_items?.[index]?.claimValue &&
                                    touched?.claim_items?.[index]?.claimValue
                                  }
                                  onBlur={(e) => {
                                    setFieldTouched(
                                      `claim_items[${index}].claimValue`,
                                      true
                                    );
                                  }}
                                />
                              </InputContainer>
                              {touched?.claim_items?.[index]?.claimValue &&
                                errors?.claim_items?.[index]?.claimValue && (
                                  <HelperText style={{ whiteSpace: "nowrap" }}>
                                    {errors?.claim_items?.[index]?.claimValue}
                                  </HelperText>
                                )}
                            </div>
                          </Value>
                        </LabelValueContainer>
                        <LabelValueContainer>
                          <Label>Unit</Label>
                          <Value>
                            {/* <div className='element'>{claim_item.unit}</div> */}
                            <CustomSelect
                              white={true}
                              menuItemValues={[
                                {
                                  label: "INR",
                                  value: "INR",
                                  disabled:
                                    values?.claim_items?.length > 1
                                      ? values?.claim_items[0].unit === "INR"
                                        ? false
                                        : true
                                      : false,
                                },
                                {
                                  label: "SGD",
                                  value: "SGD",
                                  disabled:
                                    values?.claim_items?.length > 1
                                      ? values?.claim_items[0].unit === "SGD"
                                        ? false
                                        : true
                                      : false,
                                },
                                {
                                  label: "GBP",
                                  value: "GBP",
                                  disabled:
                                    values?.claim_items?.length > 1
                                      ? values?.claim_items[0].unit === "GBP"
                                        ? false
                                        : true
                                      : false,
                                },
                                {
                                  label: "USD",
                                  value: "USD",
                                  disabled:
                                    values?.claim_items?.length > 1
                                      ? values?.claim_items[0].unit === "USD"
                                        ? false
                                        : true
                                      : false,
                                },
                              ]}
                              value={
                                values?.claim_items?.length > 1
                                  ? values?.claim_items[0].unit
                                  : claim_item?.unit
                              }
                              name={`claim_items[${index}].unit`}
                              onBlur={(e) => {
                                setFieldTouched(
                                  `claim_items[${index}].unit`,
                                  true
                                );
                              }}
                              onChange={handleChange}
                            />
                          </Value>
                        </LabelValueContainer>
                        <LabelValueContainer>
                          <Label>Supporting Document</Label>
                          <Value>
                            <div className="element">
                              {!_.isEmpty(
                                claim_item.description_document_url
                              ) ? (
                                <Row style={{ marginTop: 0, width: 123 }}>
                                  <HyperLink
                                    onClick={() =>
                                      window.open(
                                        claim_item.description_document_url
                                      )
                                    }
                                  >
                                    View
                                  </HyperLink>
                                  <Seperator />
                                  <HyperLink
                                    onClick={() =>
                                      fileRef?.current[index]?.current?.click()
                                    }
                                  >
                                    Update
                                  </HyperLink>
                                </Row>
                              ) : (
                                <>
                                  <HyperLink
                                    onClick={() =>
                                      fileRef?.current[index]?.current?.click()
                                    }
                                  >
                                    Upload
                                  </HyperLink>
                                </>
                              )}
                              <input
                                ref={fileRef.current[index]}
                                onChange={(e) =>
                                  s3Upload(
                                    e?.target?.files[0],
                                    `claim_items[${index}].description_document_url`,
                                    setFieldValue,
                                    setFieldError
                                  )
                                }
                                type="file"
                                accept=".pdf, .mp3, .mp4, .docx, .mov, image/*"
                                style={{ display: "none" }}
                              />
                            </div>
                          </Value>
                        </LabelValueContainer>
                        {values.claim_items.length > 1 && (
                          <Delete
                            onClick={() =>
                              removeClaimItem(index, values, setFieldValue)
                            }
                          >
                            Remove
                          </Delete>
                        )}
                      </Details>
                    ))}
                  </SmallTable>
                  <ButtonWrapper>
                    <SecondaryCTAButton
                      style={{ paddingLeft: 0 }}
                      onClick={() => {
                        if (
                          errors?.claim_items?.[values.claim_items.length - 1]
                            ?.title ||
                          errors?.claim_items?.[values.claim_items.length - 1]
                            ?.claimValue ||
                          errors?.claim_items?.[values.claim_items.length - 1]
                            ?.unit
                        )
                          return enqueueSnackbar(
                            "Enter claim items in order to add more claim items",
                            { variant: "error" }
                          );

                        addClaimItems(values, setFieldValue);
                      }}
                    >
                      + Add Claim Items
                    </SecondaryCTAButton>
                  </ButtonWrapper>
                </TableWrapper>
                <MonetaryText>
                  Total Monetary Claim Value :{" "}
                  <span>
                    {values?.claim_items[0].unit} {renderClaimValue(values)}
                  </span>
                </MonetaryText>
              </TableContainer>

              <FormControl>
                <Row>
                  <PrimaryOutlinedCTAButton
                    type="button"
                    onClick={() => {
                      onFormSubmit(values, null, true);
                    }}
                    style={{ width: "47%" }}
                  >
                    Save as Draft
                  </PrimaryOutlinedCTAButton>
                  <PrimaryCTAButton
                    onClick={handleSubmit}
                    disabled={!isValid}
                    style={{ width: "47%" }}
                  >
                    Submit Case
                  </PrimaryCTAButton>
                </Row>
              </FormControl>
              <DescriptionModal
                {...{
                  modal,
                  setModal,
                  handleChange,
                  errors,
                  values,
                  handleSubmit,
                  touched,
                  handleBlur,
                  setFieldValue,
                  setFieldError,
                }}
              />
              <AlertDialog isOpen={open} {...{ ...dialogData }} />
            </FormContainer>
          )}
        </Formik>
      </Container>
    </OuterContainer>
  );
};

export default Index;

const TooltipHeading = ({ tooltip, spacing, style, children, ...props }) => (
  <Subheading spacing={spacing} {...props} style={style}>
    {children}
    {tooltip && (
      <Tooltip title={tooltip} placement="top">
        <TooltipIcon src={require("../../../assets/images/toolTip.png")} />
      </Tooltip>
    )}
  </Subheading>
);

const DetailsAlertmodal = ({
  negotiationSuccessFee,
  registrationFee,
  caseObj,
}) => {
  return (
    <UnorderList>
      <li>
        <div>Respondent(s)</div>
        {caseObj?.respondentName ? (
          <div>
            <div className="data-row">
              <div className="data-field">
                <div className="label">Name</div>
                <div className="value">{caseObj?.respondentName}</div>
              </div>
              <div className="data-field">
                <div className="label">Email</div>
                <div className="value">{caseObj?.respondentEmail}</div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {caseObj?.respondentParties?.map((el, index) => (
              <div key={index}>
                <div className="data-row">
                  <div className="data-field">
                    <div className="label">Name</div>
                    <div className="value">{el?.name}</div>
                  </div>
                  <div className="data-field">
                    <div className="label">Email</div>
                    <div className="value">{el?.email}</div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </li>
      <li>
        {caseObj?.resolutionKind === "arbitration"
          ? "Arbitration"
          : "Registration"}{" "}
        Fee -{" "}
        <b
          style={{
            fontFamily: `${theme.fonts.primaryFontExtraBold}`,
          }}
        >
          {registrationFee?.unit}{" "}
          {parseFloat(registrationFee?.total).toFixed(2) || 0.0}
        </b>
      </li>
      <TextAlign>
        (The{" "}
        {caseObj?.resolutionKind === "arbitration"
          ? "Arbitration"
          : "Registration"}{" "}
        Fee will be added to the cart)
      </TextAlign>
      {caseObj?.resolutionKind !== "arbitration" && (
        <li>
          Success Fee -{" "}
          <b
            style={{
              fontFamily: `${theme.fonts.primaryFontExtraBold}`,
            }}
          >
            {registrationFee?.unit}{" "}
            {parseFloat(negotiationSuccessFee?.total).toFixed(2)}
          </b>
        </li>
      )}
      <TextAlign>
        (If the case is negotiated successfully you need to pay the Success Fee
        for getting the settlement agreement)
        <br />
        (Claim amount, once submitted, may be changed only if feasible. Any
        difference in fee will need to be paid in such a case)
      </TextAlign>
    </UnorderList>
  );
};

const InputContainer = styled.div`
  @media ${theme?.breakpoints?.md_up} {
    & input {
      text-align: right !important;
    }
  }
`;

const TooltipIcon = styled.img`
  width: 17px;
  height: 17px;
  object-fit: contain;
  cursor: pointer;
  margin-left: 5px;
`;

const TextAlign = styled.text`
  text-align: left;
`;

const SuperTextSm = styled.span`
  font-size: 8px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.INPUT_LABEL};
`;

const HelperText = styled.div`
  position: absolute;
  left: 3px;
  bottom: 2px;
  position: absolute;
  font-size: 9px;
  padding-left: 16px;
  font-family: openSans-SemiBold, sans-serif;
  color: #f44336;
`;

const UnorderList = styled.ul`
  padding: 0px 10px;
  & .data-row {
    display: flex;
    & .data-field {
      padding: 10px 0px;
      font-family: ${theme.fonts.primaryFontSemiBold};
      & .label {
        color: ${COLORS.INPUT_LABEL};
        font-size: 8px;
      }
      & .value {
        font-size: 10px;
      }
      &:nth-child(2) {
        margin-left: 20px;
      }
    }
    @media ${theme?.breakpoints?.sm_up} {
      & .data-field {
        & .label {
          color: ${COLORS.INPUT_LABEL};
          font-size: 10px;
        }
        & .value {
          font-size: 14px;
        }
        &:nth-child(2) {
          margin-left: 30px;
        }
      }
    }
  }
  & li {
    padding: 5px;
  }
`;
