import styled, { css } from "styled-components";
import COLORS from "../../assets/Colors";
import theme from "../../assets/theme";
import { Button, withStyles } from "@material-ui/core";
import { PrimaryCTAButton } from "../../components/common/Buttons";

export const CompanyLogo = styled.img`
  object-fit: contain;
  width: 144px;
  height: 60px;
  cursor: pointer;

  @media (max-width: 600px) {
    width: 90px;
    height: 50px;
    object-fit: contain;
  }
`;

export const BodySection = styled.div`
  min-height: calc(100vh - 66px);
  width: 100%;
`;

export const Layout = styled.div`
  min-height: 100vh;
  background: #f9f9f9;
  height: 100%;
`;

export const Center = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
`;

export const TextCenter = styled.div`
  text-align: center;
`;

export const Header3 = styled.h3`
  font-size: 18px;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

export const SmallText = styled.p`
  font-size: 14px;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

export const AnchorText = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  text-align: center;
  color: #293461;
  text-decoration: underline;
  cursor: pointer;
`;

export const CardWrapper = styled.form`
  width: 100%;
  & .link {
    border-bottom: 1px solid ${COLORS.COLOR_DARK};
    margin-left: 5px;
    cursor: pointer;
  }
`;

export const HeadingContainer = styled.div`
  position: relative;
  text-align: center;
`;

export const ContentContainer = styled.div`
  padding: 10px 0px;
`;

export const BackArrow = styled.img`
  position: absolute;
  left: 0;
  top: 50%;
  cursor: pointer;
  width: 20px;
  height: 14px;
  transform: translateY(-50%);
`;

export const Heading = styled.span`
  margin-bottom: 31px;
  font-family: ${theme.fonts.primaryFontExtraBold};
  font-size: 18px;
  color: ${COLORS.COLOR_DARK};
`;
export const Subheading = styled.span`
  max-width: 576px;
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 12px;
  color: ${COLORS.COLOR_DARK};
  line-height: 1.29;
  margin-top: 20px;
  margin-bottom: 40px;
`;

export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.6;
    `}
`;

export const PaymentPending = styled.div`
  padding: 5px;
  background: rgba(255, 60, 60, 0.2);
  color: #ff3c3c;
  border-radius: 5px;
`;

export const SnackbarButton = withStyles({
  root: {
    color: COLORS.PRIMARY_WHITE,
  },
})(Button);

export const DangerBtn = styled(PrimaryCTAButton)`
  background-color: #ff3c3c;
  &:focus,
  &:hover {
    background-color: #ff4d4d;
    border: 1px solid #ff3c3c;
  }
  border: 1px solid #ff3c3c;
`;
