import React, { useEffect } from "react";
import { Header } from "../../internal/Header/Header";
import { H2, P, AboutUsBody, GreySection, WhiteSection } from "./Styles";
import labels from "../../../helpers/labels.json";
import Footer from "../LandingPage/Footer";
import OurValues from "./OurValues";
import HowItWorks from "../LandingPage/Sections";
import BoardOfDirectors from "./BoardOfDirectors";
import ManagementTeam from "./TheManagementTeam";
import Partnerships from "./Partnerships";
import { scroller } from "react-scroll";
import OurAdvantages from "./OurAdvantages";
import { Helmet } from "react-helmet";
import { HyperLink } from "../../common/FormInputs";

const AboutUs = props => {
  const section = props.location.hash.slice(1);
  useEffect(() => {
    scroller.scrollTo(section, {
      duration: 500,
      offset: -70,
      smooth: true
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location]);

  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>About Us - ODR Services & ADR Services - JustAct</title>
        <meta
          name="description"
          content="JustAct is an Online Dispute Resolution platform offered by Lex Carta Private Limited and is set up by lawyers and professionals to disrupt the way disputes are resolved today."
        />
        <meta
          name="keywords"
          content="About JustAct, Online Dispute Resolution Services, ODR Platform, Alternative Dispute Resolution Services, Alternative Dispute Resolution Training, Online Arbitration, Online Mediation, JustAct"
        />
      </Helmet>
      <div>
        <Header>
          <AboutUsBody>
            <GreySection id="about-justact">
              <H2 style={{ textAlign: "center" }}>
                {labels["landing.what_is_justact"]}
              </H2>
              <P
                style={{ textAlign: "justify" }}
              // dangerouslySetInnerHTML={{
              //   __html: labels["landing.what_content"]
              // }}
              >
                JustAct is an{" "}
                <HyperLink
                  onClick={() => window.open(`https://justact.co.in/`)}
                >
                  Online Dispute Resolution
                </HyperLink>{" "}
                platform offered by Lex Carta Private Limited and is set up by
                lawyers and professionals to disrupt the way disputes are
                resolved today.
                <br />
                <br />
                We believe that long amounts of time spent in the courts is
                detrimental to the economy and JustAct will improve the
                productivity of society manifold.
                <br />
                <br />
                Our mission is to transform dispute resolution by bringing
                together mediators, arbitrators and litigants using technology
                to provide cost effective solution and to create an ecosystem &
                culture where people are pre-disposed to settling disputes
                amicably with fairness and equity.
              </P>
            </GreySection>

            <WhiteSection id="our-advantages">
              <OurAdvantages />
            </WhiteSection>

            <GreySection id="how-it-works" style={{ paddingTop: "20px" }}>
              <HowItWorks />
            </GreySection>

            <WhiteSection id="our-values">
              <OurValues />
            </WhiteSection>

            {/* <GreySection id="founders">
            <FoundingTeam />
          </GreySection> */}

            <WhiteSection id="board-of-directors" style={{ paddingTop: "0" }}>
              <BoardOfDirectors />
            </WhiteSection>

            <WhiteSection id="management-team" style={{ paddingTop: "0" }}>
              <ManagementTeam />
            </WhiteSection>

            <GreySection id="partnerships">
              <Partnerships />
            </GreySection>

            {/* <WhiteSection id="contact-us">
            <ContactUs />
          </WhiteSection> */}
          </AboutUsBody>

          <Footer />
        </Header>
      </div>
    </>
  );
};
export default AboutUs;
