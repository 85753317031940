import React from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import { CloudDownloadRounded } from "@material-ui/icons";

const Header = React.memo(
  ({
    showSettings,
    showExportOptions,
    setShowSettings,
    setShowExportOptions,
    settings,
    mediaControls,
    mediaUrl,
    exportOptions,
  }) => {
    return (
      <>
        <header>
          <HeaderContainer>
            {showSettings ? settings : null}
            {showExportOptions ? exportOptions : null}
          </HeaderContainer>
        </header>
        <nav>{mediaControls}</nav>
        <HeaderContainers>
          {/* <SettingsButton>
            <Settings
              style={{
                borderRadius: "5px",
                cursor: "pointer",
                fill: COLORS.BTN_GREEN,
              }}
              title="settings"
              onClick={() => setShowSettings(true)}
            />
          </SettingsButton> */}
          <SettingsButton>
            <CloudDownloadRounded
              style={{
                borderRadius: "5px",
                cursor: "pointer",
                fill: COLORS.BTN_GREEN,
              }}
              title="Export"
              onClick={() => setShowExportOptions(true)}
            />
          </SettingsButton>
          {/* <SettingsButton>
                    <button
                        style={{ borderRadius: "5px" }}
                        title="settings"
                        onClick={() => setShowSettings(true)}
                    >
                        <Settings style={{ cursor: "pointer" }} />
                    </button>
                </SettingsButton>
                <SettingsButton>
                    <button
                        style={{ borderRadius: "5px" }}
                        title="Export"
                        onClick={() => setShowExportOptions(true)}
                    >
                        <CloudUploadRounded style={{ cursor: "pointer" }} />
                    </button>
                </SettingsButton> */}
        </HeaderContainers>
      </>
    );
  }
);

export default Header;

export const HeaderContainer = styled.div`
  margin-top: 0;
  margin-bottom: 1em;
  border-radius: 5px;
`;

export const HeaderContainers = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  display: flex;
`;

export const SettingsButton = styled.div`
  line-height: 1em;
  margin-left: 0.5em;
  background: ${COLORS.BORDER_GREY};
  display: inline-block;
  border: 0;
  border-radius: 5px;
  color: white;
  font-size: 1em;
  cursor: pointer;
  text-align: center;
  width: 2em;
  height: 2em;

  &:hover {
    background: ${COLORS.BORDER_GREY};
  }
`;
