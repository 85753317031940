import React, { useEffect } from "react";
import {
  Content,
  SubHeadWrap,
  BlogPage,
  BlogImage,
  TextLines,
} from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import { Helmet } from "react-helmet";
import { HyperLink } from "../../common/FormInputs";

function OnlineDisputeBlogTwo(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>
          Online Dispute Resolution: Opportunities and Challenges - JustAct
        </title>
        <meta
          name="description"
          content="This blog discusses the Opportunities and Challenges in Online Dispute Resolution, and Why ODR methodologies need to be embraced?"
        />
        <meta
          name="keywords"
          content="Online Dispute Resolution Opportunities, Online Dispute Resolution Challenges, ODR Methodologies, Method of ODR, JustAct"
        />
      </Helmet>
      <Header>
        <BlogPage>
          <SubHeadWrap>
            Online Dispute Resolution: Opportunities and challenges
          </SubHeadWrap>
          <br />
          <Content>
            <p>
              Embracing an idea means embracing its methods. Embracing{" "}
              <HyperLink onClick={() => window.open(`https://justact.co.in/`)}>
                ODR
              </HyperLink>{" "}
              needs to be done rapidly but not at the cost of sacrificing the
              principles that underly our justice system. This includes aspects
              that safeguard justice such as fair trials. This therefore begs
              the question: Why?
            </p>
            <br />
            <b style={{ textAlign: "center" }}>
              Why do ODR methodologies need to be embraced?
            </b>
            <BlogImage
              src={require("../../../assets/images/BlogImg2.png")}
              alt="Independent"
            />
            <br />
            <p>
              Let’s take this from the perspective of India’s justice system,
              which itself faces several challenges. The key issue in question
              is how it stands as a system that is not delivering justice in
              time to give relief to people whose rights are violated. The
              numbers speak for this.
            </p>
            <br />
            <b style={{ textAlign: "center" }}>5 Years</b>
            <p>
              That’s what it takes on an average, at every stage of our judicial
              system - district judiciary, high courts and supreme court. Bottom
              line, the case life cycle basically is 15 years long! Simply put,
              we have a justice system that is overwhelmed and therefore
              severely crippled by the volume of cases.
            </p>
            <br />
            <p>
              But a deeper look reveals that it is not about having too many
              cases in India. It’s about an exclusion of people being able to
              access the judicial system in India. Over 3 crore cases remain
              pending and we are daunted by it!
            </p>
            <br />
            <p>
              It’s human nature to want a thorough closure to an issue faced. So
              when these cases are pending for 15 years or more, the resolution
              that comes later has no meaning to it. If there is a property
              dispute between two people, how can the case be not resolved for
              35 years? More importantly, what kind of impact does it have on
              them?
            </p>
            <br />
            <p>
              The answer is we need to do something about getting our judicial
              system function better. And if you look forward, it presents a
              challenging situation considering the number of courts that are
              built and the number of cases that come in. The capacity to deal
              with these cases just doesn’t exist at this time. This calls for
              new methodologies to resolve disputes.
            </p>
            <br />
            <p>
              One of the silver linings of the COVID pandemic is the adoption of
              online hearings. Even so, two problems still manifest. The first
              is solving the capacity problem, and then there’s the problem of
              accessibility to the judicial system. The only solution is that
              the reach of dispute resolution mechanism has to go beyond what it
              is today.
            </p>
            <br />
            <p>
              An immediate solution to this would be to further empower key
              personnel involved in the system: judges, arbitrators, mediators,
              to be able to work more efficiently, interact with the
              stakeholders more efficiently without the problems of travel,
              availability, coordinating accessibility. Considering how it’s
              booming and at our fingertips, technology becomes an appropriate
              way. It is critical to adopt newer technologies to solve the
              problems of dispute. ODR is not a different mechanism from
              delivering justice. It might not solve all problems but it
              certainly is a significant step forward if we enable our judiciary
              and our legal system to adapt technology much quicker and make
              them digitally native to enable access to justice. In the process
              we’ll be better poised to resolve the challenges our judicial
              system is facing.
            </p>
            <br />
            <p>
              With the opportunities under technologies come the challenges.
              Knowing the challenges means probing the following:
            </p>
            <TextLines>
              <ul>
                <li>Access to justice.</li>
                <li>What the ODR design really is, and</li>
                <li>
                  How it raises questions on equality, equity fairness,
                  transparency.
                  <TextLines>
                    <li style={{ listStyleType: "circle" }}>
                      How is the scheduling of cases?{" "}
                    </li>
                    <li style={{ listStyleType: "circle" }}>
                      How are documents circulated?
                    </li>
                    <li style={{ listStyleType: "circle" }}>
                      How does the video conferencing work?
                    </li>
                  </TextLines>
                </li>
              </ul>
            </TextLines>
            <p>
              ODR is not just a technology integration, but where technology is
              used to solve disputes. This means, ODR is not just a virtual
              scheduling of cases, not video conferencing, not document
              circulation but it can include automated decision making with the
              use of artificial intelligence and machine learning. All of these
              raises questions on access, particularly about the very platform
              of ODR.
            </p>
            <br />
            <BlogImage
              style={{ height: "450px" }}
              src={require("../../../assets/images/Picture3.png")}
              alt="Independent"
            />
          </Content>
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default OnlineDisputeBlogTwo;
