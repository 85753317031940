import React from 'react';
import styled from 'styled-components';
import COLORS from '../../../assets/Colors';
import theme from '../../../assets/theme';
import { H2 } from './Styles';

function Partnerships(props) {
    return (
        <>
            
            <H2 style={{textAlign:"center"}}>Our Partners</H2>
            <BodyWrap>
            <ImageBox>
                    
                    <ImageWrap src={require("../../../assets/images/fdcr.jpg")} alt="FDCR"/>
                    <div>Mediation Partner</div>
            </ImageBox>
            <ImageBox>
                    
                    <ImageWrap src={require("../../../assets/images/npac.jpg")} alt="NPAC"/>
                    <div>Arbitration Partner</div>
            </ImageBox>
            </BodyWrap>       
                    
            
            
        </>
    );
}

export default Partnerships;

const ImageWrap=styled.img `
margin:20px;
width:400px;
height:200px;
border: 1px solid gray;

@media(max-width:600px){
    margin:20px;
width:300px;
height:150px;
}

`
const BodyWrap= styled.div`
display:flex;
flex-direction:row;
flex-wrap:wrap;
align-items:center;
justify-content:center;
font-size:18px;
padding:1%;
color: ${COLORS.BTN_GREEN};
font-family: ${theme.fonts.primaryFontBold};
width:100%;
`
const ImageBox=styled.div`
display:flex;
flex-direction:column;
align-items:center;
`