import React from "react";
import {
  CardWrapper,
  HeadingContainer,
  Header3,
} from "../../../styles/component/style";
import { MessageContainer, InputContainer } from "./Styles";
import styled from "styled-components";
import { Formik } from "formik";
import FormField from "../../common/FormField/FormField";
import { states } from "../../../helpers/constants";
import theme from "../../../assets/theme";
import { profileCompletionValidationSchema } from "../../../helpers/validationSchema";
import { PrimaryCTAButton } from "../../common/Buttons";
import _ from "lodash";
import AuthService from "../../../services/AuthService";
import { useSnackbar } from "notistack";
import { navigate } from "@reach/router";
import useLoader from "../../../hooks/useLoader";
import useUser from "../../../hooks/useUser";
import { getErrorMessage } from "../../../helpers/functions";
import labels from "../../../helpers/labels.json";

export const CreateProfile = ({
  heading = labels["onboarding.step3"],
  message = `Avian thanks for verifying your phone number. We need you to fill in a few more details in order to create you profile on JustAct`,
  inputs = [],
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const { userObject, setUserObject } = useUser();

  async function onFormSubmit(values) {
    const postData = JSON.parse(JSON.stringify(values));
    try {
      setLoader({ state: true, message: "Submitting form..." });
      let payload = _.pick(postData, [
        "dob",
        "locality",
        "city",
        "state",
        "pin",
        "landmark",
      ]);
      payload.houseNumber = values.address_line;
      const response = await AuthService.profileUpdate(payload);
      if (response) {
        setUserObject(response.me);
        if (response?.me?.emailVerified) {
          navigate("/dashboard/welcome");
        } else {
          enqueueSnackbar(
            "Verification mail sent successfully. Please check your Junk Folder/ Promotions Folder incase you don't receive the email",
            {
              variant: "success",
            }
          );
          navigate("/verify-email", {
            state: {
              payload,
            },
          });
        }
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }
  return (
    <CardWrapper>
      <HeadingContainer>
        <Header3>{heading}</Header3>
      </HeadingContainer>
      <MessageContainer>{message}</MessageContainer>
      <InputContainer>
        <Formik
          initialValues={{
            dob: userObject?.dob || null,
            address_line: userObject?.houseNumber || "",
            locality: userObject?.locality || "",
            city: userObject?.city || "",
            state: userObject?.state || " ",
            pin: userObject?.pin || "",
            landmark: userObject?.landmark || "",
          }}
          enableReinitialize
          onSubmit={onFormSubmit}
          validationSchema={profileCompletionValidationSchema}
          validateOnBlur
          validateOnChange
        >
          {({
            values,
            handleChange,
            errors,
            handleSubmit,
            touched,
            handleBlur,
            setFieldValue,
          }) => (
            <FormContainer onSubmit={handleSubmit}>
              <Flex>
                <FormField
                  input={{
                    name: "dob",
                    label: labels["user.dob"],
                    type: "date",
                    placeholder: "Choose DOB",
                    required: true,
                  }}
                  {...{
                    touched,
                    errors,
                    values,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                  }}
                />
              </Flex>
              <FormLabel>{labels["user.address"]}</FormLabel>

              <FormField
                input={{
                  name: "address_line",
                  label: labels["user.house_label"],
                  type: "text",
                  placeholder: "Eg. Flat 2C, PKP Apartment",
                  required: true,
                }}
                {...{
                  touched,
                  errors,
                  values,
                  handleChange,
                  handleBlur,
                }}
              />
              <Flex>
                <HalfWidth>
                  <FormField
                    input={{
                      name: "locality",
                      label: labels["user.locality"],
                      type: "text",
                      placeholder: "Eg. Saket",
                      required: true,
                    }}
                    {...{
                      touched,
                      errors,
                      values,
                      handleChange,
                      handleBlur,
                    }}
                  />
                </HalfWidth>
                <HalfWidth>
                  <FormField
                    input={{
                      name: "city",
                      label: labels["user.city"],
                      type: "text",
                      placeholder: "Eg. Delhi",
                      required: true,
                    }}
                    {...{
                      touched,
                      errors,
                      values,
                      handleChange,
                      handleBlur,
                    }}
                  />
                </HalfWidth>
              </Flex>
              <Flex>
                <HalfWidth>
                  <FormField
                    input={{
                      name: "state",
                      label: labels["user.state"],
                      type: "select",
                      required: true,
                    }}
                    menuItems={[...states]}
                    {...{
                      touched,
                      errors,
                      values,
                      handleChange,
                      handleBlur,
                    }}
                  />
                </HalfWidth>
                <HalfWidth className="disabledArrows">
                  <FormField
                    input={{
                      name: "pin",
                      label: labels["user.pin"],
                      type: "number",
                      placeholder: "Eg. 110016",
                      required: true,
                    }}
                    {...{
                      touched,
                      errors,
                      values,
                      handleChange,
                      handleBlur,
                    }}
                  />
                </HalfWidth>
              </Flex>
              <HalfWidth>
                <FormField
                  input={{
                    name: "landmark",
                    label: labels["user.landmark"],
                    type: "text",
                    placeholder: "Eg. Garden of 5 senses",
                    required: true,
                  }}
                  {...{
                    touched,
                    errors,
                    values,
                    handleChange,
                    handleBlur,
                  }}
                />
              </HalfWidth>
              <ButtonContainer>
                <PrimaryCTAButton onClick={handleSubmit}>
                  {labels["onboarding.createProfile"]}
                </PrimaryCTAButton>
              </ButtonContainer>
            </FormContainer>
          )}
        </Formik>
      </InputContainer>
    </CardWrapper>
  );
};

const FormContainer = styled.form``;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`;

const HalfWidth = styled.div`
  width: 48%;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const FormLabel = styled.div`
  padding-top: 17px;
  padding-bottom: 13px;
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: #293461;
`;

const ButtonContainer = styled.div`
  width: 413px;
  margin: 0 auto;
  margin-top: 19px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;
