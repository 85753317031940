import { useEffect, useState } from "react";
import _ from "lodash";
import CaseService from "../services/CaseService";

function initFreshChat(userObject, chatDetail, tags) {
  window.fcWidget.init({
    token: "998c4eb3-1d78-4b8d-9e96-75dfa4a6f52f",
    host: "https://justact-org.freshchat.com",
    externalId: chatDetail?.externalId,
    restoreId: chatDetail?.restoreId || "",
    tags,
  });
  window.fcWidget.user.get(function (resp) {
    window.fcWidget.setExternalId(chatDetail?.externalId);
    // To set user name
    window.fcWidget.user.setFirstName(userObject.name);
    // To set user email
    window.fcWidget.user.setEmail(userObject.email);
    var status = resp && resp.status;
    if (status !== 200) {
      window.fcWidget.user.setProperties({
        firstName: userObject.name,
        lastName: chatDetail?.caseId ? `- ${chatDetail?.caseId}` : "",
        email: userObject.email, // user's email address
      });

      window.fcWidget.on("user:created", function (resp) {
        var status = resp && resp.status,
          data = resp && resp.data;
        if (status === 200) {
          if (data.restoreId) {
            if (chatDetail?.caseId) {
              updateRestoreId(
                chatDetail?.caseId,
                chatDetail?.externalId,
                data?.restoreId
              );
            }
            // else {
            //   localStorage.setItem("restoreId", data?.restoreId);
            // }
            // Update restoreId in your database
          }
        }
      });
    }
  });
}

/** Function to get the chatDetails */
async function updateRestoreId(id, externalId, restoreId) {
  try {
    const chatDetails = await CaseService.postRestoreId(
      id,
      externalId,
      restoreId
    );
    console.log(chatDetails);
  } catch (error) {
    console.log(error);
  }
}

function initialize(i, t, userObject, chatDetail, tag) {
  var e;
  if (i.getElementById(t)) {
    initFreshChat(userObject, chatDetail, tag);
  } else {
    (e = i.createElement("script")).id = t;
    e.async = !0;
    e.src = "https://justact-org.freshchat.com/js/widget.js";
    e.onload = () => initFreshChat(userObject, chatDetail, tag);
    i.head.appendChild(e);
  }
}
function initiateCall(userObject, chatDetail, tag) {
  initialize(document, "Freshchat-js-sdk", userObject, chatDetail, tag);
}
function destroyChatBox() {
  window.fcWidget.destroy();
}

export default function useFreshChat({ userObject }, general) {
  const [isInitialised, setIsInitialised] = useState();
  const [chatDetail, setChatDetail] = useState({});
  const [tag, setTag] = useState([]);
  const handlePopup = () => {
    if (window.fcWidget.isOpen()) {
      window.fcWidget.close();
    } else {
      window.fcWidget.open();
    }
  };
  useEffect(() => {
    if (
      (isInitialised && !_.isEmpty(chatDetail)) ||
      (isInitialised && general) ||
      tag?.length
    ) {
      destroyChatBox();
      setTimeout(() => {
        initiateCall(userObject, chatDetail, tag);
        window.fcWidget.user.clear().then(
          function () {
            console.log("clear");
          },
          function () {
            console.log("Not cleared");
          }
        );
      }, 200);
      setTimeout(() => {
        initiateCall(userObject, chatDetail, tag);
      }, 200);
    }
  }, [isInitialised, userObject, chatDetail, general, tag]);
  return { setIsInitialised, handlePopup, setChatDetail, setTag };
}
