export const Images = {
  companyLogo: require("./images/company-logo.png"),
  // companyLogoWhite: require("./images/company-logo-white.png"),
  companyLogoWhite: require("./images/JustAct_Logo_TM_V5-04.png"),
  selectArrow: require("./images/selectArrow.svg"),
  checkBox: require("./images/checkbox.svg"),
  checkBoxSelected: require("./images/checkBoxSelected.svg"),
  dropDownArrow: require("./images/dropdownIcon.svg"),
  filefloder: require("./images/files-and-folders.svg"),
};
