import React, { useEffect } from "react";
import { Content, SubHeadWrap, BlogPage } from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

/* eslint-disable jsx-a11y/anchor-is-valid */

function EnterprisesDevelopmentPart2(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header>
        <BlogPage>
          <HeadWrap>
            ADR PROCESSES WITHIN THE MICRO, SMALL AND MEDIUM ENTERPRISES
            DEVELOPMENT ACT, 2006 Part II
          </HeadWrap>
          <br />
          <br />
          <Content>
            <b
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontStyle: "italic",
              }}
            >
              {" "}
              Authored by : Aprameya Manthena{" "}
            </b>
            <SubHeadWrap style={{ marginTop: "10px", marginBottom: "15px" }}>
              Does Section 18 (3) of the MSME Act override the arbitration
              agreement?
            </SubHeadWrap>
            <p>
              It was held in this judgment<sup>1</sup> that “there is no
              provision in the Act, which negates or renders the arbitration
              agreement entered between the parties ineffective” thus creating
              ground for the arbitration agreement to be weighed alongside the
              provisions of the MSMED Act. This was contrary to the position
              taken by a Division Bench of the Gujarat High Court<sup>2</sup>{" "}
              which was challenged by way of a special leave petition to the
              Supreme Court<sup>3</sup> which affirmed the Gujarat High Court
              decision.
            </p>
            <br />
            <p>
              The Gujarat High Court followed the decision of Allahabad High
              Court which held that “subsection (3) of Section 18, therefore,
              contains a statutory reference to arbitration. This is not
              dependent on the existence of an arbitration agreement in the
              contract between the parties.”<sup>4</sup>
            </p>
            <br />
            <p>
              The next judgment under discussion dealt with Section 18 (3) of
              the MSME Act. After conciliation proceedings failed, and were
              terminated, the dispute was referred to DIAC. The issue raised was
              when the arbitration agreement subsists, whether disputes under
              section 18 (3) MSME Act could be referred to the DIAC by the
              Council? It was observed that the appointing procedure under the
              arbitration agreement and section 18 (3) MSMED Act are different.
              It was held that Section 18 (3) of the MSMED Act prevails. Section
              24 has a non obstante clause and, expressly provides that the
              provisions of Section 15 to 23 of the Act will have an overriding
              effect.
            </p>
            <br />
            <p>
              Thus, the provisions of Section 18(3) of the Act cannot be diluted
              and must be given effect to notwithstanding anything inconsistent,
              including the arbitration agreement in terms of section 7 of the
              A&amp;C Act.
            </p>
            <br />
            <p>
              The Act provides for statutory framed arbitration and under
              section 18 (3) of the MSME Act, the arbitrator is either the MSEFC
              or an ADR institution. Therefore, the Act provides only for
              institutional arbitration and not an ad hoc arbitration.
              <sup>5</sup>
            </p>
            <br />
            <p>
              The Apex Court emphasized this position in this judgment wherein
              it was held that even if there is an agreement between the parties
              for resolution of disputes by arbitration, if a seller is covered
              by Micro, Small and Medium Enterprises Development Act, 2006, the
              seller can certainly approach the competent authority to make its
              claim. If any agreement between the parties is there, same is to
              be ignored in view of the statutory obligations and mechanism
              provided under the 2006 Act.<sup>6</sup>
            </p>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              Validity of the award &amp; Limitation
            </SubHeadWrap>
            <p>
              The Court<sup>7</sup> held that the award within the meaning of
              the Act is an award of the majority of the Arbitral Tribunal and
              the award of any dissenting minority is no award. That still
              requires the participation of all Arbitrators in the reference and
              deliberations including providing reasons in case the award is not
              signed by all present. The reasons have to be germane, adequate
              and justifiable since it goes to the root of the award and if the
              reasons are not in line with the requirements, the validity of the
              award could be undermined.
            </p>
            <br />
            <p>
              Since all the provisions of the Arbitration &amp; Conciliation Act
              apply to any arbitration carried out by the Council, there is no
              exception made in respect of Section 43 of the Arbitration &amp;
              Conciliation Act and thus, the provisions of the Limitation Act
              also apply to arbitrations conducted under Section 18 (3) of the
              MSMED Act.
            </p>
            <br />
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              ADR Processes within MSMED Act
            </SubHeadWrap>
            <p>
              Section 73 of the Arbitration and Conciliation Act discusses the
              manner in which the settlement agreement is to be arrived at the
              conclusion of the conciliation proceedings or through a party
              asking for the proceedings to be terminated.<sup>8</sup>
            </p>
            <br />
            <p>
              The conciliation and arbitration proceedings have to be conducted
              in accordance with the provisions of the Arbitration and
              Conciliation Act, and the order has to be passed in keeping with
              the requirements of Section 18 (2) and 18 (3) i.e. as an order
              under Section 18 (2) and as an award under Section 18 (3).
              <sup>9</sup>
            </p>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              Exclusive jurisdiction of the Facilitation Council under the MSMED
              Act
            </SubHeadWrap>
            <p>
              The Court held that once the MSMED Act is invoked, exclusive
              jurisdiction is conferred on the Facilitation Council. The Act
              under Section 18 (3) provides for conciliation first and then
              arbitration either by the Council or by reference to an
              institution providing alternate dispute resolution services.
              Therefore, any reference to an ad hoc arbitrator is manifestly
              illegal.<sup>10</sup>
            </p>
            <br />
            <p>
              In the next judgment, it was shown that Section 18 (3) of MSMED
              Act provides for the arbitral proceedings to have commenced after
              the matter is referred for arbitration, once the conciliation has
              failed. Section 18 (3) of MSME Act also stipulates that the
              arbitral proceeding in respect of a dispute is to be tried and
              decided as if it is a reference made under the provisions of
              Arbitration &amp; Conciliation Act. Similarly, the arbitral
              proceeding is to be treated as if in pursuance to an arbitration
              agreement between the parties as per Section 7(1) of Arbitration
              &amp; Conciliation Act. In respect of arbitration agreement
              referable to Section 7 (1) of the above Act, all the provisions of
              the Arbitration &amp; Conciliation Act apply as a whole. Since the
              Act allows for the pleading of counterclaim, it follows that in
              arbitral proceedings made under Section 18 of Act, 2006, such
              counter claim can also be set up before the Arbitral Council by
              the respondent.<sup>11</sup>
            </p>
            <br />
            <p>
              This position was reiterated in a recent judgment where the Court
              held “When the provisions of Sections 15 to 23 are given
              overriding effect under Section 24 of the Act and further the 2006
              Act is a beneficial legislation, …even the buyer, if any claim is
              there, can very well subject to the jurisdiction before the
              Council and make its claim/ counter claim as otherwise it will
              defeat the very objects of the Act which is a beneficial
              legislation to micro, small and medium enterprises.”<sup>12</sup>
            </p>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              Can the Conciliator be the Arbitrator as well?
            </SubHeadWrap>
            <p>
              Continuing from the previous judgment, 13 the Court held that
              within the facts of the case, the Council had not conducted the
              conciliation proceedings and hence, the embargo contained in
              Section 80 would not be applied to the Council entering upon the
              reference for arbitration.
            </p>
            <br />
            <p>
              The next judgment also discusses the application of Section 80 of
              the Arbitration &amp; Conciliation Act since certain provisions of
              Arbitration Act 1996 including Section 80 are specifically made
              applicable to conciliation proceedings contemplated by Section
              18(2) of the MSMED Act. The Court held that the conciliator cannot
              be arbitrator or representative or counsel of a party in arbitral
              proceedings. Under Section 18 of the MSME Act, once the Council
              acts as conciliator, in view of Section 80, it is prohibited from
              acting as arbitrator. The matter is then to be referred to
              institutional arbitration once the conciliation process conducted
              by the Council fails.<sup>14</sup>
            </p>
            <SubHeadWrap
              style={{
                marginBottom: "10px",
                marginTop: "10px",
                marginLeft: "15px",
              }}
            >
              Contrary position
            </SubHeadWrap>
            <p>
              This next judgment<sup>15</sup> lays out the MSME Council’s
              capacity to carry out both arbitration and conciliation. Section
              80 of Arbitration Act was found as not applicable since the Court
              observed that Section 18(2) of MSMED Act has borrowed the
              provisions of Sections 65 to 81 of the Arbitration and
              Conciliation Act for the purpose of conducting conciliation and,
              therefore, Section 80 could not be a bar for the Facilitation
              Council to conciliate and thereafter arbitrate on the matter.
              Here, the Court held that the decision of the Facilitation Council
              is not final and it is always subject to review under Article
              226 of the Constitution of India and the appellants are, therefore
              not left helpless.
            </p>
            <br />
            <p>
              An independent arbitration agreement must be taken only as an
              additional method of appointment of an arbitrator and cannot
              exclude the application of the provisions of this Act. The non
              obstante clause that is set forth under Section 18 which begins
              with these expressions &quot;notwithstanding anything contained in
              any other law for the time being in force&quot; has sway. A
              contract that provides for appointment of an arbitrator must be
              seen as a contract as recognized by law and that provision will
              stand eclipsed by the non obstante clause that Section 18 provides
              for. <sup>5</sup>
            </p>
            <br />
            <p>
              In regard to bias, the Court observed that “the Members who
              participate in the Conciliation shall not sit in the Arbitration
              proceedings and the Facilitation Council has to amend/formulate
              its own rules in this regard at the earliest in order to avoid
              these complications.”
            </p>
            <br />
            <p>
              Similarly, it was held that Section 18(2) of MSMED Act has
              borrowed the provisions of Sections 65 to 81 of the Arbitration
              and Conciliation Act for the purpose of conducting conciliation
              and, therefore, Section 80 could not be a bar for the Facilitation
              Council to conciliate and thereafter arbitrate on the matter.
              <sup>16</sup>
            </p>
            <SubHeadWrap
              style={{
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              In conclusion
            </SubHeadWrap>
            <p>
              The ADR processes within the MSMED Act, 2006 have been subject to
              scrutiny by the courts of the country and in keeping with the
              objects and purpose of the Act, the judgments underline the
              importance of a legal framework for the growth and development of
              the MSME sector that facilitates quick, easy and effective dispute
              resolution and payments.
            </p>
            <Block />
            <p>
              <sup>1</sup>
              Steel Authority of India v. The Micro, Small Enterprise
              Facilitation Council: AIR 2012 Bom 178. The Division Bench held,
              “We, thus find that it cannot be said that because Section 18
              provides for a forum of arbitration an independent arbitration
              agreement entered into between the parties will cease to have
              effect. There is no question of an independent arbitration
              agreement ceasing to have any effect because the overriding clause
              only overrides things inconsistent therewith and there is no
              inconsistency between an arbitration conducted by the Council
              under Section 18 and arbitration conducted under an individual
              clause since both are governed by the provision of the Arbitration
              Act, 1996.”
            </p>
            <p>
              <sup>2</sup> Principal Chief Engineer v. Manibhai and Bros
              (Sleeper), FA No. 637 of 2016
            </p>
            <p>
              <sup>3</sup> Diary No. 16845 of 2017
            </p>
            <p>
              <sup>4</sup> Paper &amp; Board Convertors Versus U.P. State Micro
              &amp; Small Enterprises, Division Bench, 2014 SCC Online ALL 5825,
              Paras 15-17
            </p>
            <p>
              <sup>5</sup> Bharat Heavy Electricals Limited Versus Micro and
              Small Enterprises Facilitations Centre, 2017 SCC Online Del 10604
            </p>
            <p>
              <sup>6</sup> Check footnote 4
            </p>
            <p>
              <sup>7</sup> Maharashtra State Electricity Distribution Company
              Ltd. and Others Versus Deltron Electronics, 2017 (2) MHLJ 605,
              Paras 8,9 (discussion), Para 19, 20, 21, 27, 28 &amp; 29
            </p>
            <p>
              <sup>8</sup> See footnote 3
            </p>
            <p>
              <sup>9</sup> Ramesh Conductors P. Limited v. M &amp; SE
              Facilitation Council, 2015 SCC OnLine Mad 13110
            </p>
            <p>
              <sup>10</sup> Check footnote 7
            </p>
            <p>
              <sup>11</sup> M/s. B.H.P. Engineers Pvt. Ltd. vs. Director,
              Industries, U.P. (Facilitation Council), Kanpur &amp; Ors., AIR
              2009 All 155, Para 23
            </p>
            <p>
              <sup>12</sup> Check footnote 4
            </p>
            <p>
              <sup>13</sup> ibid
            </p>
            <p>
              <sup>14</sup> Gujarat State Petronet Ltd. Versus Micro and Small
              Enterprises Facilitation Council, 2018 SCC Online BOM 2039, Para
              29, 30, 31 &amp; 32
            </p>
            <p>
              <sup>15</sup> Eden Exports Company Versus Union of India, 2012 SCC
              Online MAD 4570, Para 16, 21, 22 &amp; 26
            </p>
            <p>
              <sup>16</sup> Institute of Chartered Accountants of India v. L.K.
              Ratna, (1986) 4 SCC 537
            </p>
          </Content>
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default EnterprisesDevelopmentPart2;

export const ContentExample = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
`;

export const Block = styled.div`
  width: 193px;
  height: 0;
  border: 1px solid ${COLORS.INPUT_BLACK};
  margin: 3px;
  display: inline-block;
  margin-top: 12px;
  margin-bottom: 12px;
  font-family: ${theme.fonts.primaryFontRegular};
`;

export const HeadWrap = styled.div`
  text-align: center;
  font-size: 18px;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontBold};
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 25px;
  }
`;

export const SubHeadWrapHeading = styled.div`
  text-align: left;
  font-size: 20px;
  padding-left: 1.5%;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontRegular};
`;

export const TextLinesData = styled.div`
  padding-left: 30px;
  margin-left: 30px;
  text-align: justify;
`;
