import React, { useEffect } from "react";
import { Content, SubHeadWrap, BlogPage, BlogImage } from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import { Helmet } from "react-helmet";
import { HyperLink } from "../../common/FormInputs";

/* eslint-disable jsx-a11y/anchor-is-valid */

function OnlineDisputeBlogNine(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>Advantages of Online Dispute Resolution (ODR) - JustAct</title>
        <meta
          name="description"
          content="Advantages of ODR over conventional ADR is that it is less time-consuming. ODR is a branch of dispute resolution which uses technology to facilitate the resolution of disputes between parties."
        />
        <meta
          name="keywords"
          content="Advantages of Online Dispute Resolution, ODR, Alternative Dispute Resolution, Negotiation, Mediation, Arbitration, Resolve Disputes Online, JustAct"
        />
      </Helmet>
      <Header>
        <BlogPage>
          <SubHeadWrap>Advantages of ODR</SubHeadWrap>
          <br />
          <Content>
            <p>
              The world now bears witness to an unprecedented situation, with
              the pandemic and its conditions rendering aspects far from a sense
              of normal, even in the future. This has affected the very way of
              working across all sectors, including that of justice
              administration.
            </p>
            <br />
            <BlogImage
              src={require("../../../assets/images/JA_I1.png")}
              alt="Independent"
            />
            <br />
            <p>
              The existing situation is further compounded by a documented
              history of delays, and although the Supreme Court has allowed
              online hearings, it has become evident that the justice system is
              overburdened, bunged with tons of cases. This calls for an
              improvised, efficient solution to ease the pressure, one such
              being{" "}
              <HyperLink onClick={() => window.open(`https://justact.co.in/`)}>
                Online Dispute Resolution (ODR).
              </HyperLink>
            </p>
            <br />
            <p>
              A means to settle disputes out of court, ODR centres on combining
              technology with mechanisms of{" "}
              <HyperLink
                onClick={() =>
                  window.open(
                    `https://justact.co.in/training/corporate-short-programme`
                  )
                }
              >
                alternative dispute resolution (ADR).
              </HyperLink>{" "}
              ODR handles disputes that are settled over the internet (having
              been initiated there) but with a source outside (offline).
            </p>
            <br />
            <p>
              ODR offers a swift, transparent, and accessible option for company
              and person alike in order to resolve their disputes online. This
              particularly applies to those who have high volume cases with both
              low and high value.
            </p>
            <br />
            <BlogImage
              src={require("../../../assets/images/JA_I2.png")}
              alt="Independent"
            />
            <br />
            <p>
              The last five years have witnessed a significant acceleration and
              growth in India in the volume of online transactions. It therefore
              is only logical that ODR stands as an efficient mechanism to
              resolve disputes involved in the same, hence incentivizing the
              implementation of a fast and fair dispute resolution system.
            </p>
            <br />
            <p>
              Online Dispute Resolution emulates ADR in its utilization of
              negotiation, mediation or{" "}
              <a
                style={{
                  color: "blue",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open(
                    "https://justact.co.in/blog/understanding-arbitration-and-the-arbitration-act"
                  )
                }
              >
                arbitration{" "}
              </a>
              for resolving disputes. Under the same, various methodologies are
              used for communication and resolution. Synchronous ODR has parties
              communicating in real-time by means of various video-conferencing
              applications. By contrast, asynchronous form is not real-time and
              is executed via channels such as email.
            </p>
            <br />
            <p>
              Typically, online mediation starts with sending an email or phone
              call to the relevant parties with basic information about the
              proceedings. These are then proceeded with via virtual meetings.
              Each method of ODR is unique and efficient to itself, with the
              capability to be tailored as per the involved parties’ needs.
            </p>
            <br />
            <p>
              In recent times, there has been a paradigm shift in dispute
              resolution ODR platforms have facilitating kinds of dispute
              resolution for many national and international companies. These
              ODR platforms catalyse dispute resolution by merging{" "}
              <HyperLink
                onClick={() =>
                  window.open(
                    `https://justact.co.in/training/corporate-short-programme`
                  )
                }
              >
                ADR
              </HyperLink>{" "}
              methodologies with the latest in technological trends. With 43.9
              million civil court cases pending, it’s clear that ODR will rise
              to the occasion, with great{" "}
              <a
                style={{
                  color: "blue",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open(
                    "https://justact.co.in/blog/the-scope-and-relevance-of-ODR"
                  )
                }
              >
                relevance{" "}
              </a>
              toward addressing the pain points of the system. It is still
              nascent in the Indian legal ecosystem.
            </p>
            <br />
            <p>
              But with the electronic form of dispute resolution enabling many
              new possibilities previously unavailable to the conventional
              court, it can and will be adopted at a large scale in the long
              run. JustAct, offered by Lex Carta Private Limited is a ODR
              platform dedicated toward this purpose, and set up by lawyers and
              professionals to disrupt the way disputes are resolved.
            </p>
            <br />
            <p style={{ textAlign: "center" }}>
              "Peace is not the absence of conflict, but the ability to cope
              with it"
            </p>
            <br />
            <b style={{ textAlign: "center" }}>~ Mahatma Gandhi</b>
          </Content>
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default OnlineDisputeBlogNine;
