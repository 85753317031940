import React from "react";
import Axios from "axios";
import _ from "lodash";
import { states, CLAIMENT_STR } from "./constants";
export const getErrorMessage = (error) =>
  error?.message
    ? error.message
    : error?.error && typeof error.error === "string"
    ? error.error
    : typeof error === "string"
    ? error
    : error[0]?.message
    ? error[0]?.message
    : "Something went wrong!";

export const uploadToAws = async (url, file) => {
  return await new Promise(async (resolve, reject) => {
    try {
      const __ = await Axios.put(url, file);
      if (__) {
        resolve(url);
      } else {
        reject();
      }
    } catch (error) {
      reject(error);
    }
  });
};

export function getAddressInSingleLine(addressArr = {}) {
  if (Object.keys(addressArr).length === 6) {
    let newAddress = {
      ...addressArr,
      state: states.find((state) => state.value === addressArr?.state)?.label,
    };
    delete newAddress?.pin;
    return _.values(newAddress).join(" , ") + ` - ${addressArr?.pin}`;
  }
}

export function getFileName(uri) {
  if (uri?.length) {
    const names = uri?.split("/");
    if (names.length) {
      return names[names.length - 1];
    }
  }
}

export function getCaseLabelByStatus({
  status,
  agentRole,
  uploadedDocuments,
  subscriptionKind,
}) {
  switch (status) {
    case "draft":
      return "Draft";
    case "pendingRegistrationFee":
      return <div className="pending-payment">Awaiting Case Fee</div>;
    case "negotiationReached":
      return subscriptionKind === "adhoc" ? (
        "Settled"
      ) : (
        <div className="pending-payment">Awaiting Agreement Fee</div>
      );
    case "negotiationOngoing":
      return "Under Resolution";
    case "claimantPaidNegotiationSuccessFee":
      return uploadedDocuments && uploadedDocuments.length > 0 ? (
        "Settled"
      ) : agentRole === CLAIMENT_STR ? (
        "Awaiting Agreement"
      ) : (
        <div className="pending-payment">Awaiting Agreement Fee</div>
      );
    case "respondentPaidNegotiationSuccessFee":
      return uploadedDocuments && uploadedDocuments.length > 0 ? (
        "Settled"
      ) : agentRole !== CLAIMENT_STR ? (
        "Awaiting Agreement"
      ) : (
        <div className="pending-payment">Awaiting Agreement Fee</div>
      );
    case "awaitingRespondentAcceptance":
      return agentRole === "respondent" ? (
        <div className="pending-payment">Awaiting Case Fee</div>
      ) : (
        "Waiting For Respondent"
      );
    case "bothPaidNegotiationSuccessFee":
      return uploadedDocuments && uploadedDocuments.length > 0
        ? "Settled"
        : "Awaiting Agreement";
    case "waitingForCaseApproval":
      return "Waiting For Case Approval";
    case "waitingForArbitratorConfirmation":
      return "Waiting for Arbitrator Confimation";

    // new life cycle stages

    case "noticeToArbitrate":
      return "Notice to Arbitrate";
    case "arbitrationReferenced":
      return "Arbitration Referenced";
    case "arbitrationNominated":
      return "Arbitrator Nominated";
    case "arbitrationConfirmed":
      return "Arbitrator Confirmed";
    case "firstNoticeSent":
      return "First Notice Sent";
    case "statementOfClaimFiled":
      return "Statement of Claim Filed";
    case "awaitingForSec17Details":
      return "Awaiting for Sec 17 details";
    case "sec17PetitionFiled":
      return "Sec 17 Petition Filed";
    case "sec17Ordered":
      return "Sec 17 Ordered";
    case "awaitingSec17Execution":
      return "Awaiting Sec 17 Execution";
    case "sec17ExtensionMemoFiled":
      return "Sec 17 Extension Memo Filed";
    case "sec17ExtensionMemoOrdered":
      return "Sec 17 Extension Memo Ordered";
    case "fsOrdered":
      return "FS Ordered";
    case "attachmentOrdered":
      return "Attachment Ordered";
    case "garnisheeProhibitaryOrder":
      return "Garnishee Prohibitary Order";
    case "garnisheeFinalOrder":
      return "Garnishee Final Order";
    case "sec17Closed":
      return "Sec 17 Closed";
    case "statementOfDefenceFiled":
      return "Statement of Defence Filed";
    case "rejoinderFromClaimant":
      return "Rejoinder from Claimant";
    case "surrejoinderFromRespondent":
      return "Surrejoinder From Respondent";
    case "2ndNoticeMOM":
      return "2nd Notice MOM";
    case "evidenceFiledByClaimant":
      return "Evidence Filed by Claimant";
    case "evidenceFiledByRespondent":
      return "Evidence Filed by Respondent";
    case "crossExamination":
      return "Cross Examination";
    case "argumentsByClaimant":
      return "Arguments by Claimant";
    case "argumentsByRespondent":
      return "Arguments by Respondent";
    case "reservedForAward":
      return "Reserved for Award";
    case "awardPassed":
      return "Award Passed";
    case "quitByClaimant":
      return "Quit by Claimant";
    case "quitByRespondent":
      return "Quit by Respondent";
    case "resolutionFailed":
      return "Resolution Failed";
    case "closedByCaseManager":
      return "Closed By CaseManager";
    case "settledAndWithdrawn":
      return "Settled & Withdrawn";
    case "updatedAndWithdrawn":
      return "Updated & Withdrawn";
    default:
      return _.startCase(status);
  }
}

export function getPartyLabelByStatus(status) {
  switch (status) {
    case "draft":
      return "Draft";
    case "underReview":
      return "Under Verification";
    case "active":
      return "Active";
    default:
      return _.startCase(status);
  }
}

export function checkKeys(object) {
  let keys = [
    "dob",
    "houseNumber",
    "landmark",
    "locality",
    "state",
    "pin",
    "city",
  ];
  let is_missing = false;
  for (const key of keys) {
    if (!object[key]) {
      is_missing = true;
    }
  }
  return is_missing;
}

export function formatNumberToMetrics(value = "", unit = "") {
  if (!value || unit !== "INR") return value;

  var afterPoint = "";
  if (value.indexOf(".") > 0)
    afterPoint = value?.substring(value?.indexOf("."), value?.length);
  value = Math.floor(value);
  value = value?.toString();
  var lastThree = value?.substring(value.length - 3);
  var otherNumbers = value?.substring(0, value.length - 3);
  if (otherNumbers !== "") lastThree = "," + lastThree;
  var res =
    otherNumbers?.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
    lastThree +
    afterPoint;
  return res;
}

export const renderStatus = (value, { resolutionKind }) => {
  if (resolutionKind !== "negotiation") {
    return value?.replace(/Negotiation|Case/gi, _.capitalize(resolutionKind));
  }
  return value?.replace(/Negotiation|Case/gi, "Negotiation");
};

export const downloadWithDifferenFileName = (url, name) => {
  if (!name) name = url.split("\\").pop().split("/").pop();
  fetch(url, {
    headers: new Headers({
      Origin: window.location.origin,
    }),
    mode: "cors",
  })
    .then((response) => response.blob())
    .then((blob) => {
      let blobUrl = window.URL.createObjectURL(blob);
      forceDownload(blobUrl, name);
    })
    .catch((e) => console.error(e));
};

function forceDownload(blob, filename) {
  var a = document.createElement("a");
  a.download = filename;
  a.href = blob;
  a.target = "_blank";
  document.body.appendChild(a);
  a.click();
  a.remove();
}

/**
 * @description Function to parse time stamp
 * @param {*} data
 */
export const parseTimeStamp = (data) => {
  return data + "Z";
};

export const numberFormat = (value, currencyUnit) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: currencyUnit ? currencyUnit : "INR",
  }).format(value);

export function getFileNameDoc(uri) {
  if (uri?.length) {
    if (uri?.length > 1) {
      const names = uri?.map((item) => item?.split("/"));
      if (names.length) {
        return "Multiple";
      }
    } else {
      const names = uri?.map((item) => item?.split("/"));
      if (names.length) {
        return names.map((data) => data[data.length - 1]);
      }
    }
  }
}

export function randomAlphaNumeric() {
  let s = "";
  let length = 6;
  Array.from({ length }).some(() => {
    s += Math.random().toString(36).slice(2);
    return s.length >= length;
  });
  return s.slice(0, length);
}

export function convertTo12HourFormat(timeString) {
  // Parse the input time string
  const [hours, minutes] = timeString.split(":").map(Number);

  // Determine AM or PM
  const period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Create the formatted time string
  const formattedTime = `${formattedHours}:${minutes} ${period}`;

  return formattedTime;
}
