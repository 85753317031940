import React, { useState, useEffect } from "react";
import ActionBar from "../../common/ActionBar";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import AWSService from "../../../services/AWSService";
import { getErrorMessage, getFileName } from "../../../helpers/functions";
import { Formik } from "formik";
import styled from "styled-components";
import theme from "../../../assets/theme";
import { CustomFileUpload } from "../../common/FormInputs";
import COLORS from "../../../assets/Colors";
import PartySelect from "./component/PartySelect";
import PartyService from "../../../services/PartyService";
import { StyledFormElementsWrapper, Button } from "./Styles";
import FormField from "../../common/FormField/FormField";
import { useMediaQuery, useTheme } from "@material-ui/core";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../common/Buttons";
import { BulkNoticeGenerationSchema } from "../../../helpers/validationSchema";
import { navigate } from "@reach/router";
import NoticeService from "../../../services/NoticeService";

/* eslint-disable jsx-a11y/anchor-is-valid */

const NoticeGeneration = () => {
  const [menuItems, setMenuItems] = useState([]);
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const themes = useTheme();
  const isBigScreen = useMediaQuery(themes.breakpoints.up(640));
  const [menuNotice, setMenuNotice] = useState([]);
  const [menuTemplateCode, setMenuTemplateCode] = useState([]);
  const [menuHeaderFooter, setMenuHeaderFooter] = useState([]);
  const [letterHeadArr, setLetterHeaderArr] = useState([]);

  useEffect(() => {
    async function getParties(params) {
      try {
        setLoader({ state: true });
        const response = await PartyService.getParties(
          "?perPage=4000&status=active"
        );
        if (response?.data?.length) {
          setMenuItems(
            response.data.map(({ name, id, owner }) => ({
              label: name,
              value: id,
              email: owner?.email,
            }))
          );
        } else {
          enqueueSnackbar(
            "You need to create a Party inorder to create a case",
            {
              variant: "error",
              autoHideDuration: 3000,
            }
          );
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }
    getParties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getNoticeTemplates(partyId) {
    try {
      setLoader({ state: true, message: "fetching notice details..." });
      const response = await NoticeService.getNoticeTemplates(partyId);
      if (response[0]) {
        setMenuNotice(
          response[0]?.noticeCategory?.map((item) => ({
            label: item,
            value: item,
          }))
        );
        setMenuTemplateCode(response[0]?.codeAndTemplates);
        const arrayOfObject = response[0]?.letterHeadArr.map((item) => ({
          label: item,
          value: item,
        }));
        setMenuHeaderFooter([{ label: "None", value: "" }, ...arrayOfObject]);
        setLetterHeaderArr(response[0]?.letterHeadOption);
      }
    } catch (err) {
      throw err;
    } finally {
      setLoader({ state: false });
    }
  }

  async function s3Upload(file, field, setFieldValue) {
    if (file) {
      try {
        const types = [
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel",
          "text/csv",
        ];
        setLoader({ state: true, message: `Uploading file ${file.name}` });
        const errorMessage = "Invalid File Type";
        if (!types.includes(file?.type)) throw errorMessage;
        const payload = {
          key: file.name,
        };
        const s3Response = await AWSService.getS3URL(payload);
        if (s3Response.url) {
          const res = await AWSService.uploadToS3(s3Response.url, file);
          setFieldValue(field, res);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, { variant: "error" });
      } finally {
        setLoader({ state: false });
      }
    }
  }

  async function onFormSubmit(values, { resetForm }) {
    try {
      setLoader({ state: true, message: "Please wait" });
      const payload = {
        partyId: values?.partyId,
        noticeCategory: values?.noticeCategory,
        templateCode: values?.templateCode,
        excelFile: values?.excelFile,
        lotNo: values?.lotNo,
        noticeTemplate:
          menuTemplateCode &&
          menuTemplateCode[values?.noticeCategory].filter(
            (template) => template?.templateCode === values?.templateCode
          )[0]?.noticeTemplate,
        letterHeadName: values?.letterHeadName,
        letterHeadImages: values?.letterHeadName
          ? letterHeadArr[values?.letterHeadName]?.length &&
            letterHeadArr[values?.letterHeadName][0]
          : { headerImage: "", footerImage: "" },
      };
      const response = await NoticeService.bulkNoticeGenerate(payload);
      if (response) {
        enqueueSnackbar(response?.message, {
          variant: "success",
        });
        resetForm();
      }
    } catch (err) {
      const message = getErrorMessage(err);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  return (
    <Container>
      <ActionBar breadcrumbs={["Notice Generation"]} />
      <CreateContainer className="CreateCase">
        <Formik
          initialValues={{
            partyId: "",
            noticeCategory: "",
            templateCode: "",
            lotNo: "",
            excelFile: "",
            letterHeadName: "",
          }}
          validationSchema={BulkNoticeGenerationSchema}
          enableReinitialize
          onSubmit={onFormSubmit}
          validateOnBlur
          validateOnChange
        >
          {({
            values,
            handleChange,
            errors,
            touched,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldError,
          }) => (
            <StyledForm>
              <PartySelect
                {...{
                  values,
                  handleChange,
                  handleSubmit,
                  errors,
                  touched,
                  handleBlur,
                  setFieldValue,
                  menuItems,
                  getNoticeTemplates,
                }}
              />
              <StyledFormElementsWrapper
                style={{ marginBottom: 15, marginTop: 15 }}
              >
                <div className="row">
                  <FormField
                    white
                    input={{
                      type: "select",
                      name: "noticeCategory",
                      label: "Notice Category",
                      required: true,
                    }}
                    menuItems={menuNotice}
                    {...{
                      values,
                      errors,
                      handleSubmit,
                      touched,
                      handleBlur,
                      setFieldValue,
                      handleChange,
                    }}
                  />
                </div>
              </StyledFormElementsWrapper>
              <StyledFormElementsWrapper
                style={{ marginBottom: 15, marginTop: 15 }}
              >
                <div className="row">
                  <FormField
                    white
                    input={{
                      type: "select",
                      name: "templateCode",
                      label: "Template Code",
                      required: true,
                    }}
                    menuItems={
                      menuTemplateCode[values?.noticeCategory]?.length
                        ? menuTemplateCode[values?.noticeCategory]?.map(
                            ({ templateCode }) => ({
                              label: templateCode,
                              value: templateCode,
                            })
                          )
                        : []
                    }
                    {...{
                      values,
                      handleChange,
                      errors,
                      handleSubmit,
                      touched,
                      handleBlur,
                      setFieldValue,
                    }}
                  />
                </div>
              </StyledFormElementsWrapper>
              <StyledFormElementsWrapper
                style={{ marginBottom: 15, marginTop: 15 }}
              >
                <div className="row">
                  <FormField
                    white
                    input={{
                      type: "select",
                      name: "letterHeadName",
                      label: "Letter Head Name",
                      required: false,
                    }}
                    menuItems={menuHeaderFooter}
                    {...{
                      values,
                      handleChange,
                      errors,
                      handleSubmit,
                      touched,
                      handleBlur,
                      setFieldValue,
                    }}
                  />
                </div>
              </StyledFormElementsWrapper>
              <StyledFormElementsWrapper
                style={{ marginBottom: 15, marginTop: 15 }}
              >
                <FormLabel>
                  Upload excel template{" "}
                  <span className="required-star"> *</span>
                  <Subheading>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        menuTemplateCode[values?.noticeCategory]?.length &&
                        menuTemplateCode[values?.noticeCategory].filter(
                          (template) =>
                            template?.templateCode === values?.templateCode
                        )[0]?.excelTemplate
                      }
                    >
                      Please download the excel template
                    </a>
                  </Subheading>
                </FormLabel>
                <CustomFileUpload
                  error={touched?.excelFile && errors?.excelFile}
                  setFile={(file) => s3Upload(file, `excelFile`, setFieldValue)}
                  style={{
                    marginTop: 3,
                    width: "100%",
                  }}
                  accept=".xlsx"
                  file={{ name: getFileName(values?.excelFile) }}
                />
              </StyledFormElementsWrapper>
              <StyledFormElementsWrapper
                style={{ marginBottom: 15, marginTop: 15 }}
              >
                <div className="disabledArrows">
                  <FormField
                    white
                    input={{
                      type: "number",
                      name: "lotNo",
                      label: "Lot Number",
                      required: true,
                    }}
                    {...{
                      values,
                      handleChange,
                      errors,
                      handleSubmit,
                      touched,
                      handleBlur,
                      setFieldValue,
                    }}
                  />
                </div>
              </StyledFormElementsWrapper>
              <StyledFormElementsWrapper
                style={{
                  display: "flex",
                  justifyContent: isBigScreen ? "space-between" : "flex-start",
                  marginTop: "25px",
                }}
              >
                <Button
                  style={{
                    marginLeft: "unset",
                  }}
                >
                  <PrimaryOutlinedCTAButton
                    onClick={() => navigate(`/dashboard`)}
                  >
                    Back
                  </PrimaryOutlinedCTAButton>
                </Button>
                <Button
                  style={{
                    marginLeft: "22px",
                  }}
                >
                  <PrimaryCTAButton onClick={handleSubmit}>
                    Generate Notice
                  </PrimaryCTAButton>
                </Button>
              </StyledFormElementsWrapper>
            </StyledForm>
          )}
        </Formik>
      </CreateContainer>
    </Container>
  );
};

export default NoticeGeneration;

const Container = styled.div`
  border-radius: 10px;
  background-color: ${COLORS.PRIMARY_WHITE};
  margin: 40px 15px;
  padding-top: 1px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
    padding-top: 0;
    border-radius: 0;
    background-color: transparent;
  }
`;

const CreateContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  padding-bottom: 12px;
  padding-left: 13px;
  padding-right: 11px;
  @media ${theme?.breakpoints?.sm_up} {
    padding-left: 46px;
    padding-right: 0;
    margin-top: 0;
    padding-bottom: 0;
  }
`;

const StyledForm = styled.form`
  @media ${theme?.breakpoints?.sm_up} {
    width: 505px;
  }
  & .heading {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    & .delete {
      height: 20px;
      width: 20px;
      margin-bottom:15px;
      cursor: pointer;
      @media ${theme?.breakpoints?.sm_up} {
        margin: 34px 0 21px 0;
      }
    }
`;

const Subheading = styled.div`
  margin-bottom: 5px;
  & a {
    margin-left: 10px;
    font-family: ${theme.fonts.primaryFontBold};
    color: ${COLORS.BTN_GREEN};
    text-decoration: underline;
    cursor: pointer;
  }
`;

const FormLabel = styled.div`
  display: flex;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  line-height: 1.7;
  text-transform: capitalize;
  color: ${COLORS.INPUT_LABEL};
  margin-right: 8px;
`;
