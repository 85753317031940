import React from "react";
import { Header } from "../../internal/Header/Header";
import {
  HeroContainerWrap,
  HeroContainer,
  HeroHeading,
  H1,
  H3,
  WidthMax,
} from "./Styles";
import Footer from "./Footer";
import Cookies from "./Cookies.js";
import useIfLoggedIn from "../../../hooks/useIfLoggedIn";
import labels from "../../../helpers/labels.json";
import HowItWorks from "./Sections";
import OnlineDispute from "./OnlineDispute";
import { PrimaryCTAButton } from "../../common/Buttons";
import { navigate } from "@reach/router";
import Blogs from "./Blogs";
import { Helmet } from "react-helmet";
import { sanitize } from "dompurify";
import styled from "styled-components";

const Index = () => {
  useIfLoggedIn();

  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>
          Online Dispute Resolution (ODR) Services - Online Arbitration &
          Mediation
        </title>
        <meta
          name="description"
          content="JustAct is an Online Dispute Resolution (ODR) platform and is set up by lawyers & professionals. Resolve disputes out of courts through negotiation, mediation and arbitration"
        />
        <meta
          name="keywords"
          content="Online Dispute Resolution, ODR Services, Online Arbitration & Mediation, Dispute Resolution Online, Alternative Dispute Resolution, ADR Services, Online Dispute Resolution in India, JustAct"
        />
      </Helmet>

      <Header LandingPage NavPages>
        <WidthMax>
          <Cookies />
          <HeroContainerWrap id="hero">
            <HeroContainer>
              <div className="hero-container">
                <ProfileImage
                  className="hero-image"
                  src={require("../../../assets/images/landing-image.png")}
                  alt="hero-image"
                />
              </div>
              <HeroHeading>
                <H1
                  dangerouslySetInnerHTML={{
                    __html: sanitize(labels.landing_title),
                  }}
                />
                <H3>{labels["landing.subheading"]}</H3>
                <H3 style={{ marginBottom: 20 }}>
                  {labels["landing.subheading2"]}
                </H3>
                <div style={{ maxWidth: 150, marginTop: 10 }}>
                  <PrimaryCTAButton
                    style={{
                      marginLeft: "unset",
                    }}
                    onClick={() => navigate("/neutral/mediators")}
                  >
                    Browse
                  </PrimaryCTAButton>
                </div>

                {/* <div style={{ marginTop: "130px" }}>
                <Carousel
                  interval="8000"
                  animation="slide"
                  navButtonsAlwaysVisible="true"
                >
                  <CarouselWrap>
                    <Link
                      to="contact_us"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      <CarousalText> Want to talk to experts?</CarousalText>

                      <ButtonCont>
                        <PrimaryStyledButton>
                          {labels["landing.herocta"]}
                        </PrimaryStyledButton>
                      </ButtonCont>
                    </Link>
                  </CarouselWrap>

                  <CarouselWrap>
                    <div onClick={() => navigate("/signup")}>
                      <CarousalText>
                        {" "}
                        Want to register your first case?
                      </CarousalText>
                      <ButtonCont>
                        <PrimaryStyledButton>Sign Up</PrimaryStyledButton>
                      </ButtonCont>
                    </div>
                  </CarouselWrap>

                  <CarouselWrap>
                    <div onClick={() => navigate("/training/40")}>
                      <CarousalText>Want to become a Mediator?</CarousalText>
                      <ButtonCont>
                        <PrimaryStyledButton>Training</PrimaryStyledButton>
                      </ButtonCont>
                    </div>
                  </CarouselWrap>
                </Carousel>
              </div> */}
              </HeroHeading>
              {/* <ImgWrapper>
              <img
                src={require("../../../assets/images/HeroImg@3x.png")}
                style={{ marginTop: 40 }}
                width="100%"
                alt="HeroImg"
              />
            </ImgWrapper> */}
            </HeroContainer>
          </HeroContainerWrap>
          {/* <div id="contact_us">
          <ScheduleForm />
        </div> */}

          <OnlineDispute />

          <div style={{ backgroundColor: "white", paddingBottom: 40 }}>
            <HowItWorks />
          </div>

          <Blogs />

          {/* <AboutUs /> */}
          <Footer />
        </WidthMax>
      </Header>
    </>
  );
};
export default Index;

export const ProfileImage = styled.img``;
