import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { PrimaryCTAButton } from "../../common/Buttons";

export const WidthMax = styled.div`
  width: 100vw;
  overflow: hidden;
`;

export const PrimaryStyledButton = styled(PrimaryCTAButton)`
  width: 100%;
  margin-top: 40px;
  color: ${COLORS.PRIMARY_WHITE};
  border: solid 1.3px ${COLORS.PRIMARY_WHITE};
  border-radius: 6px;
  font-size: 18px;
  font-family: ${theme.fonts.primaryFontRegular};
  background-color: #ffffff00;

  @media (max-width: 600px) {
    font-size: 16px;
    margin-top: 20px;
  }
`;

export const HeroContainerWrap = styled.section`
  width: 100%;
  height: 100%;
  background-color: ${COLORS.PRIMARY_WHITE};
`;

export const HeroContainer = styled.div`
  width: 100%;
  height: 500px;
  position: relative;
  background-color: ${COLORS.BTN_GREEN};
  background-image: linear-gradient(to top, #29346100, ${COLORS.COLOR_DARK});
  & .hero-container {
    width: 100%;
    height: 100%;
    & .hero-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.8;
    }
  };
 

`;

//commented for new home page changes
// export const HeroContainer = styled.div`
//   width: 100%;
//   height: 700px;
//   position: relative;
//   // background-color: ${COLORS.BTN_GREEN};
//   // background-image: linear-gradient(to top, #29346100, ${COLORS.COLOR_DARK});
//   // background: url(${require("../../../assets/images/landing-image.jpg")})
//   //   no-repeat;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   position: relative;
//   @media (max-width: 600px) {
//     height: 650px;
//   }
// `;
export const HeroHeading = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  margin-left: 100px;
  @media (max-width: 700px) {
    margin-left: 0;
    align-items: center;
    text-align: center;
    margin-top: 40px;
    // font-size:12px;
  }
`;

export const CookieWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${COLORS.PRIMARY_WHITE};
  box-shadow: 0 -3px 28px 0 rgba(0, 0, 0, 0.1);
  border-top: solid 0.2px ${COLORS.BORDER_GREY};

  @media (max-width: 600px) {
    height: 150px;
  }
`;
export const Text = styled.div`
  margin-left: 65px;
  width: 885px;
  height: 34px;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 12px;
  line-height: 1.42;
  color: ${COLORS.COLOR_DARK};
  @media (max-width: 600px) {
    line-height: 1.7;
    margin-left: 45px;
    height: unset;
    width: 600px;
    padding-right: 20px;
  }
`;
export const H1 = styled.h1`
  margin-top: 200px;
  cursor: default;
  font-family: ${theme.fonts.primaryFontRegular};
  font-weight: 300;
  font-size: 48px;
  line-height: 1.25;
  color: ${COLORS.PRIMARY_WHITE};
  & span {
    font-family: ${theme.fonts.primaryFontBold};
    font-size: 60px;
  }
  @media (max-width: 700px) {
    font-size: 28px;
    height: 20px;
    & span {
      font-size: 48px;
    }
  }
  @media (max-width: 550px) {
    margin-bottom: 0;
    font-size: 20px;
    height: 40px;
    & span {
      font-size: 36px;    }
  }
`;
export const ButtonCont = styled.div`
  width: 372px;
  margin: 0 auto;
  & button {
    & span {
      line-height: 1.6;
    }
  }
  @media (max-width: 600px) {
    width: 100%;
    padding: 0px 20px;
  }
`;
export const H2 = styled.h2`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
  font-size: 30px;
  margin-bottom: 32px;
  cursor: default;
  padding: 0 20px;
  margin: 0 auto;
  @media (max-width: 1024px) {
    // font-size: 32px;
    text-align: center;
    
  }
  @media (max-width: 600px) {
    font-size: 24px;
  }
`;
export const H3 = styled.h3`
  cursor: default;
  margin-bottom: 20px;
  font-family: ${theme.fonts.primaryFontRegular};
  font-weight: 300;
  font-size: 22px;
  line-height: 1.27;
  color: ${COLORS.PRIMARY_WHITE};
  @media (max-width: 700px) {
    // line-height: 1.7;
    height: unset;
    padding: 6px 8px;
    font-size: 12px;
    margin-bottom: 0px;
  }

  @media (max-width: 550px) {
    font-size: 12px;

  }
`;
export const P = styled.p`
  font-family: ${theme.fonts.primaryFontRegular};
  color: ${COLORS.COLOR_DARK};
  font-size: 16px;
  line-height: 1.56;
  width: 978px;
  height: 217px;
  cursor: default;
  margin-top: 20px;
  & p {
    margin-top: 20px;
  }
  & div {
    margin-left: 40px;
    & span {
      margin-right: 20px;
    }
    @media (max-width: 600px) {
      margin-left: 20px;
      font-size: 14px;
      & span {
        margin-right: 10px;
      }
    }
  }
  @media (max-width: 1024px) {
    width: 80%;
  }
`;
export const ImgWrapper = styled.div`
  position: absolute;
  bottom: -50%;
  max-width: 1152px;
  height: 804px;
  @media (max-width: 600px) {
    position: relative;
    bottom: 0;
    width: 100%;
    height: unset;
  }
`;
export const CarouselWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 250px;
  width: 900px;
  margin: 0 100px 20px 100px;
  background-image: linear-gradient(to bottom right, #00000022, #00000088);
  padding: 20px 40px 30px 40px;
  border-radius: 2%;
  cursor: pointer;
  box-shadow: 5px 5px 8px #00000044;
  @media (max-width: 1024px) {
    height: unset;
    width: 600px;
    padding: 20px 10px 30px 10px;
    margin: 0 70px 20px 70px;
  }
  @media (max-width: 700px) {
    height: unset;
    width: 400px;
    padding: 20px 10px 30px 10px;
  }
  @media (max-width: 400px) {
    height: unset;
    width: 275px;
    padding: 20px 10px 30px 10px;
  }
`;
export const CarousalText = styled.div`
  font-size: 40px;
  font-family: ${theme.fonts.primaryFontRegular};
  font-weight: 300;
  color: ${COLORS.PRIMARY_WHITE};
  text-align: center;
  @media (max-width: 1024px) {
    font-size: 32px;
  }
  @media (max-width: 700px) {
    font-size: 20px;
  }
  @media (max-width: 350px) {
    font-size: 16px;
  }
`;
export const SectionGrey = styled.section`
  height: 469px;
  border-radius: 14px;
  background-color: #f4f6ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 90px;
  width: 100vw;
  @media (max-width: 440px) {
    height: 750px;
  }

  @media (min-width: 440px) and (max-width: 768px) {
    height: 600px;
  }
`;
export const Section = styled.section`
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    width: 100%;
    p {
      height: unset;
      margin-bottom: 20px;
    }
  }
`;
export const ProductVideos = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1124px;
  margin-top: 20px;
  @media (max-width: 1024px) {
    width: 80%;
    flex-direction: column;
    padding: 0 20px;
  }
`;
export const ProductVideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  @media (max-width: 600px) {
    iframe {
      height: 190px;
      width: 340px;
    }
  }
`;
export const ProductVideoText = styled.p`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
  height: 38px;
  font-size: 14px;
  margin-top: 30px;
  cursor: default;
`;

export const Bold = styled.div`
  font-size: 16px;
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
`;
export const Light = styled.div`
  font-size: 16px;
  font-family: ${theme.fonts.primaryFontLight};
  color: ${COLORS.COLOR_DARK};
`;
export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;

  background-color: white;
`;
export const Form = styled.form`
  width: 413px;
  margin: auto;
  @media (max-width: 600px) {
    width: 90%;
  }
`;

export const FormElWrapper = styled.div``;
export const ButtonWrapper = styled.div`
  width: 414px;
  margin-bottom: 56px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;
export const FooterWrapper = styled.div`
  background-color: #293461;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    height: unset;
    padding: 20px 0;
  }
`;
export const LogoWrapper = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 20px;
  font-weight: 500;
  cursor: default;
  margin: 33px 0 52px;
  cursor: pointer;
`;
export const CompanyLogoWhite = styled.img`
  width: 78px;
  height: 80px;
`;
export const NavLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 20px;

  @media (max-width: 1024px) {
    flex-direction: row;
  }
`;
export const NavLink = styled.div`
  margin: 0 19px;
  cursor: pointer;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 25px;
  color: ${COLORS.PRIMARY_WHITE};
  transition: 350ms color;
  &:hover {
    color: ${COLORS.INPUT_LABEL};
  }
  @media (max-width: 1024px) {
    padding: 10px 0;
    font-size: 16px;
  }
  @media (max-width: 700px) {
    padding: 10px 0;
    margin: 0 10px;
    font-size: 16px;
  }
`;
export const Copyrights = styled.div`
  margin: 0 0 34px;
  opacity: 0.37;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 12px;
  color: ${COLORS.PRIMARY_WHITE};
  cursor: default;
  text-align: center;
  @media (max-width: 1024px) {
    width: 100%;
    padding: 0 40px;
    text-align: center;
  }
`;

export const LargeSidebar = styled.div`
  display: none;
  @media ${theme?.breakpoints?.sm_up} {
    display: block;
  }
`;

export const SmallSidebar = styled.div`
  display: block;
  @media ${theme?.breakpoints?.sm_up} {
    display: none;
  }
`;


