import React, { useEffect } from "react";
import { Content, SubHeadWrap, BlogPage } from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

/* eslint-disable jsx-a11y/anchor-is-valid */

function EnterprisesDevelopmentPart1(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header>
        <BlogPage>
          <HeadWrap>
            ADR PROCESSES WITHIN THE MICRO, SMALL AND MEDIUM ENTERPRISES
            DEVELOPMENT ACT, 2006 Part I
          </HeadWrap>
          <br />
          <br />
          <Content>
            <b
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontStyle: "italic",
              }}
            >
              {" "}
              Authored by : Aprameya Manthena{" "}
            </b>
            <SubHeadWrap style={{ marginTop: "10px", marginBottom: "15px" }}>
              Introduction
            </SubHeadWrap>
            <p>
              The Micro, Small &amp; Medium Enterprises (MSMEs) have a pivotal
              role to play in the creation of employment opportunities within
              developing areas of the country. MSMEs increase access of
              otherwise backward areas to processes of industrialization and
              development and are thus crucial to the proper distribution of
              resources and income. MSMEs are organised on little capital, and
              small workforces in keeping with local conditions and
              requirements.
            </p>
            <br />
            <p>
              Large industries have infrastructural, financial and
              organisational constraints which do not apply to the MSME sector.
              Therefore, the MSME sector is free to explore flexible
              alternatives to growth and development, in keeping with localised
              contexts. The Ministry of MSME has the duty of promoting the
              growth and development of Khadi, Village and Coir Industries in
              cooperation with concerned Ministries/Departments, State
              Governments and other Stakeholders, through providing support to
              existing enterprises and encouraging creation of new enterprises.
              <sup>1</sup>
            </p>
            <br />
            <p>
              As per the provisions of MSMED Act, 2006, the State
              Governments/UTs have been empowered to make rules in respect of
              MSEFCs dealing with the cases of delayed payment to MSME units.
              The Office of DC (MSME) in September, 2006 had circulated draft
              Rules for MSEFC to the States/UTs for their consideration. The
              Ministry of MSME revised the draft Rules based on discussions with
              the stakeholder and the draft revised Rules were placed as an
              advisory for consideration by the States/UTs. <sup>2</sup>
            </p>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              Provisions under the MSMED Act, 2006 relating to ADR
            </SubHeadWrap>
            <SubHeadWrap
              style={{
                marginBottom: "15px",
                fontStyle: "italic",
              }}
            >
              Section 18. Reference to Micro and small Enterprises Facilitation
              Council
            </SubHeadWrap>
            <p>
              The parties to a dispute may make a reference to the Facilitation
              Council in regard to any amount due u/s 17 of the MSME Act{" "}
              <b>(Section 18 (1)).</b> This contains a non-obstante clause which
              brings matters under the purview of the Facilitation Council.
            </p>
            <br />
            <p>
              The Council, on receipt of the reference, shall itself conduct
              conciliation in the matter or seek the assistance of an ADR
              institution by making a reference to such institution. The
              sections 65-81 <sup>3</sup> of the A &amp; C Act, 1996 shall apply
              as if it were a conciliation under Part III of the Act{" "}
              <b>(Section 18 (2))</b>
            </p>
            <br />
            <p>
              The Council would take up the dispute for arbitration or refer it
              to an ADR institution in case of failure of the conciliation
              process under sub-section (2) and the provisions of the A &amp; C
              Act would apply as if the arbitration was in pursuance of an
              arbitration agreement <b>(Section 18 (3))</b>
            </p>
            <br />
            <p>
              Both the Facilitation Council and the ADR institution shall have
              jurisdiction to act as Arbitrator and Conciliator where the
              dispute is between a supplier located within its jurisdiction and
              a buyer located anywhere in India. <b>(Section 18 (4))</b>
            </p>
            <br />
            <p>
              Every reference made under this section shall be decided within a
              period of ninety days from the date of making such a reference{" "}
              <b>(Section 18 (5))</b>
            </p>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              Section 19. Application for setting aside decree, award or order.
            </SubHeadWrap>
            <p>
              <b>Section 19</b> discusses the application process for setting
              aside the decree, award or order passed by the Facilitation
              Council.
            </p>
            <br />
            <p>
              Seventy five percent of the amount in terms of the decree, award
              or order has to be deposited by the appellant (not being a
              supplier) with a court before the application for setting aside a
              decree, award or order made by the Facilitation Council or the ADR
              institution shall be entertained.
            </p>
            <br />
            <p>
              The proviso creates the condition wherein the court shall order
              that such percentage of the amount deposited shall be paid to the
              supplier (pending the disposal of the application) as is
              considered reasonable.
            </p>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              Section 21. Composition of Micro and Small Enterprises
              Facilitation Council.—
            </SubHeadWrap>
            <p>
              As per Section 21 of the MSMED Act, the Facilitation Council shall
              consist of not less than three and not more than five members to
              be appointed from the Director of Industries{" "}
              <b>(Section 21 (1)).</b> office-bearers or representatives of
              associations of MSME, representatives of banks and financial
              institutions lending to MSMEs and persons having special knowledge
              in the fields of industry, finance, law, trade or commerce{" "}
              <b>(Section 21 (1) (i, ii, iii, iv).</b>
            </p>
            <br />
            <p>
              The Chairperson of the Facilitation Council shall be the person
              appointed under <b>Section 21 (1) (i).</b> The composition,
              vacancies and the procedures to be followed in the discharge of
              functions shall be prescribed by the State government{" "}
              <b>(Section 21 (3)).</b>
            </p>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              Case Laws
            </SubHeadWrap>
            <p>
              In Silpi Industries Etc. v. Kerala State Road Transport
              Corporation <sup>4</sup> , the Supreme Court decided that to seek
              the benefit of provisions under MSMED Act, the seller should have
              registered under the provisions of the Act, as on the date of
              entering into the contract.
            </p>
            <SubHeadWrap
              style={{
                marginBottom: "10px",
                marginTop: "10px",
                marginLeft: "15px",
              }}
            >
              1. The status of the arbitration agreement under the MSMED Act,
              2006
            </SubHeadWrap>
            <p>
              When the Director makes a reference on behalf of the Council to an
              Arbitrator, it must be taken that it was not possible to
              conciliate and resolve the dispute itself and therefore, the
              reference was made. It would make no difference that the reference
              is made to an institution or centre which will provide for
              alternative dispute resolution services or a direct reference to
              the arbitrator itself. There can be really no prejudice at all for
              the petitioner, for, there is no issue of bias.
            </p>
            <br />
            <p>
              An independent arbitration agreement must be taken only as an
              additional method of appointment of an arbitrator and cannot
              exclude the application of the provisions of this Act. The non
              obstante clause that is set forth under Section 18 which begins
              with these expressions &quot;notwithstanding anything contained in
              any other law for the time being in force&quot; has sway. A
              contract that provides for appointment of an arbitrator must be
              seen as a contract as recognized by law and that provision will
              stand eclipsed by the non obstante clause that Section 18 provides
              for. <sup>5</sup>
            </p>
            <SubHeadWrap
              style={{
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              Regarding the arbitration clause in contracts
            </SubHeadWrap>
            <p>
              This judgment discusses when the appointment of a sole arbitrator
              as per the agreements drawn up by the parties is in conflict with
              the jurisdiction of the Arbitral Tribunal (i.e. the MSME Council)
              in regard to a dispute. <sup>6</sup>
            </p>
            <br />
            <p>
              In this matter, the request for conciliation was rejected by one
              of the parties and hence, the conciliation proceedings were
              believed to be terminated. Soon after, the party whose
              conciliation request was rejected initiated arbitration
              proceedings and the notice was sent to the opposite party. In the
              meantime, a sole arbitrator was appointed. Thereafter, a grievance
              was raised that the appointment of a sole arbitrator was unfair
              since it tantamounted to the invocation of the same cause of
              action before a different forum.
            </p>
            <br />
            <p>
              As per the mandate of the MSMED Act, once the conciliation
              proceedings fail, the Council has the power under Section 18 (3)
              to initiate the arbitration proceedings. Here, the Council came to
              the conclusion that since the conciliation proceedings came to an
              end, the arbitration proceedings would start before the Council,
              and that the Council itself would work as the Arbitral Tribunal.
            </p>
            <SubHeadWrap
              style={{
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              Absence of arbitration agreement
            </SubHeadWrap>
            <p>
              In this judgment<sup>7</sup> it was held that the express
              provision under Section 24 spells out the overriding effect of the
              MSMED Act. If the statute does not save the sanctity of specific
              terms of contracts by making express provision that it shall be
              subject to any contract to the contrary, it must be read so that
              the legislation prevails over the individual volition of parties.
            </p>
            <br />
            <p>
              It does not exclude a construction that whenever there is an
              arbitration clause, the Council does not have a power to act as an
              Arbitrator. Such an interpretation would render nugatory the first
              portion of Section 18(3) that allows it to proceed to arbitrate.
            </p>
            <br />
            <p>
              Where the Council has constituted itself as an arbitrator, it is
              akin to the process of appointment of arbitrator and setting the
              arbitral process in motion. Therefore, the need for appointment of
              arbitrator under Section 11 of the Arbitration &amp; Conciliation
              Act does not arise.
            </p>
            <Block />
            <p>
              <sup>1</sup>
              <a
                style={{
                  color: "blue",
                  cursor: "pointer",
                  marginLeft: "3px",
                }}
                onClick={() =>
                  window.open("https://msme.gov.in/about-us/about-us-ministry")
                }
              >
                https://msme.gov.in/about-us/about-us-ministry
              </a>
            </p>
            <p>
              <sup>2</sup>{" "}
              <a
                style={{
                  color: "blue",
                  cursor: "pointer",
                  marginLeft: "3px",
                }}
                onClick={() =>
                  window.open(
                    "https://dcmsme.gov.in/Draft%20revised%20rules_23916.pdf",
                  )
                }
              >
                https://dcmsme.gov.in/Draft%20revised%20rules_23916.pdf
              </a>
            </p>
            <p>
              <sup>3</sup> The provisions of Sections 65 to 81 of the
              Arbitration and Conciliation Act, deals with, Submission of
              statements to conciliator, Conciliator not bound by certain
              enactments, Role of conciliator, Administrative assistance,
              Communication between conciliator and parties, Disclosure of
              information, Cooperation of parties with conciliator, Suggestions
              by parties for settlement of dispute, Settlement agreement, Status
              and effect of settlement agreement, Confidentiality, Termination
              of conciliation proceedings, Resort to arbitral or judicial
              proceedings, Costs, Deposits, Role of conciliator in other
              proceedings and Admissibility of evidence in other proceedings,
              respectively.
            </p>
            <p>
              <sup>4</sup> 2021 SCC OnLine SC 439
            </p>
            <p>
              <sup>5</sup> The Chief Administrative Officer, COFMOW Versus The
              Micro &amp; Small Enterprises Facilitation Council of Haryana and
              others, CWP No.277 of 2015, Paras 2 and 3
            </p>
            <p>
              <sup>6</sup> Bharat Sanchar Nigam Limited v. Maharashtra Micro and
              Small Enterprises, 2015 SCC OnLine Bom 4145, Para 15 to 21
            </p>
            <p>
              <sup>7</sup> Welspun Corp. Ltd. Versus Micro and Small, Medium
              Enterprises Facilitation Council, Punjab and others, 2011 SCC
              Online P &amp; H 16956, Para 6
            </p>
          </Content>
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default EnterprisesDevelopmentPart1;

export const ContentExample = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
`;

export const Block = styled.div`
  width: 193px;
  height: 0;
  border: 1px solid ${COLORS.INPUT_BLACK};
  margin: 3px;
  display: inline-block;
  margin-top: 12px;
  margin-bottom: 12px;
  font-family: ${theme.fonts.primaryFontRegular};
`;

export const HeadWrap = styled.div`
  text-align: center;
  font-size: 18px;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontBold};
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 25px;
  }
`;

export const SubHeadWrapHeading = styled.div`
  text-align: left;
  font-size: 20px;
  padding-left: 1.5%;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontRegular};
`;

export const TextLinesData = styled.div`
  padding-left: 30px;
  margin-left: 30px;
  text-align: justify;
`;
