import React from "react";
import {
  CardWrapper,
  HeadingContainer,
  Header3,
  Center,
} from "../../../styles/component/style";
import { MessageContainer } from "./Styles";
import { ResentTimer } from "../../common/ResendTimer/ResendTimer";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { useEffect } from "react";
import AuthService from "../../../services/AuthService";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import { navigate } from "@reach/router";
import useUser from "../../../hooks/useUser";
import useLoader from "../../../hooks/useLoader";

export const AccountVerification = ({
  heading = "Validate your Email (4/4)",
  timer = 60,
  message = `Hey user as the final step of becoming part of JustAct we want you to verify your email.
  Please login to your email ID and click on the verification Link we sent in order to verify`,
  triggerResent = () => {},
  location,
  email_token,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setToken, userObject } = useUser();
  const { setLoader } = useLoader();

  const resendOtp = () => {
    triggerResent();
  };

  useEffect(() => {
    if (userObject?.emailVerified && !email_token) {
      navigate("/dashboard");
    }
  }, [userObject, email_token]);

  useEffect(() => {
    async function validateEmail() {
      try {
        setLoader({ state: true, message: "Verifying your email..." });
        const body = { emailToken: email_token };
        const response = await AuthService.validateEmail(body);
        if (response && response.token) {
          enqueueSnackbar(response.message, {
            variant: "success",
          });
          setToken(response.token);
          navigate("/dashboard/welcome");
        } else {
          enqueueSnackbar(response.message, {
            variant: "error",
          });
          navigate("/login");
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
        navigate("/login");
      } finally {
        setLoader({ state: false });
      }
    }
    if (email_token) {
      validateEmail();
      localStorage.setItem("auth_token", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email_token]);

  return (
    <CardWrapper>
      <HeadingContainer>
        <Header3>{heading}</Header3>
      </HeadingContainer>
      {!email_token ? (
        <>
          <MessageContainer>{message}</MessageContainer>
          <div className="pt10">
            <Center style={{ paddingBottom: 0, textAlign: "center" }}>
              <ResentTimer
                timer={timer}
                message={`Didn’t receive verification email?`}
                resendOtp={resendOtp}
              />
            </Center>
          </div>
        </>
      ) : (
        <Flex>
          <LoadingText>Verifying your Email...</LoadingText>
          <CircularProgress style={{ color: COLORS.BTN_GREEN }} />
        </Flex>
      )}
    </CardWrapper>
  );
};

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
`;

const LoadingText = styled.span`
  margin-right: 25px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.COLOR_DARK};
`;
